import React, { useState } from "react";
import { Box, Typography, Paper, Container } from "@mui/material";
import iCleanDashboard from "../../assets/images/clean-dashboard.png";
import gallonUsage from "../../assets/images/clean-dashboard-gallons.png";
import dashMap from "../../assets/images/dashboard-map.png";
import deviceManagement from "../../assets/images/device-management.png";
import gallonToggle from "../../assets/images/more-options.png";

const DistributorTab = () => {
  return (
    <Container
      sx={{
        padding: "16px 0px",
        "& > *": { padding: "4px 0" },
      }}
    >
      <Typography variant="h4" fontWeight="bold">
        Distributor Portal
      </Typography>
      <Typography variant="body1">
        The Distributor Portal allows you to manage distributors, view customer
        data, and track orders. Here you can manage your network and device
        performance data.
      </Typography>

      {/* Image placeholder for Dashboard Overview */}
      <img
        src={iCleanDashboard}
        alt="Dashboard Overview"
        style={{
          width: "80%",
          height: "auto",
          marginTop: "20px",
          marginBottom: "20px",
          marginLeft: "10%",
          borderRadius: "5px",
        }}
      />

      <Typography variant="h6" fontWeight="bold">
        Dashboard Overview
      </Typography>
      <Typography variant="body1" paragraph>
        The dashboard provides a comprehensive visualization of device usage,
        including location, ping status, and cartridge health. You can see
        device activity through bar and chart graphs, and track metrics like
        total and average usage.
      </Typography>

      <Typography variant="h6" fontWeight="bold">
        Device Usage Charts
      </Typography>
      <Typography variant="body1" paragraph>
        The usage charts display when the device is most active during the day,
        allowing you to identify peak usage times. This helps in understanding
        device patterns and optimizing operations.
      </Typography>

      {/* Image placeholder for Gallons/Liters Toggle */}
      <Box display="flex" justifyContent="center">
        <img
          src={gallonUsage}
          alt="Gallons/Liters Toggle"
          style={{
            width: "40%",
            height: "auto",
            marginBottom: "20px",
            marginLeft: "5%",
            borderRadius: "5px",
          }}
        />
        <img
          src={gallonToggle}
          alt="Gallons/Liters Toggle"
          style={{
            width: "40%",
            height: "fit-content",
            marginBottom: "20px",
            borderRadius: "5px",
            marginLeft: "5%",
          }}
        />
      </Box>

      <Typography variant="h6" fontWeight="bold">
        Imperial/Metric Toggle
      </Typography>
      <Typography variant="body1" paragraph>
        You can choose to display data in the imperial system (gallons) or the
        metric system (liters), depending on your preference. This toggle is
        easily accessible on the dashboard.
      </Typography>

      <Typography variant="h6" fontWeight="bold">
        Reports and Sustainability
      </Typography>
      <Typography variant="body1" paragraph>
        You can generate daily, weekly, or monthly reports on device usage right
        from the dashboard. These reports can be exported in either liters or
        gallons. Additionally, you can generate sustainability reports to
        measure environmental impact. For more comprehensive reports, you can
        also generate reports from the report section, save, view, and download
        them.
      </Typography>

      <Typography variant="body1" paragraph>
        Use the dashboard's date picker to adjust the time period for reports
        and view data for specific ranges. This helps in tracking trends and
        making data-driven decisions for your distribution network.
      </Typography>

      {/* Image placeholder for Map View */}
      <img
        src={dashMap}
        alt="Device Map View"
        style={{
          width: "70%",

          height: "auto",
          marginBottom: "20px",
          marginLeft: "15%",
          borderRadius: "5px",
        }}
      />

      <Typography variant="h6" fontWeight="bold">
        Device Map View
      </Typography>
      <Typography variant="body1" paragraph>
        The map feature displays all devices based on their geographic location.
        You can also check whether devices are online or offline at a glance.
        This feature ensures you are always aware of your network's status.
      </Typography>

      <Typography variant="h6" fontWeight="bold">
        Device Management
      </Typography>
      <Typography variant="body1" paragraph>
        The device management feature is a powerful tool that allows you to
        manage your devices and their settings, logs, errors, replacement
        history, location, and more.
      </Typography>

      {/* Image placeholder for Device Management */}
      <img
        src={deviceManagement}
        alt="Device Management"
        style={{
          width: "70%",
          height: "auto",
          marginBottom: "20px",
          marginLeft: "15%",
          borderRadius: "5px",
        }}
      />
    </Container>
  );
};

export default DistributorTab;
