import React, { useState, useMemo, useEffect } from "react";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Checkbox,
  Tooltip,
  Chip,
  SelectChangeEvent,
  Typography,
  MenuItem,
  Select,
  FormControl,
  Alert,
  Snackbar,
  AlertColor,
  TextField,
  useTheme,
  Menu,
  Dialog,
  DialogTitle,
  DialogActions,
  useMediaQuery,
} from "@mui/material";
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  AddCircle,
} from "@mui/icons-material";
import {
  SalesData,
  ProductType,
  AccountsData,
} from "../../common/types/DistributorSalesTypes";
import {
  fetchSalesByDistributorEmail,
  fetchAccountsByDistributorEmail,
  deleteSalesReport,
} from "../../services/distributor.service";
import useAuthStore from "../../store/authStore";
import AddSalesReportModal from "./AddSalesModal";
import UploadCsvModal from "./UploadSalesCsvModal";
import UploadFile from "@mui/icons-material/UploadFile";
import StyledButton from "../Tabs/StyledButton";
import StyledIconButton from "../Tabs/StyledIconButton";
import { StyledTabs } from "../Tabs/IconTab";
import PlainTab from "../Tabs/PlainTab";
import GenerateSalesReport from "./GenerateSalesReport";
import SalesReportHistory from "./SalesReportHistory";
import EditSalesModal from "./EditSalesModal";

const productTypeColors: { [key: string]: string } = {
  NONE: "#e0e0e0",
  "SAO 4": "#d1c4e9",
  "SAO 24": "#c5cae9",
  "Lotus Pro": "#bbdefb",
  "IClean Mini": "#b2dfdb",
  "Iclean Pro": "#b3e5fc",
};

const accountTypeColors: { [key: string]: string } = {
  ISS: "#0057A7",
  Sodexo: "#ef5350",
  FoodBuy: "#6d4c41",
  CushmanWakefield: "#ab47bc",
  Frasers: "#827717",
  WeWork: "#2e7d32",
  JLL: "#00695c",
  McDonalds: "#f06292",
  Accor: "#607d8b",
  NonGlobalAccount: "#424242",
};

const DistributorSalesReporting: React.FC<{}> = () => {
  const [selected, setSelected] = useState<string[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [typeFilter, setTypeFilter] = useState<string | null>(null);
  const [productFilter, setProductFilter] = useState<string | null>(null);
  const [sales, setSales] = useState<SalesData[]>([]);
  const [selectedSales, setSelectedSales] = useState<SalesData | null>(null);
  const [openModal, setOpenModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [selectedDeleteReport, setSelectedDeleteReport] =
    useState<SalesData | null>(null);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [selectedTab, setSelectedTab] = useState(0);
  const [isMultipleEntries, setIsMultipleEntries] = useState(false);
  const [accounts, setAccounts] = useState<AccountsData[]>([]);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md")); // Check for mobile screen size

  const [snackbar, setSnackbar] = useState<{
    open: boolean;
    message: string;
    severity: AlertColor | undefined;
  }>({
    open: false,
    message: "",
    severity: undefined,
  });

  const { user } = useAuthStore();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget); // Open the menu
  };

  const handleClose = () => {
    setAnchorEl(null); // Close the menu
  };

  const handleSingleEntry = () => {
    handleClose();
    setIsMultipleEntries(false);
    setOpenModal(true); // Logic for adding a single entry
  };

  const handleMultipleEntries = () => {
    handleClose();
    setIsMultipleEntries(true);
    setOpenModal(true); // Logic for adding multiple entries
  };

  useEffect(() => {
    if (user) {
      fetchSales();
      fetchAccounts();
    }
  }, [user]);

  const fetchAccounts = async () => {
    try {
      const fetchedAccounts = await fetchAccountsByDistributorEmail(
        user?.email || ""
      );
      // remove duplicate accounts
      const uniqueAccounts = fetchedAccounts.filter(
        (account, index, self) =>
          index === self.findIndex((t) => t.accountName === account.accountName)
      );
      setAccounts(uniqueAccounts);
    } catch (error) {
      console.error("Error fetching accounts:", error);
    }
  };

  const fetchSales = async () => {
    if (user) {
      const salesData = await fetchSalesByDistributorEmail(
        user.email,
        user.role
      );
      setSales(salesData); // Correctly setting the sales data
    }
  };

  const handleAddReportSuccess = async () => {
    try {
      // Re-fetch the sales data after adding a new report
      await fetchSales(); // This function should fetch the latest data from your API
      await fetchAccounts();
      setSnackbar({
        open: true,
        message: "Report added successfully",
        severity: "success",
      });
    } catch (error) {
      console.error("Failed to fetch updated sales data:", error);
    }
  };

  const handleBulkAddReportSuccess = async () => {
    try {
      // Re-fetch the sales data after adding a new report
      await fetchSales(); // This function should fetch the latest data from your API
      setSnackbar({
        open: true,
        message: "Reports added successfully",
        severity: "success",
      });
    } catch (error) {
      console.error("Failed to fetch updated sales data:", error);
    }
  };

  const handleEditReportSuccess = async () => {
    try {
      // Re-fetch the sales data after editing a report
      await fetchSales(); // This function should fetch the latest data from your API
      await fetchAccounts();
      setSnackbar({
        open: true,
        message: "Report edited successfully",
        severity: "success",
      });
    } catch (error) {
      console.error("Failed to fetch updated sales data:", error);
    }
  };

  const handleAddReportError = (message: string) => {
    console.error("Failed to add report:", message);
    setSnackbar({
      open: true,
      message,
      severity: "error",
    });
  };

  const handleEditReportError = (message: string) => {
    console.error("Failed to edit report:", message);
    setSnackbar({
      open: true,
      message,
      severity: "error",
    });
  };

  const handleDeleteReport = async () => {
    if (selectedDeleteReport?._id) {
      try {
        await deleteSalesReport(selectedDeleteReport._id);
        setSnackbar({
          open: true,
          message: "Report deleted successfully",
          severity: "success",
        });
        await fetchSales(); // Refresh the sales data
      } catch (error) {
        console.error("Failed to delete report:", error);
        setSnackbar({
          open: true,
          message: "Failed to delete report",
          severity: "error",
        });
      } finally {
        setOpenDeleteDialog(false);
        setSelectedDeleteReport(null);
      }
    }
  };

  const handleDeleteClick = (report: SalesData) => {
    setSelectedDeleteReport(report);
    setOpenDeleteDialog(true);
  };

  // Extract unique account types from sales data
  const availableTypes = Array.from(
    new Set(sales.map((sale) => sale.account.accountType).filter(Boolean))
  );

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setSelected(
        sales
          .map((row) => row._id)
          .filter((id): id is string => id !== undefined)
      );
    } else {
      setSelected([]);
    }
  };

  const handleSubmit = () => {
    setSnackbar({
      open: true,
      message: "Report generated successfully",
      severity: "success",
    });
  };

  const handleSelect = (id: string) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = [...selected, id];
    } else {
      newSelected = selected.filter((itemId) => itemId !== id);
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const isSelected = (id: string) => selected.includes(id);

  const handleFilterChange = (event: SelectChangeEvent<string>) => {
    // TODO: if account type is changed, filter by account type
    if (event.target.name === "product") {
      setProductFilter(
        event.target.value === "All" ? null : event.target.value
      );
    } else if (event.target.name === "type") {
      setTypeFilter(event.target.value === "All" ? null : event.target.value);
    }
  };

  const filteredSales = useMemo(() => {
    return sales.filter(
      (sale) =>
        (!typeFilter || sale.account.accountType === typeFilter) &&
        (!productFilter || sale.product === productFilter) &&
        // Add search functionality
        (sale.product.toLowerCase().includes(searchTerm.toLowerCase()) ||
          sale.account.accountEmail
            .toLowerCase()
            .includes(searchTerm.toLowerCase()) ||
          sale.account.siteContact
            .toLowerCase()
            .includes(searchTerm.toLowerCase()) ||
          sale.account.accountType
            .toLowerCase()
            .includes(searchTerm.toLowerCase()) ||
          sale.account.accountName
            .toLowerCase()
            .includes(searchTerm.toLowerCase()))
    );
  }, [sales, typeFilter, productFilter, searchTerm]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  return (
    <Box sx={{ p: 0 }}>
      <Box mt={0} sx={{ display: "flex", justifyContent: "center" }}>
        <Box
          mt={2}
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            width: { xs: "95%", md: "96%" },
          }}
        >
          <Typography sx={{ mb: 2 }} variant="h6">
            Distributor Monthly Sales Reporting
          </Typography>
          {user?.role === "Admin" && (
            <StyledTabs
              value={selectedTab}
              onChange={handleChangeTab}
              aria-label="Profile tabs"
              variant="scrollable" // Enable scrolling
              scrollButtons="auto" // Enable auto scrolling
            >
              {/* <PlainTab label="Overview" /> */}
              <PlainTab label="Sales" />
              <PlainTab label="Generate Report" />
              <PlainTab label="History" />
            </StyledTabs>
          )}

          {selectedTab === 0 && (
            <Box>
              <Box
                sx={{
                  display: isMobile ? "flex-column" : "flex",
                  justifyContent: "space-between",
                  alignItems: "start",
                  p: 0,
                  mt: 2,
                }}
              >
                {/* Search input field */}
                <TextField
                  variant="outlined"
                  placeholder="Search..."
                  size="small"
                  value={searchTerm}
                  onChange={handleSearchChange}
                  sx={{
                    width: isMobile ? "100%" : "300px",
                    height: "28px",
                    borderRadius: 1,
                    backgroundColor: "#f5f5f5",
                    marginBottom: isMobile ? 3 : 0,
                  }}
                />
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "right",
                    gap: 1.5,
                    mb: 2,
                  }}
                >
                  <Paper
                    elevation={0}
                    sx={{
                      height: isMobile ? "42px" : "28px",
                      border: "1px solid #e0e0e0",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      px: 1,
                    }}
                  >
                    <Typography
                      variant="subtitle2"
                      sx={{
                        fontWeight: 700,
                      }}
                    >
                      TOTAL SALES :{" "}
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      sx={{
                        fontWeight: 700,
                        fontFamily: "Montserrat, sans-serif",
                      }}
                    >
                      {" "}
                      {sales.length}
                    </Typography>
                  </Paper>
                  <Tooltip title="Add New Sales" placement="bottom">
                    <>
                      <StyledButton
                        icon={<AddCircle />}
                        iconColor={theme.palette.primary.main}
                        sx={{ height: isMobile ? "43px" : "28px" }}
                        onClick={handleClick} // Open the dropdown menu
                      >
                        Add Sales
                      </StyledButton>

                      {/* Dropdown Menu */}
                      <Menu
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "left",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                      >
                        <MenuItem onClick={handleSingleEntry}>
                          Add a Single Entry
                        </MenuItem>
                        <MenuItem onClick={handleMultipleEntries}>
                          Add Multiple Entries
                        </MenuItem>
                      </Menu>
                    </>
                  </Tooltip>
                  <Tooltip title="Upload Sales Report" placement="bottom">
                    <StyledButton
                      icon={<UploadFile />}
                      iconColor={theme.palette.primary.main}
                      sx={{ height: isMobile ? "43px" : "28px" }}
                      onClick={() => setOpenUploadModal(true)}
                    >
                      Upload CSV
                    </StyledButton>
                  </Tooltip>
                </Box>
              </Box>
              <Paper sx={{ width: "100%", overflow: "hidden" }}>
                <TableContainer>
                  <Table stickyHeader aria-label="device table">
                    <TableHead>
                      <TableRow>
                        <TableCell padding="checkbox">
                          <Checkbox
                            indeterminate={
                              selected.length > 0 &&
                              selected.length < sales.length
                            }
                            checked={
                              sales.length > 0 &&
                              selected.length === sales.length
                            }
                            onChange={handleSelectAll}
                          />
                        </TableCell>

                        <TableCell>
                          <FormControl
                            variant="standard"
                            sx={{
                              "& .MuiInputBase-root": {
                                border: "none",
                                boxShadow: "none",
                              },
                              "& .MuiInputBase-root:before": {
                                borderBottom: "none",
                              },
                              "& .MuiInputBase-root:after": {
                                borderBottom: "none",
                              },
                            }}
                          >
                            <Select
                              value={typeFilter || "All"}
                              name="type"
                              onChange={handleFilterChange}
                              displayEmpty
                            >
                              <MenuItem id="accountType" value="All">
                                Account Type
                              </MenuItem>
                              {availableTypes.map((type) => (
                                <MenuItem key={type} value={type}>
                                  {type}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </TableCell>

                        <TableCell>Account Name</TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell>Phone</TableCell>
                        <TableCell> Address</TableCell>
                        <TableCell>Site Contact</TableCell>

                        <TableCell>
                          <FormControl
                            variant="standard"
                            sx={{
                              "& .MuiInputBase-root": {
                                border: "none",
                                boxShadow: "none",
                              },
                              "& .MuiInputBase-root:before": {
                                borderBottom: "none",
                              },
                              "& .MuiInputBase-root:after": {
                                borderBottom: "none",
                              },
                            }}
                          >
                            <Select
                              value={productFilter || "All"}
                              name="product"
                              onChange={handleFilterChange}
                              displayEmpty
                            >
                              <MenuItem id="product" value="All">
                                Product
                              </MenuItem>
                              {Object.values(ProductType)
                                .filter(
                                  (product) => product !== ProductType.NONE
                                )
                                .map((product: string) => (
                                  <MenuItem key={product} value={product}>
                                    {product}
                                  </MenuItem>
                                ))}
                            </Select>
                          </FormControl>
                        </TableCell>

                        <TableCell>Date</TableCell>
                        <TableCell>Notes</TableCell>
                        <TableCell align="center">Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {filteredSales
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row) => {
                          const isItemSelected = isSelected(row._id || "");
                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              aria-checked={isItemSelected}
                              tabIndex={-1}
                              key={row._id}
                              selected={isItemSelected}
                            >
                              <TableCell padding="checkbox">
                                <Checkbox
                                  checked={isItemSelected}
                                  onChange={() => handleSelect(row._id || "")}
                                />
                              </TableCell>
                              <TableCell>
                                <Chip
                                  label={row.account.accountType}
                                  size="small"
                                  style={{
                                    backgroundColor:
                                      accountTypeColors[
                                        row.account.accountType
                                      ],
                                    color: "white",
                                  }}
                                />
                              </TableCell>
                              <TableCell>{row.account.accountName}</TableCell>
                              <TableCell>{row.account.accountEmail}</TableCell>
                              <TableCell>{row.account.accountPhone}</TableCell>
                              <TableCell>
                                {row.account.accountAddress}
                              </TableCell>
                              <TableCell>{row.account.siteContact}</TableCell>
                              <TableCell>
                                <Chip
                                  label={row.product}
                                  size="small"
                                  sx={{
                                    fontSize: 14,
                                    backgroundColor: ` ${
                                      productTypeColors[row.product] ||
                                      "#e0e0e0"
                                    }`,
                                  }}
                                />
                              </TableCell>
                              <TableCell>
                                {row.date
                                  ? new Date(row.date).toLocaleString()
                                  : "N/A"}
                              </TableCell>
                              <TableCell>{row.notes}</TableCell>
                              <TableCell align="center">
                                <Tooltip title="Edit">
                                  <StyledIconButton
                                    variantType="transparent"
                                    onClick={() => {
                                      setSelectedSales(row);
                                      setOpenEditModal(true);
                                    }}
                                  >
                                    <EditIcon />
                                  </StyledIconButton>
                                </Tooltip>
                                <Tooltip title="Delete">
                                  <StyledIconButton
                                    variantType="transparent"
                                    onClick={() => handleDeleteClick(row)}
                                  >
                                    <DeleteIcon />
                                  </StyledIconButton>
                                </Tooltip>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={filteredSales.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Paper>
            </Box>
          )}

          {selectedTab === 1 && user?.role === "Admin" && (
            <GenerateSalesReport
              sales={filteredSales}
              onSubmit={handleSubmit}
            />
          )}

          {selectedTab === 2 && user?.role === "Admin" && (
            <SalesReportHistory />
          )}
        </Box>
      </Box>
      {/* Add Sales Report Modal */}
      <AddSalesReportModal
        open={openModal}
        isMultipleEntries={isMultipleEntries}
        onClose={() => setOpenModal(false)}
        onSuccess={handleAddReportSuccess}
        onError={handleAddReportError}
        accounts={accounts}
        distributorEmail={user?.email || ""}
      />{" "}
      {/* Edit Sales Report Modal */}
      {selectedSales && (
        <EditSalesModal
          open={openEditModal}
          onClose={() => setOpenEditModal(false)}
          onSuccess={handleEditReportSuccess}
          onError={handleEditReportError}
          salesReport={selectedSales}
          accounts={accounts}
        />
      )}
      {/* Upload Sales Report Modal */}
      <UploadCsvModal
        open={openUploadModal} // control the modal open state
        onClose={() => setOpenUploadModal(false)}
        onUploadSuccess={(csvData: SalesData[], errorMessage?: string) => {
          if (errorMessage) {
            setSnackbar({
              open: true,
              message: errorMessage,
              severity: "error",
            });
          } else {
            handleBulkAddReportSuccess();
          }
        }}
        distributorEmail={user?.email || ""}
      />
      {/* Delete Sales Report Dialog */}
      <Dialog
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <Typography sx={{ px: 3, pt: 1 }}>
          Are you sure you want to delete this report?
        </Typography>
        <DialogActions>
          <StyledButton
            onClick={() => setOpenDeleteDialog(false)}
            variantType="outline-primary"
          >
            Cancel
          </StyledButton>
          <StyledButton onClick={handleDeleteReport} variantType="destructive">
            Delete
          </StyledButton>
        </DialogActions>
      </Dialog>
      {/* Snackbar */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default DistributorSalesReporting;
