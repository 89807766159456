import {
  Box,
  Typography,
  Container,
  Link,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import IDHomePage from "../../assets/images/id-home-page.png";
import IDClickNewDevice from "../../assets/images/id-click-new-device.png";
import IDDeviceInfo from "../../assets/images/id-device-info.png";
import IDDeviceLocation from "../../assets/images/id-device-location.png";
import IDWifi from "../../assets/images/id-wifi.png";
import IDDone from "../../assets/images/id-done.png";

interface GettingStartedTabProps {
  handleTabChange: (event: React.SyntheticEvent, newValue: string) => void;
}

const GettingStartedTab: React.FC<GettingStartedTabProps> = ({
  handleTabChange,
}) => {
  return (
    <Container
      sx={{
        padding: "16px 0px",
        color: "#707070",
        "& > *": { padding: "4px 0" },
      }}
    >
      <Typography variant="h4" fontWeight="bold">
        Welcome to Tersano's Getting Started Guide
      </Typography>

      <Typography variant="body1" paragraph>
        Welcome to Tersano's state-of-the-art solution for making cleaning
        safer, more sustainable, and incredibly efficient. Whether you're a
        distributor, installer, or customer, our platform provides the tools you
        need to monitor, manage, and optimize your cleaning processes in real
        time. Let’s walk you through how to get the most out of our system and
        ensure that you're set up for success.
      </Typography>

      <Typography variant="h6" fontWeight="bold">
        Why Tersano?
      </Typography>
      <Typography variant="body1" paragraph>
        At Tersano, we revolutionize cleaning by harnessing the power of
        innovative, IoT-enabled technologies. Our devices, from advanced
        dispensers to eco-friendly filters, empower businesses to manage
        cleaning with precision and confidence. With real-time data at your
        fingertips, you can track usage, monitor performance, and respond to
        maintenance needs—all from our easy-to-use dashboard.
      </Typography>

      <Typography variant="h6" fontWeight="bold">
        What is IoT and How Does It Work at Tersano?
      </Typography>
      <Typography variant="body1" paragraph>
        The Internet of Things (IoT) is the backbone of Tersano’s ecosystem. Our
        IoT-connected cleaning systems continuously send data to the cloud,
        enabling real-time monitoring of device performance, cleaning schedules,
        and efficiency. Whether you're managing one device or hundreds, the
        Tersano dashboard offers seamless oversight, ensuring that no area is
        overlooked and that every action is as efficient as possible.
      </Typography>
      <Typography variant="body1" paragraph>
        Our system helps ensure that each dispenser is performing at its best,
        minimizing downtime, optimizing maintenance schedules, and providing you
        with powerful insights that drive smarter decisions for your business.
      </Typography>

      <Typography variant="h6" fontWeight="bold">
        How to Navigate the Dashboard
      </Typography>
      <Typography variant="body1" paragraph>
        The Tersano dashboard is designed to provide a comprehensive view of
        your entire cleaning operation. From real-time alerts and notifications
        to detailed usage statistics and device health updates, the dashboard is
        your hub for controlling and optimizing your cleaning processes.
      </Typography>

      <ul>
        <li>
          <Typography variant="body1">
            <Link
              onClick={(e) => {
                e.preventDefault();
                handleTabChange(e, "distributor-portal");
              }}
              href="#"
            >
              Are you a Distributor? Click here to access the Distributor
              Portal.
            </Link>
          </Typography>
        </li>
        <li>
          <Typography variant="body1">
            <Link
              onClick={(e) => {
                e.preventDefault();
                handleTabChange(e, "installer-portal");
              }}
              href="#"
            >
              Are you an Installer? Click here to access the Installer Portal.
            </Link>
          </Typography>
        </li>
        <li>
          <Typography variant="body1">
            <Link
              onClick={(e) => {
                e.preventDefault();
                handleTabChange(e, "customer-portal");
              }}
              href="#"
            >
              Are you a Customer? Click here to access the Customer Portal.
            </Link>
          </Typography>
        </li>
      </ul>

      <Typography variant="body1" paragraph>
        No matter your role, our platform offers the insights and control you
        need to achieve optimal cleaning results while reducing environmental
        impact. Let's get started on creating a cleaner, greener future with
        Tersano.
      </Typography>

      {/* Install New Device List */}
      <Typography variant="h6" fontWeight="bold">
        Steps to Install a New Device
      </Typography>

      <Typography variant="body1" paragraph>
        Follow this guide to install a new device to your account.
      </Typography>

      <List>
        {/* Step 1 */}
        <ListItem>
          <ListItemText
            primary={
              <Typography variant="body1" fontWeight="bold">
                1. Open the Hamburger Menu and Select the <i>Install Device</i>{" "}
                Tab
              </Typography>
            }
          />
        </ListItem>
        <Box
          component="img"
          src={IDHomePage}
          alt="Install Device Tab"
          width="100%"
          mb={2}
        />

        {/* Step 2 */}
        <ListItem>
          <ListItemText
            primary={
              <Typography variant="body1" fontWeight="bold">
                2. Click on <i>New Device</i>
              </Typography>
            }
            secondary={
              <Typography>
                Select <i>New Device</i> to register a new unit on the portal,
                then click <strong>Next</strong>.
              </Typography>
            }
          />
        </ListItem>
        <Box
          component="img"
          src={IDClickNewDevice}
          alt="New Device Registration"
          width="100%"
          mb={2}
        />

        {/* Step 3 */}
        <ListItem>
          <ListItemText
            primary={
              <Typography variant="body1" fontWeight="bold">
                3. Enter Device Information
              </Typography>
            }
            secondary={
              <Typography>
                Provide the following details:
                <ul>
                  <li>Serial Number</li>
                  <li>Customer company name</li>
                  <li>Customer email</li>
                  <li>Installation cartridge type</li>
                </ul>
                Once completed, click <strong>Next</strong>.
              </Typography>
            }
          />
        </ListItem>
        <Box
          component="img"
          src={IDDeviceInfo}
          alt="Device Information"
          width="100%"
          mb={2}
        />

        {/* Step 4 */}
        <ListItem>
          <ListItemText
            primary={
              <Typography variant="body1" fontWeight="bold">
                4. Enter Device Location
              </Typography>
            }
            secondary={
              <Typography>
                Fill in the location details of the device, then click{" "}
                <strong>Submit</strong>.
              </Typography>
            }
          />
        </ListItem>
        <Box
          component="img"
          src={IDDeviceLocation}
          alt="Device Location"
          width="100%"
          mb={2}
        />

        {/* Step 5 */}
        <ListItem>
          <ListItemText
            primary={
              <Typography variant="body1" fontWeight="bold">
                5. Connect Device to WiFi
              </Typography>
            }
            secondary={
              <Typography>
                After successfully registering the device, follow these steps to
                connect the device to WiFi:
                <ul>
                  <li>
                    Click on the WiFi button to begin the connection process.
                  </li>
                  <li>
                    Open the WiFi settings on your phone, laptop, or tablet.
                  </li>
                  <li>
                    Search for the unit named <i>LotusPro-(SerialNumber)</i>.
                  </li>
                  <li>
                    Connect to the unit using the password{" "}
                    <code>123456789</code>.
                  </li>
                  <li>
                    Open a browser and go to{" "}
                    <a
                      href="http://tersano.local"
                      target="_blank"
                      rel="noreferrer"
                    >
                      tersano.local
                    </a>{" "}
                    or type <code>192.168.4.1</code>.
                  </li>
                  <li>
                    Follow the instructions to connect the device to your WiFi
                    network. Once connected, wait 6-10 seconds, then click{" "}
                    <strong>Next</strong>.
                  </li>
                </ul>
              </Typography>
            }
          />
        </ListItem>
        <Box
          component="img"
          src={IDWifi}
          alt="Connect to WiFi"
          width="100%"
          mb={2}
        />

        {/* Step 6 */}
        <ListItem>
          <ListItemText
            primary={
              <Typography variant="body1" fontWeight="bold">
                6. Device Setup Complete
              </Typography>
            }
            secondary={
              <Typography>
                Once the device is connected to WiFi, you will see a
                confirmation message. Click <strong>Finish</strong> to complete
                the setup.
              </Typography>
            }
          />
        </ListItem>
        <Box
          component="img"
          src={IDDone}
          alt="Device Setup Complete"
          width="100%"
          mb={2}
        />
      </List>

      <Typography variant="h6" color="primary">
        Congratulations! Your device has been successfully installed.
      </Typography>
    </Container>
  );
};

export default GettingStartedTab;
