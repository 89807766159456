// Create a Report (Send generated report data to the backend)
import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;

// Get Report by ID (Fetch a specific report by its ID)
export const getReportById = async (reportId: string) => {
  try {
    const response = await axios.get(`${API_URL}/reports/${reportId}`);
    return response.data; // Assuming the server responds with the report data
  } catch (error) {
    console.error("Error fetching report by ID:", error);
    throw new Error("Failed to fetch report");
  }
};

// Get All Reports (Fetch all reports)
export const getAllReports = async (email: string, role: string) => {
  try {
    const response = await axios.get(`${API_URL}/reports`, {
      params: { email, role },
    });
    return response.data; // Assuming the server responds with an array of reports
  } catch (error) {
    console.error("Error fetching all reports:", error);
    throw new Error("Failed to fetch reports");
  }
};

// Update Report Data (Update a specific report's data)
export const updateReportData = async (reportId: string, data: any) => {
  try {
    const response = await axios.put(`${API_URL}/reports/${reportId}/data`, {
      data,
    });
    return response.data; // Assuming the server responds with the updated report data
  } catch (error) {
    console.error("Error updating report data:", error);
    throw new Error("Failed to update report data");
  }
};

export const createReport = async (
  name: string,
  description: string,
  reportDate: Date,
  deviceIds: any,
  generatedBy: string,
  reportData: any,
  reportType: string,
  startDate?: Date,
  endDate?: Date
) => {
  try {
    const response = await axios.post(`${API_URL}/reports/`, {
      name,
      description,
      reportDate,
      deviceIds,
      generatedBy,
      reportData,
      reportType,
      startDate,
      endDate,
    });
    return response.data;
  } catch (error) {
    console.error("Error creating usage report:", error);
    throw error;
  }
};

export const createWarrantyReport = async (
  name: string,
  description: string,
  reportDate: Date,
  distributors: string[],
  generatedBy: string,
  reportData: any,
  reportType: string,
  startDate?: Date,
  endDate?: Date
) => {
  try {
    const response = await axios.post(`${API_URL}/reports/`, {
      name,
      description,
      reportDate,
      distributors,
      generatedBy,
      reportData,
      reportType,
      startDate,
      endDate,
    });
    return response.data;
  } catch (error) {
    console.error("Error creating usage report:", error);
    throw error;
  }
};

export const deleteReport = async (reportId: string) => {
  try {
    const response = await axios.post(`${API_URL}/reports/delete`, {
      reportId,
    });
    return response.data;
  } catch (error) {
    console.error("Error deleting device:", error);
    throw error;
  }
};

export interface CreateReportPayload {
  deviceIds?: string[];
  distributors?: string[];
  generatedBy: string;
  name: string;
  description?: string;
  reportDate: string;
  reportData: InventoryReportData[];
  reportType:
    | "Credit"
    | "Return"
    | "Invalid Credit"
    | "Recovered Parts"
    | "Used Parts"
    | "Total Inventory"
    | "Usage";
  startDate?: string;
  endDate?: string;
}
// Types for report data
export interface InventoryReportData {
  part: string;
  partNo: string;
  recoveredQuantity?: number;
  usedQuantity?: number;
  delta?: number;
}

// Create an inventory report
export const createInventoryReport = async (payload: CreateReportPayload) => {
  try {
    const response = await axios.post(`${API_URL}/reports/inventory`, payload);
    return response.data;
  } catch (error) {
    console.error("Error creating inventory report:", error);
    throw error;
  }
};

// Types for report data specific to SalesReport
export interface SalesReportData {
  countryName: string;
  spend: string;
  bonus: string;
  quarters: {
    Q1: number;
    Q2: number;
    Q3: number;
    Q4: number;
  };
}

// Payload for creating a sales report
export interface CreateSalesReportPayload {
  generatedBy: string;
  name: string;
  description: string;
  reportDate: string;
  reportData: SalesReportData[];
  reportType: "ISS";
  startDate?: string;
  endDate?: string;
}

// Create a sales report
export const createSalesReport = async (payload: CreateSalesReportPayload) => {
  try {
    const response = await axios.post(`${API_URL}/reports/sales`, payload);
    return response.data;
  } catch (error) {
    console.error("Error creating sales report:", error);
    throw error;
  }
};
