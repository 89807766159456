// src/components/CreateDeviceTestModal.tsx
import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { createDeviceTest } from "../../services/device-test.service";
import useAuthStore from "../../store/authStore";
import StyledButton from "../Tabs/StyledButton";

interface Props {
  onClose: () => void;
  onCreate: () => void;
}

const CreateDeviceTestModal: React.FC<Props> = ({ onClose, onCreate }) => {
  const { user } = useAuthStore();

  const [formData, setFormData] = useState({
    deviceId: "",
    tester: user?.email || "",
    notes: "",
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleSubmit = async () => {
    await createDeviceTest({ ...formData, resultStatus: "In Progress" });
    onCreate();
    onClose();
  };

  return (
    <Dialog open={true} onClose={onClose}>
      <DialogTitle>Create Device Test</DialogTitle>
      <DialogContent>
        <TextField
          name="deviceId"
          label="Device ID"
          fullWidth
          margin="dense"
          onChange={handleChange}
        />
      </DialogContent>
      <DialogActions>
        <StyledButton onClick={onClose}>Cancel</StyledButton>
        <StyledButton onClick={handleSubmit} variantType="primary">
          Create Test
        </StyledButton>
      </DialogActions>
    </Dialog>
  );
};

export default CreateDeviceTestModal;
