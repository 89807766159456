import React, { useState, useEffect, useMemo } from "react";
import {
  Box,
  Typography,
  Chip,
  Snackbar,
  IconButton,
  Tooltip,
  TextField,
  Alert,
  AlertColor,
  Paper,
  Autocomplete,
  Stack,
  Grid,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemButton,
  ListItemSecondaryAction,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Checkbox,
} from "@mui/material";
import {
  Search as SearchIcon,
  MoreVert as MoreVertIcon,
  Delete as DeleteIcon,
  Add,
} from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import CreateNewCustomerModal from "./CreateNewCustomerModal";
import ViewToggle from "../Button/ViewToggle";
import { StyledDataGrid } from "../Grid/StyledDataGrid";
import useAuthStore from "../../store/authStore";
import CustomerCard from "./CustomerCard";
import { Customer } from "../../store/useCustomer";
import { CustomerColumns } from "./CustomerColumns";
import AssignToGroup from "./AssignToGroupModal";
import { fetchCustomersForUser } from "../../services/customer.service";
import { StyledTabs } from "../Tabs/IconTab";
import PlainTab from "../Tabs/PlainTab";
import StyledButton from "../Tabs/StyledButton";
import StyledIconButton from "../Tabs/StyledIconButton";

interface CustomerProps {
  onCustomerSelect?: (customer: Customer) => void;
}

const CustomerTable: React.FC<CustomerProps> = ({ onCustomerSelect }) => {
  const [customers, setCustomers] = useState<any[]>([]);
  const [selected, setSelected] = useState<string[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isEditingGroup, setIsEditingGroup] = useState(false);
  const { user } = useAuthStore.getState();
  const [modalOpen, setModalOpen] = useState(false);
  const [snackbar, setSnackbar] = useState<{
    open: boolean;
    message: string;
    severity: AlertColor | undefined;
  }>({ open: false, message: "", severity: undefined });
  const [view, setView] = useState("card"); // view state
  const [selectedGroup, setSelectedGroup] = useState<Customer | null>(null);
  const [groups, setGroups] = useState<Customer[]>([]);
  const [groupModalOpen, setGroupModalOpen] = useState(false); // State to control the modal visibility
  const [selectedTab, setSelectedTab] = useState(0);
  const [search, setSearch] = useState("");
  const [filterField, setFilterField] = useState("");
  const [filterValue, setFilterValue] = useState("");
  const [sortField, setSortField] = useState("");
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
    localStorage.setItem("selectedCustomerTableTab", newValue.toString());
  };

  // Fetch customers function
  const fetchCustomersData = async () => {
    try {
      const customers = await fetchCustomersForUser();
      if (customers && Array.isArray(customers)) {
        setCustomers(customers);
        groupCustomersByParent(customers); // Group customers by their parent group
      } else {
        setCustomers([]);
      }
    } catch (error) {
      console.error("Error fetching customers:", error);
      setCustomers([]); // Set an empty array on error
    }
  };

  useEffect(() => {
    fetchCustomersData(); // Fetch customers on component mount
  }, [groupModalOpen]);

  const groupCustomersByParent = (customers: any[]) => {
    // Separate group customers and regular customers
    const groupCustomers = customers.filter(
      (customer) => customer.role === "GroupCustomer",
    );
    const regularCustomers = customers.filter(
      (customer) => customer.role === "Customer",
    );

    // Create a map of groups
    const groupedData = groupCustomers.map((group) => {
      // Find all customers associated with the current group
      const groupMembers = regularCustomers.filter(
        (customer) => customer.customerGroup === group.email,
      );

      return {
        ...group,
        members: groupMembers, // Add the associated customers as members
      };
    });

    // Set the state with grouped data
    setGroups(groupedData);
  };

  const handleSnackbarChange = (snackbarData: {
    open: boolean;
    message: string;
    severity: AlertColor;
  }) => {
    setSnackbar(snackbarData);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = customers.map((customer) => customer._id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const isSelected = (name: string) => selected.includes(name);
  const handleOpenModal = () => setModalOpen(true);
  const handleCloseModal = () => {
    setModalOpen(false);
    fetchCustomersData();
  };

  const handleSelect = (name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = [...selected, name];
    } else {
      newSelected = selected.filter((id) => id !== name);
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleEditGroup = (group: Customer) => {
    setSelectedGroup(group); // Set the selected group as a customer

    // Calculate the customers that are already in the group
    const customersInGroup = customers.filter(
      (customer) => customer.customerGroup === group.email,
    );

    setGroupModalOpen(true);
    setIsEditingGroup(true);
  };

  const handleRowClick = (params: any) => {
    if (onCustomerSelect) {
      onCustomerSelect(params.row);
    }
  };

  const handleRowClickCard = (customer: any) => {
    if (onCustomerSelect) {
      onCustomerSelect(customer);
    }
  };

  const handleCustomerFilterChange = (
    event: React.ChangeEvent<{ value: unknown }>,
  ) => {
    setFilterField(event.target.value as string);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const nationalAccounts = useMemo(() => {
    return customers.filter((customer) => customer.role === "NationalAccount");
  }, [customers]);

  const globalAccounts = useMemo(() => {
    return customers.filter((customer) => customer.role === "GlobalAccount");
  }, [customers]);

  const filteredAndSortedCustomers = useMemo(() => {
    // Filter customers based on search, filter fields, and role
    const filtered = customers
      .filter((customer) => {
        // Apply role filter: this is where you filter by role, e.g., "Customer"
        if (selectedTab === 1 && customer.role !== "Customer") {
          return false; // Skip non-customer roles for this tab
        }

        // Apply search filter across multiple fields
        const searchTerm = search.toLowerCase();
        return (
          customer.firstName?.toLowerCase().includes(searchTerm) ||
          customer.lastName?.toLowerCase().includes(searchTerm) ||
          customer.email?.toLowerCase().includes(searchTerm) ||
          customer.phone?.toLowerCase().includes(searchTerm) ||
          customer.country?.toLowerCase().includes(searchTerm) ||
          customer.state?.toLowerCase().includes(searchTerm)
        );
      })
      .filter((customer) => {
        // Apply filter based on the selected filter field
        if (!filterField || !filterValue) return true;
        return customer[filterField]
          ?.toString()
          .toLowerCase()
          .includes(filterValue.toLowerCase());
      });

    // Sort filtered customers based on the selected sort field
    const sorted = [...filtered].sort((a, b) => {
      if (!sortField) return 0; // If no sort field is selected, return as is

      const fieldA = a[sortField]?.toString().toLowerCase() ?? "";
      const fieldB = b[sortField]?.toString().toLowerCase() ?? "";

      if (fieldA < fieldB) return -1;
      if (fieldA > fieldB) return 1;
      return 0;
    });

    return sorted;
  }, [customers, search, filterField, filterValue, sortField, selectedTab]);

  const filteredCustomers = useMemo(() => {
    return customers.filter((customer) => {
      // Apply group filter
      const isInSelectedGroup =
        !selectedGroup || // No group selected (show all customers)
        customer.customerGroup === selectedGroup.email; // Check if customer belongs to the selected group's email

      // Return true if customer satisfies the group filter
      return isInSelectedGroup;
    });
  }, [customers, selectedGroup]);

  // Handle when a group is selected
  const handleGroupSelect = (group: Customer | "All" | null) => {
    if (group === "All" || group === null) {
      setSelectedGroup(null); // Reset to show all customers
    } else {
      setSelectedGroup(group); // Update state with the selected Customer object
    }
  };

  const handleCloseGroupModal = () => {
    setGroupModalOpen(false); // Close the modal
    setIsEditingGroup(false); // Reset the editing state
  };

  const columns = [
    { id: "createdAt", label: "Created Time" },
    { id: "firstName", label: "First Name" },
    { id: "lastName", label: "Last Name" },
    { id: "country", label: "Country" },
    { id: "state", label: "State" },
    { id: "city", label: "City" },
    { id: "address", label: "Address" },
    { id: "phone", label: "Phone" },
    { id: "email", label: "Email" },
    { id: "status", label: "Status" },
  ];

  return (
    <Box p={0}>
      <Box mt={0} sx={{ display: "flex", justifyContent: "center" }}>
        <Box
          mt={2}
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            width: { xs: "95%", md: "96%" },
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              // borderBottom: 0.5,
              // borderColor: "divider",
              p: 0,
            }}
          >
            <Typography
              variant="h6"
              sx={{
                mb: 2,
                ml: 1,
              }}
            >
              Manage Your Customers
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "right",
                mr: 2,
                gap: 2,
              }}
            >
              {user &&
                [
                  "Admin",
                  "SuperAdmin",
                  "Installer",
                  "Distributor",
                  "SubDistributor",
                ].includes(user.role) && (
                  <ViewToggle view={view} setView={setView} />
                )}

              {user &&
                [
                  "Admin",
                  "SuperAdmin",
                  "Installer",
                  "Distributor",
                  "SubDistributor",
                ].includes(user.role) && (
                  <StyledButton
                    variantType="primary"
                    icon={<Add />}
                    onClick={handleOpenModal}
                  >
                    Add Customer
                  </StyledButton>
                )}

              {user && ["GroupCustomer"].includes(user.role) && (
                <StyledButton
                  variantType="primary"
                  icon={<Add />}
                  onClick={handleOpenModal}
                >
                  Add User
                </StyledButton>
              )}
            </Box>
          </Box>
          <CreateNewCustomerModal
            open={modalOpen}
            onClose={handleCloseModal}
            onSnackbarChange={handleSnackbarChange}
          //onSuccess={handleCustomerCreationSuccess}
          //onError={handleError}
          />
          <Snackbar
            open={snackbar.open}
            autoHideDuration={8000}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            onClose={() => setSnackbar({ ...snackbar, open: false })}
          >
            <Alert severity={snackbar.severity} sx={{ width: "100%" }}>
              {snackbar.message}
            </Alert>
          </Snackbar>
          <>
            <Box
              sx={{
                // p: 0.5,
                backgroundColor: "transparent",
                borderRadius: 1,
                border: "1px solid grey.800",
                mb: 2,
              }}
            >
              {" "}
              <StyledTabs
                value={selectedTab}
                onChange={handleTabChange}
                aria-label="Device tabs"
                variant="scrollable" // Enable scrolling
                scrollButtons="auto" // Enable auto scrolling
              >
                <PlainTab label="All" />

                {user &&
                  [
                    "Admin",
                    "SuperAdmin",
                    "Installer",
                    "Distributor",
                    "SubDistributor",
                  ].includes(user.role) && <PlainTab label="Customers" />}

                {user &&
                  [
                    "Admin",
                    "SuperAdmin",
                    "Installer",
                    "Distributor",
                    "SubDistributor",
                  ].includes(user.role) && <PlainTab label="Groups" />}

                {user && ["Admin", "SuperAdmin"].includes(user.role) && (
                  <PlainTab label="National Accounts" />
                )}
                {user && ["Admin", "SuperAdmin"].includes(user.role) && (
                  <PlainTab label="Global Accounts" />
                )}
              </StyledTabs>
            </Box>
            {selectedTab === 0 && (
              <>
                <TableContainer
                  component={Box}
                  sx={{
                    mx: "auto",
                    width: "100%",
                    maxWidth: "calc(100% - 20px)",
                    p: 1,
                    my: 1,
                    backgroundColor: "white",
                  }}
                >
                  {/* Search, Sort, and Filter Controls */}
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      p: 1.5,
                      backgroundColor: "#f5f5f5",
                      mb: 1,
                    }}
                  >
                    <Snackbar
                      open={snackbar.open}
                      autoHideDuration={8000}
                      anchorOrigin={{ vertical: "top", horizontal: "center" }}
                      onClose={() => setSnackbar({ ...snackbar, open: false })}
                    >
                      <Alert
                        severity={snackbar.severity}
                        sx={{ width: "100%" }}
                      >
                        {snackbar.message}
                      </Alert>
                    </Snackbar>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <TextField
                        variant="outlined"
                        size="small"
                        placeholder="Search"
                        value={search}
                        onChange={handleSearchChange}
                        sx={{ marginRight: 2, width: "300px" }}
                        InputProps={{
                          startAdornment: (
                            <SearchIcon
                              sx={{ marginRight: 1, color: "gray" }}
                            />
                          ),
                        }}
                      />
                      <FormControl
                        variant="outlined"
                        size="small"
                        sx={{ marginRight: 2, width: "150px" }}
                      >
                        <InputLabel>Sort By</InputLabel>
                        <Select
                          value={sortField}
                          onChange={(e) =>
                            setSortField(e.target.value as string)
                          }
                          label="Sort By"
                        >
                          <MenuItem value="">None</MenuItem>
                          {columns.map((column) => (
                            <MenuItem key={column.id} value={column.id}>
                              {column.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      {/* <FormControl
                        variant="outlined"
                        size="small"
                        sx={{ marginRight: 2, width: "150px" }}
                      >
                        <InputLabel>Filter By</InputLabel>
                        <Select
                          value={filterField}
                          onChange={(e) =>
                            setFilterField(e.target.value as string)
                          }
                          label="Filter By"
                        >
                          <MenuItem value="">None</MenuItem>
                          {columns.map((column) => (
                            <MenuItem key={column.id} value={column.id}>
                              {column.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      {filterField && (
                        <TextField
                          variant="outlined"
                          size="small"
                          placeholder={`Filter ${filterField}`}
                          value={filterValue}
                          onChange={handleCustomerFilterChange}
                          sx={{ marginRight: 2, width: "300px" }}
                        />
                      )} */}
                    </Box>
                  </Box>

                  {/* Selected Items and Actions */}
                  {selected.length > 1 && (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        alignContent: "center",
                        padding: 1,
                        backgroundColor: "#f5f5f5",
                        px: 2,
                      }}
                    >
                      <Typography>{selected.length} selected</Typography>
                      <Tooltip title="Delete Selected">
                        <StyledIconButton variantType="transparent">
                          <DeleteIcon />
                        </StyledIconButton>
                      </Tooltip>
                    </Box>
                  )}

                  {/* Data Table */}
                  <Table sx={{ minWidth: 650 }} aria-label="customer table">
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <Checkbox
                            indeterminate={
                              selected.length > 0 &&
                              selected.length < customers.length
                            }
                            checked={
                              customers.length > 0 &&
                              selected.length === customers.length
                            }
                            onChange={handleSelectAllClick}
                            inputProps={{
                              "aria-label": "select all customers",
                            }}
                          />
                        </TableCell>
                        {columns.map((column) => (
                          <TableCell key={column.id} align="left">
                            {column.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {filteredAndSortedCustomers
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage,
                        )
                        .map((customer) => {
                          const isItemSelected = isSelected(customer._id);
                          return (
                            <TableRow
                              hover
                              onClick={() => handleRowClickCard(customer)} // Click anywhere on the row to open details
                              role="checkbox"
                              aria-checked={isItemSelected}
                              tabIndex={-1}
                              key={customer._id}
                              selected={isItemSelected}
                            >
                              {/* Checkbox for selecting a customer */}
                              <TableCell
                                padding="checkbox"
                                onClick={(e) => e.stopPropagation()}
                              >
                                {" "}
                                {/* Stop propagation */}
                                <Checkbox
                                  color="primary"
                                  checked={isItemSelected}
                                  onChange={() => handleSelect(customer._id)} // Select customer
                                />
                              </TableCell>

                              {/* Other cells for customer details */}
                              <TableCell align="left">
                                {new Date(customer.createdAt).toLocaleString()}
                              </TableCell>
                              <TableCell align="left">
                                {customer.firstName}
                              </TableCell>
                              <TableCell align="left">
                                {customer.lastName}
                              </TableCell>
                              <TableCell align="left">
                                {customer.country}
                              </TableCell>
                              <TableCell align="left">
                                {customer.state}
                              </TableCell>
                              <TableCell align="left">
                                {customer.city}
                              </TableCell>
                              <TableCell align="left">
                                {customer.address}
                              </TableCell>
                              <TableCell align="left">
                                {customer.phone}
                              </TableCell>
                              <TableCell align="left">
                                {customer.email}
                              </TableCell>

                              {/* Chip indicating customer status */}
                              <TableCell align="left">
                                <Chip
                                  label={
                                    customer.isActive ? "Active" : "Inactive"
                                  }
                                  color={
                                    customer.isActive ? "success" : "error"
                                  }
                                />
                              </TableCell>

                              {/* More actions button with stopPropagation */}
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>

                  {/* Pagination */}
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={filteredAndSortedCustomers.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    sx={{
                      mr: 1,
                    }}
                  />
                </TableContainer>
              </>
            )}
            {selectedTab === 1 && (
              <>
                <TableContainer
                  component={Box}
                  sx={{
                    mx: "auto",
                    width: "100%",
                    maxWidth: "calc(100% - 20px)",
                    p: 1,
                    my: 1,
                    backgroundColor: "white",
                  }}
                >
                  {/* Search, Sort, and Filter Controls */}
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      p: 1.5,
                      backgroundColor: "#f5f5f5",
                      mb: 1,
                    }}
                  >
                    <Snackbar
                      open={snackbar.open}
                      autoHideDuration={8000}
                      anchorOrigin={{ vertical: "top", horizontal: "center" }}
                      onClose={() => setSnackbar({ ...snackbar, open: false })}
                    >
                      <Alert
                        severity={snackbar.severity}
                        sx={{ width: "100%" }}
                      >
                        {snackbar.message}
                      </Alert>
                    </Snackbar>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <TextField
                        variant="outlined"
                        size="small"
                        placeholder="Search"
                        value={search}
                        onChange={handleSearchChange}
                        sx={{ marginRight: 2, width: "300px" }}
                        InputProps={{
                          startAdornment: (
                            <SearchIcon
                              sx={{ marginRight: 1, color: "gray" }}
                            />
                          ),
                        }}
                      />
                      <FormControl
                        variant="outlined"
                        size="small"
                        sx={{ marginRight: 2, width: "150px" }}
                      >
                        <InputLabel>Sort By</InputLabel>
                        <Select
                          value={sortField}
                          onChange={(e) =>
                            setSortField(e.target.value as string)
                          }
                          label="Sort By"
                        >
                          <MenuItem value="">None</MenuItem>
                          {columns.map((column) => (
                            <MenuItem key={column.id} value={column.id}>
                              {column.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      {/* <FormControl
                        variant="outlined"
                        size="small"
                        sx={{ marginRight: 2, width: "150px" }}
                      >
                        <InputLabel>Filter By</InputLabel>
                        <Select
                          value={filterField}
                          onChange={(e) =>
                            setFilterField(e.target.value as string)
                          }
                          label="Filter By"
                        >
                          <MenuItem value="">None</MenuItem>
                          {columns.map((column) => (
                            <MenuItem key={column.id} value={column.id}>
                              {column.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl> */}
                      {/* {filterField && (
                        <TextField
                          variant="outlined"
                          size="small"
                          placeholder={`Filter ${filterField}`}
                          value={filterValue}
                          onChange={handleCustomerFilterChange}
                          sx={{ marginRight: 2, width: "300px" }}
                        />
                      )} */}
                    </Box>
                  </Box>

                  {/* Selected Items and Actions */}
                  {selected.length > 1 && (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        alignContent: "center",
                        padding: 1,
                        backgroundColor: "#f5f5f5",
                        px: 2,
                      }}
                    >
                      <Typography>{selected.length} selected</Typography>
                      <Tooltip title="Delete Selected">
                        <StyledIconButton variantType="transparent">
                          <DeleteIcon />
                        </StyledIconButton>
                      </Tooltip>
                    </Box>
                  )}

                  {/* Data Table */}
                  <Table sx={{ minWidth: 650 }} aria-label="customer table">
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <Checkbox
                            indeterminate={
                              selected.length > 0 &&
                              selected.length < customers.length
                            }
                            checked={
                              customers.length > 0 &&
                              selected.length === customers.length
                            }
                            onChange={handleSelectAllClick}
                            inputProps={{
                              "aria-label": "select all customers",
                            }}
                          />
                        </TableCell>
                        {columns.map((column) => (
                          <TableCell key={column.id} align="left">
                            {column.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {filteredAndSortedCustomers
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage,
                        )
                        .map((customer) => {
                          const isItemSelected = isSelected(customer._id);
                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              aria-checked={isItemSelected}
                              tabIndex={-1}
                              key={customer._id}
                              selected={isItemSelected}
                              onClick={() => handleRowClickCard(customer)} // Row click opens details
                            >
                              {/* Checkbox for selecting a customer */}
                              <TableCell
                                padding="checkbox"
                                onClick={(e) => e.stopPropagation()}
                              >
                                {" "}
                                {/* Prevent row click */}
                                <Checkbox
                                  color="primary"
                                  checked={isItemSelected}
                                  onChange={() => handleSelect(customer._id)} // Checkbox click selects customer
                                />
                              </TableCell>

                              {/* Table cells for customer details */}
                              <TableCell align="left">
                                {new Date(customer.createdAt).toLocaleString()}
                              </TableCell>
                              <TableCell align="left">
                                {customer.firstName}
                              </TableCell>
                              <TableCell align="left">
                                {customer.lastName}
                              </TableCell>
                              <TableCell align="left">
                                {customer.country}
                              </TableCell>
                              <TableCell align="left">
                                {customer.state}
                              </TableCell>
                              <TableCell align="left">
                                {customer.city}
                              </TableCell>
                              <TableCell align="left">
                                {customer.address}
                              </TableCell>
                              <TableCell align="left">
                                {customer.phone}
                              </TableCell>
                              <TableCell align="left">
                                {customer.email}
                              </TableCell>
                              <TableCell align="left">
                                <Chip
                                  label={
                                    customer.isActive ? "Active" : "Inactive"
                                  }
                                  color={
                                    customer.isActive ? "success" : "error"
                                  }
                                />
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>

                  {/* Pagination */}
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={filteredAndSortedCustomers.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    sx={{
                      mr: 1,
                    }}
                  />
                </TableContainer>
              </>
            )}
            {selectedTab === 2 && (
              <>
                {view === "table" && (
                  <StyledDataGrid
                    rows={customers}
                    columns={CustomerColumns}
                    getRowId={(row) => row._id}
                    initialState={{
                      pagination: {
                        paginationModel: {
                          pageSize: 10,
                        },
                      },
                    }}
                    pageSizeOptions={[10]}
                    checkboxSelection
                    onRowClick={handleRowClick}
                    sx={{ width: "100%" }}
                  />
                )}

                {view === "card" && (
                  <Grid container spacing={2} sx={{ width: "100%" }}>
                    <AssignToGroup
                      customers={customers}
                      groupMembers={
                        selectedGroup
                          ? customers.filter(
                            (customer) =>
                              customer.customerGroup === selectedGroup.email,
                          )
                          : []
                      }
                      onSubmit={() => { }}
                      onClose={handleCloseGroupModal}
                      open={groupModalOpen}
                      distributor={user?._id || ""}
                      customerGroup={selectedGroup?.email}
                      onSnackbarChange={handleSnackbarChange}
                      onSuccess={() => {
                        fetchCustomersData();
                        setGroupModalOpen(false);
                      }}
                    />
                    {/* Sidebar as the first Grid item */}
                    <Grid item xs={12} sm={3}>
                      <Paper
                        elevation={1}
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          p: 2,
                          height: "100%",
                          position: "sticky", // Make sidebar sticky
                          borderRadius: 0,
                        }}
                      >
                        <Stack direction="row" spacing={2} sx={{ mb: 2 }}>
                          {/* Autocomplete for Group Search */}
                          <Autocomplete
                            options={groups.map((group) => group)}
                            getOptionLabel={(option) =>
                              `${option.firstName} ${option.lastName} - (${option.email})`
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Search Group"
                                variant="outlined"
                              />
                            )}
                            size="small"
                            sx={{ flexGrow: 1 }}
                            onChange={(_, selectedGroup) => {
                              if (selectedGroup) {
                                handleGroupSelect(selectedGroup); // Set selected group when chosen from Autocomplete
                              } else {
                                handleGroupSelect(null); // Set selected group to null when cleared
                              }
                            }}
                          />
                        </Stack>

                        {/* List of Groups */}

                        <Divider />
                        <List sx={{ width: "100%", mt: 1 }}>
                          <ListItem
                            disablePadding
                            sx={{ borderBottom: " 1px solid #e0e0e0" }}
                          >
                            <ListItemButton
                              onClick={() => handleGroupSelect("All")} // Pass "All" to handleGroupSelect
                              selected={selectedGroup === null} // Check if selectedGroup is null for "All" selection
                            >
                              <ListItemText
                                primary="All"
                                sx={{
                                  fontWeight: "bold",
                                }}
                              />
                            </ListItemButton>
                          </ListItem>
                          {groups.map((group) => (
                            <ListItem
                              key={group._id}
                              disablePadding
                              sx={{
                                bgcolor:
                                  selectedGroup === group
                                    ? "secondary.light"
                                    : "transparent",
                                borderBottom: " 1px solid #e0e0e0",
                              }}
                            >
                              <ListItemButton
                                onClick={() => handleGroupSelect(group)} // Use group._id to identify the group
                                selected={selectedGroup === group} // Ensure selectedGroup matches the group's ID
                              >
                                <ListItemText
                                  primary={`${group.firstName} ${group.lastName} - (${group.email})`}
                                />
                              </ListItemButton>
                              <ListItemSecondaryAction>
                                <StyledIconButton
                                  variantType="transparent"
                                  onClick={() => handleEditGroup(group)}
                                >
                                  <EditIcon />
                                </StyledIconButton>
                              </ListItemSecondaryAction>
                            </ListItem>
                          ))}
                        </List>
                      </Paper>
                    </Grid>

                    {/* Customer Cards */}
                    <Grid item xs={12} sm={9}>
                      <Grid container spacing={2}>
                        {filteredCustomers.length === 0 ? (
                          <Grid item xs={12}>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "100%",
                              }}
                            >
                              <Typography
                                variant="body2"
                                color="textSecondary"
                                sx={{ mt: 2 }}
                              >
                                No members in this group
                              </Typography>
                            </Box>
                          </Grid>
                        ) : (
                          filteredCustomers.map((customer) => (
                            <Grid
                              item
                              xs={12} // Full width on extra-small screens
                              sm={4} // 2 cards per row on small screens
                              key={customer._id}
                            >
                              <CustomerCard
                                customer={customer}
                                onRowClick={onCustomerSelect}
                                sx={{ flexGrow: 1, minWidth: 270 }}
                              />
                            </Grid>
                          ))
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </>
            )}
            {selectedTab === 3 && (
              <TableContainer
                component={Box}
                sx={{
                  mx: "auto",
                  width: "100%",
                  maxWidth: "calc(100% - 20px)",
                  p: 1,
                  my: 1,
                  backgroundColor: "white",
                }}
              >
                {/* Search, Sort, and Filter Controls */}
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    p: 1.5,
                    backgroundColor: "#f5f5f5",
                    mb: 1,
                  }}
                >
                  <Snackbar
                    open={snackbar.open}
                    autoHideDuration={8000}
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    onClose={() => setSnackbar({ ...snackbar, open: false })}
                  >
                    <Alert severity={snackbar.severity} sx={{ width: "100%" }}>
                      {snackbar.message}
                    </Alert>
                  </Snackbar>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <TextField
                      variant="outlined"
                      size="small"
                      placeholder="Search"
                      value={search}
                      onChange={handleSearchChange}
                      sx={{ marginRight: 2, width: "300px" }}
                      InputProps={{
                        startAdornment: (
                          <SearchIcon sx={{ marginRight: 1, color: "gray" }} />
                        ),
                      }}
                    />
                    <FormControl
                      variant="outlined"
                      size="small"
                      sx={{ marginRight: 2, width: "150px" }}
                    >
                      <InputLabel>Sort By</InputLabel>
                      <Select
                        value={sortField}
                        onChange={(e) => setSortField(e.target.value as string)}
                        label="Sort By"
                      >
                        <MenuItem value="">None</MenuItem>
                        {columns.map((column) => (
                          <MenuItem key={column.id} value={column.id}>
                            {column.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl
                      variant="outlined"
                      size="small"
                      sx={{ marginRight: 2, width: "150px" }}
                    >
                      <InputLabel>Filter By</InputLabel>
                      <Select
                        value={filterField}
                        onChange={(e) =>
                          setFilterField(e.target.value as string)
                        }
                        label="Filter By"
                      >
                        <MenuItem value="">None</MenuItem>
                        {columns.map((column) => (
                          <MenuItem key={column.id} value={column.id}>
                            {column.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    {filterField && (
                      <TextField
                        variant="outlined"
                        size="small"
                        placeholder={`Filter ${filterField}`}
                        value={filterValue}
                        onChange={handleCustomerFilterChange}
                        sx={{ marginRight: 2, width: "300px" }}
                      />
                    )}
                  </Box>
                </Box>

                {/* Selected Items and Actions */}
                {selected.length > 1 && (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      alignContent: "center",
                      padding: 1,
                      backgroundColor: "#f5f5f5",
                      px: 2,
                    }}
                  >
                    <Typography>{selected.length} selected</Typography>
                    <Tooltip title="Delete Selected">
                      <IconButton>
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </Box>
                )}

                {/* Data Table */}
                <Table sx={{ minWidth: 650 }} aria-label="customer table">
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <Checkbox
                          indeterminate={
                            selected.length > 0 &&
                            selected.length < customers.length
                          }
                          checked={
                            customers.length > 0 &&
                            selected.length === customers.length
                          }
                          onChange={handleSelectAllClick}
                          inputProps={{
                            "aria-label": "select all customers",
                          }}
                        />
                      </TableCell>
                      {columns.map((column) => (
                        <TableCell key={column.id} align="left">
                          {column.label}
                        </TableCell>
                      ))}
                      <TableCell align="center">Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {customers &&
                      nationalAccounts
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage,
                        )
                        .map((customer) => {
                          const isItemSelected = isSelected(customer._id);
                          return (
                            <TableRow
                              hover
                              onClick={() => handleSelect(customer._id)}
                              role="checkbox"
                              aria-checked={isItemSelected}
                              tabIndex={-1}
                              key={customer._id}
                              selected={isItemSelected}
                            >
                              <TableCell padding="checkbox">
                                <Checkbox
                                  color="primary"
                                  checked={isItemSelected}
                                />
                              </TableCell>

                              <TableCell align="left">
                                {new Date(customer.createdAt).toLocaleString()}
                              </TableCell>
                              <TableCell align="left">
                                {customer.firstName}
                              </TableCell>
                              <TableCell align="left">
                                {customer.lastName}
                              </TableCell>
                              <TableCell align="left">
                                {customer.country}
                              </TableCell>
                              <TableCell align="left">
                                {customer.state}
                              </TableCell>
                              <TableCell align="left">
                                {customer.city}
                              </TableCell>
                              <TableCell align="left">
                                {customer.address}
                              </TableCell>
                              <TableCell align="left">
                                {customer.phone}
                              </TableCell>
                              <TableCell align="left">
                                {customer.email}
                              </TableCell>
                              <TableCell align="left">
                                <Chip
                                  label={
                                    customer.isActive ? "Active" : "Inactive"
                                  }
                                  color={
                                    customer.isActive ? "success" : "error"
                                  }
                                />
                              </TableCell>
                              <TableCell align="center">
                                <Tooltip title="Manage Customer">
                                  <IconButton>
                                    <MoreVertIcon />
                                  </IconButton>
                                </Tooltip>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                  </TableBody>
                </Table>

                {/* Pagination */}
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={customers.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  sx={{
                    mr: 1,
                  }}
                />
              </TableContainer>
            )}

            {selectedTab === 4 && (
              <TableContainer
                component={Box}
                sx={{
                  mx: "auto",
                  width: "100%",
                  maxWidth: "calc(100% - 20px)",
                  p: 1,
                  my: 1,
                  backgroundColor: "white",
                }}
              >
                {/* Search, Sort, and Filter Controls */}
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    p: 1.5,
                    backgroundColor: "#f5f5f5",
                    mb: 1,
                  }}
                >
                  <Snackbar
                    open={snackbar.open}
                    autoHideDuration={8000}
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    onClose={() => setSnackbar({ ...snackbar, open: false })}
                  >
                    <Alert severity={snackbar.severity} sx={{ width: "100%" }}>
                      {snackbar.message}
                    </Alert>
                  </Snackbar>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <TextField
                      variant="outlined"
                      size="small"
                      placeholder="Search"
                      value={search}
                      onChange={handleSearchChange}
                      sx={{ marginRight: 2, width: "300px" }}
                      InputProps={{
                        startAdornment: (
                          <SearchIcon sx={{ marginRight: 1, color: "gray" }} />
                        ),
                      }}
                    />
                    <FormControl
                      variant="outlined"
                      size="small"
                      sx={{ marginRight: 2, width: "150px" }}
                    >
                      <InputLabel>Sort By</InputLabel>
                      <Select
                        value={sortField}
                        onChange={(e) => setSortField(e.target.value as string)}
                        label="Sort By"
                      >
                        <MenuItem value="">None</MenuItem>
                        {columns.map((column) => (
                          <MenuItem key={column.id} value={column.id}>
                            {column.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl
                      variant="outlined"
                      size="small"
                      sx={{ marginRight: 2, width: "150px" }}
                    >
                      <InputLabel>Filter By</InputLabel>
                      <Select
                        value={filterField}
                        onChange={(e) =>
                          setFilterField(e.target.value as string)
                        }
                        label="Filter By"
                      >
                        <MenuItem value="">None</MenuItem>
                        {columns.map((column) => (
                          <MenuItem key={column.id} value={column.id}>
                            {column.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    {filterField && (
                      <TextField
                        variant="outlined"
                        size="small"
                        placeholder={`Filter ${filterField}`}
                        value={filterValue}
                        onChange={handleCustomerFilterChange}
                        sx={{ marginRight: 2, width: "300px" }}
                      />
                    )}
                  </Box>
                </Box>

                {/* Selected Items and Actions */}
                {selected.length > 1 && (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      alignContent: "center",
                      padding: 1,
                      backgroundColor: "#f5f5f5",
                      px: 2,
                    }}
                  >
                    <Typography>{selected.length} selected</Typography>
                    <Tooltip title="Delete Selected">
                      <IconButton>
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </Box>
                )}

                {/* Data Table */}
                <Table sx={{ minWidth: 650 }} aria-label="customer table">
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <Checkbox
                          indeterminate={
                            selected.length > 0 &&
                            selected.length < customers.length
                          }
                          checked={
                            customers.length > 0 &&
                            selected.length === customers.length
                          }
                          onChange={handleSelectAllClick}
                          inputProps={{
                            "aria-label": "select all customers",
                          }}
                        />
                      </TableCell>
                      {columns.map((column) => (
                        <TableCell key={column.id} align="left">
                          {column.label}
                        </TableCell>
                      ))}
                      <TableCell align="center">Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {customers &&
                      globalAccounts
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage,
                        )
                        .map((customer) => {
                          const isItemSelected = isSelected(customer._id);
                          return (
                            <TableRow
                              hover
                              onClick={() => handleSelect(customer._id)}
                              role="checkbox"
                              aria-checked={isItemSelected}
                              tabIndex={-1}
                              key={customer._id}
                              selected={isItemSelected}
                            >
                              <TableCell padding="checkbox">
                                <Checkbox
                                  color="primary"
                                  checked={isItemSelected}
                                />
                              </TableCell>

                              <TableCell align="left">
                                {new Date(customer.createdAt).toLocaleString()}
                              </TableCell>
                              <TableCell align="left">
                                {customer.firstName}
                              </TableCell>
                              <TableCell align="left">
                                {customer.lastName}
                              </TableCell>
                              <TableCell align="left">
                                {customer.country}
                              </TableCell>
                              <TableCell align="left">
                                {customer.state}
                              </TableCell>
                              <TableCell align="left">
                                {customer.city}
                              </TableCell>
                              <TableCell align="left">
                                {customer.address}
                              </TableCell>
                              <TableCell align="left">
                                {customer.phone}
                              </TableCell>
                              <TableCell align="left">
                                {customer.email}
                              </TableCell>
                              <TableCell align="left">
                                <Chip
                                  label={
                                    customer.isActive ? "Active" : "Inactive"
                                  }
                                  color={
                                    customer.isActive ? "success" : "error"
                                  }
                                />
                              </TableCell>
                              <TableCell align="center">
                                <Tooltip title="Manage Customer">
                                  <IconButton>
                                    <MoreVertIcon />
                                  </IconButton>
                                </Tooltip>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                  </TableBody>
                </Table>

                {/* Pagination */}
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={customers.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  sx={{
                    mr: 1,
                  }}
                />
              </TableContainer>
            )}
          </>
        </Box>
      </Box>
    </Box>
  );
};

export default CustomerTable;
