import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Tooltip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Checkbox,
  Snackbar,
  Alert,
  AlertColor,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Modal,
  TextField,
  InputAdornment,
} from "@mui/material";
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  OpenInNew as OpenIcon,
  DriveFileRenameOutline as DriveFileRenameOutlineIcon,
  Description as DescriptionIcon,
} from "@mui/icons-material";
import { deleteReport, getAllReports } from "../../services/reports.service";
import useAuthStore from "../../store/authStore";
import openPDFWindow from "./OpenPDFWindow";
import Papa from "papaparse";
import StyledButton from "../Tabs/StyledButton";
import StyledIconButton from "../Tabs/StyledIconButton";

interface ReportData {
  _id: string;
  reportInformation: {
    name: string;
    description?: string;
    reportType: string;
    generatedAt: Date;
    generatedBy: string;
    startDate?: Date; // Add this if it's part of your report data structure
    endDate?: Date; // Add this if it's part of your report data structure
  };
  reportData: any;
  reportCategory: string;
}

interface WarrantyReportHistoryTableProps {
  reportCategory: "WarrantyReport";
}

const WarrantyReportHistoryTable: React.FC<WarrantyReportHistoryTableProps> = ({
  reportCategory,
}) => {
  const [data, setData] = useState<ReportData[]>([]);
  const [selected, setSelected] = useState<string[]>([]);
  const [selectedForDeletion, setSelectedForDeletion] = useState<string | null>(
    null
  );
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success" as AlertColor,
  });
  const { user } = useAuthStore();
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [isReportDeleted, setIsReportDeleted] = useState(false);
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [selectedReport, setSelectedReport] = useState<ReportData | null>(null);
  const [editedName, setEditedName] = useState<string>("");
  const [editedDescription, setEditedDescription] = useState<string>("");

  const handleClose = () => setEditModalOpen(false);

  const fetchData = useCallback(async () => {
    try {
      const response = await getAllReports(
        user?.email || "Email Unavailable",
        user?.role || "Role Unavailable"
      );
      const filteredData = response.filter(
        (report: ReportData) => report.reportCategory === reportCategory
      );
      setData(filteredData);
    } catch (error) {
      console.error("Error fetching report data:", error);
    }
  }, [user, reportCategory]);

  useEffect(() => {
    fetchData();
    // Reset the isReportDeleted state after fetching data
    if (isReportDeleted) {
      setIsReportDeleted(false);
    }
  }, [fetchData, isReportDeleted, user]);

  const handleOpenDeleteModal = (id: string) => {
    const report = data.find((report) => report._id === id);
    if (report) {
      setSelectedForDeletion(report._id);
      setDeleteModalOpen(true);
    }
  };

  const handleCloseDeleteModal = () => {
    setDeleteModalOpen(false);
    setSelectedForDeletion(null);
  };

  const handleDelete = async (id: string) => {
    try {
      await deleteReport(id);
      setIsReportDeleted(true);
      // Show success message
      setSnackbar({
        open: true,
        message: "Report deleted successfully",
        severity: "success",
      });
    } catch (error) {
      console.error("Error deleting report:", error);
      setSnackbar({
        open: true,
        message: "Failed to delete report",
        severity: "error",
      });
    } finally {
      handleCloseDeleteModal();
    }
  };

  const handleSelect = (id: string) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = [...selected, id];
    } else {
      newSelected = selected.filter((itemId) => itemId !== id);
    }

    setSelected(newSelected);
  };

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setSelected(data.map((row) => row._id));
    } else {
      setSelected([]);
    }
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleEdit = (id: string) => {
    const report = data.find((report) => report._id === id);
    if (report) {
      setSelectedReport(report);
      setEditedName(report.reportInformation.name);
      setEditedDescription(report.reportInformation.description || "");
      setEditModalOpen(true);
    }
  };

  const handleSave = () => {
    if (selectedReport) {
      // Save logic here
      // For now, just log the edited report
      console.log("Saved report:", {
        ...selectedReport,
        reportInformation: {
          ...selectedReport.reportInformation,
          name: editedName,
          description: editedDescription,
        },
      });
      // Optionally update the data state to reflect the changes
      setData((prevData) =>
        prevData.map((report) =>
          report._id === selectedReport._id
            ? {
              ...report,
              reportInformation: {
                ...report.reportInformation,
                name: editedName,
                description: editedDescription,
              },
            }
            : report
        )
      );
      setEditModalOpen(false);
    }
  };

  const handleOpen = (id: string) => {
    const report = data.find((report) => report._id === id);
    if (report) {
      openPDFWindow(report);
    } else {
      console.error("Report not found or could not be loaded.");
    }
  };

  const isSelected = (id: string) => selected.includes(id);

  // Function to export the reports data
  const exportToCSV = (report: ReportData) => {
    // Check if reportData.data is an array
    if (
      Array.isArray(report.reportData?.data) &&
      report.reportData.data.length > 0
    ) {
      // General report information fields
      const generalFields = {
        startDate: report.reportInformation.startDate
          ? new Date(report.reportInformation.startDate).toLocaleDateString()
          : "N/A",
        endDate: report.reportInformation.endDate
          ? new Date(report.reportInformation.endDate).toLocaleDateString()
          : "N/A",
        reportDate: report.reportInformation.generatedAt
          ? new Date(report.reportInformation.generatedAt).toLocaleDateString()
          : "N/A",
        generatedBy: report.reportInformation.generatedBy || "N/A",
        reportType: report.reportInformation.reportType || "N/A",
      };

      // Get dynamic column headers from the keys of the first item in reportData.data
      const dynamicHeaders = Object.keys(report.reportData.data[0]);

      // Combine the dynamic headers and the general headers into one row
      const combinedHeaders = [
        ...dynamicHeaders,
        ...Object.keys(generalFields),
      ];

      // Create the first row of data with the general fields
      const generalFieldsRow = {
        ...Object.fromEntries(dynamicHeaders.map((header) => [header, ""])), // Fill dynamic columns with empty values
        ...generalFields, // General field values
      };

      // Map over the dynamic data to format it into CSV rows
      const csvData = report.reportData.data.map((item: any) => {
        const row: any = {};

        dynamicHeaders.forEach((header) => {
          row[header] = Array.isArray(item[header])
            ? item[header].join(", ")
            : item[header];
        });

        return row;
      });

      // Convert to CSV format, ensuring general fields come in the first row
      const csv = Papa.unparse({
        fields: combinedHeaders, // Combined headers (dynamic + general)
        data: [generalFieldsRow, ...csvData], // General fields row first, followed by dynamic data rows
      });

      // Create a Blob and download link for the CSV
      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${report.reportInformation.name}.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      console.error(
        "report.reportData.data is not an array or is empty:",
        report.reportData.data
      );
      alert("Report data is not available in the expected format for export.");
    }
  };

  return (
    <Box sx={{ p: 0 }}>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity={snackbar.severity} sx={{ width: "100%" }}>
          {snackbar.message}
        </Alert>
      </Snackbar>

      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer
          sx={{
            maxHeight: 440,
            "&::-webkit-scrollbar": {
              width: "8px",
              transition: "opacity 0.5s ease-in-out",
            },
            "&::-webkit-scrollbar-track": {
              background: "#f1f1f1",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "#888",
              borderRadius: "4px",
              opacity: 0,
              visibility: "hidden",
            },
            "&:hover::-webkit-scrollbar-thumb": {
              opacity: 1,
              visibility: "visible",
            },
            "&::-webkit-scrollbar-thumb:hover": {
              background: "#555",
            },
          }}
        >
          <Table stickyHeader aria-label="reports history table">
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    indeterminate={
                      selected.length > 0 && selected.length < data.length
                    }
                    checked={data.length > 0 && selected.length === data.length}
                    onChange={handleSelectAll}
                  />
                </TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Report Type</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Generated By</TableCell>
                <TableCell align="center">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  const isItemSelected = isSelected(row._id);
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row._id}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          onChange={() => handleSelect(row._id)}
                        />
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.reportInformation.name}
                      </TableCell>
                      <TableCell>{row.reportInformation.description}</TableCell>
                      <TableCell>{row.reportInformation.reportType}</TableCell>
                      <TableCell>
                        {new Date(
                          row.reportInformation.generatedAt
                        ).toLocaleDateString()}
                      </TableCell>
                      <TableCell>{row.reportInformation.generatedBy}</TableCell>
                      <TableCell align="center">
                        <Tooltip title="Open">
                          <StyledIconButton variantType="transparent" onClick={() => handleOpen(row._id)}>
                            <DescriptionIcon />
                          </StyledIconButton>
                        </Tooltip>
                        <Tooltip title="Export to CSV">
                          <StyledIconButton variantType="transparent" onClick={() => exportToCSV(row)}>
                            <OpenIcon />
                          </StyledIconButton>
                        </Tooltip>
                        <Tooltip title="Edit">
                          <StyledIconButton variantType="transparent" onClick={() => handleEdit(row._id)}>
                            <EditIcon />
                          </StyledIconButton>
                        </Tooltip>
                        <Tooltip title="Delete">
                          <StyledIconButton
                            variantType="transparent"
                            onClick={() => handleOpenDeleteModal(row._id)}
                          >
                            <DeleteIcon />
                          </StyledIconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>

      <Dialog open={isDeleteModalOpen} onClose={handleCloseDeleteModal}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this report?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <StyledButton onClick={handleCloseDeleteModal}>Cancel</StyledButton>
          <StyledButton
            onClick={() => {
              if (selectedForDeletion) {
                handleDelete(selectedForDeletion);
                handleCloseDeleteModal();
              }
            }}
            variantType="destructive"
          >
            Delete
          </StyledButton>
        </DialogActions>
      </Dialog>
      <Modal open={isEditModalOpen} onClose={() => setEditModalOpen(false)}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { xs: 220, sm: 400 },
            bgcolor: "background.paper",
            border: "1px solid gray",
            boxShadow: 24,
            pl: 4,
            pr: 4,
            pb: 4,
            borderRadius: 1,
          }}
        >
          <h3>Edit Report Details</h3>
          <TextField
            fullWidth
            margin="normal"
            label="Report Name"
            value={editedName}
            onChange={(e) => setEditedName(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <DriveFileRenameOutlineIcon />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            fullWidth
            margin="normal"
            label="Description"
            multiline
            rows={4}
            value={editedDescription}
            onChange={(e) => setEditedDescription(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <DescriptionIcon />
                </InputAdornment>
              ),
            }}
          />
          <Box
            sx={{
              mt: 2,
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              gap: 1,
            }}
          >
            <StyledButton onClick={() => setEditModalOpen(false)}>
              Cancel
            </StyledButton>
            <StyledButton onClick={handleSave} variantType="primary">
              Save
            </StyledButton>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default WarrantyReportHistoryTable;
