// src/components/Settings.tsx
import React, { useState } from "react";
import {
  Box,
  Typography,
  Switch,
  FormControlLabel,
  FormGroup,
  styled,
  Paper,
  Divider,
} from "@mui/material";

// Styling for the FormGroup
const StyledFormGroup = styled(FormGroup)(({ theme }) => ({
  flexDirection: "column",
  alignItems: "flex-start",
  padding: theme.spacing(2),
  "& .MuiFormControlLabel-root": {
    marginBottom: theme.spacing(2),
  },
}));

// Interface for the Settings Component
interface Setting {
  name: string;
  label: string;
}

const settings: Setting[] = [
  { name: "emailNotifications", label: "Email Notifications" },
  { name: "pushNotifications", label: "Push Notifications" },
  { name: "monthlySummary", label: "Monthly Summary Emails" },
  { name: "dataSharing", label: "Data Sharing with Partners" },
];

const Settings: React.FC = () => {
  const [preferences, setPreferences] = useState({
    emailNotifications: true,
    pushNotifications: false,
    monthlySummary: true,
    dataSharing: false,
  });

  const handleToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPreferences({
      ...preferences,
      [event.target.name]: event.target.checked,
    });
  };

  return (
    <Paper elevation={1} sx={{ p: 3, borderRadius: 1, mt: 2 }}>
      <Box>
        <Typography
          variant="body1"
          gutterBottom
          sx={{ fontWeight: 500, mb: 1 }}
        >
          Email Settings
        </Typography>

        <StyledFormGroup>
          {settings.map((setting) => (
            <FormControlLabel
              control={
                <Switch
                  checked={
                    preferences[setting.name as keyof typeof preferences]
                  }
                  onChange={handleToggle}
                  name={setting.name}
                />
              }
              label={setting.label}
              key={setting.name}
            />
          ))}
        </StyledFormGroup>
        <Divider sx={{ mb: 1 }} />
        <Typography
          variant="body1"
          gutterBottom
          sx={{ fontWeight: 500, mb: 1 }}
        >
          Account Settings
        </Typography>
        <StyledFormGroup>
          {settings.map((setting) => (
            <FormControlLabel
              control={
                <Switch
                  checked={
                    preferences[setting.name as keyof typeof preferences]
                  }
                  onChange={handleToggle}
                  name={setting.name}
                />
              }
              label={setting.label}
              key={setting.name}
            />
          ))}
        </StyledFormGroup>
        <Divider sx={{ mb: 1 }} />
        <Typography
          variant="body1"
          gutterBottom
          sx={{ fontWeight: 500, mb: 1 }}
        >
          Device Settings
        </Typography>
        <StyledFormGroup>
          {settings.map((setting) => (
            <FormControlLabel
              control={
                <Switch
                  checked={
                    preferences[setting.name as keyof typeof preferences]
                  }
                  onChange={handleToggle}
                  name={setting.name}
                />
              }
              label={setting.label}
              key={setting.name}
            />
          ))}
        </StyledFormGroup>
      </Box>
    </Paper>
  );
};

export default Settings;
