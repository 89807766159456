import React, { useState, useEffect } from "react";
import {
  Box,
  Container,
  TextField,
  Chip,
  Checkbox,
  Stepper,
  Step,
  StepLabel,
  Typography,
  Modal,
  Grid,
  Snackbar,
  Alert,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  RadioGroup,
  FormControlLabel,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Autocomplete,
  useTheme,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useForm, Controller } from "react-hook-form";
import { Business, AddBusiness } from "@mui/icons-material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CloseIcon from "@mui/icons-material/Close";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import Papa from "papaparse";
import {
  LotusProErrors,
  iCleanMiniErrors,
} from "../common/types/ProductWarrantyTypes";
import {
  createProductWarranty,
  fetchAllProductWarranties,
} from "../services/product-warranty.service";
import {
  CreateProductWarranty,
  DistributorInformation,
} from "../common/types/ProductWarrantyTypes";
import StyledButton from "../components/Tabs/StyledButton";
import StyledIconButton from "../components/Tabs/StyledIconButton";

const steps = [
  "Distributor Information",
  "Product Site",
  "Fault Description",
  "Summary",
];

const importSteps = ["Distributor Information", "Upload CSV", "Summary"];

const CreateWarrantyPage: React.FC = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [isNewDistributor, setIsNewDistributor] = useState(false);
  const [isImportingClaims, setIsImportingClaims] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const { handleSubmit, control, reset, getValues, setValue, watch } =
    useForm();
  const [distributors, setDistributors] = useState<DistributorInformation[]>(
    []
  );
  const [csvData, setCsvData] = useState<any[]>([]); // State to store parsed CSV data
  const creditStat = watch("creditStat", "NO"); // Default value is "NO"
  const [summaryData, setSummaryData] = useState<{
    totalProducts: number;
    productCounts: { [key: string]: number };
  }>({ totalProducts: 0, productCounts: {} });
  const [selectedFileName, setSelectedFileName] = useState("");
  const stepLength = isImportingClaims ? importSteps.length : steps.length;
  const [isDistributorSelected, setIsDistributorSelected] = useState(false);
  const theme = useTheme();

  // Utility function to convert enum to array of key-value pairs
  const enumToKeyValueArray = (enumObj: any) => {
    return Object.keys(enumObj).map((key) => ({
      code: key,
      description: enumObj[key],
    }));
  };

  // Function to get relevant errors based on the selected device model
  const getErrorCodeForDeviceModel = (deviceModel: string) => {
    const lotusProModels = [
      "Lotus PRO SAO Dispenser (LQFC225K)",
      "Lotus PRO SAO Dispenser (LQFC225K-FB)",
      "Lotus PRO SAO Dispenser (LQFC275-FB)",
      "Lotus PRO SAO Dispenser (LQFC275)",
    ];

    const iCleanMiniModels = [
      "iClean Mini (LQFC200)",
      "iClean PRO (LQFC300)",
      "SAO2GO (LQFC400)",
      "SAO2GO (LQFC401)",
      "iClean Flo (LQFC5XX)",
    ];

    if (lotusProModels.includes(deviceModel)) {
      return enumToKeyValueArray(LotusProErrors); // Convert LotusProErrors enum to key-value array
    } else if (iCleanMiniModels.includes(deviceModel)) {
      return enumToKeyValueArray(iCleanMiniErrors); // Convert iCleanMiniErrors enum to key-value array
    }

    return []; // Default to empty array if no matching device model
  };

  useEffect(() => {
    if (csvData.length > 0) {
      const productCounts = csvData.reduce((acc, row) => {
        acc[row.Product] = (acc[row.Product] || 0) + 1;
        return acc;
      }, {} as { [key: string]: number });

      setSummaryData({
        totalProducts: csvData.length,
        productCounts,
      });
    }
  }, [csvData]);
  useEffect(() => {
    if (!isNewDistributor) {
      fetchAllProductWarranties()
        .then((data) => {
          const uniqueDistributors: {
            [email: string]: DistributorInformation;
          } = {};
          data.forEach((warranty: any) => {
            const distributor = warranty.distributorInformation;
            uniqueDistributors[distributor.email] = distributor;
          });
          setDistributors(Object.values(uniqueDistributors));
        })
        .catch((error) =>
          console.error("Failed to fetch distributors:", error)
        );
    }
  }, [isNewDistributor]);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleFinish = async () => {
    if (isImportingClaims) {
      await submitClaimsFromCsv(); // Call the CSV submission function
    } else {
      const data = getValues();

      const newWarranty: CreateProductWarranty = {
        distributorInformation: {
          companyName: data.companyName,
          accountNumber: data.accountNumber,
          email: data.email,
          phone: data.phone,
          address: data.address,
          city: data.city,
          country: data.country,
          region: data.region,
          zip: data.zip,
        },
        productSiteInformation: {
          serialNumber: data.serialNumber,
          deviceModel: data.deviceModel,
          purchaseDate: data.purchaseDate,
          company: data.company,
          address: data.address,
          city: data.city,
          country: data.country,
          region: data.region,
          zip: data.zip,
        },
        faultDescription: {
          returnReason: data.returnReason,
          defectType: data.defectType,
          description: data.description,
          warrantyType: data.warrantyStat,
          creditStatus: data.creditStat,
          creditAmount: data.creditAmount,
          replacementUnit: data.replacementStat,
          photo: data.photo,
          errorCode: data.errorCode,
        },
        status: "Initial review",
      };
      console.log("Payload to be sent:", newWarranty);

      try {
        await createProductWarranty(newWarranty);
        setSnackbar({
          open: true,
          message: "Warranty created successfully!",
          severity: "success",
        });
        setIsModalOpen(false);
        reset();
      } catch (error) {
        setSnackbar({
          open: true,
          message: "Failed to create warranty.",
          severity: "error",
        });
      }
    }
  };

  const handleNewDistributor = () => {
    setIsNewDistributor(true);
    setIsImportingClaims(false);
    setIsModalOpen(true);
    reset();
    setActiveStep(0);
  };

  const handleExistingDistributor = () => {
    setIsNewDistributor(false);
    setIsImportingClaims(false);
    setIsModalOpen(true);
    reset();
    setActiveStep(0); // Reset to the first step
  };

  const handleImportClaims = () => {
    setIsNewDistributor(false);
    setIsImportingClaims(true);
    setIsModalOpen(true);
    reset();
    setActiveStep(0);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    reset({
      distributor: "", // Reset distributor selection
    });
    reset();
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const handleDistributorChange = (event: any, value: any) => {
    if (value && value.email) {
      setIsDistributorSelected(true);
      setValue("companyName", value.companyName);
      setValue("accountNumber", value.accountNumber);
      setValue("email", value.email);
      setValue("phone", value.phone);
      setValue("address", value.address);
      setValue("city", value.city);
      setValue("country", value.country);
      setValue("region", value.region);
      setValue("zip", value.zip);
    } else {
      setIsDistributorSelected(false);
    }
  };

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setSelectedFileName(file.name);
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: (result: Papa.ParseResult<any>) => {
          console.log("Parsed CSV data:", result.data);
          setCsvData(result.data); // Store the parsed data
        },
      });
    }
  };

  // Function to submit warranty claims based on CSV data
  const submitClaimsFromCsv = async () => {
    const formValues = getValues(); // Get the values from the form
    let successCount = 0;
    const currentDate = new Date(); // Dynamically set the purchase date to the current date

    for (const row of csvData) {
      const newWarrantyClaim: CreateProductWarranty = {
        distributorInformation: {
          companyName: formValues.companyName,
          accountNumber: formValues.accountNumber,
          email: formValues.email,
          phone: formValues.phone,
          address: formValues.address,
          city: formValues.city,
          country: formValues.country,
          region: formValues.region,
          zip: formValues.zip,
        },
        productSiteInformation: {
          serialNumber: row.SN,
          deviceModel: row.Product,
          purchaseDate: currentDate,
          company: formValues.companyName,
          address: formValues.address,
          city: formValues.city,
          country: formValues.country,
          region: formValues.region,
          zip: formValues.zip,
        },
        faultDescription: {
          returnReason: "Customer return", // Example fixed value
          warrantyType:
            formValues.warrantyType === "IN" ||
              formValues.warrantyType === "OUT"
              ? formValues.warrantyType
              : "IN", // Ensure the value is either "IN" or "OUT"
          creditStatus:
            formValues.creditStatus === "NO" ||
              formValues.creditStatus === "YES"
              ? formValues.creditStatus
              : "NO", // Ensure the value is either "NO" or "YES"
          creditAmount: formValues.creditAmount || 0, // Default to 0 if not provided
          replacementUnit: "NO", // Example fixed value
          defectType: row.Fault, // Using the 'Fault' from CSV
          description: row.Fault, // Using the 'Fault' from CSV
          photo: "", // Leave empty or handle separately
          errorCode: formValues.errorCategory, // Leave empty or handle separately
        },
        status: "Initial review", // Example fixed value
      };
      console.log("Submitting warranty claim:", newWarrantyClaim); // Log the payload

      try {
        await createProductWarranty(newWarrantyClaim); // Call your API function
        successCount++;
      } catch (error) {
        console.error("Error creating warranty claim:", error);
        setSnackbar({
          open: true,
          message: "Failed to create some warranty claims.",
          severity: "error",
        });
        return;
      }
    }

    setSnackbar({
      open: true,
      message: `Successfully imported ${successCount} claims from CSV`,
      severity: "success",
    });

    setIsModalOpen(false);
    reset(); // Reset the form after submission
  };

  const selectedDeviceModel = watch("deviceModel");
  const availableErrors = getErrorCodeForDeviceModel(selectedDeviceModel);

  return (
    <Container>
      <Box
        sx={{
          width: "100%",
          mt: 4,
          gap: 2,
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <StyledButton
          variant="outlined"
          onClick={handleExistingDistributor}
          sx={{
            m: 2,
            width: 300,
            height: 100,
            backgroundColor: "white",
            fontSize: "16px",
            fontWeight: 600,
            border: "1px solid #E0E0E0",
            textTransform: "none",
            boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)",
          }}
          startIcon={
            <Business
              style={{ fontSize: 40, color: theme.palette.primary.main }}
            />
          }
        >
          Existing Distributor
        </StyledButton>
        <StyledButton
          variant="outlined"
          onClick={handleNewDistributor}
          sx={{
            m: 2,
            width: 300,
            height: 100,
            backgroundColor: "white",
            fontSize: "16px",
            fontWeight: 600,
            border: "none",
            textTransform: "none",
            boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)",
          }}
          startIcon={
            <AddBusiness
              style={{ fontSize: 40, color: theme.palette.primary.main }}
            />
          }
        >
          New Distributor
        </StyledButton>
        <StyledButton
          variant="outlined"
          onClick={handleImportClaims}
          sx={{
            m: 2,
            width: 300,
            height: 100,
            backgroundColor: "white",
            fontSize: "16px",
            fontWeight: 600,
            border: "none",
            textTransform: "none",
            boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)",
          }}
          startIcon={
            <DriveFolderUploadIcon
              style={{ fontSize: 40, color: theme.palette.primary.main }}
            />
          }
        >
          Import Claims
        </StyledButton>
      </Box>

      {/* Modal for Distributor selection */}
      <Modal open={isModalOpen} onClose={handleCloseModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 600,
            bgcolor: "#f5f5f5",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 2,
            }}
          >
            <Typography variant="h6" component="h2">
              Create Warranty
            </Typography>
            <StyledIconButton variantType="transparent" iconColor={theme.palette.primary.main} onClick={handleCloseModal}>
              <CloseIcon />
            </StyledIconButton>
          </Box>
          <Stepper activeStep={activeStep} alternativeLabel>
            {(isImportingClaims ? importSteps : steps).map((label, index) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>

          <form
            onSubmit={handleSubmit(
              activeStep === stepLength - 1 ? handleFinish : handleNext
            )}
          >
            {activeStep === 0 && isNewDistributor && (
              <Box sx={{ mt: 2 }}>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={6}>
                    <Controller
                      name="companyName"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <TextField
                          fullWidth
                          required
                          label="Company Name"
                          {...field}
                          sx={{ mt: 2 }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Controller
                      name="accountNumber"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <TextField
                          fullWidth
                          required
                          label="Account Number"
                          {...field}
                          sx={{ mt: 2 }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    {" "}
                    <Controller
                      name="email"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <TextField
                          fullWidth
                          required
                          label="Email"
                          {...field}
                          sx={{ mt: 2 }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Controller
                      name="phone"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <TextField
                          fullWidth
                          required
                          label="Phone"
                          {...field}
                          sx={{ mt: 2 }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    {" "}
                    <Controller
                      name="address"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <TextField
                          fullWidth
                          required
                          label="Address"
                          {...field}
                          sx={{ mt: 2 }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Controller
                      name="city"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <TextField
                          fullWidth
                          required
                          label="City"
                          {...field}
                          sx={{ mt: 2 }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Controller
                      name="country"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <TextField
                          fullWidth
                          required
                          label="Country"
                          {...field}
                          sx={{ mt: 2 }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Controller
                      name="region"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <TextField
                          fullWidth
                          label="Region"
                          {...field}
                          sx={{ mt: 2 }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    {" "}
                    <Controller
                      name="zip"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <TextField
                          fullWidth
                          label="Zip/Postal"
                          {...field}
                          sx={{ mt: 2 }}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Box>
            )}

            {activeStep === 0 && !isNewDistributor && (
              <Box sx={{ mt: 2 }}>
                <Controller
                  name="distributor"
                  control={control}
                  defaultValue={null} // Use null or a valid default value
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      options={distributors}
                      getOptionLabel={(option) =>
                        option?.companyName
                          ? `${option.companyName} (${option.accountNumber}) - ${option.email}`
                          : ""
                      }
                      isOptionEqualToValue={(option, value) =>
                        option.email === value?.email
                      } // Proper equality check
                      onChange={(_, value) => {
                        field.onChange(value); // Directly set the value
                        handleDistributorChange(_, value); // Call your custom handler if needed
                      }}
                      value={field.value || null} // Ensure value is valid or null
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select Distributor"
                          required
                          fullWidth
                          sx={{ mt: 2 }}
                        />
                      )}
                    />
                  )}
                />
              </Box>
            )}

            {activeStep === 1 && (
              <Box sx={{ mt: 2 }}>
                <Grid container spacing={1}>
                  {!isImportingClaims ? (
                    <>
                      <Grid item xs={12}>
                        <Controller
                          name="serialNumber"
                          control={control}
                          defaultValue=""
                          render={({ field }) => (
                            <TextField
                              fullWidth
                              required
                              label="Serial no."
                              {...field}
                              sx={{ mt: 2 }}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Controller
                          name="deviceModel"
                          control={control}
                          defaultValue=""
                          render={({ field }) => (
                            <TextField
                              fullWidth
                              select
                              required
                              label="Device Model"
                              {...field}
                              sx={{ mt: 2 }}
                              SelectProps={{ native: true }}
                            >
                              <option value="" />
                              <option value="Lotus PRO SAO Dispenser (LQFC225K)">
                                Lotus PRO SAO Dispenser (LQFC225K)
                              </option>
                              <option value="Lotus PRO SAO Dispenser (LQFC225K-FB)">
                                Lotus PRO SAO Dispenser (LQFC225K-FB)
                              </option>
                              <option value="Lotus PRO SAO Dispenser (LQFC275-FB)">
                                Lotus PRO SAO Dispenser (LQFC275-FB)
                              </option>
                              <option value="Lotus PRO SAO Dispenser (LQFC275)">
                                Lotus PRO SAO Dispenser (LQFC275)
                              </option>
                              <option value="iClean Mini (LQFC200)">
                                iClean Mini (LQFC200)
                              </option>
                              <option value="iClean PRO (LQFC300)">
                                iClean PRO (LQFC300)
                              </option>
                              <option value="SAO2GO (LQFC400)">
                                SAO2GO (LQFC400)
                              </option>
                              <option value="SAO2GO (LQFC401)">
                                SAO2GO (LQFC401)
                              </option>
                              <option value="iClean Flo (LQFC5XX)">
                                iClean Flo (LQFC5XX)
                              </option>
                            </TextField>
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Controller
                          name="purchaseDate"
                          control={control}
                          defaultValue=""
                          render={({ field }) => (
                            <TextField
                              fullWidth
                              required
                              label="Purchase Date"
                              type="date"
                              {...field}
                              sx={{ mt: 2 }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Controller
                          name="company"
                          control={control}
                          defaultValue=""
                          render={({ field }) => (
                            <TextField
                              fullWidth
                              required
                              label="Company"
                              {...field}
                              sx={{ mt: 2 }}
                            />
                          )}
                        />
                      </Grid>
                    </>
                  ) : (
                    <Grid item xs={12}>
                      <Box
                        sx={{
                          border: "2px dashed grey",
                          borderRadius: 1,
                          textAlign: "center",
                          p: 2,
                          mt: 2,
                        }}
                      >
                        <Typography variant="body2" color="textSecondary">
                          {selectedFileName
                            ? `Selected file: ${selectedFileName}`
                            : "Drag and drop file to upload..."}{" "}
                        </Typography>
                        <StyledIconButton
                          variantType="transparent"
                          iconColor={theme.palette.primary.main}
                          aria-label="upload file"
                          component="label"
                        >
                          <input
                            hidden
                            accept=".csv"
                            type="file"
                            onChange={handleFileUpload}
                          />
                          <CloudUploadIcon />
                        </StyledIconButton>
                      </Box>
                    </Grid>
                  )}

                  <Grid item xs={12} sm={6}>
                    <Controller
                      name="address"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <TextField
                          fullWidth
                          required
                          label="Address"
                          {...field}
                          sx={{ mt: 2 }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Controller
                      name="city"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <TextField
                          fullWidth
                          required
                          label="City"
                          {...field}
                          sx={{ mt: 2 }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Controller
                      name="country"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <TextField
                          fullWidth
                          required
                          label="Country"
                          {...field}
                          sx={{ mt: 2 }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Controller
                      name="region"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <TextField
                          fullWidth
                          label="Region"
                          {...field}
                          sx={{ mt: 2 }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Controller
                      name="zip"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <TextField
                          fullWidth
                          label="Zip/Postal"
                          {...field}
                          sx={{ mt: 2 }}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Box>
            )}

            {activeStep === 2 && !isImportingClaims && (
              <Box sx={{ mt: 2 }}>
                <Grid container spacing={1}>
                  <Box
                    sx={{
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                    }}
                  >
                    <Grid item xs={12} sm={10} container spacing={1}>
                      <Grid item xs={6} sm={4}>
                        <Typography
                          variant="body1"
                          sx={{ fontWeight: 500, mt: 1 }}
                        >
                          Warranty Status:
                        </Typography>
                        <Typography
                          variant="body1"
                          sx={{ fontWeight: 500, mt: 2.5 }}
                        >
                          Credit Status:
                        </Typography>
                        <Typography
                          variant="body1"
                          sx={{ fontWeight: 500, mt: 2 }}
                        >
                          Replacement Unit:
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={8}>
                        <Controller
                          name="warrantyStat"
                          control={control}
                          defaultValue="IN"
                          render={({ field }) => (
                            <RadioGroup row {...field}>
                              <FormControlLabel
                                value="IN"
                                control={<Radio />}
                                label="In"
                              />
                              <FormControlLabel
                                value="OUT"
                                control={<Radio />}
                                label="Out"
                                sx={{ ml: 1.3 }}
                              />
                            </RadioGroup>
                          )}
                        />
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Controller
                            name="creditStat"
                            control={control}
                            defaultValue="NO"
                            render={({ field }) => (
                              <RadioGroup
                                row
                                {...field}
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  //ml: 2.3,
                                }}
                              >
                                <FormControlLabel
                                  value="YES"
                                  control={<Radio />}
                                  label="Yes"
                                  sx={{ mr: 2 }}
                                />
                                <FormControlLabel
                                  value="NO"
                                  control={<Radio />}
                                  label="No"
                                  sx={{ ml: 0 }}
                                />
                              </RadioGroup>
                            )}
                          />
                          {creditStat === "YES" && (
                            <Controller
                              name="creditAmount"
                              control={control}
                              defaultValue="0"
                              render={({ field }) => (
                                <TextField
                                  label="Credit Amount"
                                  type="number"
                                  {...field}
                                  sx={{
                                    ml: 3,
                                    width: "150px",
                                    height: "36px",
                                    "& .MuiInputBase-root": {
                                      height: "36px",
                                    },
                                    "& .MuiInputBase-input": {
                                      padding: "8.5px 14px",
                                    },
                                  }}
                                />
                              )}
                            />
                          )}
                        </Box>

                        <Controller
                          name="replacementStat"
                          control={control}
                          defaultValue="NO"
                          render={({ field }) => (
                            <RadioGroup row {...field}>
                              <FormControlLabel
                                value="YES"
                                control={<Radio />}
                                label="Yes"
                              />
                              <FormControlLabel
                                value="NO"
                                control={<Radio />}
                                label="No"
                                sx={{ ml: 0 }}
                              />
                            </RadioGroup>
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                  <Grid item container spacing={1} xs={12}>
                    <Grid item xs={12} sm={4}>
                      <Controller
                        name="returnReason"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <TextField
                            fullWidth
                            select
                            label="Return Category"
                            {...field}
                            sx={{ mt: 2 }}
                            SelectProps={{ native: true }}
                          >
                            <option value={""}></option>
                            <option value="Return & Repair">
                              Return & Repair
                            </option>
                            <option value="High Priority">High Priority</option>
                            <option value="Low Flow">Low Flow</option>
                            <option value="Service">Service</option>
                            <option value="Other">Other</option>
                          </TextField>
                        )}
                      />
                    </Grid>
                    {/* change sm to 6 if you uncomment above */}
                    <Grid item xs={12} sm={8}>
                      <Controller
                        name="errorCode"
                        control={control}
                        defaultValue={[]} // Default value for multiple selections
                        render={({ field }) => (
                          <Autocomplete
                            {...field}
                            multiple
                            disableCloseOnSelect // Keep dropdown open until the user clicks outside
                            limitTags={2} // Limit the number of visible tags
                            options={availableErrors || []} // Ensure availableErrors is an array, even if empty
                            getOptionLabel={(option) =>
                              option
                                ? `${option.code} - ${option.description}`
                                : ""
                            }
                            isOptionEqualToValue={(option, value) =>
                              option?.code === value?.code
                            } // Ensure correct comparison
                            onChange={(event, value) => {
                              field.onChange(value);
                            }}
                            renderOption={(props, option, { selected }) => (
                              <li {...props}>
                                <Checkbox
                                  checked={selected}
                                  style={{ marginRight: 8 }}
                                />
                                {`${option.code} - ${option.description}`}
                              </li>
                            )}
                            renderTags={(selectedErrors, getTagProps) =>
                              selectedErrors.map((selectedError, index) => (
                                <Chip
                                  {...getTagProps({ index })}
                                  label={
                                    selectedError
                                      ? `${selectedError.description}`
                                      : ""
                                  }
                                />
                              ))
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Device Error"
                                fullWidth
                                sx={{ mt: 2 }}
                              />
                            )}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      name="description"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <TextField
                          fullWidth
                          multiline
                          rows={2}
                          label="Description"
                          {...field}
                          sx={{ mt: 2 }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Box
                      sx={{
                        border: "2px dashed grey",
                        borderRadius: 1,
                        textAlign: "center",
                        p: 2,
                        mt: 2,
                      }}
                    >
                      <Typography variant="body2" color="textSecondary">
                        Drag and drop images to upload...
                      </Typography>
                      <StyledIconButton
                        variantType="transparent"
                        iconColor={theme.palette.primary.main}
                        aria-label="upload picture"
                        component="label"
                      >
                        <input hidden accept="image/*" type="file" />
                        <CloudUploadIcon />
                      </StyledIconButton>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            )}
            {activeStep === 2 && isImportingClaims && (
              <Box sx={{ mt: 2 }}>
                <Accordion defaultExpanded={false} sx={{ mt: 2 }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="distributor-content"
                    id="distributor-header"
                  >
                    <Typography sx={{ fontWeight: 500 }}>
                      Distributor Information
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="div"
                    >
                      <strong>Company Name:</strong> {getValues().companyName}
                      <br />
                      <strong>Account Number:</strong>{" "}
                      {getValues().accountNumber}
                      <br />
                      <strong>Email:</strong> {getValues().email}
                      <br />
                      <strong>Phone:</strong> {getValues().phone}
                      <br />
                      <strong>Address:</strong> {getValues().address}
                      <br />
                      <strong>City:</strong> {getValues().city}
                      <br />
                      <strong>Country:</strong> {getValues().country}
                      <br />
                      <strong>Region:</strong> {getValues().region}
                      <br />
                      <strong>Zip:</strong> {getValues().zip}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion defaultExpanded sx={{ mt: 2 }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="csv-summary-content"
                    id="csv-summary-header"
                  >
                    <Typography sx={{ fontWeight: 500 }}>
                      CSV Summary
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="div"
                    >
                      <strong>Total Products:</strong>{" "}
                      {summaryData.totalProducts}
                      <br />
                      {Object.entries(summaryData.productCounts).map(
                        ([product, count], index) => (
                          <div key={index}>
                            <strong>{product}:</strong> {count}{" "}
                            {count > 1 ? "units" : "unit"}
                          </div>
                        )
                      )}
                    </Typography>

                    {/* Scrollable Table of Products */}
                    <TableContainer
                      sx={{ maxHeight: 200, overflowY: "auto", width: "100%" }}
                    >
                      <Table size="small" aria-label="products table">
                        <TableHead>
                          <TableRow>
                            <TableCell>Product</TableCell>
                            <TableCell align="right">Serial Number</TableCell>
                            <TableCell align="right">
                              Fault Description
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {csvData.map((row, index) => (
                            <TableRow key={index}>
                              <TableCell component="th" scope="row">
                                {row.Product}
                              </TableCell>
                              <TableCell align="right">{row.SN}</TableCell>
                              <TableCell align="right">{row.Fault}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </AccordionDetails>
                </Accordion>
              </Box>
            )}

            {activeStep === 3 && !isImportingClaims && (
              <Box sx={{ mt: 2 }}>
                <Accordion defaultExpanded sx={{ mt: 2 }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="distributor-content"
                    id="distributor-header"
                  >
                    <Typography sx={{ fontWeight: 500 }}>
                      Distributor Information
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography variant="body2" color="textSecondary">
                      <strong>Company Name:</strong> {getValues().companyName}
                      <br />
                      <strong>Account Number:</strong>{" "}
                      {getValues().accountNumber}
                      <br />
                      <strong>Email:</strong> {getValues().email}
                      <br />
                      <strong>Phone:</strong> {getValues().phone}
                      <br />
                      <strong>Address:</strong> {getValues().address}
                      <br />
                      <strong>City:</strong> {getValues().city}
                      <br />
                      <strong>Country:</strong> {getValues().country}
                      <br />
                      <strong>Region:</strong> {getValues().region}
                      <br />
                      <strong>Zip:</strong> {getValues().zip}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion defaultExpanded sx={{ mt: 2 }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="product-site-content"
                    id="product-site-header"
                  >
                    <Typography>Product Site Information</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography variant="body2" color="textSecondary">
                      <strong>Serial Number:</strong> {getValues().serialNumber}
                      <br />
                      <strong>Device Model:</strong> {getValues().deviceModel}
                      <br />
                      <strong>Purchase Date:</strong> {getValues().purchaseDate}
                      <br />
                      <strong>Company:</strong> {getValues().company}
                      <br />
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion defaultExpanded sx={{ mt: 2 }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="fault-description-content"
                    id="fault-description-header"
                  >
                    <Typography>Fault Description</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography variant="body2" color="textSecondary">
                      {/* <strong>Return Reason:</strong> {getValues().returnReason} */}
                      <strong>Device Error:</strong>{" "}
                      {getValues().errorCode &&
                        Array.isArray(getValues().errorCode)
                        ? getValues()
                          .errorCode.map(
                            (error: { code: string; description: string }) =>
                              `${error.description}`
                          )
                          .join(", ")
                        : "No error codes selected"}
                      <br />
                      <strong>Description:</strong> {getValues().description}
                      <br />
                      <span
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <span>
                          <strong>Warranty:</strong> {getValues().warrantyStat}
                        </span>
                        <span>
                          <strong>Credit: </strong> {getValues().creditStat}
                        </span>
                        <span>
                          <strong> Amount: </strong>{" "}
                          {getValues().creditAmount || 0}
                        </span>
                        <span>
                          <strong>Replacement:</strong>{" "}
                          {getValues().replacementStat}
                        </span>
                      </span>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Box>
            )}
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                mt: 2,
                gap: 1.5,
              }}
            >
              {activeStep !== 0 && (
                <StyledButton onClick={handleBack}>Back</StyledButton>
              )}
              <StyledButton
                type="submit"
                disabled={
                  (!isDistributorSelected && !isNewDistributor) ||
                  (activeStep === 2 &&
                    !watch().returnReason &&
                    !isImportingClaims)
                }
                variantType="primary"
              >
                {activeStep === stepLength - 1 ? "Finish" : "Next"}
              </StyledButton>
            </Box>
          </form>
        </Box>
      </Modal>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity as "success" | "error"}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default CreateWarrantyPage;
