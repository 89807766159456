import React from "react";
import { Box, Typography } from "@mui/material";

export const CreateHeaderWithIcon = (
  icon: any,
  text: any,
  addSeparator: boolean = true
) => {
  return () => (
    <Box
      display="flex"
      alignItems="center"
      // border="1px solid  rgba(0, 0,0, 0.1)"
      sx={{
        pt: 4,
        pb: 4,
        p: 1,
      }}
      // borderRight="1px solid rgba(255, 255, 255, 0.12)"
    >
      {/* {React.createElement(icon, {
        style: { marginRight: 8, color: "grey" },
      })} */}
      <Typography
        component="span"
        fontWeight={500}
        fontFamily={"'Poppins', sans-serif"}
        fontSize="11px"
        color="text.primary"
      >
        {text}
      </Typography>
    </Box>
  );
};
