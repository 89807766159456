import React from "react";
import { Grid, TextField, Paper, Typography } from "@mui/material";
import StyledButton from "../components/Tabs/StyledButton";

const AddAccountPage: React.FC = () => {
  return (
    <Grid container spacing={3} justifyContent="center">
      <Grid item xs={12} md={8} lg={6}>
        <Paper sx={{ padding: 4, margin: "auto", maxWidth: 500, flexGrow: 1 }}>
          <Typography variant="h6" gutterBottom>
            Add Account
          </Typography>
          <form>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField fullWidth label="Name" variant="outlined" required />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Email Address"
                  variant="outlined"
                  required
                  type="email"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField fullWidth label="Company" variant="outlined" />
              </Grid>
              <Grid item xs={12}>
                <TextField fullWidth label="Country" variant="outlined" />
              </Grid>
              <Grid item xs={12}>
                <TextField fullWidth label="Phone Number" variant="outlined" />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Birthday"
                  variant="outlined"
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <StyledButton
                  type="submit"
                  fullWidth
                  variantType="primary"
                  size="large"
                >
                  Add Account
                </StyledButton>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default AddAccountPage;
