import React, { useState, useEffect, ChangeEvent } from "react";
import { StyledTabs } from "../Tabs/IconTab";
import PlainTab from "../Tabs/PlainTab";

import axios from "axios";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
  Chip,
  IconButton,
  ListItemIcon,
  Tooltip,
  TablePagination,
  TextField,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  styled,
  SelectChangeEvent,
  InputAdornment,
  Modal,
  Button,
  Grid,
  Snackbar,
  Alert,
} from "@mui/material";
import {
  Search as SearchIcon,
  MoreVert as MoreVertIcon,
  FilterList as FilterListIcon,
  CloudDownload as CloudDownloadIcon,
  ViewComfy as ViewComfyIcon,
  Add as AddIcon,
  Delete as DeleteIcon,
  CheckCircleOutline as CheckCircleOutlineIcon,
  HourglassEmpty as HourglassEmptyIcon,
  AllInbox as AllInboxIcon,
  Close as CloseIcon,
  AssignmentTurnedIn as AssignmentTurnedInIcon,
  Reply as ReplyIcon,
} from "@mui/icons-material";
import { DeviceData } from "../../common/types/DeviceTypes";
import { set } from "date-fns";
import {
  fetchWarrantyClaims,
  updateWarrantyStatus,
} from "../../services/warranty.service";
import useAuthStore from "../../store/authStore";
import { StyledTextFieldNew } from "../Forms/StyledTextFieldNew";
import DescriptionIcon from "@mui/icons-material/Description";
import WarrantyModal from "../DistributorSupport.tsx/WarrantyModal";

interface WarrantyClaimsProps {
  devices: DeviceData[];
}
interface WarrantyClaimData {
  serialNo: string;
  distributorEmail: string;
  customerEmail: string;
  faultType: string;
  status: string;
  submittedDate: string;
  updatedAt: string;
  detailedIssue?: string;
  photo?: string;
  _id: string;
}
const IconWrapper = styled(ListItemIcon)({
  justifyContent: "center",
  minWidth: "30px", // Ensures icons are properly aligned
  "& svg": {
    fill: "#9e9e9e", // Grey fill for the icon
    stroke: "black", // Black stroke color
    strokeWidth: "1px", // Thicker stroke to make it stand out
  },
  "&:hover": {
    color: "primary.main", // Light blue background on hover
    bgColor: "primary.main",
  },
});

const WarrantyClaims: React.FC<WarrantyClaimsProps> = ({ devices }) => {
  const [value, setValue] = useState(0);
  const [selected, setSelected] = useState<string[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search, setSearch] = useState("");
  const [sortField, setSortField] = useState<string>("");
  const [claims, setClaims] = useState<WarrantyClaimData[]>([]);
  const [selectedClaim, setSelectedClaim] = useState<WarrantyClaimData | null>(
    null
  );
  const [openModal, setOpenModal] = useState(false);
  const [warrantyClaimModalOpen, setWarrantyClaimModalOpen] = useState(false);
  const [comment, setComment] = useState("");
  const { user } = useAuthStore();

  const fetchClaims = async () => {
    try {
      const response = await fetchWarrantyClaims(
        user?.email || "",
        user?.role || ""
      );
      setClaims(response);
    } catch (error) {
      console.error("Error fetching warranty claims:", error);
    }
  };

  useEffect(() => {
    if (user) {
      fetchClaims();
    }
  }, [user]);

  const [snackbar, setSnackbar] = useState<{
    open: boolean;
    message: string;
    severity: "success" | "error";
  }>({ open: false, message: "", severity: "success" });

  const handleSnackbarClose = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const handleOpenModal = (claim: WarrantyClaimData) => {
    setSelectedClaim(claim);
    setSelectedStatus(claim.status);
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedClaim(null);
  };

  const handleConfirm = async (id: string, newStatus: string) => {
    try {
      await updateWarrantyStatus(
        id,
        newStatus,
        user?.email || "unknown",
        comment
      );
      setClaims((prevClaims) =>
        prevClaims.map((claim) =>
          claim._id === id ? { ...claim, status: newStatus } : claim
        )
      );
      setSnackbar({
        open: true,
        message: "Warranty status updated successfully",
        severity: "success",
      });
      handleCloseModal(); // Close the modal after confirming
    } catch (error) {
      console.error("Failed to update warranty status:", error);
      setSnackbar({
        open: true,
        message: "Failed to update warranty status",
        severity: "error",
      });
    }
    setComment(""); // Clear the comment field after confirming
  };

  const [selectedStatus, setSelectedStatus] = useState(selectedClaim?.status);

  // useEffect(() => {
  //   if (selectedClaim) {
  //     setSelectedStatus(selectedClaim.status);
  //   }
  // }, [selectedClaim]);

  const handleStatusChange = (event: SelectChangeEvent<string>) => {
    setSelectedStatus(event.target.value);
  };

  const handleSelect = (name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = [...selected, name];
    } else {
      newSelected = selected.filter((id) => id !== name);
    }

    setSelected(newSelected);
  };

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setSelected(claims.map((claim) => claim.serialNo));
    } else {
      setSelected([]);
    }
  };

  const isSelected = (serialNo: string) => selected.includes(serialNo);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    setPage(0); // Reset the page to 0 when changing tabs
  };

  const handleCommentChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    setComment(value);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const handleSortChange = (event: SelectChangeEvent<string>) => {
    setSortField(event.target.value as string);
  };

  const filteredClaims = claims.filter(
    (claim) =>
      claim.serialNo.toLowerCase().includes(search.toLowerCase()) ||
      claim.distributorEmail.toLowerCase().includes(search.toLowerCase()) ||
      claim.customerEmail.toLowerCase().includes(search.toLowerCase()) ||
      claim.faultType.toLowerCase().includes(search.toLowerCase())
  );

  const sortedClaims = filteredClaims.sort((a, b) => {
    if (sortField === "serialNo") {
      return a.serialNo.localeCompare(b.serialNo);
    }
    if (sortField === "distributorEmail") {
      return a.distributorEmail.localeCompare(b.distributorEmail);
    }
    if (sortField === "customerEmail") {
      return a.customerEmail.localeCompare(b.customerEmail);
    }
    if (sortField === "faultType") {
      return a.faultType.localeCompare(b.faultType);
    }
    if (sortField === "status") {
      return a.status.localeCompare(b.status);
    }
    if (sortField === "submittedDate") {
      return (
        new Date(a.submittedDate).getTime() -
        new Date(b.submittedDate).getTime()
      );
    }
    return 0;
  });

  const getDisplayedClaims = () => {
    switch (value) {
      case 1:
        return sortedClaims.filter((claim) => claim.status === "Approved");
      case 2:
        return sortedClaims.filter(
          (claim) =>
            claim.status === "Initial review" ||
            claim.status === "Technical assessment"
        );
      case 3:
        return sortedClaims.filter((claim) => claim.status === "Closed");
      default:
        return sortedClaims;
    }
  };

  const displayedClaims = getDisplayedClaims().slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  return (
    <Box p={0}>
      <Box mt={0} sx={{ display: "flex", justifyContent: "center" }}>
        <Box
          mt={1}
          sx={{
            display: "flex",
            flexDirection: "column",
            width: { xs: "95%", md: "96%" },
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",

              p: 0.5,
            }}
          >
            <Typography variant="h6">Manage Warranty Claims</Typography>
          </Box>
          <StyledTabs
            value={value}
            onChange={handleChange}
            aria-label="warranty claims tabs"
          >
            <PlainTab label="All" />
            <PlainTab label="Approved" />
            <PlainTab label="Pending" />
            <PlainTab label="Closed" />
          </StyledTabs>
          <Box sx={{ p: 0 }}>
            <TableContainer
              sx={{ p: 1, my: 1, boxShadow: 0, backgroundColor: "white" }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  p: 1,
                  backgroundColor: "#f5f5f5",
                  mb: 2,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <TextField
                    variant="outlined"
                    size="small"
                    placeholder="Search"
                    value={search}
                    onChange={handleSearchChange}
                    sx={{ marginRight: 2, width: "300px" }}
                    InputProps={{
                      startAdornment: (
                        <SearchIcon sx={{ marginRight: 1, color: "gray" }} />
                      ),
                    }}
                  />
                  <FormControl
                    variant="outlined"
                    size="small"
                    sx={{ marginRight: 2, width: "150px" }}
                  >
                    <InputLabel>Sort By</InputLabel>
                    <Select
                      value={sortField}
                      onChange={handleSortChange}
                      label="Sort By"
                    >
                      <MenuItem value="">None</MenuItem>
                      <MenuItem value="serialNo">Serial No.</MenuItem>
                      <MenuItem value="distributorEmail">
                        Distributor Email
                      </MenuItem>
                      <MenuItem value="customerEmail">Customer Email</MenuItem>
                      <MenuItem value="faultType">Fault Type</MenuItem>
                      <MenuItem value="status">Status</MenuItem>
                      <MenuItem value="submittedDate">Submitted Date</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    padding: 1,
                  }}
                >
                  <Tooltip title="Add New Claim">
                    <IconButton onClick={() => setWarrantyClaimModalOpen(true)}>
                      <IconWrapper>
                        <AddIcon />
                      </IconWrapper>
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Filter list">
                    <IconButton>
                      <IconWrapper>
                        <FilterListIcon />
                      </IconWrapper>
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="View">
                    <IconButton>
                      <IconWrapper>
                        <ViewComfyIcon />
                      </IconWrapper>
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Download CSV">
                    <IconButton>
                      <IconWrapper>
                        <CloudDownloadIcon />
                      </IconWrapper>
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="More options">
                    <IconButton>
                      <IconWrapper>
                        <MoreVertIcon />
                      </IconWrapper>
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>

              {selected.length > 0 && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    alignContent: "center",
                    padding: 1,
                    backgroundColor: "#f5f5f5",
                    px: 2,
                  }}
                >
                  <Typography>{selected.length} selected</Typography>
                  <Tooltip title="Delete Selected">
                    <IconButton>
                      <IconWrapper>
                        <DeleteIcon />
                      </IconWrapper>
                    </IconButton>
                  </Tooltip>
                </Box>
              )}

              <Table sx={{ minWidth: 650 }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Checkbox
                        indeterminate={
                          selected.length > 0 && selected.length < claims.length
                        }
                        checked={
                          claims.length > 0 && selected.length === claims.length
                        }
                        onChange={handleSelectAll}
                        inputProps={{ "aria-label": "select all claims" }}
                      />
                    </TableCell>
                    <TableCell>Serial No.</TableCell>
                    <TableCell>Distributor Email</TableCell>
                    <TableCell>Customer Email</TableCell>
                    <TableCell>Device</TableCell>
                    <TableCell>Fault Type</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Submitted Date</TableCell>
                    <TableCell>Last Update</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {displayedClaims.map((claim) => (
                    <TableRow
                      key={claim._id}
                      onClick={() => handleOpenModal(claim)}
                      hover
                      selected={isSelected(claim._id)}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isSelected(claim._id)}
                          onChange={() => handleSelect(claim._id)}
                        />
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Typography variant="body2">
                          {claim.serialNo}
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography variant="body2">
                          {claim.distributorEmail}
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography variant="body2">
                          {claim.customerEmail}
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography variant="body2">
                          {
                            devices.find(
                              (device) =>
                                device.deviceInformation.deviceId ===
                                claim.serialNo
                            )?.deviceType
                          }
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography variant="body2">
                          {claim.faultType}
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Chip
                          label={claim.status}
                          color={
                            claim.status === "Approved"
                              ? "success"
                              : claim.status === "Initial review"
                              ? "info"
                              : claim.status === "Technical assessment"
                              ? "warning"
                              : "error"
                          }
                          sx={{
                            fontWeight: 700,
                            fontSize: "10px",
                            borderRadius: "5px",
                          }}
                          size="small"
                        />
                      </TableCell>
                      <TableCell align="left">
                        <Typography variant="body2" color="textSecondary">
                          {new Date(claim.submittedDate).toLocaleDateString()}
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography variant="body2" color="textSecondary">
                          {new Date(claim.updatedAt).toLocaleDateString()}{" "}
                          {/* Updated Date */}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={getDisplayedClaims().length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                sx={{
                  mr: 1,
                }}
              />
              <Modal
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
              >
                <Box
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: { xs: "80%", sm: "70%", md: "40%" },
                    maxHeight: "90vh",
                    overflowY: "auto",
                    bgcolor: "#f5f5f5",
                    p: { xs: 2, sm: 3, md: 3 },
                    fontFamily: "'Poppins', sans-serif",
                    borderRadius: 1,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      mb: 1,
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        fontWeight: 500,
                        color: "grey.800",
                        textAlign: "center",
                      }}
                    >
                      Claim Details
                    </Typography>
                    <IconButton onClick={handleCloseModal} color="inherit">
                      <CloseIcon sx={{ color: "primary.main" }} />
                    </IconButton>
                  </Box>

                  {selectedClaim && (
                    <Paper
                      elevation={1}
                      sx={{
                        marginBottom: 2,
                        backgroundColor: "#fff",
                        p: 1,
                      }}
                    >
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Typography variant="body1" sx={{ fontWeight: 500 }}>
                            Device Details:
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            label="Serial No"
                            value={selectedClaim.serialNo}
                            fullWidth
                            margin="normal"
                            InputProps={{
                              readOnly: true,
                            }}
                            variant="outlined"
                            //disabled
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            label="Distributor"
                            value={selectedClaim.distributorEmail}
                            fullWidth
                            margin="normal"
                            InputProps={{
                              readOnly: true,
                            }}
                            variant="outlined"
                            //disabled
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            label="Customer"
                            value={selectedClaim.customerEmail}
                            fullWidth
                            margin="normal"
                            InputProps={{
                              readOnly: true,
                            }}
                            variant="outlined"
                            //disabled
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            label="Fault Type"
                            value={selectedClaim.faultType}
                            fullWidth
                            margin="normal"
                            InputProps={{
                              readOnly: true,
                            }}
                            variant="outlined"
                            // disabled
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            label="Detailed Issue"
                            value={
                              selectedClaim.detailedIssue ||
                              "No detailed issue provided."
                            }
                            fullWidth
                            margin="normal"
                            InputProps={{
                              readOnly: true,
                            }}
                            variant="outlined"
                            multiline
                            rows={4}
                            //disabled
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <FormControl fullWidth margin="normal">
                            <InputLabel id="status-label">Status</InputLabel>
                            <Select
                              labelId="status-label"
                              label="Status"
                              name="status"
                              value={selectedStatus}
                              onChange={handleStatusChange}
                            >
                              <MenuItem value="Initial review">
                                Initial review
                              </MenuItem>
                              <MenuItem value="Technical assessment">
                                Technical Assessment
                              </MenuItem>
                              <MenuItem value="Approved">Approved</MenuItem>
                              <MenuItem value="Closed">Closed</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                        {selectedClaim.photo && (
                          <Grid item xs={12}>
                            <img
                              src={selectedClaim.photo}
                              alt="Claim Photo"
                              style={{ width: "100%", marginTop: "10px" }}
                            />
                          </Grid>
                        )}
                        <Grid item xs={12}>
                          <StyledTextFieldNew
                            label="Comment"
                            name="Comment"
                            onChange={handleCommentChange}
                            value={comment}
                            required
                            fullWidth
                            margin="normal"
                            multiline
                            rows={2}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <IconWrapper>
                                    <DescriptionIcon />
                                  </IconWrapper>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Paper>
                  )}

                  <Box
                    sx={{
                      mt: 2,
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                  >
                    <Button
                      onClick={() => {
                        if (selectedClaim && selectedStatus) {
                          handleConfirm(selectedClaim._id, selectedStatus);
                        }
                      }}
                      variant="contained"
                      sx={{
                        fontFamily: "'Poppins', sans-serif",
                        fontWeight: 600,
                        fontSize: "12px",
                        bgcolor: "rgba(30, 58, 138, 0.9)",
                        mr: 1,
                      }}
                    >
                      Confirm
                    </Button>
                  </Box>
                </Box>
              </Modal>
              <WarrantyModal
                open={warrantyClaimModalOpen}
                onClose={() => setWarrantyClaimModalOpen(false)}
                onSubmit={() => {
                  setSnackbar({
                    open: true,
                    message: "Warranty claim submitted successfully.",
                    severity: "success",
                  });
                  fetchClaims();
                }}
                onError={() => {
                  setSnackbar({
                    open: true,
                    message:
                      "Failed to submit warranty claim. Please try again.",
                    severity: "error",
                  });
                }}
                devices={devices}
                // data={null}
              />
            </TableContainer>
          </Box>
        </Box>
      </Box>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }} // Positioning at the top center
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbar.severity}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default WarrantyClaims;
