import React, { useState, useMemo } from "react";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Checkbox,
  Tooltip,
  Chip,
  SelectChangeEvent,
  MenuItem,
  Select,
  FormControl,
  Alert,
  AlertColor,
  Snackbar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  OpenInNew as OpenIcon,
} from "@mui/icons-material";
import { User } from "../../store/authStore";
import { DeviceData } from "../../common/types/DeviceTypes";
import {
  ChangeRequestPayload,
  ChangeRequestTypes,
} from "../../common/types/ChangeRequestTypes";
import { createChangeRequest } from "../../services/change-request.service";
import EditDeviceDetailsModal from "./EditDeviceDetailsModal";
import StyledButton from "../Tabs/StyledButton";
import StyledIconButton from "../Tabs/StyledIconButton";

const DeviceHistoryTable: React.FC<{
  devices: DeviceData[];
  onRowClick: (device: DeviceData) => void;
  user: User | null;
}> = ({ devices, onRowClick, user }) => {
  const [selected, setSelected] = useState<string[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [locationFilter, setLocationFilter] = useState<string | null>(null);
  const [deviceData, setDeviceData] = useState<DeviceData | null>(null);
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success" as AlertColor,
  });

  const handleOpenEditModal = (data: DeviceData) => {
    setEditModalOpen(true);
    setDeviceData(data);
  };

  const handleCloseEditModal = () => {
    setEditModalOpen(false);
    setDeviceData(null);
  };

  const handleOpenDeleteModal = (data: DeviceData) => {
    setDeleteModalOpen(true);
    setDeviceData(data);
  };

  const handleCloseDeleteModal = () => {
    setDeleteModalOpen(false);
    setDeviceData(null);
  };

  const handleSaveDeviceDetails = (response: any) => {
    setSnackbar({
      open: true,
      message: "A request has been Submitted to Update Device Details",
      severity: "warning",
    });
    handleCloseEditModal();
    setDeviceData(null);
  };

  const handleError = (message: string) => {
    setSnackbar({ open: true, message, severity: "error" });
    setDeviceData(null);
  };

  // Change the page to the specific page of the device
  const handleClick = (device: DeviceData) => {
    onRowClick(device);
  };

  const handleDeleteDevice = async () => {
    try {
      if (!user) return;

      const changeRequestPayload: ChangeRequestPayload = {
        type: ChangeRequestTypes.DeleteDevice,
        userEmail: user?.email,
        userRole: user?.role,
        details: {
          deviceId: deviceData?.deviceInformation.deviceId,
        },
      };
      // Send the change request to the server
      await createChangeRequest(changeRequestPayload);

      // Show success message
      setSnackbar({
        open: true,
        message: `Change Request delete to device: ${deviceData?.deviceInformation.deviceId} created successfully`,
        severity: "warning",
      });
    } catch (error: any) {
      console.error("Error creating change request:", error.message);
      setSnackbar({
        open: true,
        message: error.message
          ? error.message
          : "Failed to create change request.",
        severity: "error",
      });
    }
  };

  // Extract unique countries from devices
  const availableCountries = Array.from(
    new Set(
      devices.map((device) => device.deviceLocation?.country).filter(Boolean)
    )
  );

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setSelected(devices.map((row) => row.deviceInformation.serial));
    } else {
      setSelected([]);
    }
  };

  const handleSelect = (id: string) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = [...selected, id];
    } else {
      newSelected = selected.filter((itemId) => itemId !== id);
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const isSelected = (id: string) => selected.includes(id);

  const handleLocationFilterChange = (event: SelectChangeEvent<string>) => {
    setLocationFilter(event.target.value === "All" ? null : event.target.value);
  };

  const filteredDevices = useMemo(() => {
    return devices.filter((device) => {
      return (
        !locationFilter || device.deviceLocation?.country === locationFilter
      );
    });
  }, [devices, locationFilter]);

  return (
    <Box sx={{ p: 0 }}>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer>
          <Table stickyHeader aria-label="device table">
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    indeterminate={
                      selected.length > 0 && selected.length < devices.length
                    }
                    checked={
                      devices.length > 0 && selected.length === devices.length
                    }
                    onChange={handleSelectAll}
                  />
                </TableCell>
                <TableCell>Serial Number</TableCell>
                <TableCell>Device Name</TableCell>
                <TableCell>
                  <FormControl
                    variant="standard"
                    sx={{
                      "& .MuiInputBase-root": {
                        border: "none",
                        boxShadow: "none",
                      },
                      "& .MuiInputBase-root:before": {
                        borderBottom: "none",
                      },
                      "& .MuiInputBase-root:after": {
                        borderBottom: "none",
                      },
                    }}
                  >
                    <Select
                      value={locationFilter || "All"}
                      onChange={handleLocationFilterChange}
                      displayEmpty
                      sx={{
                        fontWeight: "600",
                        fontSize: 11,
                      }}
                    >
                      <MenuItem value="All">Location</MenuItem>
                      {availableCountries.map((country) => (
                        <MenuItem key={country} value={country}>
                          {country}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </TableCell>
                <TableCell>Customer Email</TableCell>
                <TableCell>Installer Email</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Last Ping</TableCell>
                <TableCell align="center">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredDevices
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  const isItemSelected = isSelected(
                    row.deviceInformation.serial
                  );
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row._id}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          onChange={() =>
                            handleSelect(row.deviceInformation.serial)
                          }
                        />
                      </TableCell>
                      <TableCell>{row.deviceInformation.serial}</TableCell>
                      <TableCell>{row.deviceInformation.deviceName}</TableCell>
                      <TableCell>{row.deviceLocation?.country}</TableCell>
                      <TableCell>
                        {row.deviceInformation.customerEmail}
                      </TableCell>
                      <TableCell>
                        {row.deviceInformation.installerEmail}
                      </TableCell>
                      <TableCell>
                        <Chip
                          label={row.pingStatus.status}
                          size="small"
                          color={
                            row.pingStatus.status === "ONLINE"
                              ? "success"
                              : row.pingStatus.status === "OFFLINE"
                                ? "error"
                                : "default"
                          }
                          sx={{ fontWeight: 600 }}
                        />
                      </TableCell>
                      <TableCell>
                        {new Date(row.pingStatus.timestamp).toLocaleString()}
                      </TableCell>
                      <TableCell sx={{ display: "flex" }} align="center">
                        <Tooltip title="Open">
                          <StyledIconButton variantType="transparent" onClick={() => handleClick(row)}>
                            <OpenIcon />
                          </StyledIconButton>
                        </Tooltip>
                        {user &&
                          ["Distributor", "SuperAdmin", "Admin"].includes(
                            user.role
                          ) && (
                            <Box sx={{ display: "flex" }}>
                              <Tooltip title="Edit">
                                <StyledIconButton
                                  variantType="transparent"
                                  onClick={() => handleOpenEditModal(row)}
                                >
                                  <EditIcon />
                                </StyledIconButton>
                              </Tooltip>
                              <Tooltip title="Delete">
                                <StyledIconButton
                                  variantType="transparent"
                                  onClick={() => handleOpenDeleteModal(row)}
                                >
                                  <DeleteIcon />
                                </StyledIconButton>
                              </Tooltip>
                            </Box>
                          )}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={filteredDevices.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      {deviceData && (
        <EditDeviceDetailsModal
          open={isEditModalOpen}
          onClose={handleCloseEditModal}
          device={deviceData}
          onSave={handleSaveDeviceDetails}
          onError={handleError}
        />
      )}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity={snackbar.severity} sx={{ width: "100%" }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
      <Dialog open={isDeleteModalOpen} onClose={handleCloseDeleteModal}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this device?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <StyledButton onClick={handleCloseDeleteModal}>Cancel</StyledButton>
          <StyledButton
            onClick={() => {
              handleDeleteDevice();
              handleCloseDeleteModal();
            }}
            variantType="destructive"
          >
            Delete
          </StyledButton>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default DeviceHistoryTable;
