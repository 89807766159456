import React, { useState, useEffect } from "react";
import {
  Modal,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Chip,
  Paper,
  Box,
  Typography,
  TableContainer,
  TablePagination,
  TablePaginationProps,
  TextField,
  InputAdornment,
  RadioGroup,
  FormControlLabel,
  Radio,
  Autocomplete,
  Checkbox,
  Divider,
  List,
  ListItem,
  ListItemText,
  Snackbar,
  Alert,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  SelectChangeEvent,
} from "@mui/material";
import CropFreeIcon from "@mui/icons-material/CropFree";
import WarningIcon from "@mui/icons-material/Warning";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs, { Dayjs } from "dayjs";
import { StyledTabs } from "../Tabs/IconTab";
import PlainTab from "../Tabs/PlainTab";
import { InventoryPart } from "../../common/types/InventoryPartTypes";
import { ProductWarranty } from "../../common/types/ProductWarrantyTypes";
import { fetchAllProductWarranties } from "../../services/product-warranty.service";
import { fetchDeviceArrivals } from "../../services/warranty.service";
import { fetchInventoryParts } from "../../services/inventory.service";
import useAuthStore from "../../store/authStore";
import {
  fetchAllDeviceReworks,
  fetchDeviceReworksByTester,
  createDeviceRework,
} from "../../services/device-rework.service";
import { DeviceRework } from "../../common/types/DeviceReworkTypes";
import EditDeviceReworkModal from "./EditDeviceReworkModal";
import StepperMenu from "../Menu/StepperMenu";
import StyledButton from "../Tabs/StyledButton";
import theme from "../../theme";

interface DeviceArrivalData {
  warrantyClaim: string;
  status: string;
  arrivalDate: string;
  updatedAt: string;
  shippingDetails?: string;
  detailedIssue?: string;
  photo?: string;
  _id: string;
}

const ManageProdDeviceReworks: React.FC = () => {
  const { user } = useAuthStore();
  const [deviceReworks, setDeviceReworks] = useState<DeviceRework[]>([]);
  const [arrivals, setArrivals] = useState<DeviceArrivalData[]>([]);
  const [warrantiesMap, setWarrantiesMap] = useState<
    Map<string, ProductWarranty>
  >(new Map());
  const [selectedArrival, setSelectedArrival] =
    useState<DeviceArrivalData | null>(null);
  const [isUpdateModalOpen, setUpdateModalOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedDate, setSelectedDate] = useState<Dayjs | null>(null);
  const [selectedRework, setSelectedRework] = useState<DeviceRework | null>(
    null
  );
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedTab, setSelectedTab] = useState(0);
  const [activeStep, setActiveStep] = useState(0);
  const [barcode, setBarcode] = useState("");
  const [selectedParts, setSelectedParts] = useState<InventoryPart[]>([]);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [availableParts, setAvailableParts] = useState<InventoryPart[]>([]);
  const [snackbar, setSnackbar] = useState<{
    open: boolean;
    message: string;
    severity: "success" | "error";
  }>({ open: false, message: "", severity: "success" });

  const [formData, setFormData] = useState({
    warrantyClaim: selectedArrival?.warrantyClaim || "",
    reworkType: "",
    deviceId: "",
    newDeviceId: "",
    reworkDate: dayjs() as Dayjs,
    rootCauseOfRepair: [] as string[],
    otherRootCause: "" as string,
    correctiveAction: "",
    isRootSameAsError: false,
  });

  useEffect(() => {
    if (!user) return;
    const fetchData = async () => {
      try {
        const [arrivalsResponse, warrantiesResponse] = await Promise.all([
          fetchDeviceArrivals(user.email, user.role),
          fetchAllProductWarranties(),
        ]);

        setArrivals(arrivalsResponse);
        const warrantiesMap = new Map(
          warrantiesResponse.map((warranty: ProductWarranty) => [
            warranty._id,
            warranty,
          ])
        );
        setWarrantiesMap(warrantiesMap);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    if (user?.role === "Tester") {
      fetchData();
    } else if (
      user?.role === "Inventory" ||
      (user?.role === "Supervisor" && selectedTab === 0)
    ) {
      setSelectedTab(1);
      fetchDeviceReworks();
    }
  }, [user, selectedTab]);

  const fetchDeviceReworks = async () => {
    if (!user) return;
    if (user?.role === "Tester") {
      const response = await fetchDeviceReworksByTester(user.email);
      setDeviceReworks(response);
    } else if (user?.role === "Inventory" || user?.role === "Supervisor") {
      const response = await fetchAllDeviceReworks();
      setDeviceReworks(response);
    }
  };

  // List of part numbers to exclude
  const excludedPartNumbers = [
    "25190",
    "25261",
    "25266",
    "25334",
    "25360",
    "25104",
    "25070",
    "25032",
    "25063",
    "25178",
    "25180",
    "25172",
    "25360",
    "25159",
    "25182",
    "25201",
    "25205",
    "25158",
  ];

  const fetchInventory = async () => {
    try {
      const response = await fetchInventoryParts();
      // Filter out parts with excluded part numbers
      const filteredParts = response.filter(
        (part: { number: string }) => !excludedPartNumbers.includes(part.number)
      );
      setAvailableParts(filteredParts);
    } catch (error) {
      console.error("Failed to fetch inventory parts:", error);
    }
  };

  const handleUpdateRework = async () => {
    try {
      setSnackbar({
        open: true,
        message: "Device Rework Updated Successfully",
        severity: "success",
      });
      fetchDeviceReworks(); // Refresh the list
    } catch (error) {
      console.error("Failed to complete Rework:", error);
      setSnackbar({
        open: true,
        message: "Failed to complete Rework",
        severity: "error",
      });
    }
  };

  const steps = [
    "Scan Barcode",
    "Preview Warranty Details",
    "Run Diagnostics",
    "Select Parts",
  ];

  const handleChangePage: TablePaginationProps["onPageChange"] = (
    event,
    newPage
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage: React.ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  > = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Filter by search query
  const searchFilteredReworks = deviceReworks.filter(
    (rework: DeviceRework) =>
      rework.deviceId &&
      rework.deviceId.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Further filter by selected date if a date is selected
  const finalFilteredReworks = selectedDate
    ? searchFilteredReworks.filter((Rework) =>
        dayjs(Rework.createdAt).isSame(selectedDate, "day")
      )
    : searchFilteredReworks;

  const handleSelectPart = (part: InventoryPart) => {
    if (selectedParts.some((selected) => selected._id === part._id)) {
      // Remove the part if it's already selected
      const newSelectedParts = selectedParts.filter(
        (selected) => selected._id !== part._id
      );
      setSelectedParts(newSelectedParts);
      // Update the "Select All" checkbox state
      setSelectAllChecked(newSelectedParts.length === availableParts.length);
    } else {
      // Add the part with a default quantity of 1
      const newSelectedParts = [...selectedParts, { ...part, quantity: 1 }];
      setSelectedParts(newSelectedParts);
      // Update the "Select All" checkbox state
      setSelectAllChecked(newSelectedParts.length === availableParts.length);
    }
  };

  // Function to handle "Select All"
  const handleSelectAll = () => {
    if (selectAllChecked) {
      // Deselect all
      setSelectedParts([]);
    } else {
      // Select all parts, adding a default quantity of 1
      const allPartsWithQuantity = availableParts.map((part) => ({
        ...part,
        quantity: 1,
      }));
      setSelectedParts(allPartsWithQuantity);
    }
    // Toggle "Select All" checkbox state
    setSelectAllChecked(!selectAllChecked);
  };

  const handleQuantityChange = (id: string, qty: number) => {
    setSelectedParts(
      selectedParts.map((part) =>
        part._id === id ? { ...part, quantity: qty } : part
      )
    );
  };

  const filteredParts = availableParts.filter((part) =>
    part.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
    if (newValue === 1) {
      fetchDeviceReworks();
    }
  };

  const getSerialNumber = (warrantyClaimId: string) => {
    const warranty = warrantiesMap.get(warrantyClaimId);
    return warranty ? warranty.productSiteInformation.serialNumber : "Unknown";
  };

  const getDeviceModel = (warrantyClaimId: string) => {
    const warranty = warrantiesMap.get(warrantyClaimId);
    return warranty ? warranty.productSiteInformation.deviceModel : "Unknown";
  };

  const getReturnReason = (warrantyClaimId: string) => {
    const warranty = warrantiesMap.get(warrantyClaimId);
    return warranty ? warranty.faultDescription.returnReason : "Unknown";
  };

  const getDetailedIssue = (warrantyClaimId: string) => {
    const warranty = warrantiesMap.get(warrantyClaimId);
    return warranty ? warranty.faultDescription.description : "Unknown";
  };

  const getDeviceError = (warrantyClaimId: string) => {
    const warranty = warrantiesMap.get(warrantyClaimId);
    if (warranty && warranty.faultDescription?.errorCode) {
      return Array.isArray(warranty.faultDescription.errorCode)
        ? warranty.faultDescription.errorCode
            .map((error) => error.description) // Extract descriptions
            .join(", ") // Join them into a comma-separated string
        : "Unknown";
    }
    return "Unknown";
  };

  const handleReworkSubmit = async () => {
    // Prepare the rootCauseOfRepair array
    let rootCauseOfRepairArray = Array.isArray(formData.rootCauseOfRepair)
      ? [...formData.rootCauseOfRepair]
      : [formData.rootCauseOfRepair];

    // If "Other" is included, add formData.otherRootCause to the array
    if (
      rootCauseOfRepairArray.includes("Other") &&
      typeof formData.otherRootCause === "string" &&
      formData.otherRootCause.trim() !== ""
    ) {
      rootCauseOfRepairArray = rootCauseOfRepairArray.filter(
        (cause) => cause !== "Other"
      ); // Remove "Other" if it's already there
      rootCauseOfRepairArray.push(formData.otherRootCause);
    }
    const newRework = {
      warrantyClaim: selectedArrival?.warrantyClaim,
      deviceId: formData.deviceId,
      tester: user?.email, // Set tester automatically to logged-in user
      reworkDate: formData.reworkDate
        ? formData.reworkDate.toDate().toISOString()
        : undefined,
      rootCauseOfRepair: rootCauseOfRepairArray,
      correctiveAction: formData.correctiveAction,
      //status: "IN PROGRESS", // Default status
      reworkType: formData.reworkType,
      ...(formData.reworkType === "SCRAPPED" && {
        newDeviceId: formData.newDeviceId,
      }), // Add newSerialNumber if scrapped
      isRootSameAsError: formData.isRootSameAsError,
      parts: selectedParts,
    };

    try {
      await createDeviceRework(newRework); // Assuming createDeviceRework is imported
      setSnackbar({
        open: true,
        message: "Device rework created successfully!",
        severity: "success",
      });
    } catch (error) {
      console.error("Failed to create device rework:", error);
      setSnackbar({
        open: true,
        message: "Failed to create device rework.",
        severity: "error",
      });
    }
  };

  const handleSelectChange = (event: SelectChangeEvent) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleNext = () => {
    if (activeStep === steps.length - 1) {
      handleReworkSubmit();
      setActiveStep(0);
      setBarcode("");
      setSelectedParts([]);
      setFormData({
        warrantyClaim: "",
        deviceId: "",
        reworkDate: dayjs() as Dayjs,
        rootCauseOfRepair: [] as string[],
        otherRootCause: "",
        correctiveAction: "",
        reworkType: "",
        newDeviceId: "",
        isRootSameAsError: false,
      });
    } else if (activeStep === steps.length - 1) {
      setActiveStep(0);
    } else if (activeStep === 0 && barcode) {
      const filtered = arrivals.filter((arrival) => {
        const warranty = warrantiesMap.get(arrival.warrantyClaim);

        const serialNumber =
          warranty?.productSiteInformation.serialNumber?.toLowerCase() || "";

        const searchTerm = barcode.toLowerCase();

        return (
          serialNumber.includes(searchTerm) ||
          arrival.warrantyClaim.toLowerCase().includes(searchTerm)
        );
      });

      setSelectedArrival(filtered[0]);
      setActiveStep((prevStep) => prevStep + 1);
    } else if (activeStep === 1) {
      setActiveStep((prevStep) => prevStep + 1);
    } else if (activeStep === 2) {
      try {
        fetchInventory();
      } catch (error) {
        console.error("Failed to fetch inventory parts:", error);
      }
      setActiveStep((prevStep) => prevStep + 1);
    } else {
      setActiveStep((prevStep) => prevStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const handleCancel = () => {
    setActiveStep(0);
    setBarcode("");
    setSelectedParts([]);
    setFormData({
      warrantyClaim: "",
      deviceId: "",
      reworkDate: dayjs() as Dayjs,
      rootCauseOfRepair: [] as string[],
      otherRootCause: "",
      correctiveAction: "",
      reworkType: "",
      newDeviceId: "",
      isRootSameAsError: false,
    });
  };

  // Options for the Autocomplete
  const rootCauseOptions = [
    "Water In Generator",
    "Blown Fuse",
    "Failed Offgas",
    "Failed Flo Meter",
    "Failed Water Solanoid",
    "Faulty Coil",
    "Failed Check Valve",
    "Failed HCU Board",
    "Displ. Ribbon( Repl. Cart.)",
    "Flow Meter (Broken Barb)",
    "Oz. Gen. (Service/Burned)",
    "Broken Tabs",
    "Broken Footing",
    "Stained",
    "Sticker",
    "Other",
  ];

  const correctiveActionOptions = [
    "Replace Parts",
    "Salvage Parts",
    "Upgrade Device",
    "Replace and Upgrade",
    "Other",
    "No Action",
    "WIP: Replace Parts",
    "WIP: Salvage Parts",
    "WIP: Upgrade Device",
    "WIP: Replace and Upgrade",
    "WIP: Other",
    "WIP: No Action",
  ];

  const getStepContent = (step: number) => {
    const deviceError = selectedArrival
      ? getDeviceError(selectedArrival.warrantyClaim)
      : "Unknown";

    switch (step) {
      case 0:
        return (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              //height: "100vh", // Vertically center
            }}
          >
            <Typography sx={{ mb: 2, mt: 2 }} variant="h6">
              Scan Device Barcode
            </Typography>
            <TextField
              label="Device Barcode"
              variant="outlined"
              value={barcode}
              onChange={(e) => setBarcode(e.target.value)}
              autoFocus
              sx={{ mb: 3, width: "80%" }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <CropFreeIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        );
      case 1:
        return (
          <Box>
            {!selectedArrival && (
              <Typography variant="body1" sx={{ fontWeight: 500 }}>
                No device found with the given barcode. Please try again.
              </Typography>
            )}
            {selectedArrival && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 2,
                  width: "90%",
                  mx: "auto",
                }}
              >
                <Typography variant="body1" sx={{ fontWeight: 500 }}>
                  Device Details:
                </Typography>

                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "repeat(2, 1fr)", // Two equal-width columns
                    gap: 2,
                  }}
                >
                  <Box>
                    <Typography variant="subtitle2">Serial Number:</Typography>
                    <Typography variant="body1" sx={{ fontWeight: 500 }}>
                      {getSerialNumber(selectedArrival.warrantyClaim)}
                    </Typography>
                  </Box>

                  <Box>
                    <Typography variant="subtitle2">Device Model:</Typography>
                    <Typography variant="body1" sx={{ fontWeight: 500 }}>
                      {getDeviceModel(selectedArrival.warrantyClaim)}
                    </Typography>
                  </Box>
                </Box>

                <Box>
                  <Typography variant="subtitle2">Device Error:</Typography>
                  <Typography variant="body1" sx={{ fontWeight: 500 }}>
                    {deviceError}
                    {deviceError.includes("High Priority") && (
                      <WarningIcon
                        fontSize="medium"
                        sx={{ color: "red", ml: 1 }}
                      />
                    )}
                    <br />
                    {getReturnReason(selectedArrival.warrantyClaim)}
                    <br />
                    {getDetailedIssue(selectedArrival.warrantyClaim)}
                  </Typography>
                </Box>

                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography variant="h5">
                    Is the root cause same as device error?
                  </Typography>
                  <RadioGroup
                    value={formData.isRootSameAsError}
                    onChange={handleInputChange}
                    name="isRootSameAsError"
                    sx={{ ml: 2, display: "inline-block" }}
                  >
                    <FormControlLabel
                      value={true}
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                </Box>
              </Box>
            )}
          </Box>
        );
      case 2:
        return (
          <Box sx={{ mt: 2, mb: 2, width: "90%", mx: "auto" }}>
            <RadioGroup
              row
              value={formData.reworkType}
              name="reworkType"
              onChange={handleInputChange}
            >
              <FormControlLabel
                value="REPAIRED"
                control={<Radio />}
                label="Repaired"
              />
              <FormControlLabel
                value="SCRAPPED"
                control={<Radio />}
                label="Scrapped"
              />
              <FormControlLabel
                value="UPGRADED"
                control={<Radio />}
                label="Upgraded"
              />
            </RadioGroup>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <TextField
                label="Device ID"
                name="deviceId"
                value={
                  (formData.deviceId = getSerialNumber(
                    selectedArrival ? selectedArrival.warrantyClaim : ""
                  ))
                }
                onChange={handleInputChange}
                margin="normal"
                aria-readonly
              />
              {(formData.reworkType === "REPAIRED" ||
                formData.reworkType === "UPGRADED") && (
                <TextField
                  label="New Serial Number"
                  name="newDeviceId"
                  value={formData.newDeviceId}
                  onChange={handleInputChange}
                  margin="normal"
                  sx={{ mt: 2 }}
                />
              )}
              <DatePicker
                label="Rework Date"
                value={formData.reworkDate}
                readOnly
                //onChange={handleDateChange}
                sx={{ mt: 2 }}
              />
            </Box>
            <Autocomplete
              multiple
              options={rootCauseOptions}
              value={formData.rootCauseOfRepair}
              onChange={(event, newValue) => {
                // Handle multiple selection changes
                setFormData((prevData) => ({
                  ...prevData,
                  rootCauseOfRepair: newValue,
                }));
              }}
              disableCloseOnSelect
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox checked={selected} style={{ marginRight: 8 }} />
                  <ListItemText primary={option} />
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Root Cause Of Repair/Scrap"
                  placeholder="Select root causes"
                  fullWidth
                  margin="normal"
                />
              )}
            />

            {/* Conditional rendering for the "Other Root Cause" text field */}
            {formData.rootCauseOfRepair.includes("Other") && (
              <TextField
                label="Other Root Cause"
                name="otherRootCause"
                value={formData.otherRootCause}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
              />
            )}
            <FormControl fullWidth margin="normal">
              <InputLabel>Corrective Action</InputLabel>
              <Select
                label="Corrective Action"
                name="correctiveAction"
                value={formData.correctiveAction}
                onChange={handleSelectChange}
              >
                <MenuItem value="None">None</MenuItem>
                {correctiveActionOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {/* <TextField
              label="Corrective Action"
              name="correctiveAction"
              value={formData.correctiveAction}
              onChange={handleInputChange}
              fullWidth
              margin="normal"
              multiline
              maxRows={2}
              sx={{ mb: 2 }}
              required
            /> */}
            {/* <Typography variant="body2">
              Is the root cause same as device error:
            </Typography>
            <RadioGroup
              row
              value={formData.isRootSameAsError}
              name="isRootSameAsError"
              onChange={handleInputChange}
            >
              <FormControlLabel value={true} control={<Radio />} label="Yes" />
              <FormControlLabel value={false} control={<Radio />} label="No" />
            </RadioGroup> */}
          </Box>
        );
      case 3:
        return (
          <Box sx={{ mt: 3, height: 600 }}>
            <Box sx={{ display: "flex", gap: 2, mt: 3 }}>
              {/* Available Parts Box with Autocomplete */}
              <Paper
                elevation={1}
                sx={{
                  flex: 1,
                  p: 2,
                  overflow: "auto",
                  height: "550px",
                }}
              >
                <Typography variant="h6">All Inventory Parts</Typography>
                <Divider sx={{ my: 1 }} />

                <Autocomplete
                  freeSolo
                  disableClearable
                  options={[]}
                  //options={availableParts}
                  //getOptionLabel={(part) => part.name}
                  onInputChange={(event, value) => setSearchQuery(value)}
                  renderInput={(params) => (
                    <TextField {...params} label="Search Parts" fullWidth />
                  )}
                />
                <List>
                  {/* "Select All" Checkbox */}
                  <FormControlLabel
                    sx={{ ml: 0.1 }}
                    control={
                      <Checkbox
                        checked={selectAllChecked}
                        onChange={handleSelectAll}
                      />
                    }
                    label="Select All"
                  />
                  {filteredParts.map((part) => (
                    <ListItem
                      key={part._id}
                      disablePadding
                      sx={{
                        "&:hover": {
                          backgroundColor: "rgba(33, 150, 243, 0.2)",
                        },
                      }}
                    >
                      <Checkbox
                        checked={selectedParts.some(
                          (selected) => selected._id === part._id
                        )}
                        onChange={() => handleSelectPart(part)}
                      />
                      <ListItemText primary={`${part.name} - ${part.number}`} />
                    </ListItem>
                  ))}
                </List>
              </Paper>

              {/* Selected Parts Box */}
              <Paper
                elevation={1}
                sx={{
                  flex: 1,
                  p: 2,
                  overflow: "auto",
                  height: "550px",
                }}
              >
                <Typography variant="h6">
                  {formData.reworkType === "REPAIRED"
                    ? !formData.correctiveAction.includes("WIP")
                      ? "Repaired Parts"
                      : "Recovered Parts (WIP)"
                    : "Recovered Parts"}
                </Typography>
                <Divider sx={{ my: 1 }} />
                <List>
                  {selectedParts.map((part) => (
                    <ListItem key={part._id} disablePadding>
                      <ListItemText primary={`${part.name} - ${part.number}`} />
                      <TextField
                        type="number"
                        label="Qty"
                        size="small"
                        value={part.quantity}
                        onChange={(e) =>
                          handleQuantityChange(part._id, Number(e.target.value))
                        }
                        sx={{ width: 60, ml: 2, mt: 1 }}
                      />
                    </ListItem>
                  ))}
                </List>
              </Paper>
            </Box>
          </Box>
        );
      default:
        return "Scan a valid barcode";
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box sx={{ p: 2 }}>
        <StyledTabs
          value={selectedTab}
          onChange={(event, newValue) => handleTabChange(event, newValue)}
        >
          {user?.role === "Tester" && (
            <PlainTab label="Create New Device Rework" />
          )}
          {(user?.role === "Tester" || user?.role === "Inventory") && (
            <PlainTab label="Device Rework History" />
          )}
        </StyledTabs>

        {selectedTab === 0 && user?.role === "Tester" && (
          <StepperMenu
            steps={steps}
            activeStep={activeStep}
            handleStepClick={() => {}}
            handleBack={handleBack}
            handleNext={handleNext}
            handleCancel={handleCancel}
            disableNext={
              !barcode ||
              (activeStep === 2 &&
                (!formData.reworkType ||
                  !formData.reworkDate ||
                  !formData.correctiveAction ||
                  !formData.rootCauseOfRepair)) ||
              (activeStep === 1 && !selectedArrival)
            }
            disableFinish={activeStep !== steps.length - 1}
          >
            {getStepContent(activeStep)}
          </StepperMenu>
        )}

        {selectedTab === 1 && (
          <>
            <TableContainer
              sx={{ p: 1, mt: 1, width: "99%" }}
              component={Paper}
              elevation={3}
            >
              <Box sx={{ p: 1, display: "flex", gap: 2 }}>
                <TextField
                  size="small"
                  label="Search by Serial Number.."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  sx={{ width: 300 }}
                />

                <Paper
                  elevation={0}
                  sx={{ border: "1px solid #ccc", height: "30px" }}
                >
                  {" "}
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="Filter by Rework Date"
                      value={selectedDate}
                      onChange={(newValue) => {
                        setSelectedDate(newValue);
                      }}
                      sx={{
                        minWidth: "100px", // Match the button's minWidth
                        height: "28px",
                        padding: "3px 8px", // Match the button's padding
                        borderRadius: 1, // Match the button's borderRadius
                        backgroundColor: "transparent", // Match the button's background color
                        color: "white", // Match the button's text color
                        fontSize: "0.70rem", // Match the button's font size
                        fontWeight: 700, // Match the button's font weight
                        cursor: "pointer", // Make it look clickable
                        display: "flex", // Flexbox to align content like a button
                        alignItems: "center", // Center the content vertically
                        justifyContent: "center", // Center the content horizontally
                        "& .MuiOutlinedInput-root": {
                          border: "none", // Remove the border
                          padding: 0, // Remove internal padding
                          "& fieldset": {
                            display: "none", // Hide the default fieldset border
                          },
                        },
                        "& .MuiInputBase-input": {
                          textAlign: "center", // Center text
                          padding: 0, // Remove default padding
                          // color: "white", // Inherit the text color
                          fontWeight: 500,
                        },
                        "& .MuiSvgIcon-root": {
                          transform: "scale(0.8)", // Match icon scale
                          fontSize: "18px", // Match icon size
                          // color: "white", // Match icon color
                        },
                        "& .MuiInputLabel-root": {
                          display: "none", // Hide the label
                          color: "white",
                        },
                        "&:hover": {
                          color: "white", // Keep the text color on hover
                        },
                      }}
                    />
                  </LocalizationProvider>
                </Paper>
              </Box>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>#</TableCell>
                    <TableCell>Serial Number</TableCell>
                    <TableCell>Root Cause of Repair</TableCell>
                    <TableCell>Corrective Action</TableCell>
                    <TableCell>Root Cause Same As Error</TableCell>
                    <TableCell>Parts</TableCell>
                    <TableCell>Tester</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Rework Date</TableCell>
                    {user?.role === "Supervisor" && (
                      <TableCell>Actions</TableCell>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {finalFilteredReworks
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((Rework, index) => (
                      <TableRow key={Rework._id}>
                        <TableCell>{page * rowsPerPage + index + 1}</TableCell>
                        <TableCell>
                          {Rework.deviceId || "Unknown device"}
                        </TableCell>
                        <TableCell>
                          {Rework.rootCauseOfRepair.join(",")}
                        </TableCell>
                        <TableCell>{Rework.correctiveAction}</TableCell>
                        <TableCell>
                          {Rework.isRootSameAsError ? "YES" : "NO"}
                        </TableCell>
                        <TableCell>
                          {user?.role === "Inventory"
                            ? Rework.parts
                                ?.map((part) => part.number)
                                .join(", ")
                            : Rework.parts?.map((part) => part.name).join(", ")}
                        </TableCell>
                        <TableCell>{Rework.tester}</TableCell>
                        <TableCell>
                          <Chip
                            size="small"
                            label={Rework.reworkType}
                            // color={
                            //   Rework.reworkType === "REPAIRED"
                            //     ? Rework.correctiveAction.includes("WIP") // If "WIP" is included, set color to yellow
                            //       ? "warning"
                            //       : "success"
                            //     : Rework.reworkType === "SCRAPPED"
                            //     ? "error"
                            //     : "default"
                            // }
                            sx={{
                              fontWeight: 700,
                              backgroundColor:
                                Rework.reworkType === "REPAIRED"
                                  ? Rework.correctiveAction.includes("WIP")
                                    ? "#ffd700" // Use dark yellow for WIP
                                    : theme.palette.success.main // Use green for success
                                  : Rework.reworkType === "SCRAPPED"
                                  ? theme.palette.error.main // Use red for error
                                  : "gray", // Default color
                              color: "white", // Text color
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          {dayjs(Rework.reworkDate).format("DD/MM/YYYY")}
                        </TableCell>
                      {user?.role === "Supervisor" && (
                        <TableCell>
                          <StyledButton
                            variantType="outline-primary"
                            size="small"
                            onClick={() => {
                              setUpdateModalOpen(true);
                              setSelectedRework(Rework);
                            }}
                          >
                            Edit
                          </StyledButton>
                        </TableCell>
                        )}
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={finalFilteredReworks.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableContainer>
          </>
        )}
        <Snackbar
          open={snackbar.open}
          autoHideDuration={6000}
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          anchorOrigin={{ vertical: "top", horizontal: "center" }} // Positioning at the top center
        >
          <Alert
            onClose={() => setSnackbar({ ...snackbar, open: false })}
            severity={snackbar.severity}
            sx={{ width: "100%" }}
          >
            {snackbar.message}
          </Alert>
        </Snackbar>

        <Modal
          open={isUpdateModalOpen}
          onClose={() => setUpdateModalOpen(false)}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <EditDeviceReworkModal
            onClose={() => setUpdateModalOpen(false)}
            onEdit={handleUpdateRework} // Add this if you have an onEdit handler
            existingRework={{
              _id: selectedRework?._id || "",
              deviceId: selectedRework?.deviceId || "",
              rootCauseOfRepair: selectedRework?.rootCauseOfRepair || [],
              correctiveAction: selectedRework?.correctiveAction || "",
              parts: selectedRework?.parts || [],
              otherRootCause: Array.isArray(selectedRework?.rootCauseOfRepair)
                ? selectedRework?.rootCauseOfRepair
                : [],
            }}
          />
        </Modal>
      </Box>
    </LocalizationProvider>
  );
};

export default ManageProdDeviceReworks;
