import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box,
  Snackbar,
  Alert,
  TablePagination,
  Checkbox,
  TextField,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import SaveIcon from "@mui/icons-material/Save";
import {
  DeviceData,
  EmailNotificationTypes,
} from "../../common/types/DeviceTypes";
import { updateDeviceNotifications } from "../../services/device.service";
import StyledIconButton from "../Tabs/StyledIconButton";

interface NotificationSettingsProps {
  devices: DeviceData[];
}

const NotificationSettings: React.FC<NotificationSettingsProps> = ({
  devices,
}) => {
  // State to keep track of the notification settings for each device
  const [notificationSettings, setNotificationSettings] = useState<
    Record<string, EmailNotificationTypes[]>
  >({});

  // State for pagination
  const [page, setPage] = useState(0); // Current page
  const [rowsPerPage, setRowsPerPage] = useState(5); // Rows per page

  // State to keep track of devices with changed settings
  const [changedDevices, setChangedDevices] = useState<Set<string>>(new Set());

  // Snackbar state
  const [snackbar, setSnackbar] = useState<{
    open: boolean;
    message: string;
    severity: "success" | "error";
  }>({ open: false, message: "", severity: "success" });

  useEffect(() => {
    // Initialize the state with current notification settings from devices
    const initialSettings = devices.reduce((acc, device) => {
      const notifications = device.notifications?.emailNotifications || [];
      acc[device.deviceInformation.deviceId] = notifications; // Initialize with current notifications
      return acc;
    }, {} as Record<string, EmailNotificationTypes[]>);
    setNotificationSettings(initialSettings);
  }, [devices]);

  // Handle the change of notification preference
  const handleNotificationChange = (
    deviceId: string,
    value: EmailNotificationTypes[]
  ) => {
    setNotificationSettings((prevState) => ({
      ...prevState,
      [deviceId]: value,
    }));
    setChangedDevices((prevChangedDevices) => {
      const newChangedDevices = new Set(prevChangedDevices);
      newChangedDevices.add(deviceId);
      return newChangedDevices;
    });
  };

  // Handle saving changes for a specific device
  const handleSaveChanges = async (deviceId: string) => {
    const notificationsToUpdate = notificationSettings[deviceId];

    try {
      await updateDeviceNotifications(deviceId, notificationsToUpdate);
      // Show a snackbar message indicating success
      setSnackbar({
        open: true,
        message: `Preferences updated for device ${deviceId}`,
        severity: "success",
      });
      // Remove device from changedDevices after save
      setChangedDevices((prevChangedDevices) => {
        const newChangedDevices = new Set(prevChangedDevices);
        newChangedDevices.delete(deviceId);
        return newChangedDevices;
      });
    } catch (error) {
      console.error(
        `Failed to update notifications for device ${deviceId}:`,
        error
      );
      setSnackbar({
        open: true,
        message: `Failed to update preferences for device ${deviceId}`,
        severity: "error",
      });
    }
  };

  const handleSnackbarClose = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  // Handle pagination change
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to first page
  };

  // Calculate the devices to display based on pagination
  const displayedDevices = devices.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h6" gutterBottom>
        Email Notifications sent from Device{" "}
      </Typography>
      <Box sx={{ mb: 1, p: 2, maxWidth: "calc(100% - 64px)" }}>
        <TableContainer
          sx={{
            p: 2,
            my: 1,
          }}
          component={Paper}
        >
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell>Device Name</TableCell>
                <TableCell>Serial</TableCell>
                <TableCell>Customer Email</TableCell>
                <TableCell>Notification Preference</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {displayedDevices.map((device) => (
                <TableRow key={device.deviceInformation.deviceId}>
                  <TableCell>{device.deviceInformation.deviceName}</TableCell>
                  <TableCell>{device.deviceInformation.serial}</TableCell>
                  <TableCell>
                    {device.deviceInformation.customerEmail}
                  </TableCell>
                  <TableCell sx={{ width: "40%" }}>
                    <Autocomplete
                      multiple
                      options={
                        [
                          "DEVICE_ERROR",
                          "DEVICE_INSTALLED",
                        ] as EmailNotificationTypes[]
                      }
                      value={
                        notificationSettings[
                        device.deviceInformation.deviceId
                        ] || []
                      }
                      onChange={(event, newValue) =>
                        handleNotificationChange(
                          device.deviceInformation.deviceId,
                          newValue as EmailNotificationTypes[]
                        )
                      }
                      disableCloseOnSelect
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option}
                        </li>
                      )}
                      style={{ width: 600 }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label="Notifications"
                        />
                      )}
                    />
                  </TableCell>
                  <TableCell>
                    <StyledIconButton
                      variantType="transparent"
                      onClick={() =>
                        handleSaveChanges(device.deviceInformation.deviceId)
                      }
                      disabled={
                        !changedDevices.has(device.deviceInformation.deviceId)
                      }
                    >
                      <SaveIcon />
                    </StyledIconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {/* Pagination Component */}
          <TablePagination
            component="div"
            count={devices.length} // Total number of devices
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[5, 10, 25]}
          />
        </TableContainer>
      </Box>

      {/* Snackbar for success or error messages */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbar.severity}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default NotificationSettings;
