import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Avatar,
  Box,
} from "@mui/material";
import FaceIcon from "@mui/icons-material/Face";
import { Distributor } from "../../store/useDistributor"; // Make sure to import your Distributor type

interface DistributorCardProps {
  distributor: Distributor;
  installer?: Distributor[];
}

const DistributorCard: React.FC<DistributorCardProps> = ({ distributor }) => {
  return (
    <Card sx={{ margin: 2, width: 300, position: "relative", borderRadius: 0 }}>
      <CardContent>
        <Box display="flex" alignItems="center" gap={2}>
          <Box>
            <Avatar sx={{ bgcolor: "primary.main", width: 50, height: 50 }}>
              <FaceIcon sx={{ width: 30, height: 30 }} />
            </Avatar>
          </Box>
          <Box
            sx={{
              flexGrow: 1,
            }}
          >
            <Typography variant="h6" noWrap>
              {distributor.firstName} {distributor.lastName}
            </Typography>
            <Typography variant="subtitle2">{distributor.email}</Typography>

            <Typography variant="subtitle2">
              {distributor.country}, {distributor.state}, {distributor.city},{" "}
              {distributor.address}, {distributor.postalCode}
            </Typography>
            <Typography variant="subtitle2">
              Phone: {distributor.phone}
            </Typography>
          </Box>
        </Box>
      </CardContent>
      <Box
        sx={{
          backgroundColor: "primary.main",
          p: "1.5px",
          textAlign: "center",
        }}
      />
    </Card>
  );
};

export default DistributorCard;
