import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "rgba(30, 58, 138, 1)",
      light: "#0057A7",
      dark: "rgba(30, 58, 138, 1)",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#98BDEF",
      light: "#e3f2fd",
      dark: "#669ac9",
      contrastText: "#000000",
    },
    background: {
      default: "#f3f4f6",
      paper: "#ffffff",
    },
    text: {
      primary: "#212121",
      secondary: "#707070",
    },
  },
  typography: {
    fontSize: 14,
    fontWeightRegular: 400,
    fontFamily: "Inter"
  },
  components: {
    MuiListItemText: {
      // https://mui.com/material-ui/api/list-item-text/#css-api
      // Check what other styles to update if secondary are different
      styleOverrides: {
        root: {
          fontSize: 14,
        },
        primary: {
          fontSize: 14,
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          fontSize: 14
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontSize: 14,
          fontWeight: 400,
          padding: "10px",
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          "&:hover": {
            backgroundColor: "rgba(30, 58, 138, 0.1)",
            color: "rgba(30, 58, 138, 1)",
            transform: "scale(1.3)",
          },
          transition: "transform 0.3s ease-in-out",
        },
      },
    },
  },
});
export default theme;
