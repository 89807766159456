import React from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Grid,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { DeviceErrors } from "../../common/types/DeviceTypes"; // Adjust the import path

interface DeviceErrorCardProps {
  deviceErrors: DeviceErrors[];
}

const DeviceErrorCard: React.FC<DeviceErrorCardProps> = ({ deviceErrors }) => {
  const today = new Date().toDateString();

  const todayErrors = deviceErrors.filter(
    (error) => new Date(error.date).toDateString() === today
  );

  // Sort other errors by date in descending order and take the first 50
  const otherErrors = deviceErrors
    .filter((error) => new Date(error.date).toDateString() !== today)
    .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
    .slice(0, 10); // Only keep the most recent 50

  return (
    <Box>
      <Accordion defaultExpanded>
        <AccordionSummary
          sx={{ bgcolor: "rgba(0, 0, 0, 0.12)", py: 0 }}
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography variant="h6">Today ({todayErrors.length})</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ bgcolor: "rgba(0, 0, 0, 0.12)" }}>
          <Grid container spacing={2}>
            {todayErrors.map((error) => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={error._id}>
                <Card
                  onClick={() => console.log(error)}
                  sx={{
                    cursor: "pointer",
                    height: "95%",
                    width: "100%",
                    borderRadius: 0,
                  }}
                >
                  <CardContent>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        gap: 1,
                      }}
                    >
                      <Typography
                        sx={{
                          marginTop: 0.2,
                          fontSize: "12px",
                          fontWeight: 600,
                        }}
                      >
                        {error.deviceName}
                      </Typography>
                      <Box
                        sx={{
                          bgcolor:
                            error.error === "SERVICE"
                              ? "#f44336"
                              : error.error === "LOW FLOW"
                              ? "#ff9800"
                              : error.error === "LOW CARTRIDGE"
                              ? "#2196f3"
                              : "#4caf50",
                          borderRadius: 1,
                          px: 0.5,
                          py: 0.2,
                        }}
                      >
                        <Typography
                          sx={{
                            color: "white",
                            fontFamily: "'Poppins', sans-serif",
                            fontSize: "11px",
                            fontWeight: 600,
                          }}
                        >
                          {error.error}
                        </Typography>
                      </Box>
                    </Box>

                    <Typography variant="body2" sx={{ fontWeight: 600 }}>
                      Date: {new Date(error.date).toLocaleString()}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      Device ID: {error.deviceId}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      Serial Number: {error.serialNumber}
                    </Typography>
                  </CardContent>
                  <Box
                    sx={{
                      bgcolor:
                        error.error === "SERVICE"
                          ? "#f44336"
                          : error.error === "LOW FLOW"
                          ? "#ff9800"
                          : error.error === "LOW CARTRIDGE"
                          ? "#2196f3"
                          : "#4caf50",

                      p: "4px",
                    }}
                  />
                </Card>
              </Grid>
            ))}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Divider sx={{ my: 2 }} />
      <Accordion>
        <AccordionSummary
          sx={{ bgcolor: "rgba(0, 0, 0, 0.12)", py: 0 }}
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography variant="h6">Previous ({otherErrors.length}) </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ bgcolor: "rgba(0, 0, 0, 0.12)" }}>
          <Grid container spacing={2}>
            {otherErrors.map((error) => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={error._id}>
                <Card
                  onClick={() => console.log(error)}
                  sx={{
                    cursor: "pointer",
                    height: "95%",
                    width: "100%",
                    borderRadius: 0,
                  }}
                >
                  <CardContent>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        gap: 1,
                      }}
                    >
                      <Typography
                        sx={{
                          marginTop: 0.2,
                          fontSize: "12px",
                          fontWeight: 600,
                        }}
                      >
                        {error.deviceName}
                      </Typography>
                      <Box
                        sx={{
                          bgcolor:
                            error.error === "SERVICE"
                              ? "#f44336"
                              : error.error === "LOW FLOW"
                              ? "#ff9800"
                              : error.error === "LOW CARTRIDGE"
                              ? "#2196f3"
                              : "#4caf50",
                          borderRadius: 1,
                          px: 0.5,
                          py: 0.2,
                        }}
                      >
                        <Typography
                          sx={{
                            color: "white",
                            fontFamily: "'Poppins', sans-serif",
                            fontSize: "11px",
                            fontWeight: 600,
                          }}
                        >
                          {error.error}
                        </Typography>
                      </Box>
                    </Box>

                    <Typography variant="body2" sx={{ fontWeight: 600 }}>
                      Date: {new Date(error.date).toLocaleString()}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      Device Name: {error.deviceName}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      Serial Number: {error.serialNumber}
                    </Typography>
                  </CardContent>
                  <Box
                    sx={{
                      bgcolor:
                        error.error === "SERVICE"
                          ? "#f44336"
                          : error.error === "LOW FLOW"
                          ? "#ff9800"
                          : error.error === "LOW CARTRIDGE"
                          ? "#2196f3"
                          : "#4caf50",

                      p: "4px",
                    }}
                  />
                </Card>
              </Grid>
            ))}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default DeviceErrorCard;
