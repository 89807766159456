import React, { useState } from "react";
import {
  AppBar,
  Box,
  Toolbar,
  Typography,
  Container,
  Grid,
  IconButton,
  useTheme,
  useMediaQuery,
  styled,
  ListItemIcon,
  Snackbar,
  Alert,
} from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import SettingsIcon from "@mui/icons-material/Settings";
import MessageIcon from "@mui/icons-material/Message";
import PaymentsIcon from "@mui/icons-material/Payments";
import InfoIcon from "@mui/icons-material/Info";
import ChatIcon from "@mui/icons-material/Chat";
import WarrantyForm from "./WarrantyModal";
import { DeviceData } from "../../common/types/DeviceTypes";
import IconTab from "../Tabs/IconTab";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LockIcon from "@mui/icons-material/Lock";
import { StyledTabs } from "../Tabs/IconTab";
import FAQSection from "./FAQ";
import Instructions from "./Instructions";
import ContactUs from "./ContactUs";

interface DistributorSupportProps {
  devices: DeviceData[];
}

const IconWrapper = styled(ListItemIcon)({
  justifyContent: "center",
  minWidth: "30px", // Ensures icons are properly aligned
  "& svg": {
    fill: "#9e9e9e", // Grey fill for the icon
    stroke: "black", // Black stroke color
    strokeWidth: "0.8px", // Thicker stroke to make it stand out
  },
  "&:hover": {
    color: "primary.main", // Light blue background on hover
    bgColor: "primary.main",
  },
});
const DistributorSupport: React.FC<DistributorSupportProps> = ({ devices }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [isWarrantyModalOpen, setWarrantyModalOpen] = useState(false);
  const [view, setView] = useState<
    | "home"
    | "faq"
    | "orderStatus"
    | "warranty"
    | "contactUs"
    | "instructions"
    | "billing"
  >("home");

  const [snackbar, setSnackbar] = useState<{
    open: boolean;
    message: string;
    severity: "success" | "error";
  }>({ open: false, message: "", severity: "success" });

  const handleSnackbarClose = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const handleWarrantySubmit = async (formData: any) => {
    setSnackbar({
      open: true,
      message: "Warranty claim submitted successfully.",
      severity: "success",
    });
  };

  const handleWarrantyError = (message: string) => {
    setSnackbar({
      open: true,
      message: "Failed to submit warranty claim. Please try again.",
      severity: "error",
    });
  };
  const [selectedTab, setSelectedTab] = useState(0);
  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
    localStorage.setItem("selectedProfilePageTab", newValue.toString());
  };

  type ViewType =
    | "home"
    | "faq"
    | "orderStatus"
    | "warranty"
    | "contactUs"
    | "instructions"
    | "billing";

  const supportItems: {
    icon: JSX.Element;
    title: string;
    description: string;
    view?: ViewType;
    onClick?: () => void;
  }[] = [
    {
      icon: (
        <IconWrapper>
          <HelpOutlineIcon style={{ fontSize: "2.5rem" }} />
        </IconWrapper>
      ),
      title: "FAQs",
      description: "Answers to common questions",
      view: "faq", // Switch to FAQ view
    },
    // {
    //   icon: (
    //     <IconWrapper>
    //       <LocalShippingIcon style={{ fontSize: "2.5rem" }} />
    //     </IconWrapper>
    //   ),
    //   title: "Order Status",
    //   description: "Track shipping and delivery",
    //   view: "orderStatus", // Switch to order status view
    // },
    {
      icon: (
        <IconWrapper>
          <SettingsIcon style={{ fontSize: "2.5rem" }} />
        </IconWrapper>
      ),
      title: "Warranty",
      description: "Submit a claim",
      onClick: () => window.open("/warranty", "_blank"), // function to open warranty modal
    },
    {
      icon: (
        <IconWrapper>
          <MessageIcon style={{ fontSize: "2.5rem" }} />
        </IconWrapper>
      ),
      title: "Contact Us",
      description: "Connect with our team",
      view: "contactUs", // Switch to contact us view
    },
    {
      icon: (
        <IconWrapper>
          <InfoIcon style={{ fontSize: "2.5rem" }} />
        </IconWrapper>
      ),
      title: "Product Instructions",
      description: "Instructions for assembly and care",
      view: "instructions", // Switch to instructions view
    },
    // {
    //   icon: (
    //     <IconWrapper>
    //       <PaymentsIcon style={{ fontSize: "2.5rem" }} />
    //     </IconWrapper>
    //   ),
    //   title: "Billing & Payment",
    //   description: "Billing and payment Info",
    //   view: "billing", // Switch to billing view
    // },
  ];

  // Helper function to render the current view
  const renderView = () => {
    switch (view) {
      case "faq":
        return <FAQSection />;
      case "orderStatus":
        return <Typography variant="h6">Order Status Section</Typography>;
      // case "warranty":
      //   return (

      //   );
      case "contactUs":
        return <ContactUs />;
      case "instructions":
        return <Instructions />;
      case "billing":
        return (
          <Typography variant="h6">Billing and Payment Section</Typography>
        );
      default:
        return null;
    }
  };

  return (
    <Box p={0}>
      {/* Conditionally render either the helpdesk or a specific view */}
      {view === "home" ? (
        <Box mt={0} sx={{ display: "flex" }}>
          <Box
            mt={0}
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              width: { xs: "90%", md: "90%" },
            }}
          >
            <Box
              textAlign="center"
              my={1}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                mb: 5,
              }}
            >
              <Typography
                sx={{
                  fontSize: "16px",
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                }}
                variant="body1"
                gutterBottom
              >
                Chat <ChatIcon fontSize="medium" />
              </Typography>
              <Typography
                sx={{
                  fontSize: "14px",
                }}
                variant="body1"
              >
                {" "}
                <a href="mailto:dev.team@tersano.com"> dev.team@tersano.com</a>
              </Typography>
              <Typography
                sx={{
                  fontSize: "14px",
                }}
                variant="body2"
                gutterBottom
              >
                Monday - Friday: 6am - 5pm MT
              </Typography>
              <Typography
                sx={{
                  fontSize: "14px",
                }}
                variant="body2"
                gutterBottom
              >
                Saturday & Sunday: 10am - 11:45am MT and 1pm - 3pm MT
              </Typography>

              <Typography
                sx={{
                  fontSize: "14px",
                }}
                variant="body1"
                gutterBottom
              >
                or Call
              </Typography>
              <Typography
                sx={{
                  fontSize: "14px",
                }}
                variant="body1"
                gutterBottom
              >
                <a href="tel:1-855-688-7269">1-226-961-4154</a>
              </Typography>
              <Typography
                sx={{
                  fontSize: "14px",
                }}
                variant="body2"
              >
                Monday - Friday: 8am - 5pm MT
              </Typography>

              <Typography
                sx={{
                  fontSize: "14px",
                }}
                variant="body2"
              >
                <a href="Watch our Training Tutorial Videos here">
                  Watch our Training Tutorial Videos here
                </a>
              </Typography>
            </Box>
            <Grid container spacing={4}>
              {supportItems.map((item, index) => (
                <Grid item xs={12} sm={4} key={index}>
                  <Box
                    textAlign="center"
                    p={2}
                    bgcolor="background.default"
                    color="primary.contrastText"
                    onClick={() => {
                      if (item.onClick) {
                        item.onClick();
                      } else {
                        if (item.view) {
                          setView(item.view); // Set view based on card click
                        }
                      }
                    }}
                    sx={{
                      cursor: "pointer",
                      "&:hover": {
                        backgroundColor: "rgba(30, 58, 138, 1)",
                        "& .MuiTypography-root": { color: "white" },
                        "& .MuiIconButton-root": { color: "white" },
                        "& .MuiSvgIcon-root": {
                          fill: "white",
                        },
                        transform: "scale(1.03)",
                        transition: "transform 0.2s",
                      },
                      borderRadius: 1,
                      border: "1px solid #e0e0e0",
                      boxShadow: "none",
                      borderBottom: "1.5px solid #bdbdbd",
                    }}
                  >
                    <IconButton
                      size="medium"
                      sx={{ color: "primary.dark", fontSize: "3rem" }}
                    >
                      {item.icon}
                    </IconButton>
                    <Typography
                      variant="h6"
                      component="div"
                      sx={{ color: "black", fontSize: "1rem" }}
                    >
                      {item.title}
                    </Typography>
                    <Typography
                      variant="body2"
                      component="div"
                      sx={{ color: "grey.800", fontSize: "0.9rem" }}
                    >
                      {item.description}
                    </Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>
      ) : (
        <Box>
          {/* Render the correct view based on the selected card */}
          <Box textAlign="right" mt={2}>
            <IconButton
              onClick={() => setView("home")} // Go back to the helpdesk
              sx={{
                color: "primary.main",
                ":hover": { backgroundColor: "transparent" },
              }}
            >
              <Typography variant="body1">Back to Helpdesk</Typography>
            </IconButton>
          </Box>
          {renderView()}
        </Box>
      )}

      <WarrantyForm
        open={isWarrantyModalOpen}
        onClose={() => setWarrantyModalOpen(false)}
        onSubmit={handleWarrantySubmit}
        onError={handleWarrantyError}
        devices={devices}
      />

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }} // Positioning at the top center
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbar.severity}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default DistributorSupport;
