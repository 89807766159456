import React, { useState } from "react";
import {
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  TablePagination,
  Typography,
  Chip,
  Select,
  MenuItem,
  FormControl,
  SelectChangeEvent,
} from "@mui/material";
import { DeviceData } from "../../common/types/DeviceTypes";
import { addAdditionalUsers } from "../../services/device.service";
import StyledButton from "../Tabs/StyledButton";

interface DeviceAccessManagerViewProps {
  devices: DeviceData[];
}

export const DeviceAccessManager: React.FC<DeviceAccessManagerViewProps> = ({
  devices,
}) => {
  const [newUserEmail, setNewUserEmail] = useState<string>("");
  const [selectedDeviceId, setSelectedDeviceId] = useState<string>("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [countryFilter, setCountryFilter] = useState<string | null>(null);
  const [pingStatusFilter, setPingStatusFilter] = useState<string | null>(null);

  // Extract unique countries from devices
  const availableCountries = Array.from(
    new Set(
      devices.map((device) => device.deviceLocation?.country).filter(Boolean)
    )
  );

  // Status options
  const statusOptions = ["ONLINE", "OFFLINE", "UNKNOWN"];

  const handleCountryFilterChange = (event: SelectChangeEvent<string>) => {
    setCountryFilter(event.target.value === "All" ? null : event.target.value);
  };

  const handleStatusFilterChange = (event: SelectChangeEvent<string>) => {
    setPingStatusFilter(
      event.target.value === "All" ? null : event.target.value
    );
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filteredDevices = devices.filter(
    (device) =>
      (device.deviceInformation.deviceName
        .toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
        device.deviceInformation.serial
          .toLowerCase()
          .includes(searchTerm.toLowerCase())) &&
      (countryFilter === null ||
        device.deviceLocation?.country === countryFilter) &&
      (pingStatusFilter === null ||
        device.pingStatus.status === pingStatusFilter)
  );

  const handleAddUser = async (deviceId: string) => {
    if (!newUserEmail) return;
    try {
      const response = await addAdditionalUsers(deviceId, newUserEmail);
      console.log(response);
      setNewUserEmail("");
      setSelectedDeviceId("");
    } catch (error) {
      console.error("Failed to add user", error);
    }
  };

  return (
    <Box sx={{ mt: 2 }}>
      <TextField
        label="Search Devices"
        variant="outlined"
        fullWidth
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        sx={{ mb: 2 }}
      />
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow
              sx={{
                backgroundColor: "#f5f5f5",
              }}
            >
              <TableCell>
                <Typography variant="subtitle2">Device Name</Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="subtitle2">Serial Number</Typography>
              </TableCell>

              <TableCell align="center" sx={{ fontWeight: "600" }}>
                <FormControl
                  variant="standard"
                  sx={{
                    m: 1,
                    //minWidth: 100,
                    "& .MuiInputBase-root": {
                      border: "none",
                      boxShadow: "none",
                    },
                    "& .MuiInputBase-root:before": {
                      borderBottom: "none",
                    },
                    "& .MuiInputBase-root:after": {
                      borderBottom: "none",
                    },
                  }}
                >
                  <Select
                    value={countryFilter || "All"}
                    onChange={handleCountryFilterChange}
                    displayEmpty
                    sx={{
                      fontWeight: "500",
                      fontSize: 12,
                    }}
                  >
                    <MenuItem value="All">Country</MenuItem>
                    {availableCountries.map((country) => (
                      <MenuItem key={country} value={country}>
                        {country}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </TableCell>

              <TableCell align="center">
                <FormControl
                  variant="standard"
                  sx={{
                    m: 1,
                    //minWidth: 100,
                    "& .MuiInputBase-root": {
                      border: "none",
                      boxShadow: "none",
                    },
                    "& .MuiInputBase-root:before": {
                      borderBottom: "none",
                    },
                    "& .MuiInputBase-root:after": {
                      borderBottom: "none",
                    },
                  }}
                >
                  <Select
                    value={pingStatusFilter || "All"}
                    onChange={handleStatusFilterChange}
                    displayEmpty
                    sx={{ fontWeight: "500", fontSize: 12 }}
                  >
                    <MenuItem value="All">Ping Status</MenuItem>
                    {statusOptions.map((status) => (
                      <MenuItem key={status} value={status}>
                        {status}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </TableCell>

              <TableCell align="center">
                <Typography variant="subtitle2">User Emails</Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="subtitle2">Manage User Access</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody
            sx={{
              "&:last-child td, &:last-child th": {
                border: "1px solid #e0e0e0",
              },
            }}
          >
            {filteredDevices
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((device) => (
                <TableRow key={device._id}>
                  <TableCell>{device.deviceInformation.deviceName}</TableCell>
                  <TableCell align="center" sx={{ fontWeight: 600 }}>
                    {device.deviceInformation.serial}
                  </TableCell>
                  <TableCell align="center">
                    {device.deviceLocation?.country || "N/A"}
                  </TableCell>
                  <TableCell align="center">
                    <Typography
                      sx={{
                        fontWeight: 700,
                        color:
                          device.pingStatus.status === "ONLINE"
                            ? "green"
                            : device.pingStatus.status === "OFFLINE"
                            ? "red"
                            : "grey",
                      }}
                    >
                      {device.pingStatus.status}
                    </Typography>
                  </TableCell>

                  {/* <TableCell align="center">
                    <Box display="flex">
                    
                      <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="flex-start"
                        sx={{
                          mr: 2,
                          borderRight: "1px solid lightgray", // Add light border
                        }}
                      >
                        <Typography
                          variant="body2"
                          sx={{
                            lineHeight: "28px",
                            mt: 1,
                            borderBottom: "1px solid lightgrey",
                          }}
                        >
                          Customer
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{
                            lineHeight: "28px",
                            mt: 1,
                            borderBottom: "1px solid lightgrey",
                          }}
                        >
                          Distributor
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{
                            lineHeight: "28px",
                            mt: 1,
                            borderBottom: "1px solid lightgrey",
                            mr: 1,
                          }}
                        >
                          Additional Customers
                        </Typography>
                        {device.deviceInformation.additionalUsers.length >
                          0 && (
                          <Typography
                            variant="body2"
                            sx={{
                              lineHeight: "28px",
                              mt: 1,
                              borderBottom: "1px solid lightgrey",
                            }}
                          >
                            Additional Users
                          </Typography>
                        )}
                      </Box>
              
                      <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="flex-start"
                      >
                        <Box
                          display="flex"
                          flexWrap="wrap"
                          alignItems="center"
                          sx={{ height: "36px" }}
                        >
                          <Chip
                            label={device.deviceInformation.customerEmail}
                            variant="outlined"
                            sx={{ m: 0.3 }}
                          />
                        </Box>
                        <Box
                          display="flex"
                          flexWrap="wrap"
                          alignItems="center"
                          sx={{ height: "36px" }}
                        >
                          <Chip
                            label={device.deviceInformation.installerEmail}
                            variant="outlined"
                            sx={{ m: 0.3 }}
                          />
                        </Box>
                        <Box
                          display="flex"
                          flexWrap="wrap"
                          alignItems="center"
                          sx={{ height: "36px" }}
                        >
                          {device.deviceInformation.additionalCustomers.map(
                            (email, index) => (
                              <Chip
                                key={index}
                                label={email}
                                variant="outlined"
                                sx={{ m: 0.3 }}
                              />
                            )
                          )}
                        </Box>
                        {device.deviceInformation.additionalUsers.length >
                          0 && (
                          <Box
                            display="flex"
                            flexWrap="wrap"
                            alignItems="center"
                            sx={{ height: "36px" }}
                          >
                            {device.deviceInformation.additionalUsers.map(
                              (email, index) => (
                                <Chip
                                  key={index}
                                  label={email}
                                  variant="outlined"
                                  sx={{ m: 0.3 }}
                                />
                              )
                            )}
                          </Box>
                        )}
                      </Box>
                    </Box>
                  </TableCell> */}

                  <TableCell align="center">
                    <TableContainer>
                      <Table size="small">
                        <TableBody>
                          {/* Customer Row */}
                          <TableRow>
                            <TableCell sx={{ width: "27%" }}>
                              Customer
                            </TableCell>
                            <TableCell>
                              <Chip
                                label={device.deviceInformation.customerEmail}
                                variant="outlined"
                                sx={{ m: 0.3 }}
                              />
                            </TableCell>
                          </TableRow>
                          {/* Distributor (Installer) Row */}
                          <TableRow>
                            <TableCell>Distributor</TableCell>
                            <TableCell>
                              <Chip
                                label={device.deviceInformation.installerEmail}
                                variant="outlined"
                                sx={{ m: 0.3 }}
                              />
                            </TableCell>
                          </TableRow>
                          {/* Additional Customers Row conditionally rendered */}
                          {device.deviceInformation.additionalCustomers.length >
                            0 && (
                            <TableRow>
                              <TableCell>Additional Customers</TableCell>
                              <TableCell>
                                {device.deviceInformation.additionalCustomers.map(
                                  (email, index) => (
                                    <Chip
                                      key={index}
                                      label={email}
                                      variant="outlined"
                                      sx={{ m: 0.3 }}
                                    />
                                  )
                                )}
                              </TableCell>
                            </TableRow>
                          )}
                          {/* Additional Users Row (conditionally rendered) */}
                          {device.deviceInformation.additionalUsers.length >
                            0 && (
                            <TableRow>
                              <TableCell>Additional Users</TableCell>
                              <TableCell>
                                {device.deviceInformation.additionalUsers.map(
                                  (email, index) => (
                                    <Chip
                                      key={index}
                                      label={email}
                                      variant="outlined"
                                      sx={{ m: 0.3 }}
                                    />
                                  )
                                )}
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </TableCell>

                  <TableCell align="center">
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                    >
                      <TextField
                        type="email"
                        value={
                          device.deviceInformation.deviceId === selectedDeviceId
                            ? newUserEmail
                            : ""
                        }
                        onChange={(e) => {
                          setNewUserEmail(e.target.value);
                          setSelectedDeviceId(
                            device.deviceInformation.deviceId
                          );
                        }}
                        placeholder="User Email"
                        size="small"
                        sx={{ mr: 1, width: 250 }}
                      />
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: 1,
                          mt: 1,
                        }}
                      >
                        <StyledButton
                          variantType="outline-primary"
                          onClick={() =>
                            handleAddUser(device.deviceInformation.deviceId)
                          }
                        >
                          Add
                        </StyledButton>
                        <StyledButton variantType="outline-red">
                          Remove
                        </StyledButton>
                      </Box>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={filteredDevices.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </Box>
  );
};
