import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Snackbar,
  Tabs,
  Alert,
  AlertColor,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import StyledButton from "../components/Tabs/StyledButton";
import StyledIconButton from "../components/Tabs/StyledIconButton";
import { Loop, EditOutlined } from "@mui/icons-material";
import Timeline from "@mui/lab/Timeline";
import { timelineItemClasses } from "@mui/lab/TimelineItem";
import TimelineItem from "../components/Timeline/TimelineItem";
import {
  DeviceData,
  ICleanDevice,
  DeviceLogEntry,
} from "../common/types/DeviceTypes";
import ChangeCartridgeTypeModal from "../components/Device/ChangeCartridgeTypeModal";
import DeviceOverview from "../components/Device/DeviceOverview";
import DeviceUsageTimelineItem from "../components/Timeline/DeviceUsageTimelineItem";
import {
  fetchDeviceLogsByDeviceId,
  fetchDeviceDetails,
} from "../services/device.service";
import MapView from "../components/Product/Charts/MapViewDeviceDetails";
import DeviceCustomers from "../components/Device/DeviceCustomers";
import DeviceDistributors from "../components/Device/DeviceDistributors";
import DeviceInstallers from "../components/Device/DeviceInstallers";
import ReplacementHistoryTimeline from "../components/Timeline/ReplacementHistoryTimeline";
import EditDeviceDetailsModal from "../components/Device/EditDeviceDetailsModal";
import useAuthStore from "../store/authStore";
import { createChangeRequest } from "../services/change-request.service";
import {
  ChangeRequestPayload,
  ChangeRequestTypes,
} from "../common/types/ChangeRequestTypes";
import { Badge } from "@fluentui/react-components";
import { DeleteRegular, ArrowLeft24Regular } from "@fluentui/react-icons";
import PlainTab from "../components/Tabs/PlainTab";

interface DeviceDetailsProps {
  device: DeviceData;
  onBack: () => void;
}

const DeviceDetailsNew: React.FC<DeviceDetailsProps> = ({ device, onBack }) => {
  const [value, setValue] = React.useState(0);
  const [deviceLogs, setDeviceLogs] = useState<DeviceLogEntry[]>([]);
  const [cartridgeLogs, setCartridgeLogs] = useState<DeviceLogEntry[]>([]);
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [deviceData, setDeviceData] = useState(device);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);

  const { user } = useAuthStore();

  const allowedRoles = ["Admin", "SuperAdmin", "Distributor"];
  const allowedEditRoles = [
    "Admin",
    "SuperAdmin",
    "Distributor",
    "GroupCustomer",
  ];

  const handleOpenDeleteModal = () => setDeleteModalOpen(true);
  const handleCloseDeleteModal = () => setDeleteModalOpen(false);

  const handleOpenEditModal = () => setEditModalOpen(true);
  const handleCloseEditModal = () => setEditModalOpen(false);

  const tabLabels = ["Overview", "Customers", "Usage Log", "Timeline", "Cartridge Change Log",
    "Device Logs", "Device Location", "Distributor", "Installer", "Device Replacements", "Memory Log", "Device Manual"];

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleSaveDeviceDetails = (response: any) => {
    setSnackbar({
      open: true,
      message: "A request has been Submitted to Update Device Details",
      severity: "warning",
    });
    handleCloseEditModal();
    refreshDeviceData();
  };

  const refreshDeviceData = async () => {
    try {
      const updatedDevice = await fetchDeviceDetails(
        device.deviceInformation.deviceId
      );
      setDeviceData(updatedDevice);
    } catch (error) {
      console.error("Error refreshing device data:", error);
    }
  };

  const [isCartridgeModalOpen, setCartridgeModalOpen] = useState(false);

  const handleOpenCartridgeModal = () => setCartridgeModalOpen(true);
  const handleCloseCartridgeModal = () => setCartridgeModalOpen(false);

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success" as AlertColor,
  });

  const handleError = (message: string) => {
    setSnackbar({ open: true, message, severity: "error" });
  };

  const handleCartridgeChangeSuccess = (message: string) => {
    setSnackbar({
      open: true,
      message: message,
      severity: "success",
    });
    handleCloseCartridgeModal();
  };

  const handleDeleteDevice = async () => {
    try {
      if (!user) return;

      const changeRequestPayload: ChangeRequestPayload = {
        type: ChangeRequestTypes.DeleteDevice,
        userEmail: user?.email,
        userRole: user?.role,
        details: {
          deviceId: deviceData.deviceInformation.deviceId,
        },
      };
      // Send the change request to the server
      await createChangeRequest(changeRequestPayload);

      // Show success message
      setSnackbar({
        open: true,
        message: `Change Request delete to device: ${deviceData.deviceInformation.deviceId} created successfully`,
        severity: "warning",
      });

      // Optionally: delay before navigating back or performing additional actions
      setTimeout(() => {
        onBack();
      }, 2000); // 1 sec delay
    } catch (error: any) {
      console.error("Error creating change request:", error.message);
      setSnackbar({
        open: true,
        message: error.message
          ? error.message
          : "Failed to create change request.",
        severity: "error",
      });
    }
  };

  useEffect(() => {
    const fetchLogsAndDeviceData = async () => {
      try {
        const logs = await fetchDeviceLogsByDeviceId(
          device.deviceInformation.deviceId
        );
        setDeviceLogs(logs);
        const cartridgeChangeLogs = logs.filter((log) =>
          log.logMessage.includes("cartridgeChanged")
        );
        setCartridgeLogs(cartridgeChangeLogs);

        const updatedDevice = await fetchDeviceDetails(
          device.deviceInformation.deviceId
        );
        setDeviceData(updatedDevice);
      } catch (error) {
        console.error("Error fetching device data and logs:", error);
      }
    };

    fetchLogsAndDeviceData();
  }, [device.deviceInformation.deviceId]);

  const center = device.deviceLocation
    ? {
      lat: device.deviceLocation.latitude,
      lng: device.deviceLocation.longitude,
    }
    : { lat: 39.8283, lng: -82.955803 }; // Default center if no device location is available

  const zoom = 12;
  const markers = device.deviceLocation
    ? [
      {
        id: device.deviceInformation.deviceId,
        position: {
          lat: device.deviceLocation.latitude,
          lng: device.deviceLocation.longitude,
        },
        name: device.deviceInformation.deviceName,
      },
    ]
    : [];

  return (
    <Box sx={{ flex: 1, p: { xs: 2, sm: 1 } }}>
      <Box
        sx={{
          borderBottom: (theme) =>
            `1px solid ${device.pingStatus.status === "ONLINE"
              ? theme.palette.success.main
              : device.pingStatus.status === "OFFLINE"
                ? theme.palette.error.main
                : theme.palette.grey[500]
            }`,
          borderRadius: 0.5,
          pb: 1.25,
          pt: 0.25,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            gap: { xs: 2, md: 0 },
            alignItems: { xs: "stretch", md: "center" },
            width: "100%",
          }}
        >
          {/* Top row with back button and device name */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
              width: "100%",
            }}
          >
            <StyledIconButton
              variantType="transparent"
              onClick={onBack}
            >
              <ArrowLeft24Regular />
            </StyledIconButton>
            <Typography
              variant="h6"
              sx={{
                fontWeight: 700,
              }}
            >
              {deviceData.deviceInformation.deviceName}
            </Typography>
            <Badge color={
              device.pingStatus.status === "ONLINE"
                ? "success"
                : device.pingStatus.status === "OFFLINE"
                  ? "danger"
                  : "informative"}>
              {device.pingStatus.status}
            </Badge>
          </Box>

          {/* Bottom row with action buttons */}
          <Box
            sx={{
              display: "flex",
              justifyContent: { xs: "flex-start", md: "flex-end" },
              gap: 1,
              flexGrow: { md: 1 },
              flexShrink: 0,
              whiteSpace: "nowrap",
            }}
          >
            {user?.role && allowedEditRoles.includes(user.role) && (
              <StyledButton
                icon={<EditOutlined />}
                onClick={handleOpenEditModal}
                sx={{ whiteSpace: "nowrap" }}
              >
                Edit Device
              </StyledButton>
            )}
            <StyledButton
              variantType="primary"
              icon={<Loop />}
              onClick={handleOpenCartridgeModal}
              sx={{ whiteSpace: "nowrap" }}
            >
              Change Cartridge
            </StyledButton>
            {user?.role && allowedRoles.includes(user.role) && (
              <StyledIconButton variantType="destructive" onClick={handleOpenDeleteModal}>
                <DeleteRegular />
              </StyledIconButton>
            )}
          </Box>
        </Box>
      </Box>

      <ChangeCartridgeTypeModal
        open={isCartridgeModalOpen}
        onClose={handleCloseCartridgeModal}
        onSuccess={handleCartridgeChangeSuccess}
        onError={handleError}
        currentCartridgeType={device.deviceInformation.currentCartridgeType}
        deviceId={device.deviceInformation.deviceId}
      />

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity={snackbar.severity} sx={{ width: "100%" }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
      <Box sx={{ mt: 2 }}>
        <Tabs
          value={value}
          onChange={handleChangeTab}
          aria-label="Device tabs"
          variant="scrollable"
          sx={{
            minHeight: "32px",
            "& .MuiTabs-indicator": {
              display: "none",
            },
            "& .MuiTabs-flexContainer": {
              gap: "6px",
            },
          }}
        >
          {tabLabels.map((label, index) => (
            <PlainTab key={label} label={label} />
          ))}
        </Tabs>
        {/* Content based on selected tab */}
        {value === 0 && <DeviceOverview device={deviceData} />}
        {value === 1 && <DeviceCustomers device={device} />}
        {value === 2 && (
          <Timeline
            sx={{
              [`& .${timelineItemClasses.root}:before`]: {
                flex: 0,
                padding: 0,
              },
            }}
          >
            {(device as ICleanDevice).deviceUsage
              .sort(
                (a, b) =>
                  new Date(b.uploadTimestamp).getTime() -
                  new Date(a.uploadTimestamp).getTime()
              )
              .map((usage, index) => (
                <DeviceUsageTimelineItem key={index} usage={usage} />
              ))}
          </Timeline>
        )}
        {value === 3 && (
          <Timeline
            sx={{
              [`& .${timelineItemClasses.root}:before`]: {
                flex: 0,
                padding: 0,
              },
            }}
          >
            {deviceLogs
              .filter((log) => log.logMessage.includes("Ping"))
              .map((log, index) => (
                <TimelineItem
                  key={index}
                  status="Ping Received"
                  timestamp={log.timestamp}
                />
              ))}
          </Timeline>
        )}
        {value === 4 && (
          <Timeline
            sx={{
              [`& .${timelineItemClasses.root}:before`]: {
                flex: 0,
                padding: 0,
              },
            }}
          >
            {cartridgeLogs.map((log, index) => {
              const logData = JSON.parse(log.logMessage);
              return (
                <TimelineItem
                  key={index}
                  status={` ${logData.cartridgeChanged}`}
                  timestamp={log.timestamp}
                />
              );
            })}
          </Timeline>
        )}
        {value === 5 && (
          <Box
            component="div"
            sx={{
              backgroundColor: "black",
              color: "green",
              fontFamily: "'Roboto Mono', monospace",
              padding: 2,
              maxHeight: 800,
              overflowY: "auto",
              mt: 2,
            }}
          >
            {deviceLogs.map((entry) => (
              <Typography
                sx={{ fontFamily: "'Roboto Mono', monospace" }}
                key={entry.timestamp}
              >
                <span style={{ display: "inline-block", width: "200px" }}>
                  [{new Date(entry.timestamp).toLocaleString()}]
                </span>
                {entry.logMessage}
              </Typography>
            ))}
          </Box>
        )}
        {value === 6 && (
          <Box sx={{ mt: 2 }}>
            <MapView
              center={center}
              zoom={zoom}
              height="720px"
              markers={markers}
            />
          </Box>
        )}
        {value === 7 && <DeviceDistributors device={device} />}
        {value === 8 && <DeviceInstallers device={device} />}
        {value === 9 && (
          <ReplacementHistoryTimeline
            deviceId={device.deviceInformation.deviceId}
          />
        )}
      </Box>
      <EditDeviceDetailsModal
        open={isEditModalOpen}
        onClose={handleCloseEditModal}
        device={deviceData}
        onSave={handleSaveDeviceDetails}
        onError={handleError}
      />
      <Dialog open={isDeleteModalOpen} onClose={handleCloseDeleteModal}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this device?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <StyledButton onClick={handleCloseDeleteModal}>Cancel</StyledButton>
          <StyledButton
            onClick={() => {
              handleDeleteDevice();
              handleCloseDeleteModal();
            }}
            variantType="destructive"
          >
            Delete
          </StyledButton>
        </DialogActions>
      </Dialog>
    </Box >
  );
};

export default DeviceDetailsNew;
