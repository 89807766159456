import React, { useState } from "react";
import {
  AppBar,
  Box,
  Toolbar,
  Typography,
  Container,
  Grid,
  IconButton,
  useTheme,
  useMediaQuery,
  styled,
  ListItemIcon,
} from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import SettingsIcon from "@mui/icons-material/Settings";
import MessageIcon from "@mui/icons-material/Message";
import PaymentsIcon from "@mui/icons-material/Payments";
import InfoIcon from "@mui/icons-material/Info";
import { useNavigate } from "react-router-dom";
import ChatIcon from "@mui/icons-material/Chat";
import { on } from "events";
import WarrantyModal from "../DistributorSupport.tsx/WarrantyModal";
import { DeviceData } from "../../common/types/DeviceTypes";
import IconTab from "../Tabs/IconTab";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LockIcon from "@mui/icons-material/Lock";
import { StyledTabs } from "../Tabs/IconTab";
import Inventory2Icon from "@mui/icons-material/Inventory2";
import DeliveryDiningIcon from "@mui/icons-material/DeliveryDining";

interface ServiceToolsProps {
  devices: DeviceData[];
}

const IconWrapper = styled(ListItemIcon)({
  justifyContent: "center",
  minWidth: "30px", // Ensures icons are properly aligned
  "& svg": {
    fill: "#9e9e9e", // Grey fill for the icon
    stroke: "black", // Black stroke color
    strokeWidth: "0.8px", // Thicker stroke to make it stand out
  },
  "&:hover": {
    color: "primary.main", // Light blue background on hover
    bgColor: "primary.main",
  },
});
const ServiceTools: React.FC<ServiceToolsProps> = ({ devices }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();
  const [isWarrantyModalOpen, setWarrantyModalOpen] = useState(false);
  const handleWarrantySubmit = (formData: any) => {
    console.log("Warranty form data:", formData);
    // Perform the submit action , call the API endpoint
  };

  const handleWarrantyError = (message: string) => {
    console.error("Warranty form error:", message);
    // Handle the error
  };
  const [selectedTab, setSelectedTab] = useState(0);
  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
    localStorage.setItem("selectedProfilePageTab", newValue.toString());
  };

  const supportItems = [
    {
      icon: (
        <IconWrapper>
          <Inventory2Icon style={{ fontSize: "3.5rem" }} />
        </IconWrapper>
      ),
      title: "Return & Refunds",
      description: "Set up Product Return for Users",
      path: "#",
    },
    {
      icon: (
        <IconWrapper>
          <DeliveryDiningIcon style={{ fontSize: "3.5rem" }} />
        </IconWrapper>
      ),
      title: "Shipping & Delivery",
      description: "Set up Shipping & Delivery for Users",
      path: "#",
    },
    {
      icon: (
        <IconWrapper>
          <HelpOutlineIcon style={{ fontSize: "3.5rem" }} />
        </IconWrapper>
      ),
      title: "FAQs",
      description: "Set up FAQs for Users",
      path: "#",
    },
    {
      icon: (
        <IconWrapper>
          <LocalShippingIcon style={{ fontSize: "3.5rem" }} />
        </IconWrapper>
      ),
      title: "Order Status",
      description: "Track shipping and delivery",
      path: "#",
    },
    {
      icon: (
        <IconWrapper>
          <SettingsIcon style={{ fontSize: "3.5rem" }} />
        </IconWrapper>
      ),
      title: "Notifications",
      description: "Send service notifications to users",
      path: "",
      onClick: () => setWarrantyModalOpen(true), // function to open warranty modal
    },
    {
      icon: (
        <IconWrapper>
          <MessageIcon style={{ fontSize: "3.5rem" }} />
        </IconWrapper>
      ),
      title: "Contact Us",
      description: "Connect with our team",
      path: "#",
    },
    {
      icon: (
        <IconWrapper>
          <InfoIcon style={{ fontSize: "3.5rem" }} />
        </IconWrapper>
      ),
      title: "Product Instructions",
      description: "Instructions for assembly and care",
      path: "#",
    },
    {
      icon: (
        <IconWrapper>
          <PaymentsIcon style={{ fontSize: "3.5rem" }} />
        </IconWrapper>
      ),
      title: "Billing & Payment",
      description: "Billing and payment Info",
      path: "#",
    },
  ];

  return (
    <Box p={1}>
      <Box mt={1} sx={{ display: "flex", justifyContent: "center" }}>
        <Box
          mt={1}
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            width: { xs: "90%", md: "90%" },
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              borderBottom: 1,
              borderColor: "divider",
              p: 0.5,
              mb: 2,
            }}
          >
            <Typography variant="h6">Service Tools</Typography>
          </Box>
          <Grid container spacing={4}>
            {supportItems.map((item, index) => (
              <Grid item xs={12} sm={3} key={index}>
                <Box
                  textAlign="center"
                  p={3}
                  bgcolor="background.default"
                  color="primary.contrastText"
                  borderRadius={0}
                  onClick={() => {
                    if (item.onClick) {
                      item.onClick();
                    } else {
                      navigate(item.path);
                    }
                  }}
                  sx={{
                    cursor: "pointer",
                    "&:hover": {
                      backgroundColor: "rgba(30, 58, 138, 1)",
                      "& .MuiTypography-root": { color: "white" },
                      "& .MuiIconButton-root": { color: "white" },
                      "& .MuiSvgIcon-root": {
                        fill: "white",
                      },
                      transform: "scale(1.03)",
                      transition: "transform 0.2s",
                    },
                  }}
                >
                  <IconButton
                    size="large"
                    sx={{ color: "primary.dark", fontSize: "3rem" }}
                  >
                    {item.icon}
                  </IconButton>
                  <Typography
                    variant="h6"
                    component="div"
                    sx={{ color: "black", fontSize: "1.25rem" }}
                  >
                    {item.title}
                  </Typography>
                  <Typography
                    variant="body2"
                    component="div"
                    sx={{ color: "grey.800", fontSize: "1rem" }}
                  >
                    {item.description}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
      <WarrantyModal
        open={isWarrantyModalOpen}
        onClose={() => setWarrantyModalOpen(false)}
        onSubmit={handleWarrantySubmit}
        onError={handleWarrantyError}
        devices={devices}
      />
    </Box>
  );
};

export default ServiceTools;
