import React, { useEffect, useRef } from "react";
import { MapContainer, TileLayer, CircleMarker, Popup } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import { Paper } from "@mui/material";

// Props type definition
type Props = {
  center: { lat: number; lng: number };
  zoom: number;
  height: string;
  markers: Array<{
    id: string;
    position: { lat: number; lng: number };
    name: string;
    status: "ONLINE" | "OFFLINE";
  }>;
};

// Main component
const MapCircleView: React.FC<Props> = ({ center, zoom, height, markers }) => {
  const mapRef = useRef<L.Map | null>(null);

  useEffect(() => {
    if (mapRef.current && markers.length > 0) {
      const bounds = L.latLngBounds(markers.map((m) => m.position));
      mapRef.current.fitBounds(bounds, { padding: [50, 50] });
    }
  }, [markers]);

  const commonStyles = {
    borderRadius: "4px",
    border: "1px solid #eeeeee",
    backgroundColor: "background.paper",
    color: "text.primary",
    // boxShadow: 1,
  };

  return (
    <Paper elevation={0} sx={{ ...commonStyles, overflow: "hidden" }}>
      <MapContainer
        center={center}
        zoom={zoom}
        style={{ height, width: "100%" }}
        ref={mapRef}
      >
        <TileLayer
          url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://carto.com/attributions">CARTO</a>'
        />

        {markers.map((marker) => (
          <CircleMarker
            key={marker.id}
            center={marker.position}
            // color={marker.status === "ONLINE" ? "green" : "red"}
            color={"rgba(30, 58, 138, 0.5)"}
            radius={3}
            fillOpacity={1}
          >
            <Popup>{`${marker.name} - ${marker.status}`}</Popup>
          </CircleMarker>
        ))}
      </MapContainer>
    </Paper>
  );
};

export default MapCircleView;
