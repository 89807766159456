import React from "react";
import { backdropClasses, styled, Theme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { heIL } from "@mui/material/locale";

export const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  // width: "100%",
  border: "1px solid #E0E0E0",
  fontFamily: "'Poppins', sans-serif",
  fontSize: "0.6rem", // Smaller font size
  overflowX: "scroll",
  "& .MuiDataGrid-columnHeaders": {
    fontFamily: "'Poppins', sans-serif",
    fontWeight: 600,
    fontSize: "11px",
    backgroundColor: "rgba(30, 58, 138, 1)", // Change background color
    // color: "white", // Change text color
  },
  "& .MuiDataGrid-row": {
    backgroundColor: "white",
    transition: "transform 0.3s ease", // Smooth transition for zoom effect
    "&:hover": {
      transform: "scale(1.01)", // Slightly increase size on hover
      zIndex: 1, // Ensure the row is above others during the effect
    },
  },
  "& .MuiDataGrid-cell": {
    fontFamily: "'Poppins', sans-serif",
    fontSize: "11px",

    // fontWeight: 400,
  },
}));
