import React, { useState, useEffect, useMemo } from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  Chip,
  Snackbar,
  Tooltip,
  TablePagination,
  TextField,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Alert,
  AlertColor,
  SelectChangeEvent,
} from "@mui/material";
import {
  Search as SearchIcon,
  MoreVert as MoreVertIcon,
  Add as AddIcon,
  DevicesOther as DevicesOtherIcon,
  Delete as DeleteIcon,
} from "@mui/icons-material";
import CreateNewDistributorModal from "./CreateNewDistributorModal";
import AddRemoveDeviceModal from "./AddRemoveDeviceModalDistributor";
import useDeviceStore from "../../store/useDeviceStore";
import useDistributorStore from "../../store/useDistributor";
import StyledIconButton from "../Tabs/StyledIconButton";

const DistributorTable: React.FC = () => {
  const { devices } = useDeviceStore((state) => ({
    devices: state.allDevices,
  }));

  const { Distributors, fetchDistributors } = useDistributorStore();
  useEffect(() => {
    fetchDistributors();
  }, [fetchDistributors]);
  console.log(Distributors);
  // State hooks and handlers as before
  const [selected, setSelected] = useState<string[]>([]);
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [filterField, setFilterField] = useState<string>("");
  const [filterValue, setFilterValue] = useState<string>("");
  const [sortField, setSortField] = useState<string>("");
  const [modalOpen, setModalOpen] = useState(false);
  const [snackbar, setSnackbar] = useState<{
    open: boolean;
    message: string;
    severity: AlertColor | undefined;
  }>({ open: false, message: "", severity: undefined });

  const handleOpenModal = () => setModalOpen(true);
  const handleCloseModal = () => setModalOpen(false);

  const handleDistributorCreationSuccess = () => {
    setSnackbar({
      open: true,
      message: "Distributor Created successfully created.",
      severity: "success",
    });
  };

  const handleError = (message: string) => {
    setSnackbar({ open: true, message, severity: "error" });
  };
  const handleSelect = (name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = [...selected, name];
    } else if (selectedIndex === 0) {
      newSelected = [...selected.slice(1)];
    } else if (selectedIndex === selected.length - 1) {
      newSelected = [...selected.slice(0, -1)];
    } else if (selectedIndex > 0) {
      newSelected = [
        ...selected.slice(0, selectedIndex),
        ...selected.slice(selectedIndex + 1),
      ];
    }

    setSelected(newSelected);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = Distributors.map((n) => n._id);
      setSelected(newSelecteds);
      setSelectAll(true);
      return;
    }
    setSelected([]);
    setSelectAll(false);
  };

  const isSelected = (name: string) => selected.includes(name);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to the first page with the new number of rows
  };

  const handleDeleteSelected = () => {
    // Implement deletion logic here
    console.log("Deleting", selected);
    setSelected([]);
  };

  const [addRemoveModalOpen, setAddRemoveModalOpen] = useState(false);

  const handleAddRemoveDevices = (selectedDevices: string[]) => {
    console.log("Selected Devices:", selectedDevices);
    // Here you can integrate the API to link devices with the Distributor
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const handleFilterChange = (event: SelectChangeEvent<string>) => {
    setFilterField(event.target.value as string);
  };

  const handleFilterValueChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFilterValue(event.target.value);
  };

  const filteredAndSortedDistributors = useMemo(() => {
    return Distributors.filter((distributor) => {
      return (
        distributor.firstName.toLowerCase().includes(search.toLowerCase()) ||
        distributor.lastName.toLowerCase().includes(search.toLowerCase()) ||
        distributor.email.toLowerCase().includes(search.toLowerCase()) ||
        distributor.phone.toLowerCase().includes(search.toLowerCase()) ||
        distributor.address.toLowerCase().includes(search.toLowerCase()) ||
        distributor.city.toLowerCase().includes(search.toLowerCase()) ||
        distributor.state.toLowerCase().includes(search.toLowerCase()) ||
        distributor.country.toLowerCase().includes(search.toLowerCase())
      );
    }).sort((a, b) => {
      const field = sortField as keyof typeof a;
      if (a[field] < b[field]) {
        return -1;
      }
      if (a[field] > b[field]) {
        return 1;
      }
      return 0;
    });
  }, [Distributors, search, sortField]);

  const columns = [
    { id: "createdAt", label: "Created Time" },
    { id: "firstName", label: "First Name" },
    { id: "lastName", label: "Last Name" },
    { id: "country", label: "Country" },
    { id: "state", label: "State" },
    { id: "city", label: "City" },
    { id: "address", label: "Address" },
    { id: "phone", label: "Phone" },
    { id: "email", label: "Email" },
    { id: "status", label: "Status" },
  ];
  return (
    <Box p={0}>
      <Box mt={0} sx={{ display: "flex", justifyContent: "center" }}>
        <Box
          mt={2}
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            width: { xs: "95%", md: "96%" },
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              p: 0,
            }}
          >
            <Typography variant="h6" sx={{ mb: 1 }}>
              Manage Your Distributors
            </Typography>
          </Box>
          <TableContainer
            component={Box}
            sx={{
              mx: "auto",
              width: "100%",
              maxWidth: "calc(100% - 20px)",
              p: 1,
              backgroundColor: "white",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                p: 1.5,
                backgroundColor: "#f5f5f5",
                mb: 1,
              }}
            >
              <CreateNewDistributorModal
                open={modalOpen}
                onClose={handleCloseModal}
                onSuccess={handleDistributorCreationSuccess}
                onError={handleError}
              />
              <Snackbar
                open={snackbar.open}
                autoHideDuration={8000}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                onClose={() => setSnackbar({ ...snackbar, open: false })}
              >
                <Alert severity={snackbar.severity} sx={{ width: "100%" }}>
                  {snackbar.message}
                </Alert>
              </Snackbar>
              <AddRemoveDeviceModal
                open={addRemoveModalOpen}
                onClose={() => setAddRemoveModalOpen(false)}
                devices={devices}
                onSave={handleAddRemoveDevices}
                distributorId={"any"}
              />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <TextField
                  variant="outlined"
                  size="small"
                  placeholder="Search"
                  value={search}
                  onChange={handleSearchChange}
                  sx={{ marginRight: 2, width: "300px" }}
                  InputProps={{
                    startAdornment: (
                      <SearchIcon sx={{ marginRight: 1, color: "gray" }} />
                    ),
                  }}
                />
                <FormControl
                  variant="outlined"
                  size="small"
                  sx={{ marginRight: 2, width: "150px" }}
                >
                  <InputLabel>Sort By</InputLabel>
                  <Select
                    value={sortField}
                    onChange={(e) => setSortField(e.target.value as string)}
                    label="Sort By"
                  >
                    <MenuItem value="">None</MenuItem>
                    {columns.map((column) => (
                      <MenuItem key={column.id} value={column.id}>
                        {column.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <Box
                sx={{ display: "flex", justifyContent: "flex-end", padding: 1 }}
              >
                <Tooltip title="Add New Distributor">
                  <StyledIconButton variantType="transparent" onClick={handleOpenModal}>
                    <AddIcon />
                  </StyledIconButton>
                </Tooltip>
              </Box>
            </Box>
            {selected.length > 0 && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  alignContent: "center",
                  padding: 1,
                  backgroundColor: "#f5f5f5",
                  px: 2,
                }}
              >
                {" "}
                <Typography>{selected.length} selected</Typography>
                <Tooltip title="Delete Selected">
                  <StyledIconButton variantType="transparent" onClick={handleDeleteSelected}>
                    <DeleteIcon />
                  </StyledIconButton>
                </Tooltip>
              </Box>
            )}

            <Table sx={{ minWidth: 650 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Checkbox
                      indeterminate={
                        selected.length > 0 &&
                        selected.length < Distributors.length
                      }
                      checked={
                        Distributors.length > 0 &&
                        selected.length === Distributors.length
                      }
                      onChange={handleSelectAllClick}
                      inputProps={{ "aria-label": "select all Distributors" }}
                    />
                  </TableCell>
                  {columns.map((column) => (
                    <TableCell key={column.id} align="left">
                      {column.label}
                    </TableCell>
                  ))}
                  {/* <TableCell align="left">Distributor</TableCell> */}
                  <TableCell align="center">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredAndSortedDistributors
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((Distributor) => {
                    const isItemSelected = isSelected(Distributor._id);
                    return (
                      <TableRow
                        hover
                        onClick={() => handleSelect(Distributor._id)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={Distributor._id}
                        selected={isItemSelected}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox color="primary" checked={isItemSelected} />
                        </TableCell>

                        <TableCell align="left">
                          {new Date(Distributor.createdAt).toLocaleString()}
                        </TableCell>
                        <TableCell align="left">
                          {Distributor.firstName}
                        </TableCell>
                        <TableCell align="left">
                          {Distributor.lastName}
                        </TableCell>
                        <TableCell align="left">
                          {Distributor.country}
                        </TableCell>
                        <TableCell align="left">{Distributor.state}</TableCell>
                        <TableCell align="left">{Distributor.city}</TableCell>
                        <TableCell align="left">
                          {Distributor.address}
                        </TableCell>
                        <TableCell align="left">{Distributor.phone}</TableCell>
                        <TableCell align="left">{Distributor.email}</TableCell>
                        {/* <TableCell align="left">{Distributor.}</TableCell> */}
                        <TableCell align="left">
                          <Chip
                            label={Distributor.isActive ? "Active" : "Inactive"}
                            color={Distributor.isActive ? "success" : "error"}
                          />
                        </TableCell>
                        <TableCell align="center">
                          <Tooltip title="Add Device to Distributor">
                            <StyledIconButton
                              variantType="transparent"
                              onClick={() => setAddRemoveModalOpen(true)}
                            >
                              <DevicesOtherIcon />
                            </StyledIconButton>
                          </Tooltip>
                          <Tooltip title="Delete Distributor">
                            <StyledIconButton variantType="transparent">
                              <DeleteIcon />
                            </StyledIconButton>
                          </Tooltip>
                          <Tooltip title="Manage Distributor">
                            <StyledIconButton variantType="transparent">
                              <MoreVertIcon />
                            </StyledIconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={filteredAndSortedDistributors.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              sx={{
                mr: 1,
              }}
            />
          </TableContainer>
        </Box>
      </Box>
    </Box>
  );
};

export default DistributorTable;
