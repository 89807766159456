import React from "react";
import { Box, Typography, Grid, Divider } from "@mui/material";
import { DeviceData } from "../../common/types/DeviceTypes";
import DeviceUserCard from "./DeviceUserCard";
interface DeviceOverviewProps {
  device: DeviceData;
}

const DeviceOverview: React.FC<DeviceOverviewProps> = ({ device }) => {
  const {
    deviceInformation,
    memoryUsage,
    pingStatus,
    deviceType,
    deviceLocation,
  } = device;

  const commonCardStyle = {
    p: 2,
    borderRadius: "8px",
    border: "1px solid #d1d1d1",
    backgroundColor: "background.paper",
    color: "text.primary",
    boxShadow: "0 1px 2px 0 rgb(0 0 0 / 0.05)",
  }

  const renderDeviceInfo = (label: string, value: string | undefined) => (
    <Grid item xs={12} sm={6} md={4}>
      <Typography variant="subtitle2" color="textSecondary">
        {label}
      </Typography>
      <Typography variant="body1" sx={{}}>
        {value || "N/A"}
      </Typography>
    </Grid>
  );

  const deviceUsers = [
    { email: deviceInformation.customerEmail, role: "Customer" },
    { email: deviceInformation.installerEmail, role: "Installer" },
    ...deviceInformation.additionalCustomers.map((email) => ({
      email,
      role: "Additional Customer",
    })),
    ...deviceInformation.additionalUsers.map((email) => ({
      email,
      role: "Additional User",
    })),
  ].filter((user) => user.email); // Filter out any undefined or empty emails

  const SectionHeader = ({ title }: { title: string }) => (
    <>
      <Typography
        variant="h6"
        sx={{
          textAlign: "center",
          mb: 1,
          color: "#282828",
          fontWeight: "700",
          fontSize: "13px",
        }}
      >
        {title}
      </Typography>
      <Divider sx={{ mb: 2 }} />
    </>
  );

  return (
    <Box sx={{ py: 2 }}>
      {/* First Row */}
      <Grid container spacing={1.5} sx={{ mb: 1, display: 'flex', alignItems: 'stretch' }}>
        <Grid item xs={12} md={6} sx={{ display: 'flex' }}>
          <Box sx={{ ...commonCardStyle, width: '100%' }}>
            <SectionHeader title="Device Information" />
            <Grid container spacing={1.5}>
              {renderDeviceInfo("Name", deviceInformation.deviceName)}
              {renderDeviceInfo("Model", deviceType)}
              {renderDeviceInfo("Serial", deviceInformation.serial)}
              {renderDeviceInfo("Main Customer Email", deviceInformation.customerEmail)}
              {renderDeviceInfo("Installer Email", deviceInformation.installerEmail)}
              {renderDeviceInfo("Current Cartridge Type", deviceInformation.currentCartridgeType)}
            </Grid>
          </Box>
        </Grid>

        <Grid item xs={12} md={6} sx={{ display: 'flex' }}>
          <Box sx={{ ...commonCardStyle, width: '100%' }}>
            <SectionHeader title="Device Location" />
            <Grid container spacing={1.5}>
              {renderDeviceInfo("Country", deviceLocation?.country)}
              {renderDeviceInfo("State", deviceLocation?.state)}
              {renderDeviceInfo("City", deviceLocation?.city)}
              {renderDeviceInfo("Address", deviceLocation?.address)}
              {renderDeviceInfo("Postal Code", deviceLocation?.postalCode)}
              {renderDeviceInfo("Latitude", deviceLocation?.latitude?.toString())}
              {renderDeviceInfo("Longitude", deviceLocation?.longitude?.toString())}
            </Grid>
          </Box>
        </Grid>
      </Grid>

      {/* Second Row: Device Users */}
      <Box sx={{ ...commonCardStyle, mb: 1 }}>
        <SectionHeader title="Device Users" />
        <Box sx={{ display: "flex", gap: 1.5, flexWrap: "wrap" }}>
          {deviceUsers.map((user) => (
            <DeviceUserCard
              key={user.email}
              firstName={user.role}
              lastName=""
              email={user.email}
            />
          ))}
        </Box>
      </Box>

      {/* Third Row: 4 columns layout */}
      <Grid container spacing={1.5} sx={{ display: 'flex', alignItems: 'stretch' }}>
        <Grid item xs={12} sm={6} md={3} sx={{ display: 'flex' }}>
          <Box sx={{ ...commonCardStyle, width: '100%' }}>
            <SectionHeader title="Device IoT Version" />
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1.5 }}>
              <Box>
                <Typography variant="subtitle2" color="textSecondary">Version</Typography>
                <Typography variant="body1">{device.iotVersion?.version || "N/A"}</Typography>
              </Box>
              <Box>
                <Typography variant="subtitle2" color="textSecondary">Description</Typography>
                <Typography variant="body1">{device.iotVersion?.description || "N/A"}</Typography>
              </Box>
              <Box>
                <Typography variant="subtitle2" color="textSecondary">Updated Date</Typography>
                <Typography variant="body1">{device.iotVersion?.updatedAt || "N/A"}</Typography>
              </Box>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} sm={6} md={3} sx={{ display: 'flex' }}>
          <Box sx={{ ...commonCardStyle, width: '100%' }}>
            <SectionHeader title="Device Life Cycle" />
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1.5 }}>
              <Box>
                <Typography variant="subtitle2" color="textSecondary">Release Date</Typography>
                <Typography variant="body1">May 2024</Typography>
              </Box>
              <Box>
                <Typography variant="subtitle2" color="textSecondary">Install Date</Typography>
                <Typography variant="body1">May 2024</Typography>
              </Box>
              <Box>
                <Typography variant="subtitle2" color="textSecondary">Warranty</Typography>
                <Typography variant="body1">Check</Typography>
              </Box>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} sm={6} md={3} sx={{ display: 'flex' }}>
          <Box sx={{ ...commonCardStyle, width: '100%' }}>
            <SectionHeader title="Memory Usage" />
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1.5 }}>
              <Box>
                <Typography variant="subtitle2" color="textSecondary">Used</Typography>
                <Typography variant="body1">{`${memoryUsage.used} MB`}</Typography>
              </Box>
              <Box>
                <Typography variant="subtitle2" color="textSecondary">Free</Typography>
                <Typography variant="body1">{`${memoryUsage.free} MB`}</Typography>
              </Box>
              <Box>
                <Typography variant="subtitle2" color="textSecondary">Total</Typography>
                <Typography variant="body1">{`${memoryUsage.total} MB`}</Typography>
              </Box>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} sm={6} md={3} sx={{ display: 'flex' }}>
          <Box sx={{ ...commonCardStyle, width: '100%' }}>
            <SectionHeader title="Ping Status" />
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1.5 }}>
              <Box>
                <Typography variant="subtitle2" color="textSecondary">Status</Typography>
                <Typography variant="body1">{pingStatus.status}</Typography>
              </Box>
              <Box>
                <Typography variant="subtitle2" color="textSecondary">Last Ping</Typography>
                <Typography variant="body1">{pingStatus.timestamp}</Typography>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DeviceOverview;
