import React, { useState, useEffect } from "react";
import { Box, Grid, Stack, Tabs, useTheme } from "@mui/material";
import dayjs from "dayjs";
import IcleanDeviceTable from "./Charts/IcleanDeviceTable";
import MapView from "./Charts/MapView";
import DynamicBarGraph from "./Charts/DynamicBarGraph";
import TimeDynamicGraph from "./Charts/TimeDynamicGraph";
import useDeviceStore from "../../store/useDeviceStore";
import useAuthStore from "../../store/authStore";
import All from "./All";
import OldICleanLinesDashboard from "./OldCleanlinesDashboard";
import CleanlinesDashboard from "./CleanlinesDashboard";
import AverageUsageCard from "./Charts/AverageUsageCard";
import TotalUsageCard from "./Charts/TotalUsageCard";
import downloadPDF from "../Device/DownloadPDF";
import downloadCSV from "../Device/DownloadCSV";
import { ICleanDevice } from "../../common/types/DeviceTypes";
import PlainTab from "../Tabs/PlainTab";
import StyledButton from "../Tabs/StyledButton";
import StyledIconButton from "../Tabs/StyledIconButton";
import {
  makeStyles,
  Button,
  Select,
  DrawerBody,
  DrawerHeader,
  DrawerHeaderTitle,
  OverlayDrawer,
  Divider,
} from "@fluentui/react-components";
import type { SelectProps } from "@fluentui/react-components";
import {
  DataBarVerticalAscendingFilled,
  ArrowClockwiseFilled,
  Dismiss24Regular,
  TreeEvergreen20Filled,
} from "@fluentui/react-icons";
import { DatePicker } from "@fluentui/react-datepicker-compat";

interface Marker {
  id: string;
  position: {
    lat: number;
    lng: number;
  };
  name: string;
  status: "ONLINE" | "OFFLINE";
}

interface TabConfig {
  label: string;
  view: string;
  roles?: string[]; // Optional array of roles that can see this tab
}

type PortalType = 'Lotus-Pro' | 'iCleanLines';

const TAB_CONFIGURATIONS = {
  'Lotus-Pro': [
    {
      label: 'Summary',
      view: 'summary'
    },
    {
      label: 'Usage',
      view: 'dashboard'
    },
    {
      label: 'Map',
      view: 'map'
    }
  ],
  'iCleanLines': [
    {
      label: 'Icleanlines Old',
      view: 'icleanlinesOld',
      roles: ['Admin', 'SuperAdmin']
    },
    {
      label: 'iCleanLines',
      view: 'icleanlines'
    }
  ]
} as const;

const useStyles = makeStyles({
  control: {
    maxWidth: "175px",
  },
  drawerInputs: {
    width: "100%",
    marginBottom: "14px",
  },
  sustainabilityIconTextAlign: {
    display: "flex",
    alignItems: "center",
    columnGap: "8px",
  },
  treeIcon: {
    color: "#2E7D32",
  },
  drawerDividerMargin: {
    margin: "14px 0",
  },
});

const ICleanDashboard: React.FC<{ portal: PortalType }> = ({ portal }) => {
  const {
    iCleanDevices,
    selectedIcleanDevices,
    dateFilter,
    setDateFilter,
    intervalFilter,
    setIntervalFilter,
  } = useDeviceStore();
  const { user } = useAuthStore();
  const theme = useTheme();

  const [selectedTab, setSelectedTab] = useState<number>(1); // Set default to 1 (Lotus Pro)
  const [viewMode, setViewMode] = useState<string>("dashboard"); // Set default to "dashboard"
  const [selectedInterval, setSelectedInterval] = useState<string>("daily");
  const [isReportDrawerOpen, setIsReportDrawerOpen] = useState<boolean>(false);
  const [selectedReportInterval, setSelectedReportInterval] =
    useState<string>("daily");
  const [currentPortal, setCurrentPortal] = useState<PortalType>(portal);

  const styles = useStyles();

  const toggleReportDrawer = (newOpen: boolean) => () => {
    setIsReportDrawerOpen(newOpen);
  };

  const handleReportIntervalChange: SelectProps["onChange"] = (event, data) => {
    const newInterval = data.value as "daily" | "weekly" | "monthly";
    setSelectedReportInterval(newInterval);
  };

  const hasAccess = (tab: TabConfig): boolean => {
    if (!tab.roles) return true;
    return !!user && tab.roles.includes(user.role);
  };

  useEffect(() => {
    setCurrentPortal(portal);
    localStorage.setItem('selectedPortal', portal);
  }, [portal]);

  useEffect(() => {
    const savedPortal = localStorage.getItem('selectedPortal') as PortalType;
    if (savedPortal && !portal) {
      setCurrentPortal(savedPortal);
    }
  }, []);

  const getAvailableTabs = (): TabConfig[] => {
    if (!currentPortal || !(currentPortal in TAB_CONFIGURATIONS)) return [];
    // @ts-ignore
    return TAB_CONFIGURATIONS[currentPortal].filter(tab => hasAccess(tab));
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    const availableTabs = getAvailableTabs();
    if (availableTabs[newValue]) {
      setSelectedTab(newValue);
      setViewMode(availableTabs[newValue].view);
    }
  };

  useEffect(() => {
    const availableTabs = getAvailableTabs();
    let defaultTabIndex = 0;

    if (currentPortal === 'Lotus-Pro') {
      defaultTabIndex = availableTabs.findIndex(tab => tab.view === 'dashboard');
    } else if (currentPortal === 'iCleanLines') {
      defaultTabIndex = availableTabs.findIndex(tab => tab.view === 'icleanlines');
    }

    defaultTabIndex = defaultTabIndex >= 0 ? defaultTabIndex : 0;
    setSelectedTab(defaultTabIndex);
    if (availableTabs[defaultTabIndex]) {
      setViewMode(availableTabs[defaultTabIndex].view);
    }
  }, [currentPortal, user?.role]);

  const [markers, setMarkers] = useState<Marker[]>([]);
  const [markersCenter, setMarkersCenter] = useState({ lat: 0, lng: 0 });

  useEffect(() => {
    const newMarkers: Marker[] = iCleanDevices
      .filter((device) => device.deviceLocation)
      .map((device) => ({
        id: device.deviceInformation.deviceId,
        position: {
          lat: device.deviceLocation!.latitude,
          lng: device.deviceLocation!.longitude,
        },
        name: device.deviceInformation.deviceName,
        status: device.pingStatus.status === "ONLINE" ? "ONLINE" : "OFFLINE",
      }));

    setMarkers(newMarkers);

    let validDevices = iCleanDevices.filter(
      (device) =>
        device.deviceLocation?.latitude && device.deviceLocation?.longitude
    );

    if (validDevices.length > 0) {
      let latTotal = validDevices.reduce(
        (acc, device) => acc + device.deviceLocation!.latitude!,
        0
      );
      let lngTotal = validDevices.reduce(
        (acc, device) => acc + device.deviceLocation!.longitude!,
        0
      );
      let latAvg = latTotal / validDevices.length;
      let lngAvg = lngTotal / validDevices.length;
      setMarkersCenter({ lat: latAvg, lng: lngAvg });
    } else {
      setMarkersCenter({ lat: 0, lng: 0 });
    }
  }, [iCleanDevices, selectedTab]);

  const [isGallons, setIsGallons] = useState(false);
  const [selectedBarDate, setSelectedBarDate] = useState<Date | null>(null);
  const handleBarClick = (date: Date) => setSelectedBarDate(date);

  // Use selectedBarDate only if the interval is "daily"
  const selectedDate =
    intervalFilter === "daily" && selectedBarDate
      ? dayjs(selectedBarDate)
      : dayjs(dateFilter);

  const calculateLitresUsed = (device: ICleanDevice) => {
    if (!device.deviceUsage.length) return 0;
    return device.deviceUsage.reduce((acc, usage) => {
      const usageDate = dayjs(usage.uploadTimestamp);
      if (intervalFilter === "daily" && dateFilter) {
        if (usageDate.isSame(dayjs(dateFilter), "day")) {
          return acc + usage.litersDispensed;
        }
      } else if (intervalFilter === "weekly" && dateFilter) {
        const startOfWeek = dayjs(dateFilter).startOf("week");
        const endOfWeek = dayjs(dateFilter).endOf("week");
        if (
          usageDate.isSameOrAfter(startOfWeek) &&
          usageDate.isSameOrBefore(endOfWeek)
        ) {
          return acc + usage.litersDispensed;
        }
      } else if (intervalFilter === "monthly" && dateFilter) {
        const startOfMonth = dayjs(dateFilter).startOf("month");
        const endOfMonth = dayjs(dateFilter).endOf("month");
        if (
          usageDate.isSameOrAfter(startOfMonth) &&
          usageDate.isSameOrBefore(endOfMonth)
        ) {
          return acc + usage.litersDispensed;
        }
      }
      return acc;
    }, 0);
  };

  const handleReportGeneration = (
    reportType: string,
    fileType: "pdf" | "csv"
  ) => {
    // Prepare report data based on the report type
    const reportData = selectedIcleanDevices.map((device) => ({
      deviceInformation: device.deviceInformation,
      deviceLocation: device.deviceLocation,
      deviceUsage: device.deviceUsage,
      litresUsed:
        reportType === "sustainability"
          ? calculateTotalLitersUsed(device)
          : calculateLitresUsed(device),
      cartridgeLife: {
        percentage: calculateCartridgeLifePercentage(
          device.deviceUsage.length > 0
            ? device.deviceUsage[device.deviceUsage.length - 1]
              ?.cyclesRemaining || 0
            : 0,
          device.deviceInformation.currentCartridgeType
        ),
      },
    }));

    // Nested conditions for handling file type and report type
    if (fileType === "pdf") {
      switch (reportType) {
        case "daily":
        case "weekly":
        case "monthly":
          downloadPDF(reportData, reportType, isGallons, dateFilter);
          break;
        case "sustainability":
          downloadPDF(reportData, "Total", isGallons, dateFilter);
          break;
        default:
          console.warn("Unsupported report type for PDF generation");
      }
    } else if (fileType === "csv") {
      switch (reportType) {
        case "daily":
        case "weekly":
        case "monthly":
          downloadCSV(reportData, reportType, isGallons, dateFilter);
          break;
        case "sustainability":
          downloadCSV(reportData, "sustainability", isGallons, dateFilter);
          break;
        default:
          console.warn("Unsupported report type for CSV generation");
      }
    } else {
      console.warn("Unsupported file type selected");
    }
  };

  const calculateTotalLitersUsed = (device: ICleanDevice) => {
    return device.deviceUsage.reduce((total: number, usage: any) => {
      return total + (usage.litersDispensed || 0);
    }, 0);
  };

  const calculateCartridgeLifePercentage = (
    cyclesRemaining: number,
    currentCartridgeType?:
      | "SAO-24 FB"
      | "SAO-24 NON-FB"
      | "SAO-04 FB"
      | "SAO-4"
      | "SAO-24"
      | "AO/Desiccant"
      | "NONE"
      | "SAO-04 NON-FB"
  ): number => {
    const totalCycles = (() => {
      switch (currentCartridgeType) {
        case "SAO-24 FB":
        case "SAO-24 NON-FB":
        case "SAO-24":
          return 550;
        case "SAO-04 FB":
        case "SAO-04 NON-FB":
        case "SAO-4":
        case "AO/Desiccant":
          return 920;
        default:
          return 920;
      }
    })();

    return (cyclesRemaining / totalCycles) * 100;
  };

  const [unit, setUnit] = useState<"liters" | "gallons">("liters");

  const handleUnitChange: SelectProps["onChange"] = (event, data) => {
    const newValue = data.value as "liters" | "gallons";
    setUnit(newValue);
    setIsGallons(newValue === "gallons");
  };

  const handleIntervalChange: SelectProps["onChange"] = (event, data) => {
    const newInterval = data.value as "daily" | "weekly" | "monthly";
    setSelectedInterval(newInterval);
    setIntervalFilter(newInterval);
    setSelectedBarDate(null);
  };

  const refreshPage = () => {
    window.location.reload();
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        overflow: "hidden",
        px: { xs: 0.1, md: 1, sm: 1 },
        backgroundColor: theme.palette.background.default,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "8px 0 10px 0",
          overflowX: "auto",
          gap: 2,
        }}
      >
        {/* Tabs Section */}
        <Box
          sx={{
            flexShrink: 0,
            overflowX: "auto",
            paddingRight: 2,
            "&::-webkit-scrollbar": {
              height: "6px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "rgba(0,0,0,.2)",
              borderRadius: "3px",
            },
          }}
        >
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            aria-label="Dashboard tabs"
            sx={{
              minHeight: "32px",
              "& .MuiTabs-indicator": {
                display: "none",
              },
              "& .MuiTab-root": {
                marginRight: "6px",
                "&:last-child": {
                  marginRight: 0,
                },
              },
            }}
          >
            {getAvailableTabs().map((tab, index) => (
              <PlainTab key={tab.label} label={tab.label} />
            ))}
          </Tabs>
        </Box>
        {/* Controls Section - Only Shown on Lotus Pro (Dashboard) Tab */}
        {(selectedTab === 1 || selectedTab === 4) && (
          <Box
            sx={{
              flexShrink: 0,
              overflowX: "auto",
              overflowY: "hidden",
              paddingLeft: 2,
              "&::-webkit-scrollbar": {
                height: "6px",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "rgba(0,0,0,.2)",
                borderRadius: "3px",
              },
            }}
          >
            <Stack
              direction="row"
              spacing={1}
              sx={{
                flexWrap: "nowrap",
                minWidth: "fit-content",
              }}
            >
              <Select
                style={{ fontFamily: "Inter" }}
                value={selectedInterval}
                onChange={handleIntervalChange}
              >
                <option value="daily">Daily</option>
                <option value="weekly">Weekly</option>
                <option value="monthly">Monthly</option>
              </Select>
              <DatePicker
                value={dateFilter}
                className={styles.control}
                placeholder="Select a date..."
                onSelectDate={(date: Date | null | undefined) =>
                  setDateFilter(date || null)
                }
                style={{ fontFamily: "Inter" }}
              />
              <Select
                appearance="outline"
                value={unit}
                onChange={handleUnitChange}
                style={{ fontFamily: "Inter" }}
              >
                <option value="liters">Liters</option>
                <option value="gallons">Gallons</option>
              </Select>
              <StyledButton
                onClick={toggleReportDrawer(true)}
                icon={<DataBarVerticalAscendingFilled />}
                iconColor={theme.palette.primary.main}
              >
                Generate Report
              </StyledButton>
              <OverlayDrawer
                position={"end"}
                open={isReportDrawerOpen}
                onOpenChange={(_, { open }) => setIsReportDrawerOpen(open)}
              >
                <DrawerHeader>
                  <DrawerHeaderTitle
                    action={
                      <Button
                        appearance="subtle"
                        aria-label="Close"
                        icon={<Dismiss24Regular />}
                        onClick={() => setIsReportDrawerOpen(false)}
                      />
                    }
                  >
                    Generate Report
                  </DrawerHeaderTitle>
                </DrawerHeader>

                <DrawerBody>
                  <p className={styles.sustainabilityIconTextAlign}>
                    <TreeEvergreen20Filled className={styles.treeIcon} />
                    Sustainability Report
                  </p>
                  <Button
                    appearance="subtle"
                    onClick={() =>
                      handleReportGeneration("sustainability", "pdf")
                    }
                  >
                    Sustainability Report (PDF)
                  </Button>
                  <Button
                    appearance="subtle"
                    onClick={() =>
                      handleReportGeneration("sustainability", "csv")
                    }
                  >
                    Sustainability Report (CSV)
                  </Button>
                  <Divider className={styles.drawerDividerMargin} />
                  <Select
                    className={styles.drawerInputs}
                    appearance="outline"
                    value={selectedReportInterval}
                    onChange={handleReportIntervalChange}
                  >
                    <option value="daily">Daily Report</option>
                    <option value="weekly">Weekly Report</option>
                    <option value="monthly">Monthly Report</option>
                  </Select>
                  <Button
                    appearance="subtle"
                    onClick={() =>
                      handleReportGeneration(selectedReportInterval, "pdf")
                    }
                  >
                    {selectedReportInterval.charAt(0).toUpperCase() +
                      selectedReportInterval.slice(1)}{" "}
                    Report (PDF)
                  </Button>
                  <Button
                    appearance="subtle"
                    onClick={() =>
                      handleReportGeneration(selectedReportInterval, "csv")
                    }
                  >
                    {selectedReportInterval.charAt(0).toUpperCase() +
                      selectedReportInterval.slice(1)}{" "}
                    Report (CSV)
                  </Button>
                </DrawerBody>
              </OverlayDrawer>
              <StyledIconButton onClick={refreshPage}>
                <ArrowClockwiseFilled />
              </StyledIconButton>
            </Stack>
          </Box>
        )}
      </Box>

      {viewMode === "summary" && <All />}
      {viewMode === "icleanlinesOld" && <OldICleanLinesDashboard />}
      {viewMode === "icleanlines" && <CleanlinesDashboard />}
      {viewMode === "dashboard" && (
        <Grid container spacing={1.25} alignItems="flex-start">
          {/* Left Column */}
          <Grid item xs={12} md={6}>
            <Grid container spacing={1.25}>
              <Grid item xs={12} lg={6}>
                <AverageUsageCard
                  selectedDevices={selectedIcleanDevices}
                  isGallons={isGallons}
                  color="rgba(30, 58, 138, 1)"
                  intervalFilter={intervalFilter}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <TotalUsageCard
                  selectedDevices={selectedIcleanDevices}
                  isGallons={isGallons}
                  color={theme.palette.success.main}
                />
              </Grid>
              <Grid item xs={12}>
                {" "}
                <DynamicBarGraph
                  interval={intervalFilter}
                  selectedDevices={selectedIcleanDevices}
                  dateFilter={dateFilter}
                  onBarClick={handleBarClick}
                  isGallons={isGallons}
                />
              </Grid>
              <Grid item xs={12}>
                <TimeDynamicGraph
                  selectedDate={selectedDate}
                  selectedDevices={selectedIcleanDevices}
                  isGallons={isGallons}
                />
              </Grid>
            </Grid>
          </Grid>
          {/* Right Column */}
          <Grid item xs={12} md={6}>
            <Grid container spacing={1.25}>
              <Grid item xs={12}>
                <IcleanDeviceTable
                  devices={iCleanDevices}
                  dateFilter={dateFilter}
                  intervalFilter={intervalFilter}
                  isGallons={isGallons}
                  setIsGallons={() => setIsGallons(!isGallons)}
                />
              </Grid>
              <Grid item xs={12}>
                {/* <MapView center={center} zoom={zoom} markers={markers} /> */}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
      {viewMode === "map" && (
        <MapView
          center={markersCenter}
          height="80vh"
          zoom={3}
          markers={markers}
        />
      )}
    </Box>
  );
};

export default ICleanDashboard;
