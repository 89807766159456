import {
  Card,
  CardContent,
  Grid,
  Typography,
  Box,
  useTheme,
} from "@mui/material";

type SummaryReportProps = {
  primary: string;
  secondary: string;
  iconPrimary: any;
  color: string;
  iconBackgroundColor?: string;
};

const SummaryReport = ({
  primary,
  secondary,
  iconPrimary,
  color,
  iconBackgroundColor,
}: SummaryReportProps) => {
  const theme = useTheme();
  const IconPrimary = iconPrimary;
  const primaryIcon = iconPrimary ? (
    <IconPrimary sx={{ fontSize: "20px" }} />
  ) : null;

  const commonStyles = {
    borderRadius: "4px",
    elevation: 0,

    border: "1px solid #d1d1d1",
    backgroundColor: "background.paper",
    color: "text.primary",
    boxShadow: "0 1px 2px 0 rgb(0 0 0 / 0.05)",
    py: "0.5rem",
  };

  return (
    <Card elevation={0} sx={{ ...commonStyles }}>
      <CardContent>
        <Grid container spacing={2} alignItems="center" sx={{ px: 1 }}>
          <Grid item>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: iconBackgroundColor,
                p: 1,
                px: 2,
                borderRadius: "4px",
              }}
            >
              <Typography variant="caption" sx={{ color: color }}>
                {primaryIcon}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs>
            <Typography
              sx={{
                color: "text.secondary",

                // letterSpacing: "1px",
                fontWeight: 500,
                textTransform: "uppercase",
              }}
            >
              {secondary}
            </Typography>
            <Typography
              fontWeight={900}
              sx={{
                fontSize: "25px",
              }}
            >
              {primary}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default SummaryReport;
