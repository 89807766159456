import React from "react";
import WiFiSetup from "../Wifi/WifiSetup";
import { Box } from "@mui/material";

const ConnectDeviceToNetwork: React.FC = () => {
  return (
    <Box>
      <WiFiSetup />
    </Box>
  );
};

export default ConnectDeviceToNetwork;
