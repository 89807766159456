import React from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Avatar,
  Grid,
  useTheme,
} from "@mui/material";
import { DeviceData } from "../../common/types/DeviceTypes";
import { makeStyles, Badge, Divider } from "@fluentui/react-components";

const useStyles = makeStyles({
  divider: {
    margin: "10px 0",
  },
});

interface DevicesCardViewProps {
  devices: DeviceData[];
  onRowClick: (device: DeviceData) => void;
}

const DevicesCardView: React.FC<DevicesCardViewProps> = ({
  devices,
  onRowClick,
}) => {
  const theme = useTheme();
  const classes = useStyles();

  return (
    <Box>
      <Grid container spacing={2}>
        {devices.map((device) => (
          <Grid
            item
            xs={12}
            md={6}
            lg={4}
            xl={3}
            key={device.deviceInformation.deviceId}
          >
            <Card
              onClick={() => onRowClick(device)}
              sx={{
                borderRadius: "0px",
                boxShadow: 2,
                border: "1px solid #d1d1d1",
                cursor: "pointer",
                height: "auto",
                width: "100%",
                borderBottom: `2px solid ${theme.palette.primary.main}`,
                "&:hover": {
                  backgroundColor: "#ededed",
                }
              }}
            >
              <CardContent sx={{ p: 2 }}>
                {/* Header with device name and email */}
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Avatar
                    sx={{
                      bgcolor: theme.palette.primary.main,
                      p: 0.25,
                      mr: 1.5,
                    }}
                  >
                    {(() => {
                      const words =
                        device.deviceInformation.deviceName.split(" ");
                      const firstChar = words[0][0] || "";
                      const secondChar = words[1] ? words[1][0] : "";
                      return `${firstChar}${secondChar}`.toUpperCase();
                    })()}
                  </Avatar>
                  <Box sx={{
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                  }}>
                    <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                      {device.deviceInformation.deviceName}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {device.deviceInformation.customerEmail}
                    </Typography>
                  </Box>
                </Box>

                <Divider className={classes.divider} />

                {/* Device details list */}
                <Box sx={{ ml: 0.5, display: "flex", flexDirection: "column", gap: 1 }}>
                  <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                    <Typography variant="body2" color="textSecondary">
                      Status
                    </Typography>
                    <Badge
                      color={device.pingStatus.status === "ONLINE" ? "success" : device.pingStatus.status === "OFFLINE" ? "danger" : "informative"}
                    >
                      {device.pingStatus.status.charAt(0).toUpperCase() + device.pingStatus.status.slice(1).toLowerCase()}
                    </Badge>
                  </Box>

                  <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                    <Typography variant="body2" color="textSecondary">
                      Serial
                    </Typography>
                    <Typography variant="body2">
                      {device.deviceInformation.deviceId}
                    </Typography>
                  </Box>

                  <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                    <Typography variant="body2" color="textSecondary">
                      Cartridge
                    </Typography>
                    <Typography variant="body2">
                      {device.deviceInformation.currentCartridgeType}
                    </Typography>
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default DevicesCardView;
