import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import {
  AppBar,
  Box,
  Tab,
  Tabs,
  Typography,
  Container,
  Toolbar,
  IconButton,
  Switch,
  FormControlLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import Timeline from "@mui/lab/Timeline";
import { timelineItemClasses } from "@mui/lab/TimelineItem";
import TimelineItem from "../components/Timeline/TimelineItem";
import DevicesOtherIcon from "@mui/icons-material/DevicesOther";
import { DeviceData } from "../common/types/DeviceTypes";
import DeviceUsageTimelineItem from "../components/Timeline/DeviceUsageTimelineItem";
import SettingsIcon from "@mui/icons-material/Settings";
import Sidebar from "../components/Sidebar/Sidebar";
interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}
interface Controls {
  forward: boolean;
  backward: boolean;
  fullCycle: boolean;
  power: boolean;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const DeviceDetailPage: React.FC = () => {
  const location = useLocation();
  const deviceDetails: DeviceData = location.state?.device;
  const [tabValue, setTabValue] = useState(0);

  const [controls, setControls] = useState<Controls>({
    forward: false,
    backward: false,
    fullCycle: false,
    power: false,
  });

  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    setControls((prev) => ({
      ...prev,
      [name]: checked,
    }));
  };

  const [configOpen, setConfigOpen] = useState<boolean>(false);

  const openConfigDialog = () => {
    setConfigOpen(true);
  };

  const closeConfigDialog = () => {
    setConfigOpen(false);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  if (!deviceDetails) {
    return <div>Loading...</div>;
  }
  return (
    <Box sx={{ display: "flex" }}>
      <Container maxWidth="lg">
        <AppBar sx={{ margin: 2 }} position="static" color="default">
          <Toolbar>
            {/* Left content - device information */}
            <Box
              sx={{
                flexGrow: 1,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              {/* Top row - Device name and status */}
              <Box display="flex" alignItems="center">
                <DevicesOtherIcon
                  sx={{
                    marginLeft: 1.5,
                    height: 32,
                    width: 60,
                    color: "primary.main",
                  }}
                />
                <Typography fontWeight={800} variant="h4" sx={{ padding: 2 }}>
                  {deviceDetails.deviceInformation.deviceName}
                </Typography>
                <Box
                  sx={{
                    display: "inline-flex",
                    backgroundColor:
                      deviceDetails.pingStatus.status === "ONLINE"
                        ? "#8bc34a"
                        : "#f44336",
                    borderRadius: "5px",
                    padding: "0 8px",
                    color: "white",
                    marginTop: 0.5,
                  }}
                >
                  <Typography
                    variant="body1"
                    fontWeight={700}
                    sx={{ lineHeight: "inherit" }}
                  >
                    {deviceDetails.pingStatus.status}
                  </Typography>
                </Box>
              </Box>
              {/* Bottom row - Serial number and installer */}
              <Box display="flex" alignItems="center">
                <Typography
                  fontWeight={500}
                  variant="body2"
                  sx={{ padding: 2 }}
                >
                  Serial # : {deviceDetails.deviceInformation.serial}
                </Typography>
                <Typography
                  fontWeight={500}
                  variant="body2"
                  sx={{ padding: 2 }}
                >
                  Installer : {deviceDetails.deviceInformation.installerEmail}
                </Typography>
                <Typography
                  fontWeight={500}
                  variant="body2"
                  sx={{ padding: 2 }}
                >
                  Customer : {deviceDetails.deviceInformation.customerEmail}
                </Typography>
              </Box>
            </Box>
            {/* Right content - controls and settings icon */}
            <Box display="flex" alignItems="center">
              {Object.entries(controls).map(([key, value]) => (
                <FormControlLabel
                  control={
                    <Switch
                      checked={value}
                      onChange={handleSwitchChange}
                      name={key}
                      color="primary"
                    />
                  }
                  label={key.charAt(0).toUpperCase() + key.slice(1)}
                  labelPlacement="start"
                  sx={{ marginX: 1 }}
                />
              ))}
              <IconButton onClick={openConfigDialog} color="inherit">
                <SettingsIcon sx={{ color: "primary.dark" }} />
              </IconButton>
            </Box>
          </Toolbar>
        </AppBar>

        <Dialog open={configOpen} onClose={closeConfigDialog}>
          <DialogTitle>Device Configuration</DialogTitle>
          <DialogContent>{/* Configuration content here */}</DialogContent>
          <DialogActions>
            <Button onClick={closeConfigDialog}>Close</Button>
          </DialogActions>
        </Dialog>

        <Tabs value={tabValue} onChange={handleChange} aria-label="device tabs">
          <Tab
            sx={{
              fontWeight: 700,

              variant: "h3",
            }}
            label="Timeline"
          />
          <Tab
            sx={{
              fontWeight: 700,

              variant: "h3",
            }}
            label="Usage Log"
          />
          <Tab
            sx={{
              fontWeight: 700,

              variant: "h3",
            }}
            label="Device Info"
          />
          <Tab
            sx={{
              fontWeight: 700,
              fontFamily: "'Poppins', sans-serif",
              variant: "h3",
            }}
            label="Metadata"
          />
          <Tab
            sx={{
              fontWeight: 700,
              fontFamily: "'Poppins', sans-serif",
              variant: "h3",
            }}
            label="Power Log"
          />
          <Tab
            sx={{
              fontWeight: 700,
              fontFamily: "'Poppins', sans-serif",
              variant: "h3",
            }}
            label="Memory Log"
          />

          {/* ... Other tabs */}
        </Tabs>
        <TabPanel value={tabValue} index={0}>
          <Timeline
            sx={{
              [`& .${timelineItemClasses.root}:before`]: {
                flex: 0,
                padding: 0,
              },
            }}
          >
            {/* {deviceDetails.pingStatus.map((item, index) => ( */}
            <TimelineItem
              status={deviceDetails.pingStatus.status}
              timestamp={deviceDetails.pingStatus.timestamp}
            />
            {/* ))} */}
          </Timeline>
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          Device Usage content here
          {/* <Timeline
            sx={{
              [`& .${timelineItemClasses.root}:before`]: {
                flex: 0,
                padding: 0,
              },
            }}
          >
            {deviceDetails.deviceUsage.map((usage, index) => (
              <DeviceUsageTimelineItem key={index} usage={usage} />
            ))}
          </Timeline> */}
        </TabPanel>
        <TabPanel value={tabValue} index={2}>
          Device Info content here
        </TabPanel>

        <TabPanel value={tabValue} index={3}>
          Metadata content here
        </TabPanel>

        <TabPanel value={tabValue} index={4}>
          Power log content here
        </TabPanel>

        <TabPanel value={tabValue} index={3}>
          Memory Usage content here
        </TabPanel>
        {/* ... Other tab panels */}
      </Container>
    </Box>
  );
};

export default DeviceDetailPage;
