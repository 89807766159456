import { useState } from 'react';
import {
  Box,
  Typography,
  Paper,
  useTheme,
} from '@mui/material';
import { DatePicker } from "@fluentui/react-datepicker-compat";
import { makeStyles, Label, Slider, SliderProps } from "@fluentui/react-components";
import type { DatePickerProps } from "@fluentui/react-datepicker-compat";
import dayjs from 'dayjs';

const useStyles = makeStyles({
  control: {
    maxWidth: "175px",
  },
});

export default function AutoCycleComponent() {
  const [isRunning, setIsRunning] = useState(false);
  const [date, setDate] = useState(null);
  const [forwardTime, setForwardTime] = useState(5);
  const [backflushTime, setBackflushTime] = useState(5);

  const styles = useStyles();
  const theme = useTheme();

  const onForwardSlideChange: SliderProps["onChange"] = (_, data) => setForwardTime(data.value)
  const onBackflushSlideChange: SliderProps["onChange"] = (_, data) => setBackflushTime(data.value)

  return (
    <Paper elevation={0} sx={{
      width: "100%",
      padding: 2,
      borderRadius: "8px",
      border: "1px solid #d1d1d1",
      backgroundColor: "background.paper",
      color: "text.primary",
      boxShadow: "0 1px 2px 0 rgb(0 0 0 / 0.05)",
    }}>
      {/* Header */}
      <Box display="flex" alignItems="center" justifyContent="space-between" pb={2}>
        <Typography variant="h6" fontWeight="bold">
          Auto Cycle
        </Typography>
        <DatePicker
          className={styles.control}
          placeholder="Select a date..."
        />
      </Box>

      {/* Content */}
      <Box mt={2} sx={{ display: "flex", justifyContent: "space-around", gap: 1 }}>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Label>Forward Time: {forwardTime} min</Label>
          <Slider
            value={forwardTime}
            min={0}
            max={30}
            onChange={onForwardSlideChange}
          />
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Label>Backflush Time: {backflushTime} min</Label>
          <Slider
            value={backflushTime}
            min={0}
            max={30}
            onChange={onBackflushSlideChange}
          />
        </Box>
      </Box>
    </Paper>
  );
}
