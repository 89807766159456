import React, { useEffect, useState } from "react";
import { DataGrid, GridColDef, GridRowParams } from "@mui/x-data-grid";
import { CircularProgress, Box, Typography, Chip } from "@mui/material";
import {
  ChangeRequest,
  ChangeRequestStatus,
} from "../../common/types/ChangeRequestTypes";
import { StyledTabs } from "../Tabs/IconTab";
import PlainTab from "../Tabs/PlainTab";
import {
  rejectChangeRequest,
  fetchChangeRequests,
  approveChangeRequest,
} from "../../services/change-request.service";
import ChangeRequestDetailsModal from "./ChangeRequestDetailsModal";
import useAuthStore from "../../store/authStore";
import StyledButton from "../Tabs/StyledButton";

export const formatJson = (jsonString: string) => {
  try {
    const jsonObject = JSON.parse(jsonString);
    return JSON.stringify(jsonObject, null, 2) // Convert back to string with indentation
      .replace(/[\{\}\"]/g, "") // Remove curly braces and quotes
      .replace(/\[\n/g, "[") // Remove newlines before square brackets
      .replace(/\n\]/g, "]"); // Remove newlines after square brackets
  } catch (error) {
    console.error("Invalid JSON format:", error);
    return jsonString; // Return the original string if parsing fails
  }
};

const ChangeRequestAdminPanel: React.FC = () => {
  const [changeRequests, setChangeRequests] = useState<ChangeRequest[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [tabValue, setTabValue] = useState(0); // 0: All, 1: Pending, 2: Approved, 3: Rejected
  const { user } = useAuthStore();
  const [selectedRequest, setSelectedRequest] = useState<ChangeRequest | null>(
    null
  );
  const [modalOpen, setModalOpen] = useState(false);

  const openModal = (request: ChangeRequest) => {
    setSelectedRequest(request);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  useEffect(() => {
    const loadChangeRequests = async () => {
      setLoading(true);
      try {
        const data = await fetchChangeRequests();

        const mappedData = data.map((item) => ({ ...item, id: item._id }));
        setChangeRequests(mappedData);
        console.log(changeRequests);
      } catch (error) {
        console.error(error);
      }
      setLoading(false);
    };

    loadChangeRequests();
  }, []);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const filteredChangeRequests = changeRequests.filter((request) => {
    if (tabValue === 1) return request.status === "Pending";
    if (tabValue === 2) return request.status === "Approved";
    if (tabValue === 3) return request.status === "Rejected";
    return true; // All tab
  });

  const handleRowClick = (params: GridRowParams) => {
    setSelectedRequest(params.row as ChangeRequest); // Cast as ChangeRequest if necessary
    setModalOpen(true);
  };

  const formatRequestType = (type: string) => {
    return type.replace(/([a-z])([A-Z])/g, "$1 $2");
  };

  const handleApprove = async (id: string) => {
    try {
      if (!user) return;
      await approveChangeRequest(id, { reviewedBy: user?.email });
      setChangeRequests((prev) =>
        prev.map((cr) =>
          cr._id === id ? { ...cr, status: ChangeRequestStatus.Approved } : cr
        )
      );
    } catch (error) {
      console.error(error);
    }
    closeModal();
  };

  const handleReject = async (id: string) => {
    try {
      if (!user) return;
      await rejectChangeRequest(id, { reviewedBy: user?.email });
      setChangeRequests((prev) =>
        prev.map((cr) =>
          cr._id === id ? { ...cr, status: ChangeRequestStatus.Rejected } : cr
        )
      );
    } catch (error) {
      console.error(error);
    }
    closeModal();
  };

  const columns: GridColDef[] = [
    {
      field: "id", // Assuming 'id' is your unique identifier
      headerName: "#",
      width: 50,
      align: "center",
      headerAlign: "center",
      renderCell: (params) =>
        changeRequests.findIndex((item) => item._id === params.id) + 1,
    },
    { field: "userEmail", headerName: "User", width: 170 },
    {
      field: "createdAt",
      headerName: "Date Created",
      width: 150,
      renderCell: (params) => (
        <span>{new Date(params.value).toLocaleString()}</span>
      ),
    },
    {
      field: "type",
      headerName: "Type",
      width: 170,
      renderCell: (params) => <span>{formatRequestType(params.value)}</span>,
    },
    {
      field: "details",
      headerName: "Details",
      width: 300,
      flex: 1,
      renderCell: (params) => (
        <Box
          sx={{
            whiteSpace: "normal",
            lineHeight: "1.5",
            mt: 2,
            overflow: "hidden",
            textOverflow: "ellipsis",
            justifyContent: "center",
            // border: "1px solid black",
            maxHeight: "3em", // Limit to two lines approximately
          }}
        >
          <span>{formatJson(JSON.stringify(params.value, null, 2))}</span>
        </Box>
      ),
    },

    {
      field: "status",
      headerName: "Status",
      width: 120,

      renderCell: (params) => {
        let color = "default";
        if (params.value === "Approved") color = "#1b5e20";
        else if (params.value === "Rejected") color = "#b71c1c";
        else if (params.value === "Pending") color = "#EEAD0E";

        return (
          <Chip
            label={params.value}
            sx={{ bgcolor: color, color: "white", fontWeight: 700 }}
            size="small"
          />
        );
      },
    },
    {
      field: "reviewedBy",
      headerName: "Reviewed By",
      width: 150,

      renderCell: (params) => {
        if (!params.value) {
          return (
            <Typography sx={{ fontWeight: 400, fontSize: 11, mt: 2 }}>
              ------------
            </Typography>
          );
        }
        return (
          <Typography sx={{ fontWeight: 400, fontSize: 11, mt: 2 }}>
            {params.value}
          </Typography>
        );
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 100,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <StyledButton size="small" onClick={() => openModal(params.row)}>
          View
        </StyledButton>
      ),
    },
  ];

  return (
    <Box p={2} sx={{ width: "98%", height: "90vh" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          py: 1,
          mb: 0,
        }}
      >
        <Typography variant="h6" sx={{ mb: 0 }}>
          Manage Change Requests
        </Typography>
      </Box>
      <StyledTabs
        value={tabValue}
        onChange={handleTabChange}
        aria-label="change request tabs"
      >
        <PlainTab label="All" />
        <PlainTab label="Pending" />
        <PlainTab label="Approved" />
        <PlainTab label="Rejected" />
      </StyledTabs>
      <Box sx={{ mt: 2 }}>
        {selectedRequest && (
          <ChangeRequestDetailsModal
            open={modalOpen}
            handleClose={closeModal}
            changeRequest={selectedRequest}
            handleApprove={() => handleApprove(selectedRequest?._id)}
            handleReject={() => handleReject(selectedRequest?._id)}
          />
        )}
        {loading ? (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
          </Box>
        ) : (
          <Box sx={{ mt: 2, height: "80vh" }}>
            <DataGrid
              rows={filteredChangeRequests}
              columns={columns}
              getRowId={(row) => row._id}
              onRowClick={handleRowClick}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
              pageSizeOptions={[10]}
              sx={{
                border: "1px solid #E0E0E0",
                ".MuiDataGrid-cell": {
                  backgroundColor: "white",
                  color: "#333",
                },
                ".MuiDataGrid-columnHeaders": {
                  backgroundColor: "white",
                  color: "#333",
                  borderBottom: "1px solid #e0e0e0",
                },
              }}
              hideFooterSelectedRowCount // This hides the "1 row selected" message
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ChangeRequestAdminPanel;
