import React, { useState, useEffect, useMemo } from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  Chip,
  Tooltip,
  TablePagination,
  TextField,
  SelectChangeEvent,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Snackbar,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  useTheme,
} from "@mui/material";
import {
  Search as SearchIcon,
  Delete as DeleteIcon,
  Preview as PreviewIcon,
  PlaylistAddCheck as PlaylistAddCheckIcon,
  PlaylistRemove as PlaylistRemoveIcon,
  EditNote as EditNoteIcon,
} from "@mui/icons-material";
import {
  fetchAllProductWarranties,
  updateProductWarranty,
  updateMultipleProductWarrantyStatus,
  updateBulkWarrantyErrors,
} from "../../services/product-warranty.service";
import useAuthStore from "../../store/authStore";
import {
  ProductWarranty,
  DeepPartial,
} from "../../common/types/ProductWarrantyTypes";
import { StyledTabs } from "../Tabs/IconTab";
import PlainTab from "../Tabs/PlainTab";
import EditWarrantyModal from "./EditWarrantyModal";
import BulkUpdateErrorsModal from "./UpdateErrorModal";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import StyledButton from "../Tabs/StyledButton";
import StyledIconButton from "../Tabs/StyledIconButton";

interface ProductWarrantyClaimsProps { }

const ProductWarranties: React.FC<ProductWarrantyClaimsProps> = () => {
  const [value, setValue] = useState(0);
  const [selected, setSelected] = useState<string[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search, setSearch] = useState("");
  const [sortField, setSortField] = useState<string>("");
  const [warranties, setWarranties] = useState<ProductWarranty[]>([]);
  const [initialWarranties, setInitialWarranties] = useState<ProductWarranty[]>(
    []
  );
  const [selectedWarranty, setSelectedWarranty] =
    useState<ProductWarranty | null>(null);
  const [openModal, setOpenModal] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState("");
  const [dialogAction, setDialogAction] = useState<"approve" | "close" | null>(
    null
  );
  // Change the type of warrantyForm to DeepPartial<ProductWarranty>
  const [warrantyForm, setWarrantyForm] = useState<
    DeepPartial<ProductWarranty>
  >({
    productSiteInformation: {
      serialNumber: "",
      company: "",
      deviceModel: "",
    },
    distributorInformation: {
      email: "",
    },
    faultDescription: {
      errorCode: undefined,
      returnReason: "",
      description: "",
      creditAmount: 0,
      creditStatus: "NO",
      replacementUnit: "NO",
      warrantyType: "IN",
    },
    status: "Initial review",
    comment: "",
  });

  const { user } = useAuthStore();
  const [snackbar, setSnackbar] = useState<{
    open: boolean;
    message: string;
    severity: "success" | "error" | "warning";
  }>({ open: false, message: "", severity: "success" });

  const [deviceFilter, setDeviceFilter] = useState<string | null>(null);
  const [openBulkModal, setOpenBulkModal] = useState(false);
  const [selectedBulkWarranties, setSelectedBulkWarranties] = useState<
    ProductWarranty[]
  >([]);
  const [bulkErrorForm, setBulkErrorForm] = useState({
    returnReason: "",
    errorCode: [],
    description: "",
  });
  const [loading, setLoading] = useState(false);
  const theme = useTheme();

  useEffect(() => {
    if (user) {
      fetchAllWarranties();
    }
  }, [user]);

  const fetchAllWarranties = async () => {
    setLoading(true);
    try {
      const response = await fetchAllProductWarranties();
      setWarranties(response);
      setInitialWarranties(response); // Save the initial list for future use
    } catch (error) {
      console.error("Error fetching product warranties:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const handleOpenModal = (warranty: ProductWarranty) => {
    setSelectedWarranty(warranty);
    setWarrantyForm({
      productSiteInformation: {
        serialNumber: warranty.productSiteInformation.serialNumber,
        company: warranty.productSiteInformation.company,
        deviceModel: warranty.productSiteInformation.deviceModel,
      },
      distributorInformation: {
        email: warranty.distributorInformation.email,
      },
      faultDescription: {
        returnReason: warranty.faultDescription.returnReason,
        description: warranty.faultDescription.description || "",
        creditAmount: warranty.faultDescription.creditAmount || 0,
        creditStatus: warranty.faultDescription.creditStatus || "NO",
        replacementUnit: warranty.faultDescription.replacementUnit || "NO",
        warrantyType: warranty.faultDescription.warrantyType || "IN",
        errorCode: warranty.faultDescription.errorCode || "",
      },
      status: warranty.status,
      comment: warranty.comment || "",
    });
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedWarranty(null);
    setSelected([]);
  };

  const handleOpenBulkModal = () => {
    // Find the warranties that match the selected IDs
    const selectedWarrantyObjects = warranties.filter((warranty) =>
      selected.includes(warranty._id)
    );

    if (selectedWarrantyObjects.length > 0) {
      setSelectedBulkWarranties(selectedWarrantyObjects); // Set the selected warranties
      setOpenBulkModal(true); // Open the modal
    } else {
      console.error("No warranties selected.");
    }
  };

  const handleCloseBulkModal = () => {
    setOpenBulkModal(false);
    setBulkErrorForm({ returnReason: "", errorCode: [], description: "" });
    setSelectedBulkWarranties([]);
  };

  const handleUpdateBulkError = (event: any) => {
    const { name, value } = event.target;
    setBulkErrorForm((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleConfirmBulkUpdate = async () => {
    try {
      // Use the selected warranty IDs from the `selected` array
      const selectedWarrantyIds = selected; // This already contains the warranty IDs

      // Call backend service to update the errorCode and description in bulk
      await updateBulkWarrantyErrors(
        selectedWarrantyIds, // Pass the IDs of selected warranties
        bulkErrorForm.returnReason, // Pass the return reason
        bulkErrorForm.errorCode, // Pass the array of error codes
        bulkErrorForm.description // Pass the description
      );

      setSnackbar({
        open: true,
        message: "Warranties updated successfully",
        severity: "success",
      });

      handleCloseBulkModal();
      fetchAllWarranties(); // Fetch updated warranties
    } catch (error) {
      setSnackbar({
        open: true,
        message: "Failed to update warranties",
        severity: "error",
      });
    }
  };

  const handleOpenConfirmDialog = (action: "approve" | "close") => {
    const actionMessage =
      action === "approve"
        ? `Are you sure you want to approve ${selected.length} warranties?`
        : `Are you sure you want to close ${selected.length} warranties?`;

    setConfirmMessage(actionMessage);
    setDialogAction(action); // Set the current action type
    setOpenConfirmDialog(true);
  };

  const handleConfirmAction = () => {
    if (dialogAction === "approve") {
      handleApproveSelected(); // Approve selected warranties
    } else if (dialogAction === "close") {
      handleCloseSelected(); // Close selected warranties (you will implement this)
    }
    setOpenConfirmDialog(false); // Close the dialog after action
  };

  const handleOpenSelectedModal = () => {
    if (selected.length > 0) {
      const lastSelectedWarrantyId = selected[selected.length - 1];
      const warrantyToOpen = warranties.find(
        (warranty) => warranty._id === lastSelectedWarrantyId
      );
      if (warrantyToOpen) {
        handleOpenModal(warrantyToOpen); // Open the modal for the last selected warranty
      }
    }
  };

  const handleConfirm = async (
    id: string,
    updatedFields: DeepPartial<ProductWarranty> // Accepting DeepPartial<ProductWarranty> type
  ) => {
    try {
      // Call the API function with all updated fields
      await updateProductWarranty(id, updatedFields); // Using the updated service function

      // Update local state to reflect changes
      setWarranties((prevWarranties) =>
        prevWarranties.map((warranty) =>
          warranty._id === id
            ? {
              ...warranty,
              distributorInformation: {
                ...warranty.distributorInformation,
                ...updatedFields.distributorInformation,
              },
              productSiteInformation: {
                ...warranty.productSiteInformation,
                ...updatedFields.productSiteInformation,
                purchaseDate:
                  updatedFields.productSiteInformation
                    ?.purchaseDate instanceof Date
                    ? updatedFields.productSiteInformation.purchaseDate
                    : warranty.productSiteInformation.purchaseDate, // Ensure purchaseDate is a Date
              },
              faultDescription: {
                ...warranty.faultDescription,
                ...updatedFields.faultDescription,
              },
              status: updatedFields.status ?? warranty.status, // Update status if provided
              comment: updatedFields.comment ?? warranty.comment, // Update comment if provided
              createdAt: warranty.createdAt, // Ensure createdAt remains unchanged
              updatedAt: new Date().toISOString(), // Update updatedAt timestamp
            }
            : warranty
        )
      );

      setSnackbar({
        open: true,
        message: "Warranty details updated successfully",
        severity: "success",
      });

      handleCloseModal();
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response) {
          const errorMessage = error.response.data.error;
          if (errorMessage.includes("E11000")) {
            setSnackbar({
              open: true,
              message: "Warranty is already Approved/Closed. Changes saved.",
              severity: "warning",
            });
            handleCloseModal();
            fetchAllWarranties();
          }
        }
      } else {
        setSnackbar({
          open: true,
          message: "Failed to update warranty details",
          severity: "error",
        });
      }
    }
  };

  // Update form values when inputs change
  const handleUpdateWarranty = (
    event:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | { target: { name: string; value: any } } // For handling complex inputs like Autocomplete
  ) => {
    const { name, value } = event.target;

    setWarrantyForm((prevState) => {
      const newState = { ...prevState };

      // Update productSiteInformation, distributorInformation, or faultDescription as necessary
      if (name in prevState.productSiteInformation!) {
        newState.productSiteInformation = {
          ...prevState.productSiteInformation,
          [name]: value,
        };
      } else if (name in prevState.distributorInformation!) {
        newState.distributorInformation = {
          ...prevState.distributorInformation,
          [name]: value,
        };
      } else if (name in prevState.faultDescription!) {
        newState.faultDescription = {
          ...prevState.faultDescription,
          [name]: Array.isArray(value) ? value : value, // Handle arrays (e.g., errorCode) or single values
        };
      } else if (name === "comment") {
        newState.comment = value;
      } else if (name === "status") {
        newState.status = value as
          | "Initial review"
          | "Technical assessment"
          | "Approved"
          | "Closed"
          | "Rejected";
      } else {
        (newState as any)[name] = value;
      }

      return newState;
    });
  };

  const handleStatusChange = (
    event: SelectChangeEvent<
      | "Initial review"
      | "Technical assessment"
      | "Approved"
      | "Closed"
      | "Rejected"
    >
  ) => {
    setWarrantyForm((prevState) => ({
      ...prevState,
      status: event.target.value as
        | "Initial review"
        | "Technical assessment"
        | "Approved"
        | "Closed"
        | "Rejected",
    }));
  };

  const handleSelect = (name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = [...selected, name];
    } else {
      newSelected = selected.filter((id) => id !== name);
    }

    setSelected(newSelected);
    setSelectedWarranty(
      warranties.find((warranty) => warranty._id === name) || null
    );
  };

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      // Select only the displayed warranties
      setSelected(displayedWarranties.map((warranty) => warranty._id));
    } else {
      setSelected([]);
    }
  };

  // function to handle approving selected warranties
  const handleApproveSelected = async () => {
    try {
      if (selected.length === 0) return; // No selected warranties to update

      // Call the service function to update multiple warranties at once
      const { message } = await updateMultipleProductWarrantyStatus(
        selected,
        "Approved"
      ); // Pass the selected IDs and the new status

      // Update local state to reflect the changes
      setWarranties((prevWarranties) =>
        prevWarranties.map((warranty) =>
          selected.includes(warranty._id)
            ? { ...warranty, status: "Approved" }
            : warranty
        )
      );

      // Clear the selection after approving
      setSelected([]);

      // Show a snackbar message with the update result
      setSnackbar({
        open: true,
        message: message, // Use the message from the API response
        severity: "success",
      });
    } catch (error) {
      console.error("Failed to approve selected warranties:", error);
      setSnackbar({
        open: true,
        message: "Failed to approve selected warranties",
        severity: "error",
      });
    }
  };

  // function to handle closing selected warranties
  const handleCloseSelected = async () => {
    try {
      if (selected.length === 0) return; // No selected warranties to update

      // Use the correct parameters: an array of selected IDs and the new status
      const { message } = await updateMultipleProductWarrantyStatus(
        selected,
        "Closed"
      );

      // Update local state to reflect the changes
      setWarranties((prevWarranties) =>
        prevWarranties.map((warranty) =>
          selected.includes(warranty._id)
            ? { ...warranty, status: "Closed" }
            : warranty
        )
      );

      // Clear the selection after closing
      setSelected([]);

      // Show a snackbar message with the update result
      setSnackbar({
        open: true,
        message: message, // Use the message from the API response
        severity: "success",
      });
    } catch (error) {
      console.error("Failed to close selected warranties:", error);
      setSnackbar({
        open: true,
        message: "Failed to close selected warranties",
        severity: "error",
      });
    }
  };

  const isSelected = (id: string) => selected.includes(id);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const handleSortChange = (event: SelectChangeEvent<string>) => {
    const selectedSortField = event.target.value as string; // Get the new sort field from the event

    if (selectedSortField === "") {
      setWarranties(initialWarranties); // Revert to the initial list when "None" is selected
    }

    setSortField(selectedSortField); // Set the new sort field
  };

  const availableDevices = useMemo(() => {
    return Array.from(
      new Set(
        warranties
          .map((warranty) => warranty.productSiteInformation.deviceModel)
          .filter(Boolean)
      )
    );
  }, [warranties]);

  const handleDeviceFilterChange = (event: SelectChangeEvent<string>) => {
    setDeviceFilter(event.target.value === "All" ? null : event.target.value);
  };

  const filteredWarranties = useMemo(() => {
    // Apply filtering first
    const filtered = warranties.filter((warranty) => {
      const matchesSearch =
        warranty.productSiteInformation.serialNumber
          .toLowerCase()
          .includes(search.toLowerCase()) ||
        warranty.distributorInformation.email
          .toLowerCase()
          .includes(search.toLowerCase()) ||
        warranty.productSiteInformation.company
          .toLowerCase()
          .includes(search.toLowerCase()) ||
        (warranty.productSiteInformation.deviceModel &&
          warranty.productSiteInformation.deviceModel
            .toLowerCase()
            .includes(search.toLowerCase()));

      const matchesDevice =
        !deviceFilter ||
        warranty.productSiteInformation.deviceModel === deviceFilter;

      return matchesSearch && matchesDevice;
    });

    // Apply sorting after filtering
    return filtered.sort((a, b) => {
      if (sortField === "serialNo") {
        return a.productSiteInformation.serialNumber.localeCompare(
          b.productSiteInformation.serialNumber
        );
      }
      if (sortField === "distributorEmail") {
        return a.distributorInformation.email.localeCompare(
          b.distributorInformation.email
        );
      }
      if (sortField === "company") {
        return a.productSiteInformation.company.localeCompare(
          b.productSiteInformation.company
        );
      }
      if (sortField === "deviceModel") {
        const deviceModelA = a.productSiteInformation.deviceModel || "";
        const deviceModelB = b.productSiteInformation.deviceModel || "";
        return deviceModelA.localeCompare(deviceModelB);
      }
      if (sortField === "status") {
        return a.status.localeCompare(b.status);
      }
      if (sortField === "submittedDate") {
        return (
          new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
        );
      }
      return 0;
    });
  }, [warranties, search, deviceFilter, sortField]);

  const getDisplayedWarranties = () => {
    switch (value) {
      case 1:
        return filteredWarranties.filter(
          (warranty) => warranty.status === "Approved"
        );
      case 2:
        return filteredWarranties.filter(
          (warranty) =>
            warranty.status === "Initial review" ||
            warranty.status === "Technical assessment"
        );
      case 3:
        return filteredWarranties.filter(
          (warranty) =>
            warranty.status === "Closed" || warranty.status === "Rejected"
        );
      default:
        return filteredWarranties;
    }
  };

  const displayedWarranties = getDisplayedWarranties().slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    setPage(0); // Reset the page to 0 when changing tabs
  };

  const enumToKeyValueArray = (enumObj: any) => {
    return Object.keys(enumObj).map((key) => ({
      code: key,
      description: enumObj[key],
    }));
  };

  return (
    <Box p={0}>
      <Box mt={0} sx={{ display: "flex", justifyContent: "center" }}>
        <Box
          mt={1}
          sx={{
            display: "flex",
            flexDirection: "column",
            width: { xs: "95%", md: "96%" },
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 1,
              p: 0.5,
            }}
          >
            <Typography variant="h5">Manage Product Warranties</Typography>
          </Box>
          <StyledTabs
            value={value}
            onChange={handleChange}
            aria-label="warranty claims tabs"
          >
            <PlainTab label="All" />
            <PlainTab label="Approved" />
            <PlainTab label="Pending" />
            <PlainTab label="Closed" />
          </StyledTabs>
          <Box sx={{ p: 0 }}>
            {loading ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <CircularProgress />
              </Box>
            ) : (
              <TableContainer
                sx={{ p: 1, my: 1, boxShadow: 0, backgroundColor: "white" }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    p: 1,
                    backgroundColor: "#f5f5f5",
                    mb: 2,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <TextField
                      variant="outlined"
                      size="small"
                      placeholder="Search"
                      value={search}
                      onChange={handleSearchChange}
                      sx={{ marginRight: 2, width: "300px" }}
                      InputProps={{
                        startAdornment: (
                          <SearchIcon sx={{ marginRight: 1, color: "gray" }} />
                        ),
                      }}
                    />
                    <FormControl
                      variant="outlined"
                      size="small"
                      sx={{ marginRight: 2, width: "150px" }}
                    >
                      <InputLabel>Sort By</InputLabel>
                      <Select
                        value={sortField}
                        onChange={handleSortChange}
                        label="Sort By"
                      >
                        <MenuItem value="">None</MenuItem>
                        <MenuItem value="serialNo">Serial No.</MenuItem>
                        <MenuItem value="distributorEmail">
                          Distributor Email
                        </MenuItem>
                        <MenuItem value="company">Company</MenuItem>
                        <MenuItem value="deviceModel">Device Model</MenuItem>
                        <MenuItem value="status">Status</MenuItem>
                        <MenuItem value="submittedDate">
                          Submitted Date
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </Box>

                {selected.length > 0 && (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      alignContent: "center",
                      padding: 1,
                      backgroundColor: "#f5f5f5",
                      px: 2,
                    }}
                  >
                    <Typography>{selected.length} selected</Typography>
                    <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                      {selected.length === 1 && (
                        <Tooltip title="Open Selected">
                          <StyledButton
                            onClick={handleOpenSelectedModal}
                            variantType="outline-primary"
                            icon={<PreviewIcon />}
                            iconColor={theme.palette.primary.main}
                          >
                            View
                          </StyledButton>
                        </Tooltip>
                      )}
                      <Tooltip title="Approve Selected">
                        <StyledButton
                          variantType="outline-primary"
                          icon={<PlaylistAddCheckIcon />}
                          iconColor={theme.palette.primary.main}
                          onClick={() => handleOpenConfirmDialog("approve")}
                          disabled={
                            selectedWarranty?.status === "Approved" ||
                            selectedWarranty?.status === "Closed"
                          }
                        >
                          Approve
                        </StyledButton>
                      </Tooltip>
                      <Tooltip title="Edit Selected">
                        <StyledButton
                          variantType="outline-primary"
                          onClick={() => handleOpenBulkModal()}
                          disabled={
                            Array.isArray(selectedWarranty) &&
                            selectedWarranty.some(
                              (w) =>
                                w.productSiteInformation.deviceModel !==
                                selectedWarranty[0].productSiteInformation
                                  .deviceModel
                            )
                          }
                          icon={<EditNoteIcon />}
                          iconColor={theme.palette.primary.main}
                        >
                          Edit Errors
                        </StyledButton>
                      </Tooltip>

                      <Tooltip title="Close Selected">
                        <StyledIconButton
                          variantType="transparent"
                          onClick={() => handleOpenConfirmDialog("close")}
                        >
                          <PlaylistRemoveIcon />
                        </StyledIconButton>
                      </Tooltip>

                      <Tooltip title="Delete Selected">
                        <StyledIconButton variantType="transparent">
                          <DeleteIcon />
                        </StyledIconButton>
                      </Tooltip>
                    </Box>
                  </Box>
                )}

                <Table sx={{ minWidth: 650 }} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <Checkbox
                          indeterminate={
                            selected.length > 0 &&
                            selected.length < displayedWarranties.length
                          }
                          checked={
                            displayedWarranties.length > 0 &&
                            selected.length === displayedWarranties.length
                          }
                          onChange={handleSelectAll}
                          inputProps={{ "aria-label": "select all warranties" }}
                        />
                      </TableCell>

                      <TableCell>Serial No.</TableCell>
                      <TableCell>Distributor Email</TableCell>
                      <TableCell>Company</TableCell>
                      <TableCell align="left" sx={{ fontWeight: "600", py: 0 }}>
                        <FormControl
                          variant="standard"
                          sx={{
                            "& .MuiInputBase-root": {
                              border: "none",
                              boxShadow: "none",
                            },
                            "& .MuiInputBase-root:before": {
                              borderBottom: "none",
                            },
                            "& .MuiInputBase-root:after": {
                              borderBottom: "none",
                            },
                          }}
                        >
                          <Select
                            value={deviceFilter || "All"}
                            onChange={handleDeviceFilterChange}
                            label="Device Model"
                          >
                            <MenuItem value="All"> Device Model</MenuItem>
                            {availableDevices.map((device) => (
                              <MenuItem key={device} value={device}>
                                {device}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </TableCell>
                      <TableCell>Warranty</TableCell>
                      <TableCell>Credit</TableCell>
                      <TableCell>Amount</TableCell>
                      <TableCell>Replacement</TableCell>
                      <TableCell>Return Category </TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Submitted Date</TableCell>
                      {/* <TableCell>Last Update</TableCell> */}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {displayedWarranties.map((warranty) => (
                      <TableRow
                        key={warranty._id}
                        onClick={() => handleSelect(warranty._id)}
                        hover
                        selected={isSelected(warranty._id)}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={isSelected(warranty._id)}
                            onChange={() => handleSelect(warranty._id)}
                          />
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <Typography variant="body2">
                            {warranty.productSiteInformation.serialNumber}
                          </Typography>
                        </TableCell>
                        <TableCell align="left">
                          <Typography variant="body2">
                            {warranty.distributorInformation.email}
                          </Typography>
                        </TableCell>
                        <TableCell align="left">
                          <Typography variant="body2">
                            {warranty.productSiteInformation.company}
                          </Typography>
                        </TableCell>
                        <TableCell align="left">
                          <Typography variant="body2">
                            {warranty.productSiteInformation.deviceModel}
                          </Typography>
                        </TableCell>
                        <TableCell align="left">
                          <Typography variant="body2">
                            {warranty.faultDescription.warrantyType}
                          </Typography>
                        </TableCell>
                        <TableCell align="left">
                          <Typography variant="body2">
                            {warranty.faultDescription.creditStatus}
                          </Typography>
                        </TableCell>
                        <TableCell align="left">
                          <Typography variant="body2">
                            {warranty.faultDescription.creditAmount || 0}
                          </Typography>
                        </TableCell>
                        <TableCell align="left">
                          <Typography variant="body2">
                            {warranty.faultDescription.replacementUnit}
                          </Typography>
                        </TableCell>
                        <TableCell align="left">
                          {/* <Typography variant="body2">
                            {warranty.faultDescription.errorCode &&
                            Array.isArray(
                              warranty.faultDescription.errorCode
                            ) &&
                            warranty.faultDescription.errorCode.length > 0
                              ? warranty.faultDescription.errorCode
                                  .map((error) =>
                                    error &&
                                    typeof error === "object" &&
                                    "description" in error
                                      ? error.description
                                      : error
                                  )
                                  .join(", ")
                              : warranty.faultDescription.returnReason}
                          </Typography> */}
                          <Typography variant="body2">
                            {warranty.faultDescription.returnReason}
                          </Typography>
                        </TableCell>
                        <TableCell align="left">
                          <Chip
                            label={warranty.status}
                            color={
                              warranty.status === "Approved"
                                ? "success"
                                : warranty.status === "Initial review"
                                  ? "info"
                                  : warranty.status === "Technical assessment"
                                    ? "warning"
                                    : "error"
                            }
                            sx={{
                              fontWeight: 700,
                              fontSize: "10px",
                              borderRadius: "5px",
                            }}
                            size="small"
                          />
                        </TableCell>
                        <TableCell align="left">
                          <Typography variant="body2" color="textSecondary">
                            {new Date(warranty.createdAt).toLocaleDateString()}
                          </Typography>
                        </TableCell>
                        {/* <TableCell align="left">
                        <Typography variant="body2" color="textSecondary">
                          {new Date(warranty.updatedAt).toLocaleDateString()}
                        </Typography>
                      </TableCell> */}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={getDisplayedWarranties().length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  sx={{
                    mr: 1,
                  }}
                />

                <EditWarrantyModal
                  open={openModal}
                  onClose={handleCloseModal}
                  selectedWarranty={selectedWarranty}
                  warrantyForm={warrantyForm}
                  handleUpdateWarranty={handleUpdateWarranty}
                  handleConfirm={() =>
                    handleConfirm(selectedWarranty?._id || "", warrantyForm)
                  }
                  handleStatusChange={handleStatusChange}
                />
                <BulkUpdateErrorsModal
                  open={openBulkModal}
                  onClose={handleCloseBulkModal}
                  selectedWarranties={selectedBulkWarranties}
                  bulkErrorForm={bulkErrorForm}
                  handleUpdateBulkError={handleUpdateBulkError}
                  handleConfirmBulkUpdate={handleConfirmBulkUpdate}
                />
              </TableContainer>
            )}
          </Box>
        </Box>
      </Box>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }} // Positioning at the top center
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbar.severity}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
      <Dialog
        open={openConfirmDialog}
        onClose={() => setOpenConfirmDialog(false)}
        aria-labelledby="confirm-dialog-title"
        aria-describedby="confirm-dialog-description"
      >
        <DialogTitle id="confirm-dialog-title">Confirm Action</DialogTitle>
        <DialogContent>
          <DialogContentText id="confirm-dialog-description">
            {confirmMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <StyledButton onClick={() => setOpenConfirmDialog(false)}>
            Cancel
          </StyledButton>
          <StyledButton
            onClick={handleConfirmAction}
            variantType="primary"
            autoFocus
          >
            Confirm
          </StyledButton>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ProductWarranties;
