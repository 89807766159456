import React, { useState } from "react";
import axios from "axios";
import {
  Box,
  Container,
  TextField,
  Typography,
  Modal,
  Snackbar,
  Alert,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Step,
  StepLabel,
  Stepper,
  Chip,
  useTheme,
} from "@mui/material";
import FlightLandIcon from "@mui/icons-material/FlightLand";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import {
  scanDeviceArrivals,
  confirmDeviceArrival,
  uploadToGoogleSheet,
} from "../../services/product-warranty.service";
import StyledButton from "../Tabs/StyledButton";

const CreateDeviceReturn: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [scannedSerials, setScannedSerials] = useState("");
  const [invalidSerials, setInvalidSerials] = useState<string[]>([]);
  interface Arrival {
    _id: string;
    productSiteInformation: {
      serialNumber: string;
      deviceModel: string;
    };
    faultDescription?: {
      returnReason: string;
      errorCode?: { description: string }[];
    };
  }
  const steps = ["Scan Serial Numbers", "Preview"];
  const [previewData, setPreviewData] = useState<Arrival[]>([]);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const [activeStep, setActiveStep] = useState(0);
  const theme = useTheme();

  const handleOpenModal = () => {
    setIsModalOpen(true);
    setActiveStep(0);
    setScannedSerials("");
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setScannedSerials("");
    setPreviewData([]); // Reset preview data
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const handleScanInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setScannedSerials(event.target.value);
  };

  const handleNext = () => {
    if (activeStep === steps.length - 1) {
      handleSubmit();
    } else {
      handleScan();
      setActiveStep((prev) => prev + 1);
    }
  };

  const handleBack = () => {
    if (activeStep === 0) {
      return;
    } else {
      setScannedSerials("");
      setPreviewData([]);
      setActiveStep((prev) => prev - 1);
    }
  };

  const handleScan = async () => {
    // Split by newlines and trim each serial number, then filter out any empty strings
    const serialsArray = scannedSerials
      .split("\n")
      .map((serial) => serial.trim())
      .filter((serial) => serial.length > 0); // Remove empty serials caused by extra newlines

    try {
      const response = await scanDeviceArrivals({
        serialNumbers: serialsArray,
      });
      // Correctly map the warranties data to preview
      setPreviewData(response.warranties); // Access warranties field
      // Extract the invalid serial numbers
      setInvalidSerials(response.invalidSerialNumbers);

      setSnackbar({
        open: true,
        message: `Previewing ${response.warranties.length} devices.`,
        severity: "success",
      });
    } catch (error) {
      setSnackbar({
        open: true,
        message:
          axios.isAxiosError(error) && error.response
            ? error.response.data.message
            : "An unknown error occurred",
        severity: "error",
      });
      if (axios.isAxiosError(error)) {
        if (error.response) {
          setInvalidSerials(error.response.data.invalidSerialNumbers);
        }
      }
    }
  };

  const handleSubmit = async () => {
    if (previewData.length === 0) {
      setSnackbar({
        open: true,
        message: "Please scan serial numbers first.",
        severity: "error",
      });
      return;
    }

    // Extract the warranty IDs from the previewData
    const warrantyIds = previewData.map((device) => device._id);

    try {
      const response = await confirmDeviceArrival({ warrantyIds });

      console.log(response);

      setSnackbar({
        open: true,
        message: `Successfully confirmed ${response.modifiedCount} devices arrival.`,
        severity: "success",
      });
      setActiveStep(0);
      setPreviewData([]);
      setScannedSerials("");
      handleCloseModal();
    } catch (error) {
      setSnackbar({
        open: true,
        message: "Failed to confirm device arrivals. Please try again.",
        severity: "error",
      });
    }
  };

  const handleUploadToGoogleSheet = async (invalidSerials: string[]) => {
    // Prepare the data as an array of arrays for each row to be added
    const currentDate = new Date().toLocaleDateString();
    const reportData = [
      [currentDate, invalidSerials.length, invalidSerials.join(", ")],
    ];

    try {
      // Use the service function to upload data to Google Sheets via your backend
      const response = await uploadToGoogleSheet({ data: reportData });

      if (response.status === "success") {
        setSnackbar({
          open: true,
          message: "Successfully uploaded the report to Google Sheets.",
          severity: "success",
        });
      } else {
        setSnackbar({
          open: true,
          message: "Failed to upload to Google Sheets. Please try again.",
          severity: "error",
        });
      }
    } catch (error) {
      console.error("Error uploading to Google Sheets:", error);
      setSnackbar({
        open: true,
        message: "Failed to upload to Google Sheets. Please try again.",
        severity: "error",
      });
    }
  };

  return (
    <Container>
      <Box
        sx={{
          width: "100%",
          mt: 4,
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Paper
          onClick={handleOpenModal}
          elevation={8}
          sx={{
            m: 2,
            width: 300,
            height: 100,
            backgroundColor: "white",
            fontSize: "16px",
            fontWeight: 600,
            boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)",
            "&:hover": {
              backgroundColor: "#e0e0e0",
              cursor: "pointer",
            },
            borderRadius: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: 1,
          }}
        >
          <FlightLandIcon
            style={{ fontSize: 40, color: theme.palette.primary.main }}
          />
          <Typography
            variant="h6"
            color="primary"
            gutterBottom
            sx={{ fontWeight: 600 }}
          >
            New Device Return
          </Typography>
        </Paper>
      </Box>

      {/* Modal for Device Preview */}
      <Modal open={isModalOpen} onClose={handleCloseModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 800,
            bgcolor: "#f5f5f5",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          <Typography variant="h6" sx={{ mb: 2 }}>
            Scan Device Barcodes
          </Typography>

          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>

          {activeStep === 0 && (
            <>
              {/* Text area for inputting serial numbers */}
              <TextField
                fullWidth
                variant="outlined"
                placeholder="Scan Serial Number Barcodes"
                margin="normal"
                value={scannedSerials}
                onChange={handleScanInput}
                multiline
                rows={4}
              />
            </>
          )}

          {activeStep === 1 && (
            <>
              {/* Preview Table */}
              {previewData.length > 0 && (
                <>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Count</TableCell>
                          <TableCell>Serial Number</TableCell>
                          <TableCell>Device Model</TableCell>
                          <TableCell>Return Category</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {previewData.map((device, index) => {
                          // Check if the return reason contains "High Priority"
                          const isHighPriority =
                            device.faultDescription?.returnReason?.includes(
                              "High Priority"
                            );

                          return (
                            <TableRow
                              key={device.productSiteInformation.serialNumber}
                              sx={{
                                backgroundColor: isHighPriority
                                  ? "rgba(255, 0, 0, 0.1)"
                                  : "inherit",
                              }}
                            >
                              <TableCell>{index + 1}</TableCell>
                              <TableCell>
                                {device.productSiteInformation.serialNumber}
                              </TableCell>
                              <TableCell>
                                {device.productSiteInformation.deviceModel}
                              </TableCell>
                              <TableCell>
                                {device.faultDescription?.returnReason}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                        <TableRow>
                          <TableCell
                            colSpan={4}
                            align="right"
                            sx={{ fontWeight: 600 }}
                          >
                            Total Device Arrivals: {previewData.length}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                  {/* Invalid Serial Numbers */}
                  {invalidSerials.length > 0 && (
                    <Paper
                      sx={{
                        p: 3,
                        mt: 2,
                        backgroundColor: "#f9f9f9",
                        borderRadius: 2,
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "flex-start",
                        }}
                      >
                        <Box>
                          <Typography variant="h6" sx={{ mb: 2 }}>
                            Invalid Serial Numbers
                          </Typography>
                          <Typography variant="body2" sx={{ fontWeight: 500 }}>
                            The following serial numbers are not found in the
                            system:
                          </Typography>
                          <Box
                            sx={{
                              display: "flex",
                              flexWrap: "wrap",
                              gap: 1,
                              mt: 1,
                            }}
                          >
                            {invalidSerials.map((serial, index) => (
                              <Chip
                                key={index}
                                label={serial}
                                variant="outlined"
                                sx={{
                                  borderColor: "red",
                                  color: "red",
                                  borderWidth: 1,
                                }}
                              />
                            ))}
                          </Box>
                          <Typography
                            variant="body2"
                            sx={{ mt: 2, fontWeight: 500 }}
                          >
                            Total Count: {invalidSerials.length}
                          </Typography>
                        </Box>

                        <StyledButton
                          variantType="primary"
                          size="large"
                          icon={<UploadFileIcon />}
                          onClick={() =>
                            handleUploadToGoogleSheet(invalidSerials)
                          } // Assuming you have this handler function
                        >
                          Upload to Google Sheet
                        </StyledButton>
                      </Box>
                    </Paper>
                  )}
                </>
              )}
              {previewData.length === 0 && invalidSerials.length > 0 && (
                <Paper
                  sx={{
                    p: 3,
                    mt: 2,
                    backgroundColor: "#f9f9f9",
                    borderRadius: 2,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "flex-start",
                    }}
                  >
                    <Box>
                      <Typography variant="h6" sx={{ mb: 2 }}>
                        Invalid Serial Numbers
                      </Typography>
                      <Typography variant="body2" sx={{ fontWeight: 500 }}>
                        The following serial numbers are not found in the
                        system:
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          flexWrap: "wrap",
                          gap: 1,
                          mt: 1,
                        }}
                      >
                        {invalidSerials.map((serial, index) => (
                          <Chip
                            key={index}
                            label={serial}
                            variant="outlined"
                            sx={{
                              borderColor: "red",
                              color: "red",
                              borderWidth: 1,
                            }}
                          />
                        ))}
                      </Box>
                      <Typography
                        variant="body2"
                        sx={{ mt: 2, fontWeight: 500 }}
                      >
                        Total Count: {invalidSerials.length}
                      </Typography>
                      <Typography variant="body2" sx={{ mt: 2 }}>
                        Please check the serial numbers and try again. You can
                        upload the CSV file for reference.
                      </Typography>
                    </Box>

                    <StyledButton
                      variantType="primary"
                      size="large"
                      icon={<UploadFileIcon />}
                      onClick={() => handleUploadToGoogleSheet(invalidSerials)} // Assuming you have this handler function
                    >
                      Upload to Google Sheet
                    </StyledButton>
                  </Box>
                </Paper>
              )}
            </>
          )}

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              gap: 1,
              mt: 1,
            }}
          >
            <StyledButton onClick={handleBack}>Back</StyledButton>
            <StyledButton
              onClick={handleNext}
              variantType="primary"
              disabled={
                (activeStep === 0 && !scannedSerials) ||
                (activeStep === 1 && previewData.length === 0)
              }
            >
              {activeStep === 0 ? "Next" : "Submit"}
            </StyledButton>
          </Box>
        </Box>
      </Modal>

      {/* Snackbar for success/error messages */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity as "success" | "error"}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default CreateDeviceReturn;
