import React, { useState, useEffect } from "react";
import {
  Box,
  TableContainer,
  Paper,
  TextField,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  TablePagination,
  Snackbar,
  Alert,
  Modal,
  Typography,
  Autocomplete,
  Checkbox,
  Divider,
  FormControlLabel,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import HistoryIcon from "@mui/icons-material/History";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs, { Dayjs } from "dayjs";
import IconTab, { StyledTabs } from "../Tabs/IconTab";
import {
  InventoryPart,
  AddedInventory,
} from "../../common/types/InventoryPartTypes";
import {
  fetchInventoryParts,
  fetchAddedPartsHistory,
  addRecoveredParts,
} from "../../services/inventory.service";
import useAuthStore from "../../store/authStore";

const AddInventory = () => {
  const [isUpdateModalOpen, setUpdateModalOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [selectedTab, setSelectedTab] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [selectedParts, setSelectedParts] = useState<InventoryPart[]>([]);
  const [availableParts, setAvailableParts] = useState<InventoryPart[]>([]);
  const [addedPartsHistory, setAddedPartsHistory] = useState<AddedInventory[]>(
    []
  );
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedDate, setSelectedDate] = useState<Dayjs | null>(null);
  const [snackbar, setSnackbar] = useState<{
    open: boolean;
    message: string;
    severity: "success" | "error";
  }>({ open: false, message: "", severity: "success" });

  const { user } = useAuthStore((state) => state);

  // List of part numbers to include
  const includedPartNumbers = [
    "25261",
    "25360",
    "25070",
    "25032",
    "25178",
    "25180",
    "25360",
  ];

  useEffect(() => {
    fetchInventory();
    fetchHistory();
  }, []);

  const fetchInventory = async () => {
    try {
      const response = await fetchInventoryParts();
      // Filter out parts with included part numbers
      const filteredParts = response.filter((part: { number: string }) =>
        includedPartNumbers.includes(part.number)
      );
      setAvailableParts(filteredParts);
    } catch (error) {
      console.error("Failed to fetch inventory parts:", error);
    }
  };

  const fetchHistory = async () => {
    try {
      const history = await fetchAddedPartsHistory();
      setAddedPartsHistory(history);
    } catch (error) {
      console.error("Failed to fetch added parts history:", error);
    }
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleSelectPart = (part: InventoryPart) => {
    if (selectedParts.some((selected) => selected._id === part._id)) {
      // Remove the part if it's already selected
      const newSelectedParts = selectedParts.filter(
        (selected) => selected._id !== part._id
      );
      setSelectedParts(newSelectedParts);
      // Update the "Select All" checkbox state
      setSelectAllChecked(newSelectedParts.length === availableParts.length);
    } else {
      // Add the part with a default quantity of 1
      const newSelectedParts = [...selectedParts, { ...part, quantity: 1 }];
      setSelectedParts(newSelectedParts);
      // Update the "Select All" checkbox state
      setSelectAllChecked(newSelectedParts.length === availableParts.length);
    }
  };

  // Function to handle "Select All"
  const handleSelectAll = () => {
    if (selectAllChecked) {
      // Deselect all
      setSelectedParts([]);
    } else {
      // Select all parts, adding a default quantity of 1
      const allPartsWithQuantity = availableParts.map((part) => ({
        ...part,
        quantity: 1,
      }));
      setSelectedParts(allPartsWithQuantity);
    }
    // Toggle "Select All" checkbox state
    setSelectAllChecked(!selectAllChecked);
  };

  const handleQuantityChange = (id: string, qty: number) => {
    setSelectedParts(
      selectedParts.map((part) =>
        part._id === id ? { ...part, quantity: qty } : part
      )
    );
  };

  const filteredParts = availableParts.filter((part) =>
    part.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleAddRecoveredParts = async () => {
    const recoveredParts: AddedInventory = {
      part: selectedParts.map((part) => ({
        _id: part._id,
        name: part.name,
        number: part.number,
        quantity: part.quantity || 1,
      })),
      addedDate: new Date().toISOString(),
      createdBy: user?.email || "unknown",
    };

    try {
      await addRecoveredParts([recoveredParts]); // Wrap in array
      setSnackbar({
        open: true,
        message: "Parts added successfully!",
        severity: "success",
      });

      setSelectedParts([]);
      setSelectAllChecked(false);
      fetchHistory();
    } catch (error) {
      setSnackbar({
        open: true,
        message: "Failed to add parts.",
        severity: "error",
      });
    }
  };

  const finalFilteredAddedParts = addedPartsHistory.filter((partEntry) => {
    // Filter by search query if provided
    const matchesSearchQuery = searchQuery
      ? partEntry.part.some(
          (p) =>
            (p.name?.toLowerCase() || "").includes(searchQuery.toLowerCase()) ||
            (p.number?.toLowerCase() || "").includes(searchQuery.toLowerCase())
        )
      : true; // If no search query, include all

    // Filter by selected date if provided
    const matchesDate = selectedDate
      ? dayjs(partEntry.addedDate).isSame(selectedDate, "day")
      : true; // If no date is selected, include all

    // Return true if both conditions are satisfied
    return matchesSearchQuery && matchesDate;
  });

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box sx={{ p: 3 }}>
        <StyledTabs
          value={selectedTab}
          onChange={(event, newValue) => handleTabChange(event, newValue)}
        >
          <IconTab
            icon={<AddCircleOutlineIcon />}
            label="Add Recovered Parts"
          />
          <IconTab icon={<HistoryIcon />} label="Recovered Parts History" />
        </StyledTabs>

        {selectedTab === 0 && (
          <Box sx={{ mt: 3, height: 600 }}>
            <Box sx={{ display: "flex", gap: 2, mt: 3 }}>
              {/* Available Parts Box with Autocomplete */}
              <Paper
                elevation={1}
                sx={{
                  flex: 1,
                  p: 2,
                  overflow: "auto",
                  height: "550px",
                }}
              >
                <Typography variant="h6">All Inventory Parts</Typography>
                <Divider sx={{ my: 1 }} />

                <Autocomplete
                  freeSolo
                  disableClearable
                  options={[]}
                  //options={availableParts}
                  //getOptionLabel={(part) => part.name}
                  onInputChange={(event, value) => setSearchQuery(value)}
                  renderInput={(params) => (
                    <TextField {...params} label="Search Parts" fullWidth />
                  )}
                />
                <List>
                  {/* "Select All" Checkbox */}
                  <FormControlLabel
                    sx={{ ml: 0.1 }}
                    control={
                      <Checkbox
                        checked={selectAllChecked}
                        onChange={handleSelectAll}
                      />
                    }
                    label="Select All"
                  />
                  {filteredParts.map((part) => (
                    <ListItem
                      key={part._id}
                      disablePadding
                      sx={{
                        "&:hover": {
                          backgroundColor: "rgba(33, 150, 243, 0.2)",
                        },
                      }}
                    >
                      <Checkbox
                        checked={selectedParts.some(
                          (selected) => selected._id === part._id
                        )}
                        onChange={() => handleSelectPart(part)}
                      />
                      <ListItemText primary={`${part.name} - ${part.number}`} />
                    </ListItem>
                  ))}
                </List>
              </Paper>

              {/* Selected Parts Box */}
              <Paper
                elevation={1}
                sx={{
                  flex: 1,
                  p: 2,
                  overflow: "auto",
                  height: "550px",
                }}
              >
                <Typography variant="h6">Recovered Parts</Typography>
                <Divider sx={{ my: 1 }} />
                <List>
                  {selectedParts.map((part) => (
                    <ListItem key={part._id} disablePadding>
                      <ListItemText primary={`${part.name} - ${part.number}`} />
                      <TextField
                        type="number"
                        label="Qty"
                        size="small"
                        value={part.quantity}
                        onChange={(e) =>
                          handleQuantityChange(part._id, Number(e.target.value))
                        }
                        sx={{ width: 60, ml: 2, mt: 1 }}
                      />
                    </ListItem>
                  ))}
                </List>
              </Paper>
            </Box>
            {/* Submit Button for Recovered Parts */}
            <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleAddRecoveredParts}
                disabled={selectedParts.length === 0} // Disable if no parts are selected
              >
                Submit Recovered Parts
              </Button>
            </Box>
          </Box>
        )}

        {selectedTab === 1 && (
          <>
            <TableContainer
              sx={{ p: 1, mt: 2 }}
              component={Paper}
              elevation={3}
            >
              <Box sx={{ mb: 2, mt: 1, display: "flex", gap: 2 }}>
                <TextField
                  size="small"
                  label="Search by Serial Number.."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  sx={{ width: 300 }}
                />

                <Paper
                  elevation={0}
                  sx={{ border: "1px solid #ccc", height: "30px" }}
                >
                  {" "}
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="Filter by Rework Date"
                      value={selectedDate}
                      onChange={(newValue) => {
                        setSelectedDate(newValue);
                      }}
                      sx={{
                        minWidth: "100px", // Match the button's minWidth
                        height: "28px",
                        padding: "3px 8px", // Match the button's padding
                        borderRadius: 1, // Match the button's borderRadius
                        backgroundColor: "transparent", // Match the button's background color
                        color: "white", // Match the button's text color
                        fontSize: "0.70rem", // Match the button's font size
                        fontWeight: 700, // Match the button's font weight
                        cursor: "pointer", // Make it look clickable
                        display: "flex", // Flexbox to align content like a button
                        alignItems: "center", // Center the content vertically
                        justifyContent: "center", // Center the content horizontally
                        "& .MuiOutlinedInput-root": {
                          border: "none", // Remove the border
                          padding: 0, // Remove internal padding
                          "& fieldset": {
                            display: "none", // Hide the default fieldset border
                          },
                        },
                        "& .MuiInputBase-input": {
                          textAlign: "center", // Center text
                          padding: 0, // Remove default padding
                          // color: "white", // Inherit the text color
                          fontWeight: 500,
                        },
                        "& .MuiSvgIcon-root": {
                          transform: "scale(0.8)", // Match icon scale
                          fontSize: "18px", // Match icon size
                          // color: "white", // Match icon color
                        },
                        "& .MuiInputLabel-root": {
                          display: "none", // Hide the label
                          color: "white",
                        },
                        "&:hover": {
                          color: "white", // Keep the text color on hover
                        },
                      }}
                    />
                  </LocalizationProvider>
                </Paper>
              </Box>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>#</TableCell>
                    <TableCell>Part Name</TableCell>
                    <TableCell>Part Number</TableCell>
                    <TableCell>Quantity</TableCell>
                    <TableCell>Added By</TableCell>
                    <TableCell>Date</TableCell>
                    {user?.role === "Supervisor" && (
                      <TableCell>Actions</TableCell>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {finalFilteredAddedParts
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((addedPartEntry, index) => (
                      <TableRow key={addedPartEntry._id}>
                        <TableCell>{page * rowsPerPage + index + 1}</TableCell>
                        <TableCell>
                          {/* List all part names, with a fallback */}
                          {addedPartEntry.part.length > 0
                            ? addedPartEntry.part
                                .map((p) => p.name || "Unnamed Part")
                                .join(", ")
                            : "No Parts"}
                        </TableCell>
                        <TableCell>
                          {/* List all part numbers, with a fallback */}
                          {addedPartEntry.part.length > 0
                            ? addedPartEntry.part
                                .map((p) => p.number || "No Number")
                                .join(", ")
                            : "No Numbers"}
                        </TableCell>
                        <TableCell>
                          {/* Total quantity for all parts */}
                          {addedPartEntry.part.reduce(
                            (total, p) => total + (p.quantity || 0),
                            0
                          )}
                        </TableCell>
                        <TableCell>{addedPartEntry.createdBy}</TableCell>
                        <TableCell>
                          {dayjs(addedPartEntry.addedDate).format("DD/MM/YYYY")}
                        </TableCell>
                        {user?.role === "Supervisor" && (
                          <TableCell>
                            <Button
                              variant="outlined"
                              color="primary"
                              onClick={() => {
                                setUpdateModalOpen(true);
                                // Additional logic if needed to set selected entry
                              }}
                            >
                              Edit
                            </Button>
                          </TableCell>
                        )}
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={finalFilteredAddedParts.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableContainer>
          </>
        )}
        <Snackbar
          open={snackbar.open}
          autoHideDuration={6000}
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          anchorOrigin={{ vertical: "top", horizontal: "center" }} // Positioning at the top center
        >
          <Alert
            onClose={() => setSnackbar({ ...snackbar, open: false })}
            severity={snackbar.severity}
            sx={{ width: "100%" }}
          >
            {snackbar.message}
          </Alert>
        </Snackbar>

        <Modal
          open={isUpdateModalOpen}
          onClose={() => setUpdateModalOpen(false)}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography variant="h6" gutterBottom>
            Edit Parts
          </Typography>
          {/* <EditAddDevicePartModal /> */}
        </Modal>
      </Box>
    </LocalizationProvider>
  );
};

export default AddInventory;
