import React, { useEffect, useState, useMemo } from "react";
import {
  Box,
  Typography,
  Snackbar,
  Alert,
  MenuItem,
  FormControl,
  Select,
  SelectChangeEvent,
  AlertColor,
  Tooltip,
} from "@mui/material";
import {
  DeviceData,
  DeviceErrors,
} from "../common/types/DeviceTypes";
import { fetchDeviceErrors } from "../services/device.service";
import RegisterDeviceModal from "../components/Device/RegisterDeviceModal";
import useAuthStore from "../store/authStore";
import ViewToggle from "../components/Button/ViewToggle";
import DevicesCardView from "../components/Device/DevicesCard";
import DeviceErrorCard from "../components/Device/DeviceErrorsCard";
import { DeviceAccessManager } from "../components/AccessControl/DeviceAccessManager";
import ReplaceDevice from "../components/Device/ReplaceDevice";
import FirmwareList from "../components/Device/UpdateDeviceFirmware";
import DeviceHistoryTable from "../components/Device/DeviceHistoryTable";
import DeviceErrorsHistoryTable from "../components/Device/DeviceErrorsHistoryTable";
import BulkAssignModal from "../components/BulkAssign/BulkAssignModal";
import { fetchCustomersForUser } from "../services/customer.service";
import { Customer } from "../store/useCustomer";
import { StyledTabs } from "../components/Tabs/IconTab";
import PlainTab from "../components/Tabs/PlainTab";
import StyledButton from "../components/Tabs/StyledButton";
import { Add } from "@mui/icons-material";

interface DeviceMonitoringProps {
  onDeviceSelect?: (device: DeviceData) => void;
  devices: DeviceData[];
}

export const DeviceMonitoring: React.FC<DeviceMonitoringProps> = ({
  onDeviceSelect,
  devices,
}) => {
  const [deviceErrors, setDeviceErrors] = useState<DeviceErrors[]>([]);
  const { user } = useAuthStore.getState();

  const [isFiltersVisible, setIsFiltersVisible] = useState<boolean>(false);
  const [isBulkAssignModal, setIsBulkAssignModal] = useState<boolean>(false);
  const [customerEmailFilter, setCustomerEmailFilter] = useState<string | null>(
    null
  );
  const [locationFilter, setLocationFilter] = useState<string | null>(null);
  const [statusFilter, setStatusFilter] = useState<string | null>(null);
  const [customers, setCustomers] = useState<Customer[]>([]);

  // Bulk Assign Modal
  const handleOpenBulkModal = () => setIsBulkAssignModal(true);
  const handleCloseBulkModal = () => setIsBulkAssignModal(false);

  // Extract unique customer emails from devices
  const availableCustomerEmails = Array.from(
    new Set(
      devices
        .map((device) => device.deviceInformation.customerEmail)
        .filter(Boolean)
    )
  );

  // Extract unique locations from devices
  const availableLocations = Array.from(
    new Set(
      devices.map((device) => device.deviceLocation?.country).filter(Boolean)
    )
  );

  // Extract unique statuses from devices
  const availableStatuses = Array.from(
    new Set(devices.map((device) => device.pingStatus.status).filter(Boolean))
  );

  const handleCustomerEmailFilterChange = (
    event: SelectChangeEvent<string>
  ) => {
    setCustomerEmailFilter(
      event.target.value === "All" ? null : event.target.value
    );
  };

  const handleLocationFilterChange = (event: SelectChangeEvent<string>) => {
    setLocationFilter(event.target.value === "All" ? null : event.target.value);
  };

  const handleStatusFilterChange = (event: SelectChangeEvent<string>) => {
    setStatusFilter(event.target.value === "All" ? null : event.target.value);
  };

  const filteredDevices = useMemo(() => {
    return devices.filter((device) => {
      return (
        (!customerEmailFilter ||
          device.deviceInformation.customerEmail === customerEmailFilter) &&
        (!locationFilter ||
          device.deviceLocation?.country === locationFilter) &&
        (!statusFilter || device.pingStatus.status === statusFilter)
      );
    });
  }, [devices, customerEmailFilter, locationFilter, statusFilter]);

  const handleIsFiltersVisibleChange = () => {
    setIsFiltersVisible((prev) => !prev);
  };

  const [modalOpen, setModalOpen] = useState(false);
  const [snackbar, setSnackbar] = useState<{
    open: boolean;
    message: string;
    severity: AlertColor | undefined;
  }>({ open: false, message: "", severity: undefined });

  const handleOpenModal = () => setModalOpen(true);
  const handleCloseModal = () => setModalOpen(false);
  const [selectedTab, setSelectedTab] = useState(
    parseInt(localStorage.getItem("selectedDeviceMonitoringTab") || "0")
  );

  const handleSuccess = () => {
    setSnackbar({
      open: true,
      message: "Device successfully created.",
      severity: "success",
    });
  };
  const handleError = (message: string) => {
    setSnackbar({ open: true, message, severity: "error" });
  };

  useEffect(() => {
    if (!user) {
      console.error("User not logged in");
      return;
    }

    const fetchAndSetDeviceErrors = async () => {
      try {
        const errorsData = await fetchDeviceErrors(user.email, user.role);
        setDeviceErrors(errorsData);
        console.log("ERRORS DATA", errorsData);
      } catch (error) {
        console.error("Error fetching device errors:", error);
      }
    };

    const loadCustomers = async () => {
      try {
        const fetchedCustomers = await fetchCustomersForUser();
        console.log("Fetched customers:", fetchedCustomers);
        setCustomers(fetchedCustomers);
      } catch (error) {
        console.error("Error fetching customers:", error);
      }
    };

    fetchAndSetDeviceErrors();
    loadCustomers();
  }, [user]);

  const handleRowClickCard = (device: DeviceData) => {
    if (onDeviceSelect) {
      onDeviceSelect(device);
    }
  };

  const tabLabels = ["Devices", "Device Errors"];
  if (user && ["SuperAdmin", "Admin", "Distributor"].includes(user.role)) {
    tabLabels.push("Replace Device");
  }
  if (user && ["SuperAdmin", "Admin"].includes(user.role)) {
    tabLabels.push("Update Firmware");
    tabLabels.push("Manage Access");
  }

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
    localStorage.setItem("selectedDeviceMonitoringTab", newValue.toString());
  };

  const [view, setView] = useState("card"); // view state

  return (
    <Box p={0}>
      <BulkAssignModal
        open={isBulkAssignModal}
        onClose={() => setIsBulkAssignModal(false)}
        devices={devices}
        customers={customers}
      />
      <Box mt={0} sx={{ display: "flex", justifyContent: "center" }}>
        <Box
          mt={2}
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            width: { xs: "95%", md: "96%" },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              justifyContent: { xs: "space-between", md: "space-between" },
              alignItems: { xs: "stretch", md: "center" },
              p: 0,
              width: "100%"
            }}
          >
            <Typography sx={{ mb: 2, textAlign: { xs: "left" } }} variant="h6">
              Manage Your Devices
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                justifyContent: 'space-between',
                gap: 1,
                mb: 2,
                width: { xs: "100%", md: "auto" },
              }}
            >
              <Box sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: 1,
                width: "auto"
              }}>
                {user && user.role !== "Customer" && (
                  <StyledButton
                    variantType="outline-primary"
                    onClick={handleOpenBulkModal}
                  >
                    Bulk Assign
                  </StyledButton>
                )}
                <StyledButton
                  variantType="outline-primary"
                  onClick={handleIsFiltersVisibleChange}
                >
                  Select Filters
                </StyledButton>
                <ViewToggle view={view} setView={setView} />
              </Box>
              {user &&
                ["Distributor", "SuperAdmin", "Admin"].includes(user.role) && (
                  <Box
                    sx={{
                      display: "flex",
                      width: { xs: "100%", sm: "auto" },
                      mt: { xs: 2, sm: 0 },
                    }}
                  >
                    <Tooltip title="Register New Device" placement="bottom">
                      <StyledButton
                        onClick={handleOpenModal}
                        variantType="primary"
                        icon={<Add />}
                        style={{ width: "100%" }}
                      >
                        Add Device
                      </StyledButton>
                    </Tooltip>
                  </Box>
                )}
            </Box>
          </Box>
          <Box>
            <StyledTabs
              value={selectedTab}
              onChange={handleChangeTab}
              aria-label="Device tabs"
              variant="scrollable"
              scrollButtons="auto"
              sx={{
                "& .MuiTab-root": {
                  marginRight: "6px",
                  "&:last-child": {
                    marginRight: 0,
                  },
                },
              }}
            >
              {tabLabels.map((label, index) => (
                <PlainTab key={label} label={label} />
              ))}
            </StyledTabs>
          </Box>
          {/* Filters Box Start */}
          {isFiltersVisible && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: 1,
                overflowY: "auto",
                "&::-webkit-scrollbar": {
                  display: "none",
                },
                pt: 1.25,
              }}
            >
              <FormControl sx={{ minWidth: 140 }} size="small">
                <Select
                  value={customerEmailFilter || "All"}
                  onChange={handleCustomerEmailFilterChange}
                  sx={{ fontSize: "14px" }}
                >
                  <MenuItem value="All">All Customer Emails</MenuItem>
                  {availableCustomerEmails.map((email) => (
                    <MenuItem key={email} value={email}>
                      {email}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl sx={{ minWidth: 140 }} size="small">
                <Select
                  value={locationFilter || "All"}
                  onChange={handleLocationFilterChange}
                  sx={{ fontSize: "14px" }}
                >
                  <MenuItem value="All">All Location</MenuItem>
                  {availableLocations.map((location) => (
                    <MenuItem key={location} value={location}>
                      {location}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl sx={{ minWidth: 140 }} size="small">
                <Select
                  value={statusFilter || "All"}
                  onChange={handleStatusFilterChange}
                  sx={{ fontSize: "14px" }}
                >
                  <MenuItem value="All">Ping Status</MenuItem>
                  {availableStatuses.map((status) => (
                    <MenuItem key={status} value={status}>
                      {status}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          )}
          {/* Filters Box End */}
          <RegisterDeviceModal
            open={modalOpen}
            onClose={handleCloseModal}
            onSuccess={handleSuccess}
            onError={handleError}
          />
          <Snackbar
            open={snackbar.open}
            autoHideDuration={8000}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            onClose={() => setSnackbar({ ...snackbar, open: false })}
          >
            <Alert severity={snackbar.severity} sx={{ width: "100%" }}>
              {snackbar.message}
            </Alert>
          </Snackbar>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              mt: 2, // Add some margin top for spacing between header and data grid
              width: "100%", // Ensure the inner box takes the full width of the parent box
            }}
          >
            {selectedTab === 0 && (
              <>
                {view === "table" && (
                  <DeviceHistoryTable
                    devices={filteredDevices}
                    onRowClick={handleRowClickCard}
                    user={user}
                  />
                )}
                {view === "card" && (
                  <DevicesCardView
                    devices={filteredDevices}
                    onRowClick={handleRowClickCard}
                  />
                )}
              </>
            )}
            {selectedTab === 1 && (
              <>
                {view === "table" && (
                  <DeviceErrorsHistoryTable deviceErrors={deviceErrors} />
                )}
                {view === "card" && (
                  <DeviceErrorCard deviceErrors={deviceErrors} />
                )}
              </>
            )}
            {selectedTab === 2 && (
              <ReplaceDevice devices={filteredDevices} onSuccess={() => { }} />
            )}
            {selectedTab === 3 && <FirmwareList devices={filteredDevices} />}
            {user &&
              (user.role === "Admin" || user.role === "SuperAdmin") &&
              selectedTab === 4 && (
                <DeviceAccessManager devices={filteredDevices} />
              )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
