import React, { useState } from "react";
import {
  Box,
  TextField,
  Typography,
  Link,
  Grid,
  Snackbar,
  Alert,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import useAuthStore from "../store/authStore";
import StyledButton from "../components/Tabs/StyledButton";

const LoginPage: React.FC = () => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const login = useAuthStore((state) => state.login);
  const snackbar = useAuthStore((state) => state.snackbar);
  const setSnackbar = useAuthStore((state) => state.setSnackbar);
  const navigate = useNavigate();

  const handleLogin = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/auth/login`,
        { email, password }
      );
      if (response.data && response.data.response) {
        const { token, user, expiresIn } = response.data.response;
        if (
          expiresIn === undefined ||
          (typeof expiresIn === "number" && expiresIn > 0)
        ) {
          login(user, token, expiresIn); // Correctly handle null for non-expiring tokens
          setSnackbar({
            open: true,
            message: "Login successful",
            severity: "success",
          });
          navigate("/dashboard");
        } else {
          setSnackbar({
            open: true,
            message: "Invalid expiration time received.",
            severity: "error",
          });
          return;
        }
      }
    } catch (error: any) {
      setSnackbar({
        open: true,
        message: error.response?.data?.details || "Login failed", // Gracefully handle errors
        severity: "error",
      });
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      handleLogin();
    }
  };
  return (
    <Box
      sx={{
        // widsth: "100%",
        // height: "100%",
        display: "flex",
        // alignItems: "center",
        justifyContent: "center",
        // alignContent: "center",
        minHeight: "100vh",
        background:
          "linear-gradient(45deg, rgba(30, 58, 138, 1) 55%, rgba(30, 58, 138, 0.6) 90%)",
      }}
    >
      <Box
        sx={{
          m: 1,
          ml: 2,
          maxWidth: "500px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          alignContent: "center",
          mb: 10,
        }}
      >
        {" "}
        <Grid
          container
          spacing={1}
          sx={{
            padding: "1.5rem",
            backgroundColor: "white",
            borderRadius: 2,
            boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
          }}
        >
          <Snackbar
            open={snackbar.open}
            autoHideDuration={6000}
            onClose={() => setSnackbar({ ...snackbar, open: false })}
            anchorOrigin={{ vertical: "top", horizontal: "center" }} // Positioning at the top center
          >
            <Alert
              onClose={() => setSnackbar({ ...snackbar, open: false })}
              severity={snackbar.severity}
            >
              {snackbar.message}
            </Alert>
          </Snackbar>
          <Grid item xs={12} sx={{ textAlign: "center" }}>
            <img
              src="/logo.png"
              alt="Company Logo"
              style={{ width: "50px", height: "50px", marginBottom: "1rem" }}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography
              component="h1"
              variant="h5"
              textAlign="center"
              sx={{ fontWeight: 600 }}
            >
              Sign In
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="subtitle1"
              sx={{ color: "gray", textAlign: "center", mt: -1 }} // Reduced margin top to bring text closer to 'Sign in'
            >
              To keep connected with us.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address / Username"
              name="email"
              autoComplete="email"
              onKeyPress={handleKeyPress}
              onChange={(e) => setEmail(e.target.value)}
              autoFocus
              sx={{
                ".MuiInputLabel-root": { fontSize: "0.875rem" },
                ".MuiInputBase-input": { fontSize: "0.875rem" },
              }} // Reduced font size
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              onKeyPress={handleKeyPress}
              autoComplete="current-password"
              onChange={(e) => setPassword(e.target.value)}
              sx={{
                ".MuiInputLabel-root": { fontSize: "0.875rem" },
                ".MuiInputBase-input": { fontSize: "0.875rem" },
              }} // Reduced font size
            />
          </Grid>
          <Grid item xs={12}>
            <StyledButton
              type="submit"
              fullWidth
              variantType="primary"
              size="large"
              onClick={handleLogin}
            >
              Sign In
            </StyledButton>
          </Grid>
          <Grid item xs={6}>
            <Link href="/forgot-password" variant="body2">
              Forgot password?
            </Link>
          </Grid>
          <Grid item xs={6} sx={{ textAlign: "right" }}>
            {/* <Link
              variant="body2"
              href="#"
              sx={{
                "&:hover": {
                  transform: "scale(1.5)", // subtle zoom out effect
                },
              }}
            >
              Create new account
            </Link> */}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default LoginPage;
