import React, { useState } from "react";
import { Box, TextField, Typography, Modal } from "@mui/material";
import StyledButton from "../Tabs/StyledButton";

interface OzoneResultsModalProps {
  open: boolean;
  onClose: () => void;
  onSave: (ozoneData: {
    aqueousOzone: string;
    orp: string;
    ph: string;
  }) => void;
}

const OzoneResultsModal: React.FC<OzoneResultsModalProps> = ({
  open,
  onClose,
  onSave,
}) => {
  const [aqueousOzone, setAqueousOzone] = useState("");
  const [orp, setorp] = useState("");
  const [ph, setPh] = useState("");

  const handleSave = () => {
    onSave({ aqueousOzone, orp, ph });
    onClose();
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          p: 2,
          backgroundColor: "background.paper",
          width: "90%",
          maxWidth: 400,
          margin: "auto",
          mt: 5,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography variant="h6">Enter Ozone Results</Typography>
        <TextField
          label="Aqueous Ozone"
          value={aqueousOzone}
          onChange={(e) => setAqueousOzone(e.target.value)}
          sx={{ mt: 2 }}
        />
        <TextField
          label="ORP Reading"
          value={orp}
          onChange={(e) => setorp(e.target.value)}
          sx={{ mt: 2 }}
        />
        <TextField
          label="pH"
          value={ph}
          onChange={(e) => setPh(e.target.value)}
          sx={{ mt: 2 }}
        />
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            mt: 2,
            gap: 1,
          }}
        >
          <StyledButton onClick={onClose}>Cancel</StyledButton>
          <StyledButton variantType="primary" onClick={handleSave}>
            Save
          </StyledButton>
        </Box>
      </Box>
    </Modal>
  );
};

export default OzoneResultsModal;
