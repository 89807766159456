import React from "react";
import { Box, Typography } from "@mui/material";
import ReplaceDeviceComponent from "../Device/ReplaceDevice";
import { DeviceData } from "../../common/types/DeviceTypes";

interface ReplaceDeviceProps {
  devices: DeviceData[]; // List of devices passed from the parent component
  onSuccess: (newDeviceId: string) => void;
}

const ReplaceDevice: React.FC<ReplaceDeviceProps> = ({
  devices,
  onSuccess,
}) => {
  // Placeholder for the replace device logic.
  const handleSuccess = (newDevice: any) => {
    onSuccess(newDevice);
  };
  return (
    <Box>
      <ReplaceDeviceComponent devices={devices} onSuccess={handleSuccess} />
    </Box>
  );
};

export default ReplaceDevice;
