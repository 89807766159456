import React from "react";
import Papa from "papaparse";

type ReportData = {
  deviceInformation: {
    deviceName: string;
    serial: string;
    currentCartridgeType?: string;
  };
  deviceLocation?: {
    country?: string;
  };
  deviceUsage: any[];
  litresUsed: number;
  cartridgeLife: {
    percentage: number;
  };
};

const downloadCSV = (
  reportData: ReportData[],
  reportType: string,
  isGallons?: boolean,
  dateFilter?: any
) => {
  // Prepare CSV data based on the selected report type
  let csvData: { [key: string]: any }[] = [];

  if (["daily", "weekly", "monthly"].includes(reportType)) {
    csvData = reportData.map((device) => {
      const plasticPrevented = calculatePlasticPrevented(device.litresUsed);
      return {
        "Device Name": device.deviceInformation.deviceName,
        "Serial Number": device.deviceInformation.serial,
        Location: device.deviceLocation?.country ?? "N/A",
        "Chemicals Prevented": isGallons
          ? (device.litresUsed * 0.264172).toFixed(2) + " gallons"
          : device.litresUsed.toFixed(2) + " L",
        "Plastic Prevented": isGallons
          ? (plasticPrevented * 2.20462).toFixed(2) + "pounds"
          : plasticPrevented.toFixed(2) + " kg",
        "Cartridge Life (%)": device.cartridgeLife.percentage.toFixed(2),
      };
    });

    // Calculate totals for daily, weekly, or monthly usage
    const totalUsage = calculateTotalUsage(reportData);
    const plasticPrevented = calculatePlasticPrevented(totalUsage);
    const treesEquivalent = calculateTreesEquivalent(totalUsage);

    // Add total values to the CSV data
    csvData.push(
      {},
      {
        "Total Chemicals Prevented": isGallons
          ? (totalUsage * 0.264172).toFixed(2) + " gallons"
          : totalUsage.toFixed(2) + " L",
        "Total Plastic Prevented": isGallons
          ? (plasticPrevented * 2.20462).toFixed(2) + " pounds"
          : plasticPrevented.toFixed(2) + " kg",
        "Equivalent Trees": treesEquivalent.toFixed(2),
      }
    );
  } else if (reportType === "sustainability") {
    csvData = reportData.map((device) => {
      const plasticPrevented = calculatePlasticPrevented(device.litresUsed);
      return {
        "Device Name": device.deviceInformation.deviceName,
        "Serial Number": device.deviceInformation.serial,
        Location: device.deviceLocation?.country ?? "N/A",
        "Total Chemicals Prevented": isGallons
          ? (device.litresUsed * 0.264172).toFixed(2) + " gallons"
          : device.litresUsed.toFixed(2) + " L",
        "Total Plastic Prevented": isGallons
          ? (plasticPrevented * 2.20462).toFixed(2) + " pounds"
          : plasticPrevented.toFixed(2) + " kg",
        "Cartridge Life (%)": device.cartridgeLife.percentage.toFixed(2),
      };
    });

    // Calculate totals for sustainability usage
    const totalUsage = calculateTotalUsage(reportData);
    const plasticPrevented = calculatePlasticPrevented(totalUsage);
    const treesEquivalent = calculateTreesEquivalent(totalUsage);

    // Add total values to the CSV data
    csvData.push(
      {},
      {
        "Total Chemicals Prevented": isGallons
          ? (totalUsage * 0.264172).toFixed(2) + " gallons"
          : totalUsage.toFixed(2) + " L",
        "Total Plastic Prevented": isGallons
          ? (plasticPrevented * 2.20462).toFixed(2) + " pounds"
          : plasticPrevented.toFixed(2) + " kg",
        "Equivalent Trees": treesEquivalent.toFixed(2),
      }
    );
  }

  // Calculate total values for the metadata section
  const totalUsage = calculateTotalUsage(reportData);
  const totalPlasticPrevented = calculatePlasticPrevented(totalUsage);
  const totalTreesEquivalent = calculateTreesEquivalent(totalUsage);

  // Disclaimer to be added at the top of the CSV after metadata
  const disclaimer =
    "Disclaimer: Information based on comparison of one liter ready-to-use plastic bottle and recycled SAO-24 cartridges. " +
    "Not meant as a substitute for Scope 3 emissions reporting. Does not include emissions associated with transport.";

  // Add metadata to the CSV file as a header
  const metadata = `Report Type: ${reportType}\nDate: ${
    dateFilter ? dateFilter.toDateString() : "N/A"
  }\nVolume of Plastic*: ${
    isGallons
      ? (totalPlasticPrevented * 2.20462).toFixed(2) + " pounds"
      : totalPlasticPrevented.toFixed(2) + " kg"
  }\nVolume of Chemicals*: ${
    isGallons
      ? (totalUsage * 0.264172).toFixed(2) + " gallons"
      : totalUsage.toFixed(2) + " L"
  }\nNo. of Trees**: ${totalTreesEquivalent.toFixed(
    2
  )}\n* prevented from entering the environment\n** equivalent of CO reduction`;

  // Convert CSV data using PapaParse
  const csv = Papa.unparse(csvData);

  // Combine metadata, CSV data, and disclaimer
  const completeCsv = `${metadata}\n\n${disclaimer}\n\n${csv}`;

  // Create a blob from the CSV data
  const blob = new Blob([completeCsv], { type: "text/csv;charset=utf-8;" });
  const url = URL.createObjectURL(blob);

  // Create a link element for downloading
  const link = document.createElement("a");
  link.href = url;
  link.download = `tersano-${reportType}-report.csv`;
  link.click();

  // Clean up
  URL.revokeObjectURL(url);
};

// Total usage calculation across devices
const calculateTotalUsage = (devices: ReportData[]): number => {
  return devices.reduce((total, device) => total + device.litresUsed, 0);
};

// Plastic prevented calculation based on total liters dispensed
const calculatePlasticPrevented = (litersDispensed: number) => {
  const weightPerBottleGrams = 23.4; // Weight of one RTU bottle in grams
  const volumePerBottleLiters = 0.975; // Volume of one RTU bottle in liters

  const numberOfBottles = litersDispensed / volumePerBottleLiters;
  const totalPlasticGrams = numberOfBottles * weightPerBottleGrams;
  const totalPlasticKilograms = totalPlasticGrams / 1000;

  return totalPlasticKilograms;
};

// Trees equivalent calculation based on CO2 removal
const calculateTreesEquivalent = (litersDispensed: number) => {
  const co2eqPerBottleKg = 0.125; // CO2eq per RTU bottle in kg
  const co2RemovalPerTreeKg = 21; // CO2 removed by one tree in kg
  const volumePerBottleLiters = 0.975; // Volume of one RTU bottle in liters

  const numberOfBottles = litersDispensed / volumePerBottleLiters;
  const totalCO2eq = numberOfBottles * co2eqPerBottleKg;
  const numberOfTrees = totalCO2eq / co2RemovalPerTreeKg;

  return numberOfTrees;
};

export default downloadCSV;
