import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Avatar,
  Box,
  IconButton,
  Tooltip,
  styled,
  ListItemIcon,
  SxProps,
  Theme,
} from "@mui/material";
import FaceIcon from "@mui/icons-material/Face";
import EditIcon from "@mui/icons-material/Edit";
import { Customer } from "../../store/useCustomer"; // Make sure to import your Customer type

const IconWrapper = styled(ListItemIcon)({
  justifyContent: "center",
  minWidth: "30px", // Ensures icons are properly aligned
  "& svg": {
    fill: "#9e9e9e", // Grey fill for the icon
    stroke: "black", // Black stroke color
    strokeWidth: "1.1px", // Thicker stroke to make it stand out
  },
  "&:hover": {
    color: "primary.main", // Light blue background on hover
    bgColor: "primary.main",
  },
});

interface CustomerCardProps {
  customer: Customer | undefined;
  onRowClick?: (customer: Customer) => void; // Add onRowClick prop
  sx?: SxProps<Theme>; // Add sx prop for custom styles
}

const CustomerCard: React.FC<CustomerCardProps> = ({
  customer,
  onRowClick,
  sx,
}) => {
  if (!customer) {
    return null; // or some fallback UI
  }
  const isMainCustomer = customer.role === "Customer";

  const handleCardClick = () => {
    if (onRowClick) {
      onRowClick(customer);
    }
  };

  return (
    <Card
      sx={{
        minWidth: 300,
        maxWidth: 300,
        position: "relative",
        cursor: "pointer",
        borderRadius: 0,
        ...sx,
      }}
      onClick={handleCardClick} // Attach the onClick handler
    >
      {/* <Box
        sx={{
          position: "absolute",
          top: 8,
          right: 8,
          display: "flex",
          alignItems: "center",
          gap: 0.5,
          padding: "4px",
          backgroundColor: customer.isActive ? "success.main" : "error.main",
          borderRadius: "4px",
          color: "white",
        }}
      >
        <Typography variant="body2" sx={{ fontWeight: 400, fontSize: 11 }}>
          {customer.isActive ? "Active" : "Inactive"}
        </Typography>
      </Box> */}
      <CardContent>
        <Box display="flex" alignItems="center" gap={2} sx={{ marginLeft: 0 }}>
          <Box>
            <Avatar sx={{ bgcolor: "primary.main", width: 50, height: 50 }}>
              <FaceIcon sx={{ width: 30, height: 30 }} />
            </Avatar>
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              //fontSize: "clamp(0.875rem, 2vw, 1rem)", // Adjust font size dynamically
            }}
          >
            <Typography variant="h6" noWrap>
              {customer.firstName} {customer.lastName}
            </Typography>
            <Typography
              sx={{
                fontSize: "clamp(0.75rem, 0.8rem, 0.875rem)", // Adjust font size dynamically
                fontWeight: 500,
              }}
              variant="subtitle1"
              noWrap
            >
              {customer.email}
            </Typography>

            <Typography variant="subtitle2" noWrap>
              {customer.country}, {customer.state}, {customer.city}
            </Typography>
            <Typography variant="subtitle2" noWrap>
              {customer.address}, {customer.postalCode}
            </Typography>
            <Typography variant="subtitle2" noWrap>
              Phone: {customer.phone}
            </Typography>
          </Box>
        </Box>
      </CardContent>
      <Box
        sx={{
          backgroundColor: "primary.main",
          p: "1.5px",
          textAlign: "center",
        }}
      />
    </Card>
  );
};

export default CustomerCard;
