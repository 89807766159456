import axios from "axios";
import useAuthStore from "../store/authStore";
const API_URL = process.env.REACT_APP_API_URL;
const { user } = useAuthStore.getState();

export const changePassword = async (
  email: string,
  currentPassword: string,
  newPassword: string
): Promise<any> => {
  try {
    const response = await axios.post(`${API_URL}/auth/change-password`, {
      email,
      oldPassword: currentPassword,
      newPassword,
    });
    return response.data;
  } catch (error) {
    console.error("Error changing password:", error);
    throw new Error("Failed to change password");
  }
};

export const impersonateUser = async (
  email: string,
  userImpersonate: string
) => {
  try {
    if (user?.role !== "SuperAdmin" && user?.role !== "Admin") {
      throw new Error(
        "[Unauthorized] You do not have permissions to impersonate a user"
      );
    }

    const response = await axios.post(`${API_URL}/auth/impersonate`, {
      email,
      userImpersonate,
    });
    return response.data;
  } catch (error) {
    console.error("Failed to impersonate user", error);
    throw error;
  }
};
