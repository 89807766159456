import React, { useState, useEffect } from "react";
import {
  Modal,
  Box,
  Typography,
  IconButton,
  Paper,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  InputAdornment,
  Button,
  Snackbar,
  Alert,
  useTheme,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DeveloperBoardIcon from "@mui/icons-material/DeveloperBoard";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import WifiIcon from "@mui/icons-material/Wifi";
import WifiTetheringIcon from "@mui/icons-material/WifiTethering";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import StyledIconButton from "../Tabs/StyledIconButton";

interface DeviceInfo {
  device: BluetoothDevice;
  characteristic: BluetoothRemoteGATTCharacteristic;
  statusCharacteristic: BluetoothRemoteGATTCharacteristic;
}

const SERVICE_UUID = "4fafc201-1fb5-459e-8fcc-c5c9c331914b";
const CHARACTERISTIC_UUID = "beb5483e-36e1-4688-b7f5-ea07361b26a8";
const STATUS_CHARACTERISTIC_UUID = "beb5483e-36e1-4688-b7f5-ea07361b26a9";

const WiFiSetup: React.FC = () => {
  const [selectedDevice, setSelectedDevice] = useState<DeviceInfo | null>(null);
  const [ssidList, setSSIDList] = useState<string[]>([]);
  const [selectedSSID, setSelectedSSID] = useState("");
  const [otherSSID, setOtherSSID] = useState("");
  const [password, setPassword] = useState("");
  const [status, setStatus] = useState("");
  const [wifiModalOpen, setWifiModalOpen] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [statusColor, setStatusColor] = useState("#e0e0e0");
  const [portalModalOpen, setPortalModalOpen] = useState(false);
  const [networkModalOpen, setNetworkModalOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const theme = useTheme();

  const setupDevice = async (device: BluetoothDevice) => {
    try {
      const server = await device.gatt.connect();
      const service = await server.getPrimaryService(SERVICE_UUID);
      const characteristic = await service.getCharacteristic(
        CHARACTERISTIC_UUID
      );
      const statusCharacteristic = await service.getCharacteristic(
        STATUS_CHARACTERISTIC_UUID
      );
      setSelectedDevice({ device, characteristic, statusCharacteristic });

      await characteristic.startNotifications();
      await statusCharacteristic.startNotifications();

      characteristic.addEventListener(
        "characteristicvaluechanged",
        handleCharacteristicValueChanged
      );
      statusCharacteristic.addEventListener(
        "characteristicvaluechanged",
        handleStatusCharacteristicValueChanged
      );

      const encoder = new TextEncoder();
      await characteristic.writeValue(encoder.encode("scan"));
    } catch (error) {
      console.error("Error setting up the device:", error);
    }
  };

  const handleCharacteristicValueChanged = (event: any) => {
    const dataView = event.target.value;
    const value = new TextDecoder().decode(dataView);
    const ssids = value.split("\n").filter(Boolean);

    // Use a Set to ensure uniqueness
    const uniqueSSIDs = Array.from(new Set(ssids));
    uniqueSSIDs.push("Other");
    setSSIDList(uniqueSSIDs);
  };

  const handleStatusCharacteristicValueChanged = (event: any) => {
    const dataView = event.target.value;
    const value = new TextDecoder().decode(dataView);
    setStatus(value);
    if (value.includes("successfully")) {
      setStatusColor("#4caf50");
    } else if (value.includes("failed")) {
      setStatusColor("#f44336");
    } else {
      setStatusColor("#ff9966");
    }
  };

  const sendCredentials = async () => {
    if (!selectedDevice) {
      console.error("No device selected");
      return;
    }

    try {
      const ssid = selectedSSID === "Other" ? otherSSID : selectedSSID;
      const data = JSON.stringify({ ssid, password });
      const encoder = new TextEncoder();
      await selectedDevice.characteristic.writeValue(encoder.encode(data));
      console.log("Credentials sent!");
    } catch (error) {
      console.error("Error sending credentials:", error);
    }
  };

  useEffect(() => {
    console.log("Updated SSID List:", ssidList);
  }, [ssidList]);

  useEffect(() => {
    console.log("Status:", status);
    console.log("Status Color:", statusColor);
  }, [status]);

  const handleClose = () => {
    setWifiModalOpen(false);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
    console.log("Copied to clipboard:", text);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: 1,
        mb: 3,
      }}
    >
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="error"
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>

      <Box
        textAlign="center"
        bgcolor="background.default"
        color="primary.contrastText"
        onClick={() => setNetworkModalOpen(true)}
        sx={{
          cursor: "pointer",
          mb: 2,
          py: 2,
          border: "1px solid rgba(0, 0, 0, 0.13)",
          width: { xs: "90%", sm: "50%", md: "50%" },
          "&:hover": {
            backgroundColor: "rgba(30, 58, 138, 1)",
            "& .MuiTypography-root": { color: "white" },
            "& .MuiIconButton-root": { color: "white" },
            "& .MuiSvgIcon-root": {
              fill: "white",
            },
            transform: "scale(1.01)",
          },
        }}
      >
        <WifiIcon sx={{ fontSize: 30, color: "grey.800" }} />{" "}
        {/* Using Material-UI WiFi icon */}
        <Typography
          variant="body1"
          sx={{ color: "black", fontWeight: 500, fontSize: "13px" }}
        >
          WiFi
        </Typography>
        <Typography
          variant="subtitle2"
          component="div"
          sx={{ color: "grey.800" }}
        >
          Give Device Access to Wifi
        </Typography>
      </Box>

      <Modal open={wifiModalOpen} onClose={handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { xs: "80%", sm: "70%", md: "40%" },
            maxHeight: "90vh",
            overflowY: "auto",
            bgcolor: "#f5f5f5",
            p: { xs: 2, sm: 3, md: 3 },

            borderRadius: 1,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 1,
            }}
          >
            <Typography
              variant="h6"
              sx={{ fontWeight: 500, color: "grey.800", textAlign: "center" }}
            >
              Connect to WiFi
            </Typography>
            <StyledIconButton
              variantType="transparent"
              iconColor={theme.palette.primary.main}
              onClick={handleClose}
            >
              <CloseIcon />
            </StyledIconButton>
          </Box>

          <Paper
            elevation={1}
            sx={{
              marginBottom: 2,
              backgroundColor: "#fff",
              p: 1,
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth margin="normal">
                  <InputLabel id="wifi-ssid-label">WiFi Network</InputLabel>
                  <Select
                    label="WiFi Network"
                    labelId="wifi-ssid-label"
                    value={selectedSSID}
                    onChange={(e) => setSelectedSSID(e.target.value)}
                  >
                    {ssidList.map((ssid, index) => (
                      <MenuItem key={index} value={ssid}>
                        {ssid}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              {selectedSSID === "Other" && (
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth margin="normal">
                    <TextField
                      value={otherSSID}
                      onChange={(e) => setOtherSSID(e.target.value)}
                      placeholder="Enter SSID"
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <WifiTetheringIcon />
                          </InputAdornment>
                        ),
                        autoComplete: "off", // Disable autofill
                      }}
                    />
                  </FormControl>
                </Grid>
              )}
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth margin="normal">
                  <TextField
                    type={showPassword ? "text" : "password"}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Password"
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <DeveloperBoardIcon />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword((prev) => !prev)}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                      autoComplete: "off",
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                {status && (
                  <Box
                    sx={{
                      display: "inline-flex",
                      alignItems: "center",
                      justifyContent: "center",
                      fontWeight: 700,
                      fontSize: "14px",
                      borderRadius: "5px",
                      padding: "6px 12px",
                      backgroundColor: statusColor,
                    }}
                  >
                    {status}
                  </Box>
                )}
              </Grid>
            </Grid>
          </Paper>

          <Button
            onClick={sendCredentials}
            variant="contained"
            sx={{
              mt: 2,

              fontWeight: 600,
              fontSize: "12px",
              bgcolor: "rgba(30, 58, 138, 0.9)",
              mr: 1,
            }}
          >
            Connect to WiFi
          </Button>
          <Button
            onClick={() => {
              handleClose();
            }}
            variant="contained"
            sx={{
              mt: 2,

              fontWeight: 500,
              fontSize: "12px",
              textTransform: "none",
              bgcolor: "error.main",
              minWidth: "auto",
              "&:hover": {
                bgcolor: "rgba(211, 47, 47, 0.8)",
              },
            }}
          >
            <CloseIcon />
          </Button>
        </Box>
      </Modal>

      <Modal open={portalModalOpen} onClose={() => setPortalModalOpen(false)}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { xs: "80%", sm: "70%", md: "40%" },
            maxHeight: "90vh",
            overflowY: "auto",
            bgcolor: "#f5f5f5",
            p: { xs: 2, sm: 3, md: 3 },

            borderRadius: 1,
            textAlign: "center",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 1,
            }}
          >
            <Typography
              variant="body1"
              sx={{
                fontWeight: 500,
                color: "grey.800",
                textAlign: "center",
                fontSize: "16px",
              }}
            >
              Click or Copy the on the link below while connected to the device
              in your browser
            </Typography>
            <StyledIconButton
              variantType="transparent"
              iconColor={theme.palette.primary.main}
              onClick={() => setPortalModalOpen(false)}
            >
              <CloseIcon />
            </StyledIconButton>
          </Box>
          <Typography
            variant="body2"
            sx={{
              display: "inline-flex",
              alignItems: "center",
              fontSize: "14px",
              color: "grey.800",
            }}
          >
            <a
              href="http://192.168.4.1/setup"
              target="_blank"
              rel="noopener noreferrer"
            >
              http://tersano.local/setup
            </a>
            <StyledIconButton
              variantType="transparent"
              iconColor={theme.palette.primary.main}
              onClick={() => copyToClipboard("http://tersano.local/setup")}
              sx={{ ml: 1 }}
            >
              <FileCopyIcon />
            </StyledIconButton>
          </Typography>
        </Box>
      </Modal>

      <Modal open={networkModalOpen} onClose={() => setNetworkModalOpen(false)}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { xs: "80%", sm: "70%", md: "40%" },
            maxHeight: "90vh",
            overflowY: "auto",
            bgcolor: "#f5f5f5",
            p: { xs: 2, sm: 3, md: 3 },
            borderRadius: 1,
            textAlign: "left",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              mb: 1,
            }}
          >
            <Typography
              variant="body1"
              sx={{
                fontWeight: 500,
              }}
            >
              Follow Instructions
            </Typography>
            <StyledIconButton
              variantType="transparent"
              iconColor={theme.palette.primary.main}
              onClick={() => setNetworkModalOpen(false)}
            >
              <CloseIcon />
            </StyledIconButton>
          </Box>
          <Box
            sx={{
              width: "90%",
              mt: 2,
            }}
          >
            <Typography
              variant="body1"
              sx={{
                mb: 2,
              }}
            >
              1. Open the WiFi settings on your phone/laptop/tablet
            </Typography>
            <Typography
              variant="body1"
              sx={{
                mb: 2,
              }}
            >
              2. Search for the Unit - LotusPro-(SerialNumber)
            </Typography>
            <Typography
              variant="body1"
              sx={{
                mb: 2,
              }}
            >
              3. Connect to the Unit and enter password 123456789
            </Typography>

            <Typography
              variant="body1"
              sx={{
                mb: 2,
              }}
            >
              4. Open Browser on Device and type "tersano.local" or
              "192.168.4.1"
            </Typography>
            <Typography
              variant="body1"
              sx={{
                mb: 2,
              }}
            >
              5. Connect device to WiFi. After successful connection, wait 6-10
              seconds and click Next.
            </Typography>
          </Box>
          <Typography
            variant="body2"
            sx={{
              display: "inline-flex",
              alignItems: "center",
              fontSize: "14px",
              color: "grey.800",
              mt: 2,
            }}
          >
            <a
              href="http://192.168.4.1/wifi"
              target="_blank"
              rel="noopener noreferrer"
            >
              http://tersano.local/wifi
            </a>
            <StyledIconButton
              variantType="transparent"
              iconColor={theme.palette.primary.main}
              onClick={() => copyToClipboard("http://192.168.4.1/wifi")}
              sx={{ ml: 1 }}
            >
              <FileCopyIcon />
            </StyledIconButton>
          </Typography>
        </Box>
      </Modal>
    </Box>
  );
};

export default WiFiSetup;
