import React, { useState, useEffect } from "react";
import {
  Box,
  Tab,
  Tabs,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Checkbox,
  TablePagination,
  Modal,
  Chip,
  styled,
  useTheme,
} from "@mui/material";
import useDeviceStore from "../../store/useDeviceStore"; // Adjust path as necessary
import { DeviceData } from "../../common/types/DeviceTypes";
import { updateTestStatusPayload } from "../../common/types/payload";
import TestModal from "./TestModal";
import { updateDeviceTestStatus } from "../../services/device.service";

const StyledTab = styled(Tab)(({ theme }) => ({
  textTransform: "none",
  minWidth: 100,
  minHeight: 32, // Reduced height
  padding: "6px 12px", // Slimmer padding
  borderRadius: "20px 20px 0 0",
  marginRight: 1,
  borderBottom: "1px solid transparent",
  "&.Mui-selected": {
    backgroundColor: "rgba(30, 58, 138, 0.2)",
    color: "rgba(30, 58, 138, 1)",
    borderLeft: "1px solid rgba(30, 58, 138, 1)",
    borderTop: "1px solid rgba(30, 58, 138, 1)",
    borderRight: "1px solid rgba(30, 58, 138, 1)",
    borderBottom: "2px solid rgba(30, 58, 138, 1)", // Remove bottom border for selected tab
  },
}));
const ManageTests: React.FC = () => {
  const { allDevices, fetchDevices } = useDeviceStore();
  const [value, setValue] = useState(0);
  const [selected, setSelected] = useState<string[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openModal, setOpenModal] = useState(false);
  const [editStatus, setEditStatus] = useState<Record<string, any>>({});
  const [currentDevice, setCurrentDevice] = useState<DeviceData | null>(null);

  useEffect(() => {
    fetchDevices();
  }, [fetchDevices]);

  const handleSelect = (serial: string) => {
    setSelected([serial]);
    const device = allDevices.find(
      (d) => d.deviceInformation.serial === serial
    );
    if (device) {
      setCurrentDevice(device);
      setOpenModal(true);
    }
  };

  const isSelected = (name: string) => selected.includes(name);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const filterDevicesByStatus = (status: "UNKNOWN" | "PASSED" | "FAILED") => {
    return allDevices.filter(
      (device) => device.testStatus.overallTestStatus.testStatus === status
    );
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setCurrentDevice(null);
  };

  const handleSubmitTestStatus = async (
    deviceId: string,
    testStatus: updateTestStatusPayload
  ) => {
    await updateDeviceTestStatus(deviceId, testStatus);
    setOpenModal(false);
    fetchDevices(); // Optionally refresh device data after update
  };

  const DeviceTable = ({ devices }: { devices: DeviceData[] }) => {
    return (
      <Box sx={{ backgroundColor: "white" }}>
        <Table size="small" aria-label="customized table">
          <TableHead>
            <TableRow sx={{ backgroundColor: "#f1f1f1" }}>
              <TableCell />
              <TableCell>Device</TableCell>
              <TableCell align="center">Overall Status</TableCell>
              <TableCell align="center">Front Cover</TableCell>
              <TableCell align="center">Filter Alarm</TableCell>
              <TableCell align="center">Ribbon Cable</TableCell>
              <TableCell align="center">Off Gas</TableCell>
              <TableCell align="center">Valves Present</TableCell>
              <TableCell align="center">Ozone Gen</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {devices
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((device, index) => (
                <TableRow
                  key={index}
                  onClick={() => handleSelect(device.deviceInformation.serial)}
                  hover
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={isSelected(device.deviceInformation.serial)}
                    />
                  </TableCell>

                  <TableCell component="th" scope="row">
                    <Box display="flex" alignItems="center" gap={2}>
                      <Box>
                        <Typography variant="body1">
                          {device.deviceInformation.deviceName}
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                          Serial : #{device.deviceInformation.serial}
                        </Typography>
                      </Box>
                    </Box>
                  </TableCell>

                  <TableCell align="center">
                    {" "}
                    <Chip
                      label={device.testStatus.overallTestStatus.testStatus}
                      color={
                        device.testStatus.overallTestStatus.testStatus ===
                        "PASSED"
                          ? "success"
                          : device.testStatus.overallTestStatus.testStatus ===
                            "FAILED"
                          ? "error"
                          : "default"
                      }
                      sx={{
                        fontWeight: 700,
                        fontSize: "10px",
                        borderRadius: "5px",
                      }}
                      size="small"
                    />
                  </TableCell>
                  <TableCell align="center">
                    {" "}
                    <Chip
                      label={device.testStatus.frontCover.testStatus}
                      color={
                        device.testStatus.frontCover.testStatus === "PASSED"
                          ? "success"
                          : device.testStatus.frontCover.testStatus === "FAILED"
                          ? "error"
                          : "default"
                      }
                      sx={{ fontWeight: 600, fontSize: "10px" }}
                      size="small"
                    />
                  </TableCell>
                  <TableCell align="center">
                    {" "}
                    <Chip
                      label={device.testStatus.filterAlarm.testStatus}
                      color={
                        device.testStatus.filterAlarm.testStatus === "PASSED"
                          ? "success"
                          : device.testStatus.filterAlarm.testStatus ===
                            "FAILED"
                          ? "error"
                          : "default"
                      }
                      sx={{ fontWeight: 600, fontSize: "10px" }}
                      size="small"
                    />
                  </TableCell>
                  <TableCell align="center">
                    {" "}
                    <Chip
                      label={device.testStatus.ribbonCable.testStatus}
                      color={
                        device.testStatus.ribbonCable.testStatus === "PASSED"
                          ? "success"
                          : device.testStatus.ribbonCable.testStatus ===
                            "FAILED"
                          ? "error"
                          : "default"
                      }
                      sx={{ fontWeight: 600, fontSize: "10px" }}
                      size="small"
                    />
                  </TableCell>

                  <TableCell align="center">
                    {" "}
                    <Chip
                      label={device.testStatus.offGasHasNoWater.testStatus}
                      color={
                        device.testStatus.offGasHasNoWater.testStatus ===
                        "PASSED"
                          ? "success"
                          : device.testStatus.offGasHasNoWater.testStatus ===
                            "FAILED"
                          ? "error"
                          : "default"
                      }
                      sx={{ fontWeight: 600, fontSize: "10px" }}
                      size="small"
                    />
                  </TableCell>

                  <TableCell align="center">
                    {" "}
                    <Chip
                      label={device.testStatus.valvesPresent.testStatus}
                      color={
                        device.testStatus.valvesPresent.testStatus === "PASSED"
                          ? "success"
                          : device.testStatus.valvesPresent.testStatus ===
                            "FAILED"
                          ? "error"
                          : "default"
                      }
                      sx={{ fontWeight: 600, fontSize: "10px" }}
                      size="small"
                    />
                  </TableCell>

                  <TableCell align="center">
                    {" "}
                    <Chip
                      label={device.testStatus.ozoneGeneratorPerMin.testStatus}
                      color={
                        device.testStatus.ozoneGeneratorPerMin.testStatus ===
                        "PASSED"
                          ? "success"
                          : device.testStatus.ozoneGeneratorPerMin
                              .testStatus === "FAILED"
                          ? "error"
                          : "default"
                      }
                      sx={{ fontWeight: 600, fontSize: "10px" }}
                      size="small"
                    />
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={devices.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
    );
  };

  const DeviceModal = () => (
    <Modal
      open={openModal}
      onClose={() => setOpenModal(false)}
      aria-labelledby="device-details"
      aria-describedby="detailed-information-about-selected-device"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography id="device-details" variant="h6" component="h2">
          Detailed Information
        </Typography>
        {/* Additional device details can be displayed here */}
      </Box>
    </Modal>
  );

  return (
    <Box sx={{ width: "100%", p: 2 }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider", p: 2 }}>
        <Typography variant="h5">Manage Tests</Typography>
      </Box>
      <Tabs value={value} onChange={handleChange} aria-label="test status tabs">
        <Tab label="Not Tested" />
        <Tab label="Failed" />
        <Tab label="Passed" />
        <Tab label="All" />
      </Tabs>
      <Box sx={{ p: 2 }}>
        {value === 0 && (
          <DeviceTable devices={filterDevicesByStatus("UNKNOWN")} />
        )}
        {value === 1 && (
          <DeviceTable devices={filterDevicesByStatus("FAILED")} />
        )}
        {value === 2 && (
          <DeviceTable devices={filterDevicesByStatus("PASSED")} />
        )}
        {value === 3 && <DeviceTable devices={allDevices} />}
        {currentDevice && (
          <TestModal
            open={openModal}
            onClose={handleCloseModal}
            submitTestStatus={handleSubmitTestStatus}
            device={currentDevice}
          />
        )}
      </Box>
    </Box>
  );
};

export default ManageTests;
