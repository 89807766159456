import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;

export const getAllUsers = async () => {
  try {
    const response = await axios.get(`${API_URL}/user/all`);
    return response.data;
  } catch (error) {
    console.error("Failed to fetch all users:", error);
    throw new Error("Failed to fetch all users");
  }
};

export const updateUserInfo = async (email: string, data: any) => {
  try {
    const response = await axios.patch(`${API_URL}/user/update`, {
      email,
      ...data, // Spread the data object to send the fields to be updated
    });
    return response.data;
  } catch (error) {
    console.error("Failed to update user info:", error);
    throw new Error("Failed to update user info");
  }
};
