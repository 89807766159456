import React, { useState } from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
  Font,
} from "@react-pdf/renderer";
import { ICleanDevice } from "../../common/types/DeviceTypes";
import tersanoLogoRegistered from "../../assets/images/tersano-logo-registered.png";
import SustainabilityKg from "../../assets/images/Sustainability-kg.png";
import SustainabilityLbs from "../../assets/images/Sustainability-lbs.png";

Font.register({
  family: "Aventa",
  fonts: [
    {
      src: "/fonts/Aventa-Regular.ttf",
      fontWeight: 400,
    },
    {
      src: "/fonts/Aventa-Medium.ttf",
      fontWeight: 500,
    },
    {
      src: "/fonts/Aventa-SemiBold.ttf",
      fontWeight: 600,
    },
    {
      src: "/fonts/Aventa-Bold.ttf",
      fontWeight: "bold",
    },
    {
      src: "/fonts/Aventa-ExtraBold.ttf",
      fontWeight: 800,
    },
  ],
});

// Create styles
const styles = StyleSheet.create({
  page: {
    backgroundColor: "#fff",
    padding: 30,
    fontFamily: "Aventa",
  },
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 50,
  },
  headerRight: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    margin: 0,
    lineHeight: 0.5,
    top: -5,
  },
  content: {
    display: "flex",
    lineHeight: 1,
    marginBottom: 30,
  },
  logo: {
    width: 200,
    marginTop: 10,
  },
  title: {
    fontSize: 16,
    textAlign: "center",
    marginTop: 10,
    color: "#003299",
    fontWeight: "bold",
  },
  litresUsed: { fontSize: 24, fontWeight: "bold" },
  subtitle: {
    fontSize: 20,
    textAlign: "center",
    margin: 5,
    color: "#003299",
    fontWeight: "bold",
  },
  table: {
    width: "100%",
    marginTop: 40,
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    flexDirection: "row",
  },
  tableColHeader: {
    width: "30%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    borderColor: "lightgray",
    padding: 5,
    backgroundColor: "#003299",
  },
  tableCol: {
    width: "30%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: 5,
  },
  tableCellHeader: {
    fontSize: 12,
    fontWeight: "bold",
    color: "#fff",
    textAlign: "center",
  },
  tableCell: {
    fontSize: 10,
    textAlign: "center",
    color: "#000",
  },

  container: {
    position: "absolute",
    top: 30,
    left: -50,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: 50,
  },

  backGround: {
    width: "130%",
    // height: 64,
    //opacity: 0.2,
  },
  leftCircle: {
    width: 64,
    color: "#78C4EB",
    fontSize: 24,
    textAlign: "center",
    fontWeight: "bold",
    //marginLeft: 20,
    marginTop: 60,
    position: "absolute",
    //left: "1%",
    top: "25%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    transform: "translate(50%, -50%)", // Center the entire text block
  },
  middleCircle: {
    width: 128,
    color: "#003299",
    fontSize: 32,
    marginTop: 60,
    textAlign: "center",
    fontWeight: "extrabold",
    //marginLeft: 60,
    position: "absolute",
    left: "28%",
    top: "20%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    transform: "translate(50%, -50%)", // Center the entire text block
  },
  rightCircle: {
    width: 64,
    color: "green",
    fontSize: 24,
    marginTop: 60,
    textAlign: "center",
    fontWeight: "bold",
    //marginLeft: 50,
    position: "absolute",
    right: "17%",
    top: "25%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    transform: "translate(50%, -50%)", // Center the entire text block
  },
  valuesContainer: {
    position: "relative",
    width: "100%",
    //display: "flex",
    //flexDirection: "row",
    //justifyContent: "space-between",
    marginTop: 60,
    marginBottom: 45,
  },
  footnote: {
    marginTop: 60,
    textAlign: "right",
  },
  footnoteText: {
    fontSize: 8,
    color: "#666666",
  },
  alternateRow: {
    backgroundColor: "#E0F7FA", // Light blue
  },
  footer: {
    position: "absolute",
    bottom: 0,
    left: 30,
    right: 30,
    borderTopWidth: 1,
    borderColor: "#000",
    paddingTop: 5,
    paddingBottom: 5,
  },
  footerContent: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    fontSize: 10,
    color: "#2c3e50",
  },
  footerLeft: {
    fontSize: 8,
    flex: 1,
  },
  footerCenter: {
    fontSize: 8,
    flex: 1,
    textAlign: "center",
  },
  footerRight: {
    fontSize: 8,
    flex: 1,
    textAlign: "right",
  },
  footerDisclaimer: {
    textAlign: "center",
    fontSize: 6,
    marginTop: 5,
    color: "#666666",
  },
  boldText: {
    fontWeight: "extrabold", // Bold weight
  },
});

export interface TersanoReportProps {
  selectedDevices: {
    deviceInformation: ICleanDevice["deviceInformation"];
    deviceLocation?: ICleanDevice["deviceLocation"];
    deviceUsage: ICleanDevice["deviceUsage"];
    litresUsed: number;
    cartridgeLife: {
      percentage: number;
    };
  }[];
  user: string;
  intervalFilter?: any;
  dateFilter?: any;
  isGallons?: boolean;
}

const TersanoReport: React.FC<TersanoReportProps> = ({
  selectedDevices,
  user,
  intervalFilter,
  isGallons,
}) => {
  const [totalPages, setTotalPages] = useState(1); // Initialize with 1 page

  const calculateTotalUsage = (): number => {
    return selectedDevices.reduce(
      (total, device) => total + device.litresUsed,
      0
    );
  };

  // Calculation Functions
  const calculatePlasticPrevented = (litersDispensed: number) => {
    const weightPerBottleGrams = 23.4; // Weight of one RTU bottle in grams
    const volumePerBottleLiters = 0.975; // Volume of one RTU bottle in liters

    const numberOfBottles = litersDispensed / volumePerBottleLiters;
    const totalPlasticGrams = numberOfBottles * weightPerBottleGrams;
    const totalPlasticKilograms = totalPlasticGrams / 1000;

    return totalPlasticKilograms;
  };

  const calculateTreesEquivalent = (litersDispensed: number) => {
    const co2eqPerBottleKg = 0.125; // CO2eq per RTU bottle in kg
    const co2RemovalPerTreeKg = 21; // CO2 removed by one tree in kg
    const volumePerBottleLiters = 0.975; // Volume of one RTU bottle in liters

    const numberOfBottles = litersDispensed / volumePerBottleLiters;
    const totalCO2eq = numberOfBottles * co2eqPerBottleKg;
    const numberOfTrees = totalCO2eq / co2RemovalPerTreeKg;

    return numberOfTrees;
  };

  const totalUsage = calculateTotalUsage();
  const plasticPrevented = calculatePlasticPrevented(totalUsage);
  const treesEquivalent = calculateTreesEquivalent(totalUsage);

  // Get current date
  const currentDate = new Date().toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  // Define row limits for each page
  const rowsOnFirstPage = 10;
  const rowsOnSecondPage = 20;

  // Split the table rows into those that fit on the first page and the rest
  const firstPageRows = selectedDevices.slice(0, rowsOnFirstPage);
  const remainingRows = selectedDevices.slice(rowsOnFirstPage);

  // Split the remaining rows to fit on the second page with a limit of 20 rows
  const secondPageRows = remainingRows.slice(0, rowsOnSecondPage);
  const thirdPageRows = remainingRows.slice(rowsOnSecondPage);

  // Update the total pages dynamically based on the number of remaining rows
  const totalPagesCalculated =
    remainingRows.length > 0 ? (thirdPageRows.length > 0 ? 3 : 2) : 1;

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
          <Image style={styles.logo} src={tersanoLogoRegistered} />
          <View style={styles.headerRight}>
            <Text style={[styles.title, { color: "#00b4e3" }]}>
              {intervalFilter.toString().toUpperCase()}
            </Text>
            <Text style={styles.title}>SUSTAINABILITY REPORT</Text>
          </View>
        </View>
        <View style={styles.content}>
          <Text style={styles.subtitle}>
            Congratulations{" "}
            <Text
              style={{
                color: "#00b4e3",
                fontWeight: "extrabold",
                fontSize: 22,
              }}
            >
              {user}
            </Text>
            !
          </Text>
          <Text style={styles.subtitle}>
            Together, we are{" "}
            <Text style={styles.boldText}>
              Changing The Way The World{" "}
              <Text style={{ color: "#00b4e3" }}>Cleans</Text>
            </Text>
            .
          </Text>
        </View>

        <View style={styles.container}>
          {isGallons ? (
            <Image style={styles.backGround} src={SustainabilityLbs} />
          ) : (
            <Image style={styles.backGround} src={SustainabilityKg} />
          )}
        </View>

        <View style={styles.valuesContainer}>
          <Text style={styles.leftCircle}>
            {isGallons ? (
              <>{Math.round(plasticPrevented * 2.20462).toLocaleString()} </>
            ) : (
              <>{Math.round(plasticPrevented).toLocaleString()}</>
            )}
          </Text>
          <Text style={styles.middleCircle}>
            {isGallons ? (
              <>
                {" "}
                {Math.round(
                  calculateTotalUsage() * 0.264172
                ).toLocaleString()}{" "}
              </>
            ) : (
              <> {Math.round(calculateTotalUsage()).toLocaleString()} </>
            )}
          </Text>
          <Text style={styles.rightCircle}> {treesEquivalent.toFixed(0)} </Text>
        </View>

        <View style={styles.footnote}>
          <Text style={styles.footnoteText}>
            * from entering the environment
          </Text>
          <Text style={styles.footnoteText}>** of CO₂ eq reduction</Text>
        </View>

        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={[styles.tableColHeader, { width: "40%" }]}>
              <Text style={[styles.tableCellHeader, { textAlign: "left" }]}>
                Location
              </Text>
            </View>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>Chemicals Prevented</Text>
            </View>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>Plastic Prevented</Text>
            </View>
          </View>
          {firstPageRows.map((device, index) => (
            <View
              style={[
                styles.tableRow,
                index % 2 === 0 ? styles.alternateRow : {},
              ]}
              key={index}
            >
              <View style={[styles.tableCol, { width: "40%" }]}>
                <Text
                  style={[
                    styles.tableCell,
                    { fontWeight: "bold", textAlign: "left" },
                  ]}
                >
                  {device.deviceInformation.deviceName}
                </Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>
                  {isGallons ? (
                    <>{(device.litresUsed * 0.264172).toFixed(2)} Gallons</>
                  ) : (
                    <>{device.litresUsed.toFixed(2)} Liters</>
                  )}
                </Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>
                  {isGallons ? (
                    <>
                      {(
                        calculatePlasticPrevented(device.litresUsed) * 2.20462
                      ).toFixed(2)}{" "}
                      Pounds
                    </>
                  ) : (
                    <>
                      {calculatePlasticPrevented(device.litresUsed).toFixed(2)}{" "}
                      Kilograms
                    </>
                  )}
                </Text>
              </View>
            </View>
          ))}
        </View>
        {/* Footer */}
        <View style={styles.footer}>
          <View style={styles.footerContent}>
            <Text style={styles.footerLeft}>
              iClean® Dashboard | www.tersano.com
            </Text>
            <Text style={styles.footerCenter}>
              Pg 1 of {totalPagesCalculated}
            </Text>
            <Text style={styles.footerRight}>Printed {currentDate}</Text>
          </View>
          <Text style={styles.footerDisclaimer}>
            Disclaimer: Information based on comparison of one liter
            ready-to-use plastic bottle and recycled SAO-24 cartridges. Not
            meant as a substitute for Scope 3 emissions’ reporting. Does not
            include emissions associated with transport
          </Text>
        </View>
      </Page>

      {/* Render a second page if there are remaining rows */}
      {secondPageRows.length > 0 && (
        <Page size="A4" style={styles.page}>
          <View style={styles.header}>
            <Image style={styles.logo} src={tersanoLogoRegistered} />
            <View style={styles.headerRight}>
              <Text style={[styles.title, { color: "#00b4e3" }]}>
                {intervalFilter.toString().toUpperCase()}
              </Text>
              <Text style={styles.title}>SUSTAINABILITY REPORT</Text>
            </View>
          </View>

          {/* Table Header */}
          <View style={styles.table}>
            <View style={styles.tableRow}>
              <View style={[styles.tableColHeader, { width: "40%" }]}>
                <Text style={[styles.tableCellHeader, { textAlign: "left" }]}>
                  Location
                </Text>
              </View>
              <View style={styles.tableColHeader}>
                <Text style={styles.tableCellHeader}>Chemicals Prevented</Text>
              </View>
              <View style={styles.tableColHeader}>
                <Text style={styles.tableCellHeader}>Plastic Prevented</Text>
              </View>
            </View>
            {secondPageRows.map((device, index) => (
              <View
                style={[
                  styles.tableRow,
                  index % 2 === 0 ? styles.alternateRow : {},
                ]}
                key={index}
              >
                <View style={[styles.tableCol, { width: "40%" }]}>
                  <Text
                    style={[
                      styles.tableCell,
                      { fontWeight: "bold", textAlign: "left" },
                    ]}
                  >
                    {device.deviceInformation.deviceName}
                  </Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>
                    {isGallons ? (
                      <>{(device.litresUsed * 0.264172).toFixed(2)} Gallons</>
                    ) : (
                      <>{device.litresUsed.toFixed(2)} Liters</>
                    )}
                  </Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>
                    {isGallons ? (
                      <>
                        {(
                          calculatePlasticPrevented(device.litresUsed) * 2.20462
                        ).toFixed(2)}{" "}
                        Pounds
                      </>
                    ) : (
                      <>
                        {calculatePlasticPrevented(device.litresUsed).toFixed(
                          2
                        )}{" "}
                        Kilograms
                      </>
                    )}
                  </Text>
                </View>
              </View>
            ))}
          </View>

          {/* Footer */}
          <View style={styles.footer}>
            <View style={styles.footerContent}>
              <Text style={styles.footerLeft}>
                iClean® Dashboard | www.tersano.com
              </Text>
              <Text style={styles.footerCenter}>
                Pg 2 of {totalPagesCalculated}
              </Text>
              <Text style={styles.footerRight}>Printed {currentDate}</Text>
            </View>
            <Text style={styles.footerDisclaimer}>
              Disclaimer: Information based on comparison of one liter
              ready-to-use plastic bottle and recycled SAO-24 cartridges. Not
              meant as a substitute for Scope 3 emissions’ reporting. Does not
              include emissions associated with transport
            </Text>
          </View>
        </Page>
      )}

      {/* Render a third page if there are still remaining rows */}
      {thirdPageRows.length > 0 && (
        <Page size="A4" style={styles.page}>
          <View style={styles.header}>
            <Image style={styles.logo} src={tersanoLogoRegistered} />
            <View style={styles.headerRight}>
              <Text style={[styles.title, { color: "#00b4e3" }]}>
                {intervalFilter.toString().toUpperCase()}
              </Text>
              <Text style={styles.title}>SUSTAINABILITY REPORT</Text>
            </View>
          </View>

          {/* Table Header */}
          <View style={styles.table}>
            <View style={styles.tableRow}>
              <View style={[styles.tableColHeader, { width: "40%" }]}>
                <Text style={[styles.tableCellHeader, { textAlign: "left" }]}>
                  Location
                </Text>
              </View>
              <View style={styles.tableColHeader}>
                <Text style={styles.tableCellHeader}>Chemicals Prevented</Text>
              </View>
              <View style={styles.tableColHeader}>
                <Text style={styles.tableCellHeader}>Plastic Prevented</Text>
              </View>
            </View>
            {thirdPageRows.map((device, index) => (
              <View
                style={[
                  styles.tableRow,
                  index % 2 === 0 ? styles.alternateRow : {},
                ]}
                key={index}
              >
                <View style={styles.tableCol}>
                  <Text
                    style={[
                      styles.tableCell,
                      { fontWeight: "bold", textAlign: "left" },
                    ]}
                  >
                    {device.deviceInformation.deviceName}
                  </Text>
                </View>
                <View style={[styles.tableCol, { width: "40%" }]}>
                  <Text style={styles.tableCell}>
                    {isGallons ? (
                      <>{(device.litresUsed * 0.264172).toFixed(2)} Gallons</>
                    ) : (
                      <>{device.litresUsed.toFixed(2)} Liters</>
                    )}
                  </Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>
                    {isGallons ? (
                      <>
                        {(
                          calculatePlasticPrevented(device.litresUsed) * 2.20462
                        ).toFixed(2)}{" "}
                        Pounds
                      </>
                    ) : (
                      <>
                        {calculatePlasticPrevented(device.litresUsed).toFixed(
                          2
                        )}{" "}
                        Kilograms
                      </>
                    )}
                  </Text>
                </View>
              </View>
            ))}
          </View>

          {/* Footer */}
          <View style={styles.footer}>
            <View style={styles.footerContent}>
              <Text style={styles.footerLeft}>
                iClean® Dashboard | www.tersano.com
              </Text>
              <Text style={styles.footerCenter}>
                Pg 3 of {totalPagesCalculated}
              </Text>
              <Text style={styles.footerRight}>Printed {currentDate}</Text>
            </View>
            <Text style={styles.footerDisclaimer}>
              Disclaimer: Information based on comparison of one liter
              ready-to-use plastic bottle and recycled SAO-24 cartridges. Not
              meant as a substitute for Scope 3 emissions’ reporting. Does not
              include emissions associated with transport
            </Text>
          </View>
        </Page>
      )}
    </Document>
  );
};

export default TersanoReport;
