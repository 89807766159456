import React from "react";
import { pdf } from "@react-pdf/renderer";
import TersanoReport, {
  TersanoReportProps,
} from "../../components/Device/UsageReport"; // Adjust path as needed
import useAuthStore from "../../store/authStore";

const downloadPDF = async (
  selectedDevices: TersanoReportProps["selectedDevices"],
  intervalFilter: any,
  isGallons?: boolean,
  dateFilter?: any
) => {
  const { user } = useAuthStore.getState();

  const blob = await pdf(
    <TersanoReport
      selectedDevices={selectedDevices}
      user={user?.firstName || ""}
      intervalFilter={intervalFilter}
      isGallons={isGallons}
    />
  ).toBlob();

  const url = URL.createObjectURL(blob);

  const link = document.createElement("a");
  link.href = url;
  link.download = "tersano-report.pdf";
  link.click();

  URL.revokeObjectURL(url);
};

export default downloadPDF;
