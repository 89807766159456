import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useAuthStore from "../../store/authStore";

const TokenExpiryChecker: React.FC = () => {
  const { isTokenExpired, logout, token, isImpersonating } = useAuthStore(
    (state) => state
  );
  const navigate = useNavigate();

  useEffect(() => {
    if (!token || isImpersonating) return; // If no token or impersonating, no need to check for expiry

    const checkTokenExpiry = () => {
      if (isTokenExpired()) {
        logout(true); // Log out and show session expired message
        navigate("/login"); // Redirect to login page
      }
    };

    // Check immediately when the token changes or component mounts
    checkTokenExpiry();

    // Set an interval to check token expiry every minute (60000 ms)
    const intervalId = setInterval(checkTokenExpiry, 60000);

    // Cleanup the interval on component unmount
    return () => clearInterval(intervalId);
  }, [isTokenExpired, logout, navigate, token, isImpersonating]);

  return null; // This component does not render anything
};

export default TokenExpiryChecker;
