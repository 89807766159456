import React from "react";
import {
  TimelineItem as MuiTimelineItem,
  TimelineSeparator,
  TimelineDot,
  TimelineConnector,
  TimelineContent,
} from "@mui/lab";

import { Box, Typography, Paper, useTheme } from "@mui/material";

interface TimelineItemProps {
  status: string;
  timestamp: string;
}

const TimelineItem: React.FC<TimelineItemProps> = ({ status, timestamp }) => {
  const date = new Date(timestamp);
  const formattedTime = date.toLocaleTimeString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });
  const formattedDate = date.toLocaleDateString();
  const theme = useTheme(); // Using theme to get the default color

  return (
    <MuiTimelineItem>
      <TimelineSeparator>
        <TimelineDot color="primary" />
        <TimelineConnector
        // sx={{
        //   borderStyle: "dotted",
        //   // borderWidth: "0.5px",
        //   borderWidth: "2px",
        //   borderColor: theme.palette.grey[400], // Adjust this color as neede/ Adjust thickness as needed
        // }}
        />
      </TimelineSeparator>
      <TimelineContent>
        <Paper
          elevation={0}
          sx={{
            padding: "6px 16px",
            margin: "0",
            border: "1px solid #bbb",
            borderRadius: 0,
          }}
        >
          <Typography variant="body1" component="span" fontWeight={800}>
            {status}
          </Typography>
          <Box>
            <Typography color="textSecondary">{formattedDate}</Typography>
            <Typography color="textSecondary">{formattedTime}</Typography>
          </Box>
        </Paper>
      </TimelineContent>
    </MuiTimelineItem>
  );
};

export default TimelineItem;
