import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
  Font,
} from "@react-pdf/renderer";
import tersanoLogo from "../../assets/images/tersano-logo.png";

// // Register the Poppins font
Font.register({
  family: "Garamond",
  fonts: [
    {
      src: "/fonts/garamond.ttf",
      fontWeight: 400,
    },
  ],
});

const styles = StyleSheet.create({
  page: {
    backgroundColor: "#fff",
    padding: 30,
    fontFamily: "Garamond",
  },
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 20,
  },
  logo: {
    width: 100,
  },
  title: {
    fontSize: 16,
    textAlign: "center",
    margin: 10,
    color: "black",
  },
  litresUsed: { fontSize: 24, fontWeight: "bold" },
  subtitle: {
    fontSize: 18,
    textAlign: "center",
    margin: 5,
    color: "#16a085",
  },
  table: {
    width: "100%",
    marginTop: 20,
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    flexDirection: "row",
  },
  tableColHeader: {
    width: "25%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: 5,
    backgroundColor: "#34495e",
  },
  tableCol: {
    width: "25%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: 5,
  },
  tableCellHeader: {
    fontSize: 12,
    fontWeight: "bold",
    color: "#ecf0f1",
    textAlign: "center",
  },
  tableCell: {
    fontSize: 10,
    textAlign: "center",
    color: "#2c3e50",
  },
  cartridgeIndicator: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  cartridgeText: {
    color: "#fff", // Ensure text color is white for visibility
    fontSize: 10,
    textAlign: "center",
  },
  cartridgeCircle: {
    width: 25,
    height: 25,
    borderRadius: 12.5,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#fff",
    textAlign: "center",
  },
  greenCircle: {
    backgroundColor: "#2ecc71",
  },
  yellowCircle: {
    backgroundColor: "#f1c40f",
  },
  redCircle: {
    backgroundColor: "#e74c3c",
  },

  // Existing styles...
  infoSection: {
    marginBottom: 10,
    textAlign: "left",
  },
  period: {
    fontSize: 12,
    marginBottom: 5,
    color: "black",
  },
  generatedInfo: {
    fontSize: 10,
    color: "black",
  },
  summarySection: {
    marginBottom: 20,
    padding: 10,
    backgroundColor: "#f3f3f3",
    borderRadius: 5,
  },
  usageHighlight: {
    fontSize: 12,
    marginVertical: 5,
    color: "#2c3e50",
    textAlign: "left",
  },
  totalUsage: {
    fontSize: 14,
    fontWeight: "bold",
    color: "#16a085",
    textAlign: "center",
    marginTop: 10,
  },
});

export interface ReportTemplateProps {
  reportType: string;
  reportName: string;
  reportData: any;
  startDate: Date;
  endDate: Date;
  generatedBy: string;
  generatedAt: Date;
  devices: string[];
  distributors?: string[];
  reportCategory?: string;
}

const ReportTemplate: React.FC<ReportTemplateProps> = ({
  reportType,
  reportName,
  reportData,
  startDate,
  endDate,
  generatedBy,
  generatedAt,
  devices,
}) => {
  const renderContent = () => {
    if (reportType === "Usage") {
      const { totalUsage, totalAverageUsage, usagePerDevice } = reportData;
      // Calculate the number of days between startDate and endDate
      const numberOfDays =
        (new Date(endDate).getTime() - new Date(startDate).getTime()) /
          (1000 * 60 * 60 * 24) +
        1;

      return (
        <>
          {/* Summary Section */}
          <View style={styles.summarySection}>
            <Text style={styles.usageHighlight}>
              Total Usage: {totalUsage.toFixed(2)} Liters
            </Text>
            <Text style={styles.usageHighlight}>
              Total Average Daily Usage: {totalAverageUsage.toFixed(2)}{" "}
              Liters/Day
            </Text>
            <Text style={styles.usageHighlight}>
              Number of Days: {numberOfDays}
            </Text>
          </View>

          {/* Device Table */}
          <View style={styles.table}>
            <View style={styles.tableRow}>
              <View style={styles.tableColHeader}>
                <Text style={styles.tableCellHeader}>Device Name</Text>
              </View>
              <View style={styles.tableColHeader}>
                <Text style={styles.tableCellHeader}>Device ID</Text>
              </View>
              <View style={styles.tableColHeader}>
                <Text style={styles.tableCellHeader}>Usage (Liters)</Text>
              </View>
              <View style={styles.tableColHeader}>
                <Text style={styles.tableCellHeader}>Average (Liters/Day)</Text>
              </View>
            </View>

            {usagePerDevice &&
              usagePerDevice.map(
                (
                  device: {
                    deviceId: string;
                    deviceName: string;
                    totalUsage: number;
                    averageUsage: number;
                  },
                  index: number
                ) => (
                  <View style={styles.tableRow} key={index}>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>{device.deviceName}</Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>{device.deviceId}</Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>
                        {device.totalUsage.toFixed(2)}
                      </Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>
                        {device.averageUsage.toFixed(2)}
                      </Text>
                    </View>
                  </View>
                )
              )}
          </View>
        </>
      );
    } else if (reportType === "Cartridge") {
      return (
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>Device ID</Text>
            </View>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>Replacement Date</Text>
            </View>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>Reason</Text>
            </View>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>Replaced By</Text>
            </View>
          </View>

          {reportData.map(
            (
              item: {
                deviceId: string;
                replacementHistory: {
                  replacedDate: string | number | Date;
                  reasonForReplacement: any;
                  replacedBy: string;
                };
              },
              index: React.Key | null | undefined
            ) => (
              <View style={styles.tableRow} key={index}>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{item.deviceId}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>
                    {item.replacementHistory?.replacedDate
                      ? new Date(
                          item.replacementHistory.replacedDate
                        ).toLocaleDateString()
                      : "N/A"}
                  </Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>
                    {item.replacementHistory?.reasonForReplacement || "N/A"}
                  </Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>
                    {item.replacementHistory?.replacedBy || "N/A"}
                  </Text>
                </View>
              </View>
            )
          )}
        </View>
      );
    } else if (reportType === "OverallUsage") {
      const { totalUsage, usagePerDevice } = reportData;
      return (
        <>
          {/* Summary Section */}
          <View style={styles.summarySection}>
            <Text style={styles.usageHighlight}>
              Total Usage: {totalUsage.toFixed(2)} Liters
            </Text>
          </View>

          {/* Device Table */}
          <View style={styles.table}>
            <View style={styles.tableRow}>
              <View style={styles.tableColHeader}>
                <Text style={styles.tableCellHeader}>Device Name</Text>
              </View>
              <View style={styles.tableColHeader}>
                <Text style={styles.tableCellHeader}>Device ID</Text>
              </View>
              <View style={styles.tableColHeader}>
                <Text style={styles.tableCellHeader}>Total Usage (Liters)</Text>
              </View>
              <View style={styles.tableColHeader}>
                <Text style={styles.tableCellHeader}>First Usage Date</Text>
              </View>
            </View>

            {usagePerDevice &&
              usagePerDevice.map(
                (
                  device: {
                    deviceId: string;
                    deviceName: string;
                    totalUsage: number;
                    firstUsageDate: string | null;
                  },
                  index: number
                ) => (
                  <View style={styles.tableRow} key={index}>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>{device.deviceName}</Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>{device.deviceId}</Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>
                        {device.totalUsage.toFixed(2)}
                      </Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>
                        {device.firstUsageDate
                          ? new Date(device.firstUsageDate).toLocaleDateString()
                          : "N/A"}
                      </Text>
                    </View>
                  </View>
                )
              )}
          </View>
        </>
      );
    }
    return null;
  };

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
          <Image style={styles.logo} src={tersanoLogo} />
          <View style={styles.infoSection}>
            {reportType !== "OverallUsage" && (
              <Text style={styles.period}>
                Period: {new Date(startDate).toLocaleDateString()} -{" "}
                {new Date(endDate).toLocaleDateString()}
              </Text>
            )}
            <Text style={styles.generatedInfo}>
              Generated by: {generatedBy}
            </Text>
            <Text style={styles.generatedInfo}>
              Date: {new Date(generatedAt).toLocaleDateString()}
            </Text>
            <Text style={styles.generatedInfo}>
              {["Usage", "OverallUsage", "Cartridge"].includes(reportType) && (
                <Text style={styles.generatedInfo}>
                  Devices:{" "}
                  {devices
                    .filter(Boolean) // Ensure the array is defined and not empty
                    .map(
                      (item, index) =>
                        index % 4 === 0 && index !== 0 ? `\n${item}` : item // Line break every 4 items for devices
                    )
                    .join(", ") || "N/A"}
                </Text>
              )}
            </Text>
          </View>
        </View>
        <Text style={styles.title}>{reportName} Report</Text>
        {renderContent()}
      </Page>
    </Document>
  );
};

export default ReportTemplate;
