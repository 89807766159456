import { create } from "zustand";
import axios from "axios";

export interface User {
  _id: string;
  email: string;
  role: string;
  firstName?: string;
  lastName?: string;
  phone?: string;
  country?: string;
  city?: string;
  address?: string;
  postalCode?: string;
  distributorEmail?: string;
}

interface SnackbarState {
  open: boolean;
  message: string;
  severity: "success" | "error" | "info" | "warning";
}

interface AuthState {
  user: User | null;
  setUser: (user: User | null) => void; // Add setUser method with type annotation
  token: string | null;
  tokenExpiry: number | null;
  isImpersonating: boolean; // Add this flag
  snackbar: SnackbarState;
  setSnackbar: (snackbar: SnackbarState) => void;
  login: (
    user: User,
    token: string,
    expiresIn: number,
    isImpersonating?: boolean
  ) => void;
  logout: (expired?: boolean) => void;
  isTokenExpired: () => boolean;
}

const useAuthStore = create<AuthState>((set, get) => ({
  user: JSON.parse(localStorage.getItem("user") || "null"),
  token: localStorage.getItem("token"),
  tokenExpiry: localStorage.getItem("tokenExpiry")
    ? parseInt(localStorage.getItem("tokenExpiry")!, 10)
    : null,
  isImpersonating: false, // Default to false

  snackbar: { open: false, message: "", severity: "info" },

  setSnackbar: (snackbar) => set({ snackbar }),
  setUser: (user: User | null) => set({ user }), // Add setUser method

  login: (user, token, expiresIn, isImpersonating = false) => {
    let expiry =
      typeof expiresIn === "number" ? Date.now() + expiresIn * 1000 : null; // Set to null if undefined or no expiration intended
    localStorage.setItem("token", token);
    localStorage.setItem("user", JSON.stringify(user));
    if (expiry) {
      localStorage.setItem("tokenExpiry", expiry.toString());
    } else {
      localStorage.removeItem("tokenExpiry"); // Ensure no expiry time is stored
    }
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    set({ user, token, tokenExpiry: expiry, isImpersonating });
  },

  logout: (expired = false) => {
    localStorage.clear();
    delete axios.defaults.headers.common["Authorization"];
    set({ user: null, token: null, tokenExpiry: null });
    set({
      snackbar: {
        open: true,
        message: expired ? "Session Expired" : "Logged out successfully",
        severity: expired ? "warning" : "success",
      },
    });
  },
  isTokenExpired: () => {
    const { tokenExpiry, token, isImpersonating } = get();
    if (isImpersonating) return false; // Skip expiry check if impersonating
    if (!token) return true; // If there's no token, assume expired for safety
    if (tokenExpiry === null || tokenExpiry === undefined) return false; // Never expires if expiry is null or undefined
    return Date.now() > tokenExpiry;
  },
}));

export default useAuthStore;
