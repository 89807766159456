import React, { useState, useEffect } from "react";
import { Box, FormControl, Autocomplete, TextField, Chip } from "@mui/material";
import { Customer } from "../../store/useCustomer";
import { DeviceData } from "../../common/types/DeviceTypes";
import { deviceToCustomers } from "../../services/bulkAssign.service";
import StyledButton from "../Tabs/StyledButton";

type AssignCustomersToDeviceProps = {
  devices: DeviceData[];
  customers: Customer[];
  onClose: () => void;
  onSuccess: (message: string) => void;
};

export default function AssignCustomersToDevice({
  devices,
  customers,
  onClose,
  onSuccess,
}: AssignCustomersToDeviceProps) {
  const [selectedDevice, setSelectedDevice] = useState<DeviceData | null>(null);
  const [assignedCustomers, setAssignedCustomers] = useState<Customer[]>([]);
  const [availableCustomers, setAvailableCustomers] = useState<Customer[]>([]);

  useEffect(() => {
    if (selectedDevice) {
      updateAvailableCustomers(selectedDevice);
    } else {
      setAvailableCustomers(customers);
    }
  }, [selectedDevice, customers]);

  const updateAvailableCustomers = (device: DeviceData) => {
    const filteredCustomers = customers.filter(
      (customer) =>
        customer.email !== device.deviceInformation?.customerEmail &&
        !device.deviceInformation?.additionalCustomers?.includes(customer.email)
    );
    setAvailableCustomers(filteredCustomers);
  };

  const handleDeviceSelect = (
    event: React.SyntheticEvent,
    value: DeviceData | null
  ) => {
    setSelectedDevice(value);
    setAssignedCustomers([]);
  };

  const handleCustomerAssignment = (
    event: React.SyntheticEvent,
    value: Customer[]
  ) => {
    setAssignedCustomers(value);
  };

  const handleSaveAssignments = async () => {
    if (selectedDevice === null) return;

    const payload = {
      deviceId: selectedDevice.deviceInformation?.deviceId,
      customers: assignedCustomers.map((customer) => customer.email),
    };

    try {
      await deviceToCustomers(payload);
      onSuccess("Customers assigned successfully");
    } catch (error) {
      console.error("Error assigning customers to device:", error);
      // Add error handling here if needed
    }
  };

  return (
    <>
      <FormControl fullWidth variant="outlined" margin="dense">
        <Autocomplete
          options={devices}
          getOptionLabel={(option) =>
            option.deviceInformation?.deviceName || "Unknown Device"
          }
          renderInput={(params) => (
            <TextField {...params} label="Select Device" />
          )}
          value={selectedDevice}
          onChange={handleDeviceSelect}
        />
      </FormControl>

      {selectedDevice && (
        <Autocomplete
          multiple
          options={availableCustomers}
          getOptionLabel={(option) =>
            `${option.firstName} ${option.lastName} - ${option.email}`
          }
          value={assignedCustomers}
          onChange={handleCustomerAssignment}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label="Select Customers"
              placeholder="Search customers"
              margin="normal"
            />
          )}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => {
              const { key, ...chipProps } = getTagProps({ index });
              return (
                <Chip
                  key={key}
                  label={`${option.firstName} ${option.lastName}`}
                  {...chipProps}
                />
              );
            })
          }
        />
      )}

      <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 1 }}>
        <StyledButton
          onClick={handleSaveAssignments}
          variantType="primary"
          disabled={!selectedDevice || assignedCustomers.length === 0}
        >
          Save Assignments
        </StyledButton>
      </Box>
    </>
  );
}
