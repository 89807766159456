import { useState, useEffect } from "react";
import {
  Box,
  Divider,
  Typography,
  Grid,
  useTheme,
  useMediaQuery,
  ListItemText,
  ListItemSecondaryAction,
  ListItemButton,
} from "@mui/material";
import DevicesIcon from "@mui/icons-material/Devices";
import PodcastsIcon from "@mui/icons-material/Podcasts";
import PeopleIcon from "@mui/icons-material/People";
import Error from "@mui/icons-material/Error";
import { PortableWifiOff } from "@mui/icons-material";
import { Bar, Pie, Doughnut } from "react-chartjs-2";
import useDeviceStore from "../../store/useDeviceStore";
import useCustomerStore from "../../store/useCustomer";
import useDistributorStore from "../../store/useDistributor";
import useAuthStore from "../../store/authStore";
import { fetchTodaysDeviceErrors } from "../../services/device.service";
import { fetchDevices } from "../../services/device.service";
import MapCircleView from "./Charts/DeviceStatusMapView";
import SummaryReport from "../Cards/SummaryReport";
import dayjs from "dayjs";

import QueryBuilderTwoToneIcon from "@mui/icons-material/QueryBuilderTwoTone";
import CircularProgress from "@mui/material/CircularProgress";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
} from "chart.js";

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement
);

interface Marker {
  id: string;
  position: {
    lat: number;
    lng: number;
  };
  name: string;
  status: "ONLINE" | "OFFLINE";
}

const All = () => {
  const { user } = useAuthStore.getState();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { allDevices } = useDeviceStore();
  const { customers, fetchCustomersForUser } = useCustomerStore();
  const { Distributors, fetchDistributors, Installers, fetchInstaller } =
    useDistributorStore();

  const [onlineDeviceCount, setOnlineDeviceCount] = useState(0);
  const [offlineDeviceCount, setOfflineDeviceCount] = useState(0);

  const [deviceErrors, setDeviceErrors] = useState<any[]>([]);
  const [markers, setMarkers] = useState<Marker[]>([]);
  const [markersCenter, setMarkersCenter] = useState({ lat: 0, lng: 0 });
  const [isErrorsLoading, setIsErrorsLoading] = useState(true);

  useEffect(() => {
    if (!user) {
      console.error("User not logged in");
      return;
    }
    const fetchData = async () => {
      try {
        await fetchDevices(user.email, user.role);

        if (user?.role !== "Customer") {
          await fetchCustomersForUser();
        }

        await fetchDistributors();
        await fetchInstaller(user.email, user.role);

        setIsErrorsLoading(true);
        const errors = await fetchTodaysDeviceErrors(user.email, user.role);
        setDeviceErrors(errors);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsErrorsLoading(false);
      }
    };
    fetchData();
  }, [
    user,
    fetchCustomersForUser,
    fetchDistributors,
    fetchInstaller,
    user?.role,
  ]);

  useEffect(() => {
    const offlineDevices = allDevices.filter(
      (device) => device.pingStatus.status === "OFFLINE"
    );

    const onlineDevices = allDevices.filter(
      (device) => device.pingStatus.status === "ONLINE"
    );

    setOfflineDeviceCount(offlineDevices.length);
    setOnlineDeviceCount(onlineDevices.length);

    setMarkers(
      allDevices.map((device) => ({
        id: device.deviceInformation.deviceId,
        position: {
          lat: device.deviceLocation?.latitude || 0,
          lng: device.deviceLocation?.longitude || 0,
        },
        name: device.deviceInformation.deviceName,
        status: device.pingStatus.status === "ONLINE" ? "ONLINE" : "OFFLINE",
      }))
    );

    // Calc center point of all devices
    let validDevices = allDevices.filter(
      (device) =>
        device.deviceLocation?.latitude && device.deviceLocation?.longitude
    );

    if (validDevices.length > 0) {
      let latTotal = validDevices.reduce(
        (acc, device) => acc + device.deviceLocation!.latitude!,
        0
      );
      let lngTotal = validDevices.reduce(
        (acc, device) => acc + device.deviceLocation!.longitude!,
        0
      );
      let latAvg = latTotal / validDevices.length;
      let lngAvg = lngTotal / validDevices.length;
      setMarkersCenter({ lat: latAvg, lng: lngAvg });
    } else {
      setMarkersCenter({ lat: 0, lng: 0 });
    }
  }, [allDevices, customers, Distributors, offlineDeviceCount]);

  const countByCountry = (data: any[], countryField: string) => {
    return data.reduce((acc: { [key: string]: number }, item) => {
      const country = item[countryField];
      if (!acc[country]) {
        acc[country] = 0;
      }
      acc[country]++;
      return acc;
    }, {});
  };

  const customersByCountry = countByCountry(customers, "city");
  const distributorsByCountry = countByCountry(Distributors, "country");

  // Passed index to make it so we can color the charts dynamically
  const prepareBarChartData = (
    data: { [key: string]: number },
    index: number
  ) => {
    return {
      labels: Object.keys(data),
      datasets: [
        {
          label: "Count",
          backgroundColor:
            index === 0 ? "rgba(30, 58, 138, 0.5)" : "rgba(30, 58, 138, 0.8)",
          data: Object.values(data),
        },
      ],
    };
  };

  const customersByCountryData = prepareBarChartData(customersByCountry, 0);
  const distributorsByCountryData = prepareBarChartData(
    distributorsByCountry,
    1
  );

  const calculateCartridgeStatuses = () => {
    const statuses = { HIGH: 0, OKAY: 0, LOW: 0, EMPTY: 0 };
    allDevices.forEach((device) => {
      const cyclesRemaining =
        // @ts-ignore
        device.deviceUsage.length > 0
          ? // @ts-ignore
            device.deviceUsage[device.deviceUsage.length - 1]
              ?.cyclesRemaining || 0
          : 0;
      const totalCycles =
        device.deviceInformation.currentCartridgeType?.includes("SAO-24")
          ? 550
          : 920;
      const percentage = (cyclesRemaining / totalCycles) * 100;

      if (percentage > 70) statuses.HIGH++;
      else if (percentage <= 70 && percentage > 30) statuses.OKAY++;
      else if (percentage <= 30 && percentage > 0) statuses.LOW++;
      else statuses.EMPTY++;
    });
    return statuses;
  };

  const cartridgeStatuses = calculateCartridgeStatuses();

  const cartridgeStatusData = {
    labels: ["High", "Okay", "Low", "Empty"],
    datasets: [
      {
        data: [
          cartridgeStatuses.HIGH,
          cartridgeStatuses.OKAY,
          cartridgeStatuses.LOW,
          cartridgeStatuses.EMPTY,
        ],
        backgroundColor: [
          "rgba(30, 58, 138, 0.7)",
          "rgba(59, 130, 246, 0.7)",
          "rgba(255, 159, 64, 0.7)",
          "rgba(239, 68, 68, 0.7)",
        ],
        borderColor: [
          "rgba(30, 58, 138, 1)",
          "rgba(59, 130, 246, 1)",
          "rgba(255, 159, 64, 1)",
          "rgba(239, 68, 68, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const pieOptions = {
    responsive: true,
    maintainAspectRatio: false,
    cutout: "60%",
    plugins: {
      legend: {
        position: "right" as const,
        labels: {
          boxWidth: 15,
          padding: 15,
        },
      },
      tooltip: {
        callbacks: {
          label: function (context: any) {
            const label = context.label || "";
            const value = context.raw || 0;
            const total = context.dataset.data.reduce(
              (a: number, b: number) => a + b,
              0
            );
            const percentage = ((value / total) * 100).toFixed(1);
            return `${label}: ${value} (${percentage}%)`;
          },
        },
      },
      datalabels: {
        color: "#555",
      },
    },
  };

  const renderStatsCards = () => {
    const latestError = deviceErrors[0]?.error
      ? `Latest Device Error: ${deviceErrors[0].error}`
      : ".";

    const defaultCards = [
      {
        icon: DevicesIcon,
        value: allDevices.length,
        label: "All Devices",
        changeLabel: ".",
        isPositive: true,
        color: "rgba(30, 58, 138, 1)",
        iconBackgroundColor: "rgba(30, 58, 138, 0.1)",
      },
      {
        icon: PodcastsIcon,
        value: onlineDeviceCount,
        label: "Online Devices",
        changeLabel: ".",
        isPositive: true,
        color: "rgba(59, 130, 246, 1)",
        iconBackgroundColor: "rgba(59, 130, 246, 0.1)",
      },
      {
        icon: PortableWifiOff,
        value: offlineDeviceCount,
        label: "Offline Devices",
        changeLabel: ".",
        isPositive: true,
        color: "rgba(255, 159, 64, 1)",
        iconBackgroundColor: "rgba(255, 159, 64, 0.1)",
      },
      {
        icon: Error,
        value: deviceErrors.length,
        label: "Errors Today",
        changeLabel: latestError,
        isPositive: false,
        color: "rgba(239, 68, 68, 1)",
        iconBackgroundColor: "rgba(239, 68, 68, 0.1)",
      },
    ];

    const specificCards = [
      {
        icon: DevicesIcon,
        value: allDevices.length,
        label: "All Devices",
        changeLabel: ".",
        isPositive: true,
        color: "rgba(30, 58, 138, 1)",
        iconBackgroundColor: "rgba(30, 58, 138, 0.1)",
      },
      {
        icon: PodcastsIcon,
        value: onlineDeviceCount,
        label: "Online Devices",
        changeLabel: ".",
        isPositive: true,
        color: "rgba(59, 130, 246, 1)",
        iconBackgroundColor: "rgba(59, 130, 246, 0.1)",
      },

      {
        icon: PeopleIcon,
        value: customers.length,
        label: "Customers",
        changeLabel: ".",
        isPositive: true,
        color: "rgba(255, 159, 64, 1)",
        iconBackgroundColor: "rgba(255, 159, 64, 0.1)",
      },
      {
        icon: Error,
        value: deviceErrors.length,
        label: "Errors Today",
        changeLabel: latestError,
        isPositive: false,
        color: "rgba(239, 68, 68, 1)",
        iconBackgroundColor: "rgba(239, 68, 68, 0.1)",
      },
    ];

    const cardsToRender = ["Admin", "SuperAdmin", "Distributor"].includes(
      user?.role || ""
    )
      ? specificCards
      : defaultCards;

    return cardsToRender.map((card, index) => (
      <Grid item xs={12} sm={6} key={index}>
        <SummaryReport
          primary={card.value.toString()}
          secondary={card.label}
          iconPrimary={card.icon}
          color={card.color}
          iconBackgroundColor={card.iconBackgroundColor}
        />
      </Grid>
    ));
  };

  const commonStyles = {
    borderRadius: "8px",
    border: "1px solid #d1d1d1",
    backgroundColor: "background.paper",
    color: "text.primary",
    boxShadow: "0 1px 2px 0 rgb(0 0 0 / 0.05)",
  };

  const prepareDeviceLocationData = (devices: any[]) => {
    const devicesByCity = devices.reduce(
      (acc: { [key: string]: number }, device) => {
        const city = device.deviceLocation?.city || "Unknown";
        acc[city] = (acc[city] || 0) + 1;
        return acc;
      },
      {}
    );

    return {
      labels: Object.keys(devicesByCity),
      datasets: [
        {
          label: "Count",
          backgroundColor: "rgba(30, 58, 138, 0.5)",
          data: Object.values(devicesByCity),
        },
      ],
    };
  };

  const isCustomerByCityFullWidth = ![
    "Admin",
    "SuperAdmin",
    "Distributor",
  ].includes(user?.role || "");

  return (
    <Box
      sx={{
        flexGrow: 1,
        mb: "0.75rem",
        height: "100%",
      }}
    >
      <Grid container spacing={isMobile ? 1 : 1.5}>
        {/* COLUMN 1 */}
        <Grid item xs={12} md={6} sx={{}}>
          <Grid container spacing={1.5}>
            {renderStatsCards()}

            <Grid item xs={12} sm={12}>
              <Box
                sx={{
                  ...commonStyles,
                  p: 2,
                  height: "250px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Typography
                  sx={{
                    textAlign: "center",
                    mb: 1,
                    fontWeight: "700",
                  }}
                >
                  {user?.role !== "Admin" && user?.role !== "SuperAdmin"
                    ? "Devices by City"
                    : "Distributors by Country"}{" "}
                </Typography>
                <Divider sx={{ mb: 2 }} />

                <Box
                  sx={{
                    flexGrow: 1,
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Bar
                    data={
                      user?.role !== "Admin" && user?.role !== "SuperAdmin"
                        ? prepareDeviceLocationData(allDevices)
                        : distributorsByCountryData
                    }
                    options={{
                      indexAxis: "y",
                      responsive: true,
                      maintainAspectRatio: false,
                      scales: {
                        x: {
                          grid: {
                            display: false,
                          },
                          ticks: {
                            autoSkip: true,
                            maxRotation: 0,
                            minRotation: 0,
                          },
                        },
                        y: {
                          beginAtZero: true,
                          title: {
                            display: true,
                          },
                        },
                      },
                      plugins: {
                        legend: {
                          display: false,
                        },
                        tooltip: {
                          mode: "nearest",
                          intersect: true,
                          axis: "y",
                        },
                      },
                    }}
                  />
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12} sm={isCustomerByCityFullWidth ? 12 : 6}>
              <Box
                sx={{
                  ...commonStyles,
                  height: "260px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Typography
                  sx={{
                    textAlign: "center",
                    pt: 2,
                    mb: 1,
                    fontWeight: "700",
                  }}
                >
                  Most Recent Errors
                </Typography>

                <Divider sx={{ mb: 1 }} />
                <Box
                  sx={{
                    px: 2,
                    pb: 1,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    overflowY: "auto",
                    height: "100%",
                  }}
                >
                  {isErrorsLoading ? (
                    <CircularProgress />
                  ) : deviceErrors.length === 0 ? (
                    <Typography>You have no errors! Woo hoo!</Typography>
                  ) : (
                    deviceErrors.slice(0, 3).map((error, index) => (
                      <ListItemButton
                        key={index}
                        sx={{
                          width: "100%",
                          borderBottom: `1px solid ${theme.palette.divider}`,
                        }}
                      >
                        <ListItemText
                          primary={
                            <Typography
                              variant="body2"
                              sx={{
                                color: theme.palette.primary.main,
                                fontWeight: "600",
                              }}
                            >
                              {error.error}
                            </Typography>
                          }
                          secondary={
                            <Typography variant="subtitle2">
                              {error.deviceName}
                            </Typography>
                          }
                        />
                        <ListItemSecondaryAction
                          sx={{ top: "50%", transform: "translateY(-50%)" }}
                        >
                          <Grid
                            container
                            justifyContent="flex-end"
                            alignItems="center"
                            spacing={1}
                          >
                            <Grid item>
                              <QueryBuilderTwoToneIcon
                                sx={{
                                  color: theme.palette.primary.main,
                                }}
                              />
                            </Grid>
                            <Grid item>
                              <Typography variant="subtitle2">
                                {dayjs(error.createdAt).fromNow()}
                              </Typography>
                            </Grid>
                          </Grid>
                        </ListItemSecondaryAction>
                      </ListItemButton>
                    ))
                  )}
                </Box>
              </Box>
            </Grid>

            {["Admin", "SuperAdmin", "Distributor"].includes(
              user?.role || ""
            ) && (
              <Grid item xs={12} sm={6}>
                <Box
                  sx={{
                    ...commonStyles,
                    p: 2,
                    height: "230px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Typography
                    sx={{
                      textAlign: "center",
                      mb: 1,
                      fontWeight: "700",
                    }}
                  >
                    Customers by City
                  </Typography>
                  <Divider sx={{ mb: 2 }} />

                  <Box
                    sx={{
                      flexGrow: 1,
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Bar
                      data={customersByCountryData}
                      options={{
                        responsive: true,
                        maintainAspectRatio: false,
                        scales: {
                          x: {
                            grid: {
                              display: false,
                            },
                            ticks: {
                              autoSkip: true,
                              maxRotation: 0,
                              minRotation: 0,
                            },
                          },
                          y: {
                            beginAtZero: true,
                            title: {
                              display: true,
                            },
                          },
                        },
                        plugins: {
                          legend: {
                            display: false,
                          },
                          tooltip: {
                            mode: "index",
                            intersect: false,
                          },
                        },
                      }}
                    />
                  </Box>
                </Box>
              </Grid>
            )}
          </Grid>
        </Grid>

        {/* COLUMN 2 */}
        <Grid item xs={12} md={6} sx={{}}>
          {" "}
          <Grid container spacing={1.5}>
            <Grid item xs={12}>
              <Box
                sx={{
                  ...commonStyles,
                  p: 2,
                  height: "200px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Typography
                  sx={{
                    textAlign: "center",
                    mb: 1,
                    fontWeight: "700",
                  }}
                >
                  Cartridge Life Distribution
                </Typography>

                <Divider sx={{ mb: 2 }} />

                <Box
                  sx={{
                    flexGrow: 1,
                    width: "90%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Doughnut data={cartridgeStatusData} options={pieOptions} />
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12} sm={12}>
              <MapCircleView
                center={markersCenter}
                zoom={2}
                height="400px"
                markers={markers}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default All;
