import React from "react";
import { GridColDef } from "@mui/x-data-grid";
import PersonIcon from "@mui/icons-material/Person";
import EmailIcon from "@mui/icons-material/Email";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PhoneIcon from "@mui/icons-material/Phone";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { CreateHeaderWithIcon } from "../Grid/CreateHeaderWithIcon";
import { Box, Typography } from "@mui/material";

export const CustomerColumns: GridColDef[] = [
  {
    field: "firstName",
    headerName: "First Name",
    flex: 1,
    renderHeader: CreateHeaderWithIcon(PersonIcon, "First Name"),
    align: "left",
    headerAlign: "left",
    renderCell: (params) => (
      <Box mt={1} ml={0} p={0.5} width={50}>
        <Typography
          sx={{
            fontFamily: "'Poppins', sans-serif",
            fontSize: "12px",
          }}
        >
          {params.row.firstName}
        </Typography>
      </Box>
    ),
  },
  {
    field: "lastName",
    headerName: "Last Name",
    flex: 1,
    renderHeader: CreateHeaderWithIcon(PersonIcon, "Last Name"),
    align: "left",
    headerAlign: "left",
    renderCell: (params) => (
      <Box mt={1} ml={0} p={0.5} width={50}>
        <Typography
          sx={{
            fontFamily: "'Poppins', sans-serif",
            fontSize: "12px",
          }}
        >
          {params.row.lastName}
        </Typography>
      </Box>
    ),
  },
  {
    field: "email",
    headerName: "Email",
    flex: 1,
    renderHeader: CreateHeaderWithIcon(EmailIcon, "Email"),
    align: "left",
    headerAlign: "left",
    renderCell: (params) => (
      <Box mt={1} ml={0} p={0.5} width={100}>
        <Typography
          sx={{
            fontFamily: "'Poppins', sans-serif",
            fontSize: "12px",
          }}
        >
          {params.row.email}
        </Typography>
      </Box>
    ),
  },
  {
    field: "phone",
    headerName: "Phone",
    flex: 1,
    renderHeader: CreateHeaderWithIcon(PhoneIcon, "Phone"),
    align: "left",
    headerAlign: "left",
    renderCell: (params) => (
      <Box mt={1} ml={0} p={0.5} width={80}>
        <Typography
          sx={{
            fontFamily: "'Poppins', sans-serif",
            fontSize: "12px",
          }}
        >
          {params.row.phone}
        </Typography>
      </Box>
    ),
  },
  {
    field: "country",
    headerName: "Country",
    flex: 1,
    renderHeader: CreateHeaderWithIcon(LocationOnIcon, "Country"),
    align: "left",
    headerAlign: "left",
    renderCell: (params) => (
      <Box mt={1} ml={0} p={0.5} width={80}>
        <Typography
          sx={{
            fontFamily: "'Poppins', sans-serif",
            fontSize: "12px",
          }}
        >
          {params.row.country}
        </Typography>
      </Box>
    ),
  },
  {
    field: "state",
    headerName: "State",
    flex: 1,
    renderHeader: CreateHeaderWithIcon(LocationOnIcon, "State"),
    align: "left",
    headerAlign: "left",
    renderCell: (params) => (
      <Box mt={1} ml={0} p={0.5} width={80}>
        <Typography
          sx={{
            fontFamily: "'Poppins', sans-serif",
            fontSize: "12px",
          }}
        >
          {params.row.state}
        </Typography>
      </Box>
    ),
  },
  {
    field: "city",
    headerName: "City",
    flex: 1,
    renderHeader: CreateHeaderWithIcon(LocationOnIcon, "City"),
    align: "left",
    headerAlign: "left",
    renderCell: (params) => (
      <Box mt={1} ml={0} p={0.5} width={80}>
        <Typography
          sx={{
            fontFamily: "'Poppins', sans-serif",
            fontSize: "12px",
          }}
        >
          {params.row.city}
        </Typography>
      </Box>
    ),
  },
  {
    field: "address",
    headerName: "Address",
    flex: 1,
    renderHeader: CreateHeaderWithIcon(LocationOnIcon, "Address"),
    align: "left",
    headerAlign: "left",
    renderCell: (params) => (
      <Box mt={1} ml={0} p={0.5} width={120}>
        <Typography
          sx={{
            fontFamily: "'Poppins', sans-serif",
            fontSize: "12px",
          }}
        >
          {params.row.address}
        </Typography>
      </Box>
    ),
  },
  {
    field: "createdAt",
    headerName: "Created At",
    flex: 1,
    renderHeader: CreateHeaderWithIcon(CalendarTodayIcon, "Created At"),
    align: "left",
    headerAlign: "left",
    renderCell: (params) => (
      <Box mt={1} ml={0} p={0.5} width={100}>
        <Typography
          sx={{
            fontFamily: "'Poppins', sans-serif",
            fontSize: "12px",
          }}
        >
          {new Date(params.row.createdAt).toLocaleDateString()}
        </Typography>
      </Box>
    ),
  },
];
