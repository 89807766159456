import React, { useState, useEffect } from "react";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  Paper,
  Divider,
  IconButton,
  Typography,
  Checkbox,
} from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { updateGroupUsers } from "../../services/customer.service";
import { Customer } from "../../store/useCustomer";
import StyledButton from "../Tabs/StyledButton";

interface AssignToGroupProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (
    email: string,
    selectedCustomers: Customer[],
    distributor: string
  ) => void;
  onSuccess: () => void;
  customers: Customer[];
  distributor: string;
  customerGroup?: string;
  groupMembers?: Customer[];
  onSnackbarChange: (snackbarData: {
    open: boolean;
    message: string;
    severity: "success" | "error";
  }) => void;
}

const AssignToGroup: React.FC<AssignToGroupProps> = ({
  open,
  onClose,
  onSubmit,
  onSuccess,
  customers,
  groupMembers,
  onSnackbarChange,
  customerGroup,
}) => {
  const [customerGroupEmail, setCustomerGroupEmail] = useState<string>(""); // Provide a default value for customerGroupEmail
  const [availableCustomers, setAvailableCustomers] =
    useState<Customer[]>(customers);
  const [selectedCustomers, setSelectedCustomers] = useState<Customer[]>([]);
  const [selectedAvailable, setSelectedAvailable] = useState<Customer[]>([]);
  const [selectedGroup, setSelectedGroup] = useState<Customer[]>([]);
  const [isSubmitted, setIsSubmitted] = useState(false); // State to track if form is submitted

  // Reset modal state when it opens
  useEffect(() => {
    if (open) {
      setCustomerGroupEmail(customerGroup ? customerGroup : "");
      console.log("customerGroupEmail", customerGroupEmail);
      setSelectedGroup([]);
      setSelectedAvailable([]);
      setIsSubmitted(false);

      if (groupMembers) {
        setSelectedCustomers(groupMembers); // Initialize selected customers with group members
        setAvailableCustomers(
          customers.filter(
            (customer) =>
              !groupMembers.some((member) => member._id === customer._id)
          )
        ); // Remove group members from available customers
      } else {
        setAvailableCustomers(customers); // Reset to initial customers when not editing
        setSelectedCustomers([]); // Ensure selectedCustomers is empty when not editing
      }
    }
  }, [open, customers, groupMembers]); // Include isEditing and groupMembers in dependencies

  // Handle selecting and deselecting customers
  const handleSelectAvailable = (customer: Customer) => {
    if (selectedAvailable.includes(customer)) {
      setSelectedAvailable(
        selectedAvailable.filter((c) => c._id !== customer._id)
      );
    } else {
      setSelectedAvailable([...selectedAvailable, customer]);
    }
  };

  const handleSelectGroup = (customer: Customer) => {
    if (selectedGroup.includes(customer)) {
      setSelectedGroup(selectedGroup.filter((c) => c._id !== customer._id));
    } else {
      setSelectedGroup([...selectedGroup, customer]);
    }
  };

  // Handle moving customers from available to selected
  const handleMoveToSelected = () => {
    setAvailableCustomers(
      availableCustomers.filter((c) => !selectedAvailable.includes(c))
    );
    setSelectedCustomers([...selectedCustomers, ...selectedAvailable]);
    setSelectedAvailable([]);
  };

  // Handle moving customers from selected back to available
  const handleMoveToAvailable = () => {
    setSelectedCustomers(
      selectedCustomers.filter((c) => !selectedGroup.includes(c))
    );
    setAvailableCustomers([...availableCustomers, ...selectedGroup]);
    setSelectedGroup([]);
  };

  //   // Handle form submission
  const handleSubmit = () => {
    // Editing an existing group - no need to validate hidden fields
    if (selectedCustomers.length === 0) {
      onSnackbarChange({
        open: true,
        message: "Please select customers to add to the group.",
        severity: "error",
      });
      return; // Don't proceed if no customers are selected
    }
    onSubmit(
      "", // Not needed for editing
      selectedCustomers, // The selected customers to add to the group
      "" // Distributor ID is not needed for editing here
    );
    try {
      updateGroupUsers(
        customerGroupEmail,
        selectedCustomers.map((c) => c.email)
      );
      onSnackbarChange({
        open: true,
        message: "Group users updated successfully.",
        severity: "success",
      });
      onSuccess();
    } catch (error) {
      onSnackbarChange({
        open: true,
        message: "Failed to update group users. Please try again.",
        severity: "error",
      });
    }
    onClose(); // Close the modal after submission
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>Add Members to Group</DialogTitle>

      {/* <DialogTitle>Add New Group User</DialogTitle> */}
      <DialogContent>
        {/* Customer Selection Boxes */}
        <Box sx={{ display: "flex", gap: 2, mt: 3 }}>
          {/* Available Customers Box */}
          <Paper elevation={1} sx={{ flex: 1, p: 2 }}>
            <Typography variant="h6">Available Customers</Typography>
            <Divider sx={{ my: 1 }} />
            <List>
              {availableCustomers.map((customer) => (
                <ListItem
                  key={customer._id}
                  disablePadding
                  button
                  onClick={() => handleSelectAvailable(customer)}
                  sx={{
                    "&:hover": { backgroundColor: "rgba(33, 150, 243, 0.2)" }, // Hover effect to enhance visibility
                  }}
                >
                  <Checkbox
                    checked={selectedAvailable.includes(customer)}
                    onChange={() => handleSelectAvailable(customer)}
                  />
                  <ListItemText
                    primary={`${customer.firstName} ${customer.lastName}`}
                  />
                </ListItem>
              ))}
            </List>
          </Paper>

          {/* Arrow Buttons for Moving Customers */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              gap: 1,
            }}
          >
            <IconButton
              onClick={handleMoveToSelected}
              disabled={selectedAvailable.length === 0}
            >
              <ArrowForwardIcon />
            </IconButton>
            <IconButton
              onClick={handleMoveToAvailable}
              disabled={selectedGroup.length === 0}
            >
              <ArrowBackIcon />
            </IconButton>
          </Box>

          {/* Selected Customers Box */}
          <Paper elevation={1} sx={{ flex: 1, p: 2 }}>
            <Typography variant="h6">Selected Customers</Typography>
            <Divider sx={{ my: 1 }} />
            <List>
              {selectedCustomers.map((customer) => (
                <ListItem
                  key={customer._id}
                  disablePadding
                  button
                  onClick={() => handleSelectGroup(customer)}
                  sx={{
                    "&:hover": { backgroundColor: "rgba(33, 150, 243, 0.2)" }, // Hover effect to enhance visibility
                  }}
                >
                  <Checkbox
                    checked={selectedGroup.includes(customer)}
                    onChange={() => handleSelectGroup(customer)}
                  />
                  <ListItemText
                    primary={`${customer.firstName} ${customer.lastName}`}
                  />
                </ListItem>
              ))}
            </List>
          </Paper>
        </Box>
      </DialogContent>
      <DialogActions>
        <StyledButton onClick={onClose}>Cancel</StyledButton>
        <StyledButton onClick={handleSubmit} variantType="primary">
          Submit
        </StyledButton>
      </DialogActions>
    </Dialog>
  );
};

export default AssignToGroup;
