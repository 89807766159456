import axios from "axios";
import useAuthStore from "../store/authStore";

const API_URL = process.env.REACT_APP_API_URL;
const { user } = useAuthStore.getState();

type deviceToCustomers = {
  deviceId: string;
  customers: string[];
};

type customerToDevices = {
  customer: string;
  deviceIds: string[];
};

// Assign Customers to a Device
export const deviceToCustomers = async (payload: deviceToCustomers) => {
  try {
    const response = await axios.post(
      `${API_URL}/bulk/device-to-customers`,
      payload
    );
    return response.data;
  } catch (error: any) {
    console.error(error.message);
    throw error;
  }
};

// Assign Devices to a Customer
export const customerToDevices = async (payload: customerToDevices) => {
  try {
    const response = await axios.post(
      `${API_URL}/bulk/customer-to-devices`,
      payload
    );
    return response.data;
  } catch (error: any) {
    console.error(error.message);
    throw error;
  }
};
