import React from "react";
import { TextField, styled } from "@mui/material";

export const StyledTextFieldNew = styled(TextField)({
  "& label.Mui-focused": {
    color: "rgba(30, 58, 138, 1)",
  },
  "& label": {
    color: "text.primary",
    fontSize: "14px",
    fontWeight: 700,
  },
  "& .requiredLabel::after": {
    content: "'*'",
    color: "red",
  },
  "& .MuiFormLabel-asterisk.MuiInputLabel-asterisk": {
    color: "red", // This targets the asterisk directly
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "text.primary",
    },
    "&:hover fieldset": {
      borderColor: "rgba(30, 58, 138, 1)",
    },
    "&.Mui-focused fieldset": {
      borderColor: "rgba(30, 58, 138, 1)",
    },
  },
});
