import axios from "axios";
import { DeviceData } from "../common/types/DeviceTypes";
import { WarrantyFormData } from "../common/types/WarrantyTypes";
const API_URL = process.env.REACT_APP_API_URL;

export const fetchWarrantyClaims = async (
  email: string,
  role: string
): Promise<any> => {
  try {
    const response = await axios.get(`${API_URL}/warranty/all`, {
      params: { email, role },
    });
    // Sort the claims by submittedDate in descending order
    const sortedClaims = response.data.sort(
      (a: any, b: any) =>
        new Date(b.submittedDate).getTime() -
        new Date(a.submittedDate).getTime()
    );
    return sortedClaims;
  } catch (error) {
    console.error("Failed to fetch warranty claims:", error);
    throw new Error("Failed to fetch warranty claims");
  }
};

export const updateWarrantyStatus = async (
  id: string,
  status: string,
  updatedBy: string,
  comments: string
): Promise<any> => {
  try {
    const response = await axios.patch(
      `${API_URL}/warranty/${id}/status-with-logs`,
      {
        status,
        updatedBy,
        comments,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Failed to update warranty status:", error);
    throw new Error("Failed to update warranty status");
  }
};

// export const submitWarrantyClaim = async (
//   data: any,
//   formData: WarrantyFormData,
//   user: { email: string },
//   devices: DeviceData[],
//   onSubmit: (formData: WarrantyFormData) => void,
//   onError: (message: string) => void,
//   //resetForm: () => void,
//  // onClose: () => void
// ): Promise<any> => {
//   if (
//     !formData.deviceSerial ||
//     !formData.issueDescription ||
//     !formData.returnReason
//   ) {
//     onError("Please fill all required fields.");
//     return;
//   }
//   try {
//     const response = await axios.post(`${API_URL}/warranty`, data, {
//       headers: {
//         "Content-Type": "application/json",
//       },
//     });
//     onSubmit(formData);
//     //resetForm(); // Reset the form after successful submission
//     //onClose();
//     return response.data;
//   } catch (error) {
//     onError("Error submitting warranty claim.");
//     console.error("Error submitting warranty claim:", error);
//     throw new Error("Failed to submit warranty claim");
//   }
// };

export const submitWarrantyClaim = async (
  serialNo: string,
  detailedIssue: string,
  faultType: string,
  photo: string | undefined,
  submittedDate: string,
  status: string,
  distributorEmail: string,
  customerEmail: string
): Promise<any> => {
  try {
    const data = {
      serialNo,
      detailedIssue,
      faultType,
      photo,
      submittedDate,
      status,
      distributorEmail,
      customerEmail,
    };

    const response = await axios.post(`${API_URL}/warranty`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Failed to submit warranty claim:", error);
    throw new Error("Failed to submit warranty claim");
  }
};

export const fetchDeviceArrivals = async (
  email: string,
  role: string
): Promise<any> => {
  try {
    const response = await axios.get(`${API_URL}/device-arrivals/all`, {
      params: { email, role },
    });
    // // Sort the claims by submittedDate in descending order
    // const sortedClaims = response.data.sort(
    //   (a: any, b: any) =>
    //     new Date(b.submittedDate).getTime() -
    //     new Date(a.submittedDate).getTime()
    // );
    // return sortedClaims;
    return response.data;
  } catch (error) {
    console.error("Failed to fetch warranty claims:", error);
    throw new Error("Failed to fetch warranty claims");
  }
};
