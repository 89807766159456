import React, { useState } from "react";
import {
  Box,
  Grid,
  TextField,
  Typography,
  Snackbar,
  Alert,
  Link,
} from "@mui/material";
import axios from "axios";
import StyledButton from "../components/Tabs/StyledButton";

const ForgotPasswordPage: React.FC = () => {
  const [email, setEmail] = useState<string>("");
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "info" as "success" | "info" | "warning" | "error",
  });

  const handleForgotPassword = async () => {
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/auth/forgot-password`,
        {
          email,
        }
      );
      setSnackbar({
        open: true,
        message: "Password reset link has been sent to your email.",
        severity: "success",
      });
    } catch (error: any) {
      setSnackbar({
        open: true,
        message: error.response?.data?.details || "Failed to send reset link.",
        severity: "error",
      });
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        minHeight: "100vh",
        background:
          "linear-gradient(45deg, rgba(30, 58, 138, 1) 55%, rgba(30, 58, 138, 0.6) 90%)",
      }}
    >
      <Box
        sx={{
          m: 1,
          ml: 2,
          maxWidth: "500px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          alignContent: "center",
          mb: 10,
        }}
      >
        <Grid
          container
          spacing={1}
          sx={{
            padding: "1.5rem",
            backgroundColor: "white",
            borderRadius: 2,
            boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
          }}
        >
          <Snackbar
            open={snackbar.open}
            autoHideDuration={6000}
            onClose={() => setSnackbar({ ...snackbar, open: false })}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert
              onClose={() => setSnackbar({ ...snackbar, open: false })}
              severity={snackbar.severity}
            >
              {snackbar.message}
            </Alert>
          </Snackbar>
          <Grid item xs={12}>
            <Typography
              component="h1"
              variant="h5"
              textAlign="center"
              sx={{ fontWeight: 600 }}
            >
              Forgot Password
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="subtitle1"
              sx={{ color: "gray", textAlign: "center" }}
            >
              Enter your email address and we will send you a link to reset your
              password.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              onChange={(e) => setEmail(e.target.value)}
              autoFocus
              sx={{
                ".MuiInputLabel-root": { fontSize: "0.875rem" },
                ".MuiInputBase-input": { fontSize: "0.875rem" },
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <StyledButton
              type="submit"
              fullWidth
              variantType="primary"
              size="large"
              onClick={handleForgotPassword}
            >
              Send Reset Link
            </StyledButton>
          </Grid>
          <Grid item xs={6} sx={{ textAlign: "left" }}>
            <Link
              href="/login"
              variant="body2"
              sx={{
                "&:hover": {
                  transform: "scale(1.5)", // subtle zoom out effect
                },
              }}
            >
              Back{" "}
            </Link>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default ForgotPasswordPage;
