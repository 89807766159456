import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Snackbar,
  Alert,
  AlertColor,
  ListItemIcon,
  styled,
} from "@mui/material";
import { StyledTabs } from "../Tabs/IconTab";
import ListIcon from "@mui/icons-material/List";
import Inventory2Icon from "@mui/icons-material/Inventory2";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import ChatIcon from "@mui/icons-material/Chat";
import ManageHistoryIcon from "@mui/icons-material/ManageHistory";
import DistributorSupport from "../DistributorSupport.tsx/DistributorSupport";
import { fetchWarrantyClaims } from "../../services/warranty.service";
import { DeviceData } from "../../common/types/DeviceTypes";
//import { WarrantyClaimData } from "../../common/types/WarrantyTypes";
import useAuthStore from "../../store/authStore";
// import WarrantyClaimsTable from "../DistributorSupport.tsx/WarrantyClaimsTable";
import ChatBox from "./ChatBox";
import ChangeRequestsTable from "./ChangeRequestsTable";
import PlainTab from "../Tabs/PlainTab";

interface WarrantyClaimData {
  serialNo: string;
  distributorEmail: string;
  customerEmail: string;
  faultType: string;
  status: string;
  submittedDate: string;
  updatedAt: string;
  detailedIssue?: string;
  photo?: string;
  _id: string;
}

interface HelpDeskProps {
  devices: DeviceData[];
  //claims: WarrantyClaimData[];
}

export const HelpDesk: React.FC<HelpDeskProps> = ({ devices }) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [snackbar, setSnackbar] = useState<{
    open: boolean;
    message: string;
    severity: AlertColor | undefined;
  }>({ open: false, message: "", severity: undefined });

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
    localStorage.setItem("selectedHelpDeskTab", newValue.toString());
  };

  const [claims, setClaims] = useState<WarrantyClaimData[]>([]);
  const { user } = useAuthStore.getState();

  useEffect(() => {
    const fetchClaims = async () => {
      try {
        if (user && selectedTab === 1) {
          // Check if the user is defined and the "Warranty Claims" tab is selected
          const response = await fetchWarrantyClaims(user.email, user.role);
          setClaims(response);
        }
      } catch (error) {
        console.error("Error fetching warranty claims:", error);
      }
    };

    fetchClaims();
  }, [user, selectedTab]); // Add selectedTab to dependencies

  return (
    <Box p={0}>
      <Box mt={0} sx={{ display: "flex", justifyContent: "center" }}>
        <Box
          mt={2}
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            width: { xs: "95%", md: "96%" },
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              // borderBottom: 0.5,
              // borderColor: "divider",
              p: 0,
            }}
          >
            <Typography variant="h6" sx={{ mb: 2 }}>
              Help Desk
            </Typography>
          </Box>

          <StyledTabs
            value={selectedTab}
            onChange={handleChangeTab}
            aria-label="Profile tabs"
            variant="scrollable" // Enable scrolling
            scrollButtons="auto" // Enable auto scrolling
          >
            <PlainTab label="Overview" />
            <PlainTab label="Change requests" />
            {/*<PlainTab label="Warranty Claims" />
                        <PlainTab label="Support Tickets" />
                        <PlainTab label="Chat" />*/}
          </StyledTabs>
          <Box sx={{ p: 2 }}>
            {selectedTab === 0 && <DistributorSupport devices={devices} />}
            {selectedTab === 1 && <ChangeRequestsTable />}
            {/* {selectedTab === 2 && ( */}
            {/*   <WarrantyClaimsTable devices={devices} claims={claims} /> */}
            {/* )} */}
            {/* {selectedTab === 3 && supportTickets } */}
            {selectedTab === 4 && <ChatBox />}
          </Box>
          <Snackbar
            open={snackbar.open}
            autoHideDuration={6000}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            onClose={() => setSnackbar({ ...snackbar, open: false })}
          >
            <Alert severity={snackbar.severity} sx={{ width: "100%" }}>
              {snackbar.message}
            </Alert>
          </Snackbar>
        </Box>
      </Box>
    </Box>
  );
};
