import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Snackbar,
  Alert,
  TextField,
  Modal,
} from "@mui/material";
import Add from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import {
  fetchInventoryParts,
  addInventoryPart,
} from "../../services/inventory.service";
import { InventoryPart } from "../../common/types/InventoryPartTypes";
import StyledButton from "../Tabs/StyledButton";

function InventoryPartsTable() {
  const [inventoryParts, setInventoryParts] = useState<InventoryPart[]>([]);
  const [search, setSearch] = useState("");
  const [snackbar, setSnackbar] = useState<{
    open: boolean;
    message: string;
    severity: "success" | "error" | "warning" | "info" | undefined;
  }>({
    open: false,
    message: "",
    severity: undefined,
  });

  const [openModal, setOpenModal] = useState(false);
  const [newInventory, setNewInventory] = useState({
    name: "",
    number: "",
  });

  useEffect(() => {
    const fetchData = async () => {
      const result = await fetchInventoryParts();
      setInventoryParts(result);
    };
    fetchData();
  }, []);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const filteredInventoryParts = () => {
    let filteredParts = inventoryParts;
    if (search) {
      filteredParts = filteredParts.filter((part) =>
        part.name.toLowerCase().includes(search.toLowerCase())
      );
    }
    return filteredParts;
  };

  const chunkedInventoryParts = [];
  const parts = filteredInventoryParts();
  for (let i = 0; i < parts.length; i += 3) {
    chunkedInventoryParts.push(parts.slice(i, i + 3));
  }

  const handleSubmitInventory = async () => {
    try {
      if (!newInventory.name || !newInventory.number) {
        setSnackbar({
          open: true,
          message: "Name and Part Number are required!",
          severity: "error",
        });
        return;
      }

      await addInventoryPart(newInventory);

      const updatedInventoryParts = await fetchInventoryParts();
      setInventoryParts(updatedInventoryParts);

      setNewInventory({ name: "", number: "" });
      setOpenModal(false);

      setSnackbar({
        open: true,
        message: "Inventory part added successfully!",
        severity: "success",
      });
    } catch (error) {
      setSnackbar({
        open: true,
        message: "Error adding inventory part.",
        severity: "error",
      });
    }
  };

  return (
    <Box p={0}>
      <Box mt={0} sx={{ display: "flex", justifyContent: "center" }}>
        <Box
          mt={2}
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            width: { xs: "95%", md: "96%" },
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              p: 0,
              mb: 1,
            }}
          >
            <Typography sx={{ ml: 1 }} variant="h5">
              Manage Inventory
            </Typography>
            <StyledButton
              variantType="primary"
              icon={<Add />}
              onClick={() => setOpenModal(true)}
            >
              Add Inventory
            </StyledButton>
          </Box>
          <TableContainer
            component={Box}
            sx={{
              mx: "auto",
              width: "100%",
              maxWidth: "calc(100% - 20px)",
              p: 1,
              my: 1,
              backgroundColor: "white",
              boxShadow: 1,
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                p: 1.5,
                backgroundColor: "#f5f5f5",
                mb: 2,
              }}
            >
              <Snackbar
                open={snackbar.open}
                autoHideDuration={8000}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                onClose={() => setSnackbar({ ...snackbar, open: false })}
              >
                <Alert severity={snackbar.severity} sx={{ width: "100%" }}>
                  {snackbar.message}
                </Alert>
              </Snackbar>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <TextField
                  variant="outlined"
                  size="small"
                  placeholder="Search"
                  value={search}
                  onChange={handleSearchChange}
                  sx={{ marginRight: 2, width: "300px" }}
                  InputProps={{
                    startAdornment: (
                      <SearchIcon sx={{ marginRight: 1, color: "gray" }} />
                    ),
                  }}
                />
              </Box>
            </Box>

            <Table sx={{ minWidth: 650 }} aria-label="inventory parts table">
              <TableHead>
                <TableRow>
                  <TableCell>Name 1</TableCell>
                  <TableCell sx={{ borderRight: "2px solid #e0e0e0" }}>
                    Part Number 1
                  </TableCell>
                  <TableCell>Name 2</TableCell>
                  <TableCell sx={{ borderRight: "2px solid #e0e0e0" }}>
                    Part Number 2
                  </TableCell>
                  <TableCell>Name 3</TableCell>
                  <TableCell>Part Number 3</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {chunkedInventoryParts.map((row, rowIndex) => (
                  <TableRow key={rowIndex}>
                    {row.map((part, index) => (
                      <React.Fragment key={part._id}>
                        <TableCell>{part.name}</TableCell>
                        <TableCell
                          sx={
                            index === 2
                              ? {}
                              : { borderRight: "2px solid #e0e0e0" }
                          }
                        >
                          {part.number}
                        </TableCell>
                      </React.Fragment>
                    ))}
                    {row.length < 3 &&
                      Array.from({ length: 3 - row.length }).map((_, i) => (
                        <React.Fragment key={i}>
                          <TableCell />
                          <TableCell />
                        </React.Fragment>
                      ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>

      {/* Modal for Adding Inventory */}
      <Modal open={openModal} onClose={() => setOpenModal(false)}>
        <Box
          sx={{
            width: "400px",
            backgroundColor: "white",
            p: 4,
            borderRadius: 2,
            mx: "auto",
            mt: "15%",
          }}
        >
          <Typography variant="h6" gutterBottom>
            Add Inventory
          </Typography>

          <Box mb={2}>
            <TextField
              label="Name"
              value={newInventory.name}
              onChange={(e) =>
                setNewInventory({ ...newInventory, name: e.target.value })
              }
              fullWidth
              sx={{ mb: 1 }}
            />
            <TextField
              label="Part Number"
              value={newInventory.number}
              onChange={(e) =>
                setNewInventory({ ...newInventory, number: e.target.value })
              }
              fullWidth
              sx={{ mb: 1 }}
            />
          </Box>
          <Box
            sx={{ display: "flex", justifyContent: "flex-end", mt: 2, gap: 1 }}
          >
            <StyledButton
              onClick={() => {
                setNewInventory({ name: "", number: "" });
                setOpenModal(false);
              }}
            >
              Cancel
            </StyledButton>
            <StyledButton variantType="primary" onClick={handleSubmitInventory}>
              Submit
            </StyledButton>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}

export default InventoryPartsTable;
