import React from "react";
import {
  Box,
  Typography,
  Container,
  List,
  ListItem,
  ListItemText,
  Divider,
} from "@mui/material";
import InstallerHomePage from "../../assets/images/i-home-page.png";
import InstallDevice from "../../assets/images/i-install-device.png";
import Dashboard from "../../assets/images/c-dashboard.png";
import InstallerDevices from "../../assets/images/i-devices.png";
import CDeviceDetails from "../../assets/images/c-device-details.png";
import CAddAdditionalCustomer from "../../assets/images/c-add-additional-customer.png";
import InstallerCustomer from "../../assets/images/i-customer.png";

const InstallerTab = () => {
  return (
    <Container
      sx={{
        padding: "16px 0px",
        "& > *": { padding: "4px 0" },
      }}
    >
      {/* Main Title */}
      <Typography variant="h4" fontWeight="bold">
        Installer Portal
      </Typography>

      {/* Introduction */}
      <Typography variant="body1" gutterBottom>
        The Installer Portal enables you to install new devices, view your
        existing devices, manage device settings, and access data and statistics
        related to your devices. Here’s how the home page will appear upon
        logging in.
      </Typography>

      {/* Home Page Section */}
      <Typography variant="h6" fontWeight="bold">
        Home Page:
      </Typography>
      <Box
        component="img"
        src={InstallerHomePage}
        alt="Installer Home Page"
        width="100%"
        mb={2}
      />
      <Typography variant="body1" gutterBottom>
        The home page is designed with simplicity in mind, allowing you to
        navigate quickly and access everything you need with ease.
      </Typography>

      {/* Home Page List */}
      <List>
        <ListItem>
          <ListItemText
            primary={
              <Typography variant="body1">
                1. <strong>Install Device</strong> – The most important button
                for installers, conveniently located on the home page for quick
                access.
              </Typography>
            }
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={
              <Typography variant="body1">
                2. <strong>Hamburger Menu</strong> – Click this icon to expand
                the menu and navigate to different pages.
              </Typography>
            }
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={
              <Typography variant="body1">
                3. <strong>Bell Icon</strong> – Alerts you to important
                notifications regarding your devices or account.
              </Typography>
            }
          />
        </ListItem>
      </List>

      <Typography variant="body1" gutterBottom>
        You can find these four buttons in the center of the screen:
      </Typography>
      <List>
        <ListItem>
          <ListItemText
            primary={
              <Typography variant="body1">
                <strong>View Devices</strong> – Access and manage your devices.
              </Typography>
            }
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={
              <Typography variant="body1">
                <strong>Access Support Desk</strong> – Get help quickly.
              </Typography>
            }
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={
              <Typography variant="body1">
                <strong>Visit Tersano Website</strong> – Learn more about our
                products and company.
              </Typography>
            }
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={
              <Typography variant="body1">
                <strong>Set Up Profile</strong> – Change profile settings,
                password, or notification preferences.
              </Typography>
            }
          />
        </ListItem>
      </List>

      <Divider sx={{ my: 2 }} />

      {/* Install Device Section */}
      <Typography variant="h6" fontWeight="bold">
        Install Device:
      </Typography>
      <Box
        component="img"
        src={InstallDevice}
        alt="Install Device"
        width="100%"
        mb={2}
      />
      <Typography variant="body1" gutterBottom>
        This page allows you to either install a new device or replace an
        existing one. After clicking either button, an installation wizard will
        guide you through the process of adding or replacing a device. For more
        information, you can watch the videos in the training portal.
      </Typography>
      <Typography variant="body1" gutterBottom>
        Now, you can open the hamburger menu and click on Dashboards.
      </Typography>

      <Divider sx={{ my: 2 }} />

      {/* Dashboard Overview Section */}
      <Typography variant="h6" fontWeight="bold">
        Dashboard Overview:
      </Typography>
      <Box
        component="img"
        src={Dashboard}
        alt="Dashboard Overview"
        width="100%"
        mb={2}
      />
      <Typography variant="body1" gutterBottom>
        The dashboard provides a comprehensive visualization of device usage,
        including location, ping status, and cartridge health. You'll be able to
        track device activity using bar and chart graphs, as well as monitor key
        metrics like total and average usage.
      </Typography>

      {/* Dashboard List */}
      <List>
        <ListItem>
          <ListItemText primary="1. You can see a few tabs here. We'll explore them shortly, but for now, we're on the Lotus Pro tab." />
        </ListItem>

        <ListItem>
          <ListItemText primary="2. View devices, locations, liters used, cartridge life, and device status. Filters based on country or status can be applied." />
        </ListItem>
        <ListItem>
          <ListItemText primary="3. Usage charts show total liters used, average usage, peak activity times, and daily usage. Switch between daily, weekly, and monthly views." />
        </ListItem>
        <ListItem>
          <ListItemText primary="4. Generate reports on device usage. Export in liters or gallons. Sustainability reports can also be generated." />
        </ListItem>
        <ListItem>
          <ListItemText primary="5. Toggle between imperial (gallons) and metric (liters) systems to view data based on your preference." />
        </ListItem>
      </List>

      <Divider sx={{ my: 2 }} />

      {/* Device Management Section */}
      <Typography variant="h6" fontWeight="bold">
        Device Management:
      </Typography>
      <Box
        component="img"
        src={InstallerDevices}
        alt="Device Management"
        width="100%"
        mb={2}
      />
      <Typography variant="body1" gutterBottom>
        The device management feature allows you to manage your devices,
        including settings, logs, errors, replacement history, and location. You
        can also access the Device Errors and Serial Monitor from the tabs.
      </Typography>
      <Typography variant="body1" gutterBottom>
        Let's click on one device for demonstration purposes.
      </Typography>

      <Box
        component="img"
        src={CDeviceDetails}
        alt="Device Details"
        width="100%"
        mb={2}
      />
      <Typography variant="body1" gutterBottom>
        Here, you can see all the details about the device, including an
        overview, logs, manual, and device information. You can also add
        additional customers by clicking on the Customers tab.
      </Typography>

      <Box
        component="img"
        src={CAddAdditionalCustomer}
        alt="Add Additional Customer"
        width="100%"
        mb={2}
      />
      <Typography variant="body1" gutterBottom>
        You can click the Add Additional User button to add more users.
      </Typography>
      <Typography variant="body1" gutterBottom>
        Lastly, let’s open the hamburger menu and click on Manage Customers.
      </Typography>

      <Divider sx={{ my: 2 }} />

      {/* Customer Management Section */}
      <Typography variant="h6" fontWeight="bold">
        Customer Management:
      </Typography>
      <Box
        component="img"
        src={InstallerCustomer}
        alt="Customer Management"
        width="100%"
        mb={2}
      />
      <Typography variant="body1" gutterBottom>
        The customer management feature enables you to manage your customers
        effectively. You can view all customers or organize them into groups by
        clicking on the Customers or Groups tabs. Additionally, you can add a
        new customer using the button located at the top right corner.
      </Typography>
    </Container>
  );
};

export default InstallerTab;
