import React, { useState, useEffect } from "react";
import {
  Paper,
  Typography,
  Divider,
  List,
  ListItem,
  ListItemText,
  Checkbox,
  IconButton,
  Box,
  CircularProgress,
  Chip,
  Snackbar,
  Alert,
} from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { DeviceData } from "../../common/types/DeviceTypes";
import {
  addAdditionalCustomersToDevice,
  removeAdditionalCustomerFromDevice,
} from "../../services/customer.service";
import { Customer } from "../../store/useCustomer";
import StyledButton from "../Tabs/StyledButton";

interface CustomerDeviceAccessProps {
  devices: DeviceData[]; // All devices available for the distributor
  customer: Customer; // Customer's email to filter related devices
  onSubmit: (selectedDevices: DeviceData[]) => void; // Callback to handle device submission
}

export const CustomerDeviceAccess: React.FC<CustomerDeviceAccessProps> = ({
  devices,
  customer,
  onSubmit,
}) => {
  const [availableDevices, setAvailableDevices] = useState<DeviceData[]>([]);
  const [relatedDevices, setRelatedDevices] = useState<DeviceData[]>([]);
  const [selectedAvailable, setSelectedAvailable] = useState<DeviceData[]>([]);
  const [selectedRelated, setSelectedRelated] = useState<DeviceData[]>([]);
  const [initialRelatedDevices, setInitialRelatedDevices] = useState<
    DeviceData[]
  >([]); // Track the initial related devices
  const [loading, setLoading] = useState(true); // Loading state
  const [hasChanges, setHasChanges] = useState(false); // Track if any changes are made
  const [snackbar, setSnackbar] = useState<{ open: boolean; message: string }>({
    open: false,
    message: "",
  });

  // Update availableDevices and relatedDevices when devices prop changes
  useEffect(() => {
    setLoading(true); // Set loading to true when fetching data

    // Simulate a small delay for loading state (if needed)
    setTimeout(() => {
      const newAvailableDevices = devices.filter(
        (device) =>
          device.deviceInformation.customerEmail !== customer.email &&
          device.deviceInformation.installerEmail !== customer.email &&
          !device.deviceInformation.additionalUsers.includes(customer.email) &&
          !device.deviceInformation.additionalCustomers.includes(customer.email)
      );

      const newRelatedDevices = devices.filter(
        (device) =>
          device.deviceInformation.customerEmail === customer.email ||
          device.deviceInformation.installerEmail === customer.email ||
          device.deviceInformation.additionalUsers.includes(customer.email) ||
          device.deviceInformation.additionalCustomers.includes(customer.email)
      );

      setAvailableDevices(newAvailableDevices);
      setRelatedDevices(newRelatedDevices);
      setInitialRelatedDevices(newRelatedDevices); // Track initial related devices
      setLoading(false); // Set loading to false when data is ready
    }, 500); // Adjust the delay time if needed
  }, [devices, customer.email]);

  const handleSelectAvailable = (device: DeviceData) => {
    setSelectedAvailable((prev) =>
      prev.includes(device)
        ? prev.filter((d) => d !== device)
        : [...prev, device]
    );
  };

  const handleSelectRelated = (device: DeviceData) => {
    setSelectedRelated((prev) =>
      prev.includes(device)
        ? prev.filter((d) => d !== device)
        : [...prev, device]
    );
  };

  const handleMoveToRelated = () => {
    setRelatedDevices((prev) => [...prev, ...selectedAvailable]);
    setAvailableDevices((prev) =>
      prev.filter((device) => !selectedAvailable.includes(device))
    );
    setSelectedAvailable([]);
    setHasChanges(true);
  };

  const handleMoveToAvailable = () => {
    // Only move devices that are not "Main" customers
    const nonMainDevices = selectedRelated.filter(
      (device) =>
        device.deviceInformation.customerEmail !== customer.email &&
        !device.deviceInformation.additionalUsers.includes(customer.email)
    );

    setAvailableDevices((prev) => [...prev, ...nonMainDevices]);
    setRelatedDevices((prev) =>
      prev.filter((device) => !nonMainDevices.includes(device))
    );
    setSelectedRelated([]);
    setHasChanges(true);
  };

  const handleSubmit = async () => {
    let successfulAddCalls = 0; // Counter for successful add API calls
    let successfulRemoveCalls = 0; // Counter for successful remove API calls

    // Determine which devices need to be added and removed
    const devicesToAdd = relatedDevices.filter(
      (device) => !initialRelatedDevices.includes(device)
    );
    const devicesToRemove = initialRelatedDevices.filter(
      (device) => !relatedDevices.includes(device)
    );

    // Loop through devices to be removed and hit the API
    for (const device of devicesToRemove) {
      try {
        await removeAdditionalCustomerFromDevice(
          device.deviceInformation.deviceId,
          customer.email
        );
        successfulRemoveCalls++; // Increment the counter on success
      } catch (error) {
        console.error("Failed to remove customer from device:", error);
      }
    }

    // Loop through devices to be added and hit the API
    for (const device of devicesToAdd) {
      try {
        await addAdditionalCustomersToDevice(
          device.deviceInformation.deviceId,
          [
            {
              email: customer.email,
              firstName: customer.firstName,
              lastName: customer.lastName,
            },
          ]
        );
        successfulAddCalls++; // Increment the counter on success
      } catch (error) {
        console.error("Failed to add customer to device:", error);
      }
    }

    // Ensure state is updated correctly to show Snackbar
    setSnackbar({
      open: true,
      message: `Successfully granted access to ${successfulAddCalls} devices and removed access from ${successfulRemoveCalls} devices.`,
    });

    // Ensure re-render by using a callback to manage state updates
    onSubmit(relatedDevices);
  };

  return (
    <Box sx={{ mt: 1 }}>
      {/* Page Title */}
      <Typography variant="h6" sx={{ textAlign: "center" }}>
        Manage Device Access for Customer
      </Typography>
      <Typography variant="body1" sx={{ mb: 2, textAlign: "center" }}>
        Select a device and hit right icon to add to customer's devices and left
        to remove
      </Typography>

      {/* Show Circular Progress while loading */}
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "200px", // Adjust the height as needed
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        /* Device Selection Boxes */
        <Box sx={{ display: "flex", gap: 2, mt: 3 }}>
          {/* Available Devices Box */}
          <Paper elevation={1} sx={{ flex: 1, p: 2 }}>
            <Typography variant="h6">All Devices</Typography>
            <Divider sx={{ my: 1 }} />
            <List>
              {availableDevices.map((device) => (
                <ListItem
                  key={device._id}
                  disablePadding
                  button
                  onClick={() => handleSelectAvailable(device)}
                  sx={{
                    "&:hover": {
                      backgroundColor: "rgba(33, 150, 243, 0.2)",
                    },
                  }}
                >
                  <Checkbox
                    checked={selectedAvailable.includes(device)}
                    onChange={(e) => {
                      e.stopPropagation();
                      handleSelectAvailable(device);
                    }}
                  />
                  <ListItemText
                    primary={
                      device.deviceInformation?.deviceName || "Unknown Device"
                    }
                  />
                </ListItem>
              ))}
            </List>
          </Paper>

          {/* Arrow Buttons for Moving Devices */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              gap: 1,
            }}
          >
            <IconButton
              onClick={handleMoveToRelated}
              disabled={selectedAvailable.length === 0}
            >
              <ArrowForwardIcon fontSize="large" />
            </IconButton>
            <IconButton
              onClick={handleMoveToAvailable}
              disabled={
                selectedRelated.length === 0 ||
                selectedRelated.some(
                  (device) =>
                    device.deviceInformation.customerEmail === customer.email ||
                    device.deviceInformation.additionalUsers.includes(
                      customer.email
                    )
                )
              } // Disable if any "Main" devices are selected
            >
              <ArrowBackIcon fontSize="large" />
            </IconButton>
          </Box>

          {/* Related Devices Box */}
          <Paper elevation={1} sx={{ flex: 1, p: 2 }}>
            <Typography variant="h6">Customer's Devices</Typography>
            <Divider sx={{ my: 1 }} />
            <List>
              {relatedDevices.map((device) => {
                // Check if the customer is the main customer for the device
                const isMain =
                  device.deviceInformation.customerEmail === customer.email ||
                  device.deviceInformation.additionalUsers.includes(
                    customer.email
                  );

                return (
                  <ListItem
                    key={device._id}
                    disablePadding
                    // Use conditional rendering to determine the properties
                    {...(isMain
                      ? {
                          onClick: undefined,
                          sx: {},
                        }
                      : {
                          onClick: () => handleSelectRelated(device),
                          sx: {
                            "&:hover": {
                              backgroundColor: "rgba(33, 150, 243, 0.2)",
                            },
                          },
                        })}
                  >
                    <Checkbox
                      checked={selectedRelated.includes(device)}
                      onChange={(e) => {
                        e.stopPropagation();
                        handleSelectRelated(device);
                      }}
                      disabled={isMain} // Disable checkbox if it's a main customer
                    />
                    <ListItemText
                      primary={
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          {device.deviceInformation?.deviceName ||
                            "Unknown Device"}
                          {isMain && (
                            <Chip
                              label="Main"
                              color="primary"
                              size="small"
                              sx={{ ml: 1 }}
                            />
                          )}
                        </Box>
                      }
                    />
                  </ListItem>
                );
              })}
            </List>
          </Paper>
        </Box>
      )}

      {/* Submit Button */}
      <Box sx={{ mt: 3, display: "flex", justifyContent: "flex-end" }}>
        <StyledButton
          onClick={handleSubmit}
          variantType="primary"
          disabled={!hasChanges}
        >
          Submit
        </StyledButton>
      </Box>

      {/* Snackbar for Success Message */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={() => setSnackbar({ open: false, message: "" })}
      >
        <Alert severity="success" sx={{ width: "100%" }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};
