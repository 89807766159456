import React, { useEffect, useState } from "react";
import {
  Modal,
  Box,
  TextField,
  Typography,
  Button,
  Paper,
  List,
  ListItem,
  ListItemText,
  Checkbox,
  Divider,
  Select,
  MenuItem,
  Autocomplete,
  FormControl,
  InputLabel,
  SelectChangeEvent,
  Stepper,
  Step,
  StepLabel,
  Snackbar,
  Alert,
  AlertProps,
} from "@mui/material";
import { fetchInventoryParts } from "../../services/inventory.service";
import { updateRework } from "../../services/device-rework.service";
import { InventoryPart } from "../../common/types/InventoryPartTypes";
import StyledButton from "../Tabs/StyledButton";

interface EditDeviceReworkModalProps {
  onClose: () => void;
  onEdit: () => void;
  existingRework: {
    _id: string;
    rootCauseOfRepair: string[];
    correctiveAction: string;
    parts: InventoryPart[];
    deviceId?: string;
    otherRootCause?: string[];
  };
}

const rootCauseOptions = [
  "Water In Generator",
  "Blown Fuse",
  "Failed Offgas",
  "Failed Flo Meter",
  "Failed Water Solanoid",
  "Faulty Coil",
  "Failed Check Valve",
  "Failed HCU Board",
  "Displ. Ribbon( Repl. Cart.)",
  "Flow Meter (Broken Barb)",
  "Oz. Gen. (Service/Burned)",
  "Broken Tabs",
  "Broken Footing",
  "Stained",
  "Sticker",
  "Other",
];

const correctiveActionOptions = [
  "Replace Parts",
  "Salvage Parts",
  "Upgrade Device",
  "Replace and Upgrade",
  "Other",
  "No Action",
  "WIP: Replace Parts",
  "WIP: Salvage Parts",
  "WIP: Upgrade Device",
  "WIP: Replace and Upgrade",
  "WIP: Other",
  "WIP: No Action",
];

const steps = ["Root Cause & Corrective Action", "Parts"];

const EditDeviceReworkModal: React.FC<EditDeviceReworkModalProps> = ({
  onClose,
  onEdit,
  existingRework,
}) => {
  const [formData, setFormData] = useState({
    rootCauseOfRepair: existingRework.rootCauseOfRepair || [],
    correctiveAction: existingRework.correctiveAction || "",
    otherRootCause: "",
    parts: existingRework.parts || [],
  });
  const [availableParts, setAvailableParts] = useState<InventoryPart[]>([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [activeStep, setActiveStep] = useState(0);

  // List of part numbers to exclude
  const excludedPartNumbers = [
    "25190",
    "25261",
    "25266",
    "25334",
    "25360",
    "25104",
    "25070",
    "25032",
    "25063",
    "25178",
    "25180",
    "25172",
    "25360",
    "25159",
    "25182",
    "25201",
    "25205",
    "25158",
  ];

  useEffect(() => {
    fetchInventory();
  }, []);

  const fetchInventory = async () => {
    try {
      const response = await fetchInventoryParts();

      // Filter out parts with excluded part numbers
      const filteredParts = response.filter(
        (part: { number: string }) => !excludedPartNumbers.includes(part.number)
      );
      setAvailableParts(filteredParts);
    } catch (error) {
      console.error("Failed to fetch inventory parts:", error);
    }
  };

  const handleNext = () => {
    if (activeStep < steps.length - 1) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else {
      handleSubmit();
      onClose();
    }
  };

  const handleSubmit = async () => {
    // Prepare the rootCauseOfRepair array
    let rootCauseOfRepairArray = Array.isArray(formData.rootCauseOfRepair)
      ? [...formData.rootCauseOfRepair]
      : [formData.rootCauseOfRepair];

    // If "Other" is included and otherRootCause is a string, add it to the array
    if (
      rootCauseOfRepairArray.includes("Other") &&
      typeof formData.otherRootCause === "string" &&
      formData.otherRootCause.trim() !== ""
    ) {
      rootCauseOfRepairArray = rootCauseOfRepairArray.filter(
        (cause) => cause !== "Other"
      ); // Remove "Other" if it's already there
      rootCauseOfRepairArray.push(formData.otherRootCause);
    }

    const updates = {
      rootCauseOfRepair: rootCauseOfRepairArray, // Use the modified array
      correctiveAction: formData.correctiveAction,
      parts: formData.parts,
    };

    try {
      await updateRework(existingRework._id, updates);
      onEdit();
      onClose();
    } catch (error) {
      console.error("Failed to update device rework:", error);
    }
  };

  const handleSelectPart = (part: InventoryPart) => {
    setFormData((prevData) => ({
      ...prevData,
      parts: prevData.parts.some((selected) => selected._id === part._id)
        ? prevData.parts.filter((selected) => selected._id !== part._id)
        : [...prevData.parts, { ...part, quantity: 1 }],
    }));
  };

  const handleQuantityChange = (id: string, qty: number) => {
    setFormData((prevData) => ({
      ...prevData,
      parts: prevData.parts.map((part) =>
        part._id === id ? { ...part, quantity: qty } : part
      ),
    }));
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    setFormData((prevData) => ({
      ...prevData,
      correctiveAction: event.target.value as string,
    }));
  };

  const filteredParts = availableParts.filter((part) =>
    part.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <Modal
      open
      onClose={onClose}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          bgcolor: "background.paper",
          p: 3,
          borderRadius: 2,
          width: "800px",
          height: 600,
        }}
      >
        <Typography variant="h6" gutterBottom>
          Edit Device Rework
        </Typography>
        <Box sx={{ mt: 3 }}>
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          {activeStep === 0 && (
            <>
              <Autocomplete
                multiple
                options={rootCauseOptions}
                value={formData.rootCauseOfRepair}
                onChange={(event, newValue) => {
                  setFormData((prevData) => ({
                    ...prevData,
                    rootCauseOfRepair: newValue,
                  }));
                }}
                disableCloseOnSelect
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox checked={selected} style={{ marginRight: 8 }} />
                    <ListItemText primary={option} />
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Root Cause Of Repair/Scrap"
                    placeholder="Select root causes"
                    fullWidth
                    margin="normal"
                  />
                )}
              />
              {formData.rootCauseOfRepair.includes("Other") && (
                <TextField
                  label="Other Root Cause"
                  name="otherRootCause"
                  value={formData.otherRootCause}
                  onChange={handleInputChange}
                  fullWidth
                  margin="normal"
                />
              )}
              <FormControl fullWidth margin="normal">
                <InputLabel>Corrective Action</InputLabel>
                <Select
                  label="Corrective Action"
                  name="correctiveAction"
                  value={formData.correctiveAction}
                  onChange={handleSelectChange}
                >
                  <MenuItem value="None">None</MenuItem>
                  {correctiveActionOptions.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </>
          )}
        </Box>
        {activeStep === 1 && (
          <Box sx={{ mt: 3, display: "flex", gap: 2 }}>
            <Paper
              elevation={1}
              sx={{ flex: 1, p: 2, height: 400, overflow: "auto" }}
            >
              <Typography variant="h6">Available Parts</Typography>
              <Divider sx={{ my: 1 }} />
              <TextField
                label="Search Parts"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                fullWidth
                margin="normal"
              />
              <List>
                {filteredParts.map((part) => (
                  <ListItem key={part._id} disablePadding>
                    <Checkbox
                      checked={formData.parts.some(
                        (selected) => selected._id === part._id
                      )}
                      onChange={() => handleSelectPart(part)}
                    />
                    <ListItemText primary={`${part.name} - ${part.number}`} />
                  </ListItem>
                ))}
              </List>
            </Paper>

            <Paper
              elevation={1}
              sx={{ flex: 1, p: 2, height: 400, overflow: "auto" }}
            >
              <Typography variant="h6">Selected Parts</Typography>
              <Divider sx={{ my: 1 }} />
              <List>
                {formData.parts.map((part) => (
                  <ListItem key={part._id} disablePadding>
                    <ListItemText primary={`${part.name} - ${part.number}`} />
                    <TextField
                      type="number"
                      label="Qty"
                      size="small"
                      value={part.quantity}
                      onChange={(e) =>
                        handleQuantityChange(part._id, Number(e.target.value))
                      }
                      sx={{ width: 60, ml: 2, mt: 1 }}
                    />
                  </ListItem>
                ))}
              </List>
            </Paper>
          </Box>
        )}

        <Box
          sx={{ mt: 2, display: "flex", justifyContent: "flex-end", gap: 1 }}
        >
          <StyledButton variantType="primary" onClick={handleNext}>
            {activeStep === steps.length - 1 ? "Submit" : "Next"}
          </StyledButton>
          <StyledButton variantType="outline-red" onClick={onClose}>
            Cancel
          </StyledButton>
        </Box>
      </Box>
    </Modal>
  );
};

export default EditDeviceReworkModal;
