import React, { useState, ChangeEvent, useEffect } from "react";
import {
  Box,
  Button,
  Modal,
  Typography,
  Grid,
  InputAdornment,
  Paper,
  IconButton,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TextField,
  Autocomplete,
  ListItemIcon,
  styled,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DescriptionIcon from "@mui/icons-material/Description";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { StyledTextFieldNew } from "../Forms/StyledTextFieldNew";
import useAuthStore from "../../store/authStore";
import { DeviceData } from "../../common/types/DeviceTypes";
import DeveloperBoardIcon from "@mui/icons-material/DeveloperBoard";
import { submitWarrantyClaim } from "../../services/warranty.service";

const IconWrapper = styled(ListItemIcon)({
  minWidth: "40px",
  "& svg": {
    fill: "#9e9e9e",
    stroke: "black",
    strokeWidth: "1px",
  },
});

interface WarrantyModalProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (formData: WarrantyFormData) => void;
  onError: (message: string) => void;
  devices: DeviceData[];
  //data: any;
}

interface WarrantyFormData {
  deviceSerial: string;
  issueDescription: string;
  claimPicture?: File | null;
  returnReason: string;
}

const WarrantyModal: React.FC<WarrantyModalProps> = ({
  open,
  onClose,
  onSubmit,
  onError,
  devices,
}) => {
  const { user } = useAuthStore.getState();
  const initialFormData = {
    deviceSerial: "",
    issueDescription: "",
    claimPicture: null,
    returnReason: "",
  };
  const [formData, setFormData] = useState<WarrantyFormData>(initialFormData);
  const resetForm = () => {
    setFormData(initialFormData);
  };

  const handleChange = (
    event:
      | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | { target: { name: string; value: string } }
  ) => {
    const { name, value } = event.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleAutocompleteChange = (event: any, value: any) => {
    setFormData((prev) => ({
      ...prev,
      deviceSerial: value || "",
    }));
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      setFormData((prev) => ({
        ...prev,
        claimPicture: files[0],
      }));
    }
  };

  const handleSubmit = async () => {
    if (
      !formData.deviceSerial ||
      !formData.issueDescription ||
      !formData.returnReason
    ) {
      onError("Please fill all required fields.");
      return;
    }
    if (user) {
      submitWarrantyClaim(
        data.serialNo,
        data.detailedIssue,
        data.faultType,
        data.photo,
        data.submittedDate,
        data.status,
        data.distributorEmail || user?.email,
        data.customerEmail || "Unknown Customer Email"
      );
      onSubmit(formData);
      resetForm();
      onClose();
    } else {
      onError("User information is missing.");
    }
  };

  const data = {
    serialNo: formData.deviceSerial,
    detailedIssue: formData.issueDescription,
    faultType: formData.returnReason,
    photo: formData.claimPicture ? formData.claimPicture.name : "", // use the file name/path as a string for now
    submittedDate: new Date().toISOString(), // Ensure the date is in ISO format
    status: "Initial review",
    distributorEmail: user?.email,
    customerEmail: devices.find(
      (device) => device.deviceInformation.deviceId === formData.deviceSerial
    )?.deviceInformation.customerEmail,
  };

  return (
    <Modal
      open={open}
      onClose={() => {
        onClose();
        resetForm();
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: { xs: "80%", sm: "70%", md: "40%" },
          maxHeight: "90vh",
          overflowY: "auto",
          bgcolor: "#f5f5f5",
          p: { xs: 2, sm: 3, md: 3 },
          fontFamily: "'Poppins', sans-serif",
          borderRadius: 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 1,
          }}
        >
          <Typography
            variant="h6"
            sx={{ fontWeight: 500, color: "grey.800", textAlign: "center" }}
          >
            Submit Warranty Claim
          </Typography>
          <IconButton onClick={onClose} color="inherit">
            <CloseIcon sx={{ color: "primary.main" }} />
          </IconButton>
        </Box>

        <Paper
          elevation={1}
          sx={{
            marginBottom: 2,
            backgroundColor: "#fff",
            p: 1,
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                options={devices.map(
                  (device) => device.deviceInformation.deviceId
                )}
                renderInput={(params) => (
                  <StyledTextFieldNew
                    {...params}
                    label="Device Serial"
                    margin="normal"
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <InputAdornment position="start">
                          <IconWrapper>
                            {" "}
                            <DeveloperBoardIcon />
                          </IconWrapper>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
                onChange={handleAutocompleteChange}
                value={formData.deviceSerial}
                noOptionsText="Device not found"
                blurOnSelect
                clearOnEscape
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth margin="normal">
                <InputLabel id="return-reason-label">Return Reason</InputLabel>
                <Select
                  labelId="return-reason-label"
                  label="Return Reason"
                  name="returnReason"
                  value={formData.returnReason}
                  onChange={handleChange}
                >
                  <MenuItem value="" disabled>
                    Select return reason
                  </MenuItem>
                  <MenuItem value="Defective">Defective</MenuItem>
                  <MenuItem value="Received wrong item">
                    Received wrong item
                  </MenuItem>
                  <MenuItem value="Damaged shipment">Damaged shipment</MenuItem>
                  <MenuItem value="Lost shipment">Lost shipment</MenuItem>
                  <MenuItem value="Missing item">Missing item</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth margin="normal">
                <TextField
                  id="claim-picture-upload"
                  type="file"
                  name="claimPicture"
                  label="Upload Picture"
                  onChange={handleFileChange}
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconWrapper>
                          {" "}
                          <CloudUploadIcon />
                        </IconWrapper>
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <StyledTextFieldNew
                label="Issue Description"
                name="issueDescription"
                onChange={handleChange}
                value={formData.issueDescription}
                required
                fullWidth
                margin="normal"
                multiline
                // rows={4}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconWrapper>
                        <DescriptionIcon />
                      </IconWrapper>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </Paper>

        <Button
          onClick={handleSubmit}
          variant="contained"
          sx={{
            mt: 2,
            fontFamily: "'Poppins', sans-serif",
            fontWeight: 600,
            fontSize: "12px",
            bgcolor: "rgba(30, 58, 138, 0.9)",
            mr: 1,
          }}
        >
          Submit
        </Button>
        <Button
          onClick={() => {
            onClose();
            resetForm();
          }}
          variant="contained"
          sx={{
            mt: 2,
            fontFamily: "'Poppins', sans-serif",
            fontWeight: 500,
            fontSize: "12px",
            textTransform: "none",
            bgcolor: "error.main",
            minWidth: "auto",
            "&:hover": {
              bgcolor: "rgba(211, 47, 47, 0.8)",
            },
          }}
        >
          <CloseIcon />
        </Button>
      </Box>
    </Modal>
  );
};

export default WarrantyModal;
