import React, { useState, useMemo, useEffect, useRef } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Checkbox,
  useMediaQuery,
  Chip,
  Box,
  Typography,
  Tooltip,
  TablePagination,
  useTheme,
  SelectChangeEvent,
  MenuItem,
  Select,
  FormControl,
  Autocomplete,
  TextField,
  IconButton,
  Collapse,
  CircularProgress,
} from "@mui/material";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import { keyframes, margin } from "@mui/system";
import { ICleanDevice } from "../../../common/types/DeviceTypes";
import dayjs from "dayjs";
import useDeviceStore from "../../../store/useDeviceStore";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import useAuthStore from "../../../store/authStore";
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

interface IcleanDeviceTableProps {
  devices: ICleanDevice[];
  dateFilter: Date | null;
  intervalFilter: "daily" | "weekly" | "monthly";
  isGallons?: boolean;
  setIsGallons?: () => void;
}

const blink = keyframes`
  from {
    opacity: 1;
    transform: scale(1); 
  }
  to {
    opacity: 0.7;
    transform: scale(1.1); 
  }
`;

interface CartridgeIndicatorProps {
  cyclesRemaining: number;
  currentCartridgeType?:
  | "SAO-24 FB"
  | "SAO-24 NON-FB"
  | "SAO-04 FB"
  | "SAO-4"
  | "SAO-24"
  | "AO/Desiccant"
  | "NONE"
  | "SAO-04 NON-FB";
  isGallons: boolean;
}

const CartridgeIndicator: React.FC<CartridgeIndicatorProps> = ({
  cyclesRemaining,
  currentCartridgeType,
  isGallons,
}) => {
  const theme = useTheme();

  const convertToGallons = (liters: number) => {
    return liters * 0.264172;
  };

  const totalCycles = (() => {
    switch (currentCartridgeType) {
      case "SAO-24 FB":
      case "SAO-24":
      case "SAO-24 NON-FB":
        return 550;
      case "SAO-04 FB":
      case "SAO-4":
      case "AO/Desiccant":
      case "SAO-04 NON-FB":
        return 920;
      default:
        return 920;
    }
  })();

  const percentage = (cyclesRemaining / totalCycles) * 100;

  let status = "NO DATA";
  let backgroundColor = theme.palette.error.main;
  let textColor = "white";
  let animation: string | undefined = undefined;

  if (percentage > 70) {
    status = "HIGH";
    backgroundColor = theme.palette.success.main;
  } else if (percentage <= 70 && percentage > 30) {
    status = "OKAY";
    backgroundColor = theme.palette.warning.light;
    textColor = "black";
  } else if (percentage <= 30 && percentage > 0) {
    status = "LOW";
    backgroundColor = theme.palette.error.main;
    textColor = "black";
    animation = `${blink} 1s linear infinite`;
  } else if (percentage === 0) {
    status = "EMPTY";
    backgroundColor = theme.palette.error.main;
  }

  const getTooltipLabel = () => (
    <Typography
      sx={{
        fontSize: "1rem",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        p: 1,
        gap: 0.1,
      }}
    >
      <strong>Status:</strong> {status}
      <br />
      <strong>Cycles left:</strong> {cyclesRemaining}
      <br />
      <strong>{isGallons ? "Gallons" : "Liters"} left:</strong>{" "}
      {isGallons
        ? convertToGallons(cyclesRemaining * 5.5).toFixed(2)
        : (cyclesRemaining * 5.5).toFixed(2)}
      <br />
      <strong>Cartridge:</strong> {currentCartridgeType}
    </Typography>
  );

  return (
    <Tooltip title={getTooltipLabel()}>
      <Box sx={{ display: "flex", justifyContent: "center", alignItems: 'center', gap: 1 }}>
        <Box
          sx={{
            width: 50,
            height: 10,
            backgroundColor:
              percentage === 0
                ? theme.palette.error.main
                : theme.palette.grey[300],
            borderRadius: 2,
          }}
        >
          <Box
            sx={{
              width: `${percentage}%`,
              height: "100%",
              backgroundColor,
              borderRadius: 2,
              animation,
              transition: "width 0.5s ease-in-out",
            }}
          />
        </Box>
        <Typography
          variant="caption"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "0.8rem",
            fontWeight: 600,
          }}
        >
          {`${percentage.toFixed(0)}%`}
        </Typography>
      </Box>
    </Tooltip>
  );
};

const IcleanDeviceTable: React.FC<IcleanDeviceTableProps> = ({
  devices,
  dateFilter,
  intervalFilter,
  isGallons,
}) => {
  const { user } = useAuthStore();
  const { setSelectedIcleanDevices } = useDeviceStore();
  const [selected, setSelected] = useState<ICleanDevice[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(11);
  const [countryFilter, setCountryFilter] = useState<string | null>(null);
  const [statusFilter, setStatusFilter] = useState<string | null>(null);
  const [cartridgeStatFilter, setCartridgeStatFilter] = useState<string | null>(
    null
  );
  const [usageFilter, setUsageFilter] = useState<string | null>(null);
  const [locationFilter, setLocationFilter] = useState<string | null>(null);
  const [deviceSearch, setDeviceSearch] = useState<string | null>(null);
  const [inputValue, setInputValue] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const [showDeviceSearch, setShowDeviceSearch] = useState<boolean>(false);
  const searchBarRef = useRef<HTMLDivElement | null>(null); // Ref for the search bar

  // Extract unique countries from devices
  const availableCountries = Array.from(
    new Set(
      devices.map((device) => device.deviceLocation?.country).filter(Boolean)
    )
  );

  const [viewMode, setViewMode] = useState<"table" | "card">("table");

  // Status options
  const statusOptions = ["ON", "OFF", "N/A"];
  // cartridge options
  const cartridgeStatusOptions = ["HIGH", "OKAY", "LOW"];

  useEffect(() => {
    setSelected(devices);
    setSelectedIcleanDevices(selected);
  }, [devices, setSelectedIcleanDevices]);

  // Close the search bar if clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        searchBarRef.current &&
        !searchBarRef.current.contains(event.target as Node)
      ) {
        handleToggleDeviceSearch(false); // Close the search bar
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleSelect = (device: ICleanDevice) => {
    const selectedIndex = selected.findIndex(
      (d) => d.deviceInformation.deviceId === device.deviceInformation.deviceId
    );
    let newSelected: ICleanDevice[] = [];

    if (selectedIndex === -1) {
      newSelected = [...selected, device];
    } else {
      newSelected = selected.filter(
        (d) =>
          d.deviceInformation.deviceId !== device.deviceInformation.deviceId
      );
    }

    setSelected(newSelected);
    setSelectedIcleanDevices(newSelected);
  };

  const getUsageDetails = (device: ICleanDevice): React.ReactNode => {
    if (device.deviceUsage.length === 0) return "No usage data available.";

    const filteredUsage = device.deviceUsage.filter((usage) => {
      const usageDate = dayjs(usage.uploadTimestamp);
      if (!dateFilter) return false;

      if (intervalFilter === "daily") {
        return usageDate.isSame(dayjs(dateFilter), "day");
      } else if (intervalFilter === "weekly") {
        const startOfWeek = dayjs(dateFilter).startOf("week");
        const endOfWeek = dayjs(dateFilter).endOf("week");
        return (
          usageDate.isSameOrAfter(startOfWeek) &&
          usageDate.isSameOrBefore(endOfWeek)
        );
      } else if (intervalFilter === "monthly") {
        const startOfMonth = dayjs(dateFilter).startOf("month");
        const endOfMonth = dayjs(dateFilter).endOf("month");
        return (
          usageDate.isSameOrAfter(startOfMonth) &&
          usageDate.isSameOrBefore(endOfMonth)
        );
      }
      return false;
    });

    const latestUsage = filteredUsage[filteredUsage.length - 1];
    if (!latestUsage) return "No usage data available on the selected date.";

    return (
      <>
        <div>
          {"Liters Dispensed: " +
            (latestUsage.litersDispensed?.toFixed(2) || 0) +
            " L"}
        </div>
        <div>{"Seconds Run: " + latestUsage.secsRun + " sec"}</div>
        <div>
          {"Timestamp: " +
            dayjs(latestUsage.uploadTimestamp).format("DD/MM/YYYY HH:mm:ss")}
        </div>
      </>
    );
  };

  const calculateLitresUsed = (device: ICleanDevice) => {
    if (!device.deviceUsage.length) return 0;
    return device.deviceUsage.reduce((acc, usage) => {
      const usageDate = dayjs(usage.uploadTimestamp);
      if (intervalFilter === "daily" && dateFilter) {
        if (usageDate.isSame(dayjs(dateFilter), "day")) {
          return acc + usage.litersDispensed;
        }
      } else if (intervalFilter === "weekly" && dateFilter) {
        const startOfWeek = dayjs(dateFilter).startOf("week");
        const endOfWeek = dayjs(dateFilter).endOf("week");
        if (
          usageDate.isSameOrAfter(startOfWeek) &&
          usageDate.isSameOrBefore(endOfWeek)
        ) {
          return acc + usage.litersDispensed;
        }
      } else if (intervalFilter === "monthly" && dateFilter) {
        const startOfMonth = dayjs(dateFilter).startOf("month");
        const endOfMonth = dayjs(dateFilter).endOf("month");
        if (
          usageDate.isSameOrAfter(startOfMonth) &&
          usageDate.isSameOrBefore(endOfMonth)
        ) {
          return acc + usage.litersDispensed;
        }
      }
      return acc;
    }, 0);
  };

  const calculateCartridgeLifePercentage = (
    cyclesRemaining: number,
    currentCartridgeType?:
      | "SAO-24 FB"
      | "SAO-24 NON-FB"
      | "SAO-04 FB"
      | "SAO-4"
      | "SAO-24"
      | "AO/Desiccant"
      | "NONE"
      | "SAO-04 NON-FB"
  ): number => {
    const totalCycles = (() => {
      switch (currentCartridgeType) {
        case "SAO-24 FB":
        case "SAO-24 NON-FB":
        case "SAO-24":
          return 550;
        case "SAO-04 FB":
        case "SAO-04 NON-FB":
        case "SAO-4":
        case "AO/Desiccant":
          return 920;
        default:
          return 920;
      }
    })();

    return (cyclesRemaining / totalCycles) * 100;
  };
  const getCartridgeStatus = (percentage: number): "HIGH" | "OKAY" | "LOW" => {
    if (percentage > 70) {
      return "HIGH";
    } else if (percentage <= 70 && percentage > 30) {
      return "OKAY";
    } else {
      return "LOW";
    }
  };

  const headerLabel = useMemo(() => {
    if (intervalFilter === "daily" && dateFilter) {
      return (
        <>
          {isGallons ? "Gallons" : "Liters"} <br />
        </>
      );
    } else if (intervalFilter === "weekly") {
      return (
        <>
          {isGallons ? "Gallons" : "Liters"} <br />
        </>
      );
    } else if (intervalFilter === "monthly") {
      return (
        <>
          {isGallons ? "Gallons" : "Liters"} <br />
        </>
      );
    }
    return "Liters";
  }, [intervalFilter, dateFilter, isGallons]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = devices.map((n) => n);
      setSelected(newSelected);
      setSelectedIcleanDevices(newSelected);
    } else {
      setSelected([]);
      setSelectedIcleanDevices([]);
    }
  };

  const handleCountryFilterChange = (event: SelectChangeEvent<string>) => {
    setCountryFilter(event.target.value === "All" ? null : event.target.value);
  };

  const handleStatusFilterChange = (event: SelectChangeEvent<string>) => {
    setStatusFilter(event.target.value === "All" ? null : event.target.value);
  };

  const handleCartridgeStatFilterChange = (
    event: SelectChangeEvent<string>
  ) => {
    setCartridgeStatFilter(
      event.target.value === "All" ? null : event.target.value
    );
  };

  const handleUsageFilterChange = (event: SelectChangeEvent<string>) => {
    setUsageFilter(event.target.value === "All" ? null : event.target.value);
  };

  const handleLocationFilterChange = (event: SelectChangeEvent<string>) => {
    setLocationFilter(event.target.value === "All" ? null : event.target.value);
  };

  // Handle input changes and set loading state
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setInputValue(value);

    if (value === "") {
      setDeviceSearch(value);
      setLoading(false);
      return;
    }
    setLoading(true);

    // Simulate a delay to mimic loading and then set the search term
    setTimeout(() => {
      setDeviceSearch(value);
      setLoading(false);
    }, 500); // Adjust the delay as needed
  };

  const handleToggleDeviceSearch = (show: boolean) => {
    setShowDeviceSearch(show); // Set the visibility explicitly
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const availableLocations = Array.from(
    new Set(
      devices
        .map((device) =>
          device.deviceLocation?.city && device.deviceLocation?.country
            ? `${device.deviceLocation.city}, ${device.deviceLocation.country}`
            : device.deviceLocation?.country || device.deviceLocation?.city
        )
        .filter(Boolean)
    )
  );

  // Move this function to the top of the component
  const getStatusLabel = (status: string): string => {
    if (!status) return "N/A";
    switch (status.toUpperCase()) {
      case "ONLINE":
        return "ON";
      case "OFFLINE":
        return "OFF";
      default:
        return "N/A";
    }
  };

  const filteredDevices = useMemo(() => {
    return devices.filter((device) => {
      const litersUsed = calculateLitresUsed(device);
      const percentage = calculateCartridgeLifePercentage(
        device.deviceUsage.length > 0
          ? device.deviceUsage[device.deviceUsage.length - 1]
            ?.cyclesRemaining || 0
          : 0,
        device.deviceInformation.currentCartridgeType
      );

      const cartridgeStatus = getCartridgeStatus(percentage);
      const location =
        device.deviceLocation?.city && device.deviceLocation?.country
          ? `${device.deviceLocation.city}, ${device.deviceLocation.country}`
          : device.deviceLocation?.country ||
          device.deviceLocation?.city ||
          "N/A";

      // Make search case-insensitive
      const deviceName = device.deviceInformation.deviceName.toLowerCase();
      const searchQuery = deviceSearch?.toLowerCase() || "";

      return (
        // Include real-time search filter
        (!deviceSearch || deviceName.includes(searchQuery)) &&
        (!countryFilter || device.deviceLocation?.country === countryFilter) &&
        (!statusFilter ||
          getStatusLabel(device.pingStatus.status) === statusFilter) &&
        (!cartridgeStatFilter || cartridgeStatus === cartridgeStatFilter) &&
        (!usageFilter || (usageFilter === "No Usage" && litersUsed === 0)) &&
        (!locationFilter || location === locationFilter)
      );
    });
  }, [
    devices,
    deviceSearch,
    countryFilter,
    statusFilter,
    cartridgeStatFilter,
    usageFilter,
    locationFilter,
  ]);

  useEffect(() => {
    setSelectedIcleanDevices(filteredDevices);
  }, [filteredDevices, setSelectedIcleanDevices]);

  const commonFormControlStyle = {
    width: "100%",
    "& .MuiInputBase-root": {
      fontSize: "inherit",
      fontWeight: "inherit",
      "&:before": { borderBottom: "none" },
      "&:after": { borderBottom: "none" },
      "&:hover:not(.Mui-disabled):before": { borderBottom: "none" },
    },
    "& .MuiSelect-select": {
      paddingLeft: "0",
      // paddingRight: "24px", // Make room for the arrow
    },
    "& .MuiSelect-icon": {
      right: "0",
    },
  };

  return (
    <Box
      sx={{
        borderRadius: "8px",
        border: "1px solid #d1d1d1",
        backgroundColor: "background.paper",
        color: "text.primary",
        boxShadow: "0 1px 2px 0 rgb(0 0 0 / 0.05)",
      }}
    >
      {viewMode === "table" && (
        <Box sx={{ minHeight: 690, overflowX: "auto" }}>
          <Table
            sx={{
              overflowX: "auto",
              "& .MuiTableCell-root": {
                padding: "8px",
                textAlign: "left", // Left-align all cells
              },
              "& .MuiTableCell-head": {
                fontWeight: 600,
                fontSize: "0.875rem", // 14px
              },
            }}
            aria-label="customized table"
          >
            <TableHead>
              <TableRow sx={{ backgroundColor: "#f1f1f1" }}>
                <TableCell padding="checkbox">
                  <Checkbox
                    color="primary"
                    indeterminate={
                      selected.length > 0 && selected.length < devices.length
                    }
                    checked={
                      devices.length > 0 && selected.length === devices.length
                    }
                    onChange={handleSelectAllClick}
                  />
                </TableCell>
                <TableCell sx={{ position: "relative", padding: "8px" }}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography variant="body2" sx={{ fontWeight: 600 }}>
                      Device Name
                    </Typography>
                    <IconButton
                      size="small"
                      onClick={() => handleToggleDeviceSearch(true)}
                      sx={{
                        marginLeft: 1,
                        transition: "transform 0.2s",
                        "&:hover": {
                          transform: "scale(1.1)",
                          backgroundColor: "transparent",
                        },
                      }}
                    >
                      <ManageSearchIcon />
                    </IconButton>
                  </Box>

                  <Collapse
                    in={showDeviceSearch}
                    ref={searchBarRef}
                    sx={{
                      position: "absolute",
                      left: -10,
                      right: 10,
                      zIndex: 10,
                      backgroundColor: "white",
                      boxShadow: 2,
                      borderRadius: 1,
                      p: 1,
                      "& .MuiInputBase-root": {
                        margin: "0 !important",
                      },
                      "& .MuiFormControl-root": {
                        margin: "0 !important",
                      },
                    }}
                  >
                    <Autocomplete
                      freeSolo
                      options={devices.map(
                        (device) => device.deviceInformation.deviceName
                      )}
                      inputValue={inputValue || ""}
                      onInputChange={(event, value) =>
                        handleInputChange({
                          target: { value },
                        } as React.ChangeEvent<HTMLInputElement>)
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={!inputValue ? "Search Device" : ""} // Hide label when typing
                          size="small"
                          variant="standard"
                          fullWidth
                          onChange={handleInputChange}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                {loading ? (
                                  <CircularProgress size={20} />
                                ) : null}{" "}
                                {/* Spinner */}
                                {params.InputProps.endAdornment}
                              </>
                            ),
                          }}
                          sx={{
                            "& .MuiInput-underline:before": {
                              borderBottomColor: "primary.main",
                            },
                            "& .MuiInput-underline:hover:not(.Mui-disabled):before":
                            {
                              borderBottomColor: "primary.dark",
                            },
                            "& .MuiInputLabel-root": {
                              transform: "translate(0, 1.5px) scale(0.75)",
                              transformOrigin: "top left",
                            },
                          }}
                        />
                      )}
                      popupIcon={null}
                      clearOnBlur={false}
                    />
                  </Collapse>
                </TableCell>
                {!isMobile && (
                  <TableCell>
                    <FormControl variant="standard" sx={commonFormControlStyle}>
                      <Select
                        value={countryFilter || "All"}
                        onChange={handleCountryFilterChange}
                        displayEmpty
                      >
                        <MenuItem value="All">Location</MenuItem>
                        {availableCountries.map((country) => (
                          <MenuItem key={country} value={country}>
                            {country}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </TableCell>
                )}
                <TableCell>
                  <FormControl variant="standard" sx={commonFormControlStyle}>
                    <Select
                      value={usageFilter || "All"}
                      onChange={handleUsageFilterChange}
                      displayEmpty
                    >
                      <MenuItem value="All">{headerLabel}</MenuItem>
                      <MenuItem value="No Usage">No Usage</MenuItem>
                    </Select>
                  </FormControl>
                </TableCell>
                <TableCell>
                  <FormControl variant="standard" sx={commonFormControlStyle}>
                    <Select
                      value={cartridgeStatFilter || "All"}
                      onChange={handleCartridgeStatFilterChange}
                      displayEmpty
                    >
                      <MenuItem value="All">Cartridge</MenuItem>
                      {cartridgeStatusOptions.map((status) => (
                        <MenuItem key={status} value={status}>
                          {status}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </TableCell>
                <TableCell>
                  <FormControl variant="standard" sx={commonFormControlStyle}>
                    <Select
                      value={statusFilter || "All"}
                      onChange={handleStatusFilterChange}
                      displayEmpty
                    >
                      <MenuItem value="All">Status</MenuItem>
                      {statusOptions.map((status) => (
                        <MenuItem key={status} value={status}>
                          {status}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredDevices
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((device) => {
                  const isItemSelected = selected.includes(device);
                  const statusLabel = getStatusLabel(device.pingStatus.status);
                  return (
                    <TableRow
                      hover
                      key={device.deviceInformation.deviceId}
                      selected={isItemSelected}
                      onClick={() => handleSelect(device)}
                      sx={{ cursor: "pointer" }}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox checked={isItemSelected} />
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Box display="flex" alignItems="center" gap={0}>
                          <Box>
                            <Tooltip
                              title={
                                <Typography
                                  sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    p: 1,
                                    gap: 0.1,
                                  }}
                                >
                                  Serial No: {device.deviceInformation.serial}
                                  {device.deviceLocation?.country && (
                                    <>
                                      <strong>Location:</strong>{" "}
                                      {device.deviceLocation.country}
                                      <br />
                                    </>
                                  )}
                                  {device.deviceLocation?.city && (
                                    <>
                                      {device.deviceLocation.city}
                                      <br />
                                    </>
                                  )}
                                  {device.deviceLocation?.address && (
                                    <>
                                      {device.deviceLocation.address}
                                      <br />
                                    </>
                                  )}
                                  {device.deviceLocation?.postalCode && (
                                    <>
                                      {device.deviceLocation.postalCode}
                                      <br />
                                    </>
                                  )}
                                  {device.deviceLocation?.additionalDetails && (
                                    <>
                                      {device.deviceLocation.additionalDetails}
                                      <br />
                                    </>
                                  )}
                                  {device.deviceLocation?.room && (
                                    <>
                                      {device.deviceLocation.room}
                                      <br />
                                    </>
                                  )}
                                  {device.deviceLocation?.door && (
                                    <>
                                      {device.deviceLocation.door}
                                      <br />
                                    </>
                                  )}
                                  {device.deviceLocation?.block && (
                                    <>
                                      {device.deviceLocation.block}
                                      <br />
                                    </>
                                  )}
                                </Typography>
                              }
                            >
                              <Typography sx={{ fontSize: "inherit" }}>
                                {device.deviceInformation.deviceName}
                              </Typography>
                            </Tooltip>
                          </Box>
                        </Box>
                      </TableCell>
                      {!isMobile && (
                        <TableCell>
                          <Box>
                            <Typography sx={{ fontSize: "inherit" }}>
                              {device.deviceLocation?.country +
                                ", " +
                                device.deviceLocation?.city ||
                                "Unknown Address"}
                            </Typography>
                            <Typography variant="caption" color="textSecondary">
                              {device.deviceLocation?.address ||
                                "Unknown Address"}
                            </Typography>
                          </Box>
                        </TableCell>
                      )}
                      <TableCell>
                        <Tooltip
                          title={
                            <Typography sx={{}}>
                              {getUsageDetails(device)}
                            </Typography>
                          }
                        >
                          <span>
                            {isGallons
                              ? (calculateLitresUsed(device) / 3.78541).toFixed(
                                2
                              )
                              : calculateLitresUsed(device).toFixed(2)}{" "}
                            {isGallons ? "gal" : "L"}
                          </span>
                        </Tooltip>
                      </TableCell>
                      <TableCell>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-start",
                            height: "100%",
                          }}
                        >
                          <CartridgeIndicator
                            cyclesRemaining={
                              device.deviceUsage.length > 0
                                ? device.deviceUsage[
                                  device.deviceUsage.length - 1
                                ]?.cyclesRemaining || 0
                                : 0
                            }
                            currentCartridgeType={
                              device.deviceInformation.currentCartridgeType
                            }
                            isGallons={isGallons || false}
                          />
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Tooltip
                          title={
                            <Typography>
                              Last Ping:{" "}
                              {dayjs(device.pingStatus.timestamp).format(
                                "DD/MM/YYYY HH:mm:ss"
                              )}
                            </Typography>
                          }
                        >
                          <Chip
                            label={statusLabel}
                            size="small"
                            sx={{
                              color: "white",
                              backgroundColor: (theme) => {
                                switch (statusLabel) {
                                  case "ON":
                                    return theme.palette.success.main;
                                  case "OFF":
                                    return theme.palette.error.main;
                                  default:
                                    return theme.palette.grey[500];
                                }
                              },
                              "& .MuiChip-label": {
                                padding: "0 8px",
                              },
                            }}
                          />
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </Box>
      )}

      <TablePagination
        rowsPerPageOptions={[10]}
        component="div"
        count={filteredDevices.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{
          mr: 1,
        }}
      />
    </Box>
  );
};

export default IcleanDeviceTable;
