import React from "react";
import RegisterDeviceModal from "../Device/RegisterDeviceModal";
import { Box } from "@mui/material";

interface Props {
  onSuccess: (createdDevice: any) => void;
  onClose: () => void;
}

const SetupNewDevice: React.FC<Props> = ({ onSuccess, onClose }) => {
  const handleSuccess = (createdDevice: any) => {
    onSuccess(createdDevice);
    onClose();
  };
  return (
    <Box>
      <RegisterDeviceModal
        open={true}
        onClose={onClose}
        onSuccess={handleSuccess}
        onError={(message: any) => onClose()} // On error, call onClose
      />
    </Box>
  );
};

export default SetupNewDevice;
