import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  Chip,
  Snackbar,
  Tooltip,
  TablePagination,
  TextField,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Alert,
  AlertColor,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import {
  Search as SearchIcon,
  Delete as DeleteIcon,
  Add,
} from "@mui/icons-material";
import AddRemoveDeviceModalDistributor from "./AddRemoveDeviceModalDistributor";
import useDistributorStore from "../../store/useDistributor";
import useAuthStore from "../../store/authStore";
import {
  fetchSubDistributors,
  deleteSubDistributor,
} from "../../services/distributor.service";
import CreateSubDistributorModal from "./CreateSubDistributorModal";
import StyledButton from "../Tabs/StyledButton";
import axios from "axios";
import StyledIconButton from "../Tabs/StyledIconButton";

const DistributorTableV2: React.FC = () => {
  const { Distributors, fetchDistributors } = useDistributorStore();
  const { user } = useAuthStore();
  const [subDistributors, setSubDistributors] = useState<any[]>([]);
  const [selected, setSelected] = useState<string[]>([]);
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search, setSearch] = useState("");
  const [filterField, setFilterField] = useState<string>("");
  const [filterValue, setFilterValue] = useState<string>("");
  const [sortField, setSortField] = useState<string>("");
  const [modalOpen, setModalOpen] = useState(false);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [snackbar, setSnackbar] = useState<{
    open: boolean;
    message: string;
    severity: AlertColor | undefined;
  }>({ open: false, message: "", severity: undefined });

  // Fetch distributors on component mount
  useEffect(() => {
    fetchDistributors();
  }, [fetchDistributors]);

  // Fetch sub-distributors when Distributors or user role changes
  const fetchSubDistributorsData = useCallback(async () => {
    if (!user?._id) return;

    try {
      const subDistributorsData = await fetchSubDistributors(
        user.email,
        user.role
      );
      setSubDistributors(subDistributorsData);
    } catch (error) {
      console.error(error);
    }
  }, [user]);

  useEffect(() => {
    fetchSubDistributorsData();
  }, [fetchSubDistributorsData]);

  const handleOpenModal = () => setModalOpen(true);
  const handleCloseModal = () => setModalOpen(false);

  const handleDistributorCreationSuccess = () => {
    setSnackbar({
      open: true,
      message: "Distributor successfully created.",
      severity: "success",
    });
    fetchSubDistributorsData(); // Refresh the list after creation
    handleCloseModal(); // Close the modal after success
  };

  const handleError = (message: string) => {
    setSnackbar({ open: true, message, severity: "error" });
  };

  const handleSnackbarChange = (snackbarData: {
    open: boolean;
    message: string;
    severity: "success" | "error";
  }) => {
    setSnackbar(snackbarData);
  };

  const handleSelect = (name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = [...selected, name];
    } else {
      newSelected = selected.filter((id) => id !== name);
    }

    setSelected(newSelected);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = subDistributors.map((n) => n._id);
      setSelected(newSelecteds);
      setSelectAll(true);
      return;
    }
    setSelected([]);
    setSelectAll(false);
  };

  const isSelected = (name: string) => selected.includes(name);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDeleteSubDistributor = async () => {
    try {
      // call API to delete selected distributors
      await deleteSubDistributor(selected[0]);
      setSnackbar({
        open: true,
        message: "Sub Distributor deleted successfully.",
        severity: "success",
      });
      setSelected([]);
      fetchSubDistributorsData();
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        setSnackbar({
          open: true,
          message: error.response.data.message,
          severity: "error",
        });
      }
    }
  };

  const [addRemoveModalOpen, setAddRemoveModalOpen] = useState(false);

  const handleAddRemoveDevices = (selectedDevices: string[]) => {
    console.log("Selected Devices:", selectedDevices);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const handleFilterValueChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFilterValue(event.target.value);
  };

  const filteredDistributors = subDistributors.filter((distributor) => {
    if (filterField && filterValue) {
      if (filterField === "status") {
        return distributor.isActive.toString().includes(filterValue);
      }
      return (distributor as any)[filterField]
        .toLowerCase()
        .includes(filterValue.toLowerCase());
    }
    return (
      distributor.firstName.toLowerCase().includes(search.toLowerCase()) ||
      distributor.lastName.toLowerCase().includes(search.toLowerCase()) ||
      distributor.email.toLowerCase().includes(search.toLowerCase())
    );
  });

  const sortedDistributors = filteredDistributors.sort((a, b) => {
    if (sortField === "createdAt") {
      return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
    }
    if (sortField === "firstName") {
      return a.firstName.localeCompare(b.firstName);
    }
    if (sortField === "lastName") {
      return a.lastName.localeCompare(b.lastName);
    }
    return 0;
  });

  const handleOpenDeleteModal = () => setDeleteModalOpen(true);
  const handleCloseDeleteModal = () => setDeleteModalOpen(false);

  const columns = [
    { id: "createdAt", label: "Created Time" },
    { id: "firstName", label: "First Name" },
    { id: "lastName", label: "Last Name" },
    { id: "country", label: "Country" },
    { id: "state", label: "State" },
    { id: "city", label: "City" },
    { id: "address", label: "Address" },
    { id: "phone", label: "Phone" },
    { id: "email", label: "Email" },
    { id: "status", label: "Status" },
  ];

  return (
    <Box p={0}>
      <Box mt={0} sx={{ display: "flex", justifyContent: "center" }}>
        <Box
          mt={2}
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            width: { xs: "95%", md: "96%" },
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              p: 0,
              mb: 1,
            }}
          >
            <Typography sx={{ ml: 1 }} variant="h6">
              Manage Your Sub-Distritbutors
            </Typography>
            <StyledButton
              variantType="primary"
              icon={<Add />}
              onClick={handleOpenModal}
            >
              Add Sub-Distributor
            </StyledButton>
          </Box>
          <TableContainer
            component={Box}
            sx={{
              mx: "auto",
              width: "100%",
              maxWidth: "calc(100% - 20px)",
              p: 1,
              my: 1,
              backgroundColor: "white",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                p: 1.5,
                backgroundColor: "#f5f5f5",
                mb: 1,
              }}
            >
              <CreateSubDistributorModal
                open={modalOpen}
                onClose={handleCloseModal}
                onSuccess={handleDistributorCreationSuccess}
                onError={handleError}
                onSnackbarChange={handleSnackbarChange}
              />
              <Snackbar
                open={snackbar.open}
                autoHideDuration={8000}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                onClose={() => setSnackbar({ ...snackbar, open: false })}
              >
                <Alert severity={snackbar.severity} sx={{ width: "100%" }}>
                  {snackbar.message}
                </Alert>
              </Snackbar>
              <AddRemoveDeviceModalDistributor
                open={addRemoveModalOpen}
                onClose={() => setAddRemoveModalOpen(false)}
                devices={[]} // Replace with the actual devices data for distributors
                onSave={handleAddRemoveDevices}
                distributorId={"any"}
              />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <TextField
                  variant="outlined"
                  size="small"
                  placeholder="Search"
                  value={search}
                  onChange={handleSearchChange}
                  sx={{ marginRight: 2, width: "300px" }}
                  InputProps={{
                    startAdornment: (
                      <SearchIcon sx={{ marginRight: 1, color: "gray" }} />
                    ),
                  }}
                />
                <FormControl
                  variant="outlined"
                  size="small"
                  sx={{ marginRight: 2, width: "150px" }}
                >
                  <InputLabel>Sort By</InputLabel>
                  <Select
                    value={sortField}
                    onChange={(e) => setSortField(e.target.value as string)}
                    label="Sort By"
                  >
                    <MenuItem value="">None</MenuItem>
                    {columns.map((column) => (
                      <MenuItem key={column.id} value={column.id}>
                        {column.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {filterField && (
                  <TextField
                    variant="outlined"
                    size="small"
                    placeholder={`Filter ${filterField}`}
                    value={filterValue}
                    onChange={handleFilterValueChange}
                    sx={{ marginRight: 2, width: "300px" }}
                  />
                )}
              </Box>
            </Box>

            {selected.length > 0 && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  alignContent: "center",
                  padding: 1,
                  backgroundColor: "#f5f5f5",
                  px: 2,
                }}
              >
                <Typography>{selected.length} selected</Typography>
                <Tooltip title="Delete Selected">
                  <StyledIconButton
                    variantType="transparent"
                    sx={selected.length > 1 ? { visibility: "hidden" } : {}}
                    onClick={handleOpenDeleteModal}
                  >
                    <DeleteIcon />
                  </StyledIconButton>
                </Tooltip>
              </Box>
            )}

            <Table sx={{ minWidth: 650 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Checkbox
                      indeterminate={
                        selected.length > 0 &&
                        selected.length < subDistributors.length
                      }
                      checked={
                        subDistributors.length > 0 &&
                        selected.length === subDistributors.length
                      }
                      onChange={handleSelectAllClick}
                      inputProps={{ "aria-label": "select all distributors" }}
                    />
                  </TableCell>
                  {columns.map((column) => (
                    <TableCell key={column.id} align="left">
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedDistributors
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((distributor) => {
                    const isItemSelected = isSelected(distributor._id);
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={distributor._id}
                        selected={isItemSelected}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            checked={isItemSelected}
                            onClick={() => handleSelect(distributor._id)}
                          />
                        </TableCell>
                        <TableCell align="left">
                          {new Date(distributor.createdAt).toLocaleString()}
                        </TableCell>
                        <TableCell align="left">
                          {distributor.firstName}
                        </TableCell>
                        <TableCell align="left">
                          {distributor.lastName}
                        </TableCell>
                        <TableCell align="left">
                          {distributor.country}
                        </TableCell>
                        <TableCell align="left">{distributor.state}</TableCell>
                        <TableCell align="left">{distributor.city}</TableCell>
                        <TableCell align="left">
                          {distributor.address}
                        </TableCell>
                        <TableCell align="left">{distributor.phone}</TableCell>
                        <TableCell align="left">{distributor.email}</TableCell>
                        <TableCell align="left">
                          <Chip
                            label={distributor.isActive ? "Active" : "Inactive"}
                            color={distributor.isActive ? "success" : "error"}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={subDistributors.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              sx={{
                mr: 1,
              }}
            />
          </TableContainer>
        </Box>
      </Box>
      <Dialog open={isDeleteModalOpen} onClose={handleCloseDeleteModal}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this Sub Distributor?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <StyledButton onClick={handleCloseDeleteModal}>Cancel</StyledButton>

          <StyledButton
            onClick={() => {
              handleDeleteSubDistributor();
              handleCloseDeleteModal();
            }}
            variantType="destructive"
          >
            Delete
          </StyledButton>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default DistributorTableV2;
