import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  ListItemText,
  Checkbox,
  Typography,
  Box,
  ListItem,
} from "@mui/material";
import { FixedSizeList as List } from "react-window";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { DeviceData } from "../../common/types/DeviceTypes";
import StyledButton from "../Tabs/StyledButton";

interface AddRemoveDeviceModalProps {
  open: boolean;
  onClose: () => void;
  devices: DeviceData[];
  distributorId: string;
  onSave: (selectedDevices: string[]) => void;
}

const AddRemoveDeviceModal: React.FC<AddRemoveDeviceModalProps> = ({
  open,
  onClose,
  devices,
  onSave,
  distributorId,
}) => {
  const [selectedDevices, setSelectedDevices] = useState<string[]>([]);

  const handleToggle = (id: string) => {
    const currentIndex = selectedDevices.indexOf(id);
    const newSelected = [...selectedDevices];

    if (currentIndex === -1) {
      newSelected.push(id);
    } else {
      newSelected.splice(currentIndex, 1);
    }
    setSelectedDevices(newSelected);
  };

  const handleSave = () => {
    onSave(selectedDevices);
    // try {
    //   await addDevicesToDistributor(DistributorId, selectedDevices);
    // } catch (error) {
    //   console.error("Error adding devices to Distributor:", error);
    // }
    onClose();
  };

  const Row = ({
    index,
    style,
  }: {
    index: number;
    style: React.CSSProperties;
  }) => (
    <ListItem
      style={style}
      key={devices[index].deviceInformation.deviceId}
      button
      onClick={() => handleToggle(devices[index].deviceInformation.deviceId)}
    >
      <ListItemText
        primary={
          <>
            <Typography variant="body1">
              {devices[index].deviceInformation.deviceName}
            </Typography>
            <Typography variant="caption" color="textSecondary">
              Serial: {devices[index].deviceInformation.serial}
            </Typography>
          </>
        }
      />
      <Checkbox
        edge="end"
        onChange={() => handleToggle(devices[index].deviceInformation.deviceId)}
        checked={selectedDevices.includes(
          devices[index].deviceInformation.deviceId
        )}
      />
    </ListItem>
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="add-remove-device-dialog-title"
    >
      <DialogTitle id="add-remove-device-dialog-title">
        Add or Remove Devices
      </DialogTitle>
      <DialogContent
        dividers={true}
        sx={{
          //   maxHeight: "400px", // Set a maximum height
          overflowY: "scroll", // Always show vertical scrollbar
        }}
      >
        <List
          height={110}
          width={"100%"}
          itemSize={46}
          itemCount={devices.length}
          overscanCount={5}
          style={{ border: "1px solid #ccc", overflowY: "scroll" }}
        >
          {Row}
        </List>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            gap: 1,
            mt: 2,
          }}
        >
          <StyledButton onClick={onClose} variantType="outline-red">
            Cancel
          </StyledButton>
          <StyledButton
            onClick={handleSave}
            variantType="primary"
            icon={<AddCircleOutlineIcon />}
          >
            Save Changes
          </StyledButton>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default AddRemoveDeviceModal;
