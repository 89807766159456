// StatCard.tsx
import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Box,
  SvgIconProps,
} from "@mui/material";

interface StatCardProps {
  icon: React.ReactElement<SvgIconProps>;
  title: string;
  value: string | number;
  iconColor: string;
  subtitle?: string;
}

const StatCard: React.FC<StatCardProps> = ({
  icon,
  title,
  value,
  iconColor,
  subtitle,
}) => {
  return (
    <Card
      sx={{
        minWidth: 120,
        // boxShadow: "1px 2px 4px rgba(0, 0, 0, 0.1)",
        border: "1px solid rgba(0, 0, 0, 0.18)",
      }}
      elevation={0}
    >
      {" "}
      <CardContent>
        <Box display="flex" alignItems="center">
          {React.cloneElement(icon, {
            sx: { color: iconColor, marginRight: 1 },
          })}
          <Typography variant="body2">{title}</Typography>
        </Box>
        <Typography variant="h5" fontWeight="bold">
          {value}
        </Typography>
        {subtitle && (
          <Typography variant="body2" color="text.secondary">
            {subtitle}
          </Typography>
        )}
      </CardContent>
    </Card>
  );
};

export default StatCard;
