import React, { useState, useEffect } from "react";
import useAuthStore from "../../store/authStore";
import {
  Box,
  TextField,
  Typography,
  Avatar,
  Grid,
  Paper,
  Divider,
  Autocomplete,
} from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { updateUserInfo } from "../../services/user.service";
import { countryLabels } from "../../common/types/constants";
import StyledButton from "../Tabs/StyledButton";

const ProfileDetails: React.FC = () => {
  const { user, setUser, setSnackbar } = useAuthStore();
  const [userDetails, setUserDetails] = useState({
    firstName: user?.firstName || "",
    lastName: user?.lastName || "",
    email: user?.email || "",
    phone: user?.phone || "",
    country: user?.country || "",
    city: user?.city || "",
    address: user?.address || "",
    postalCode: user?.postalCode || "",
  });

  const [isUpdated, setIsUpdated] = useState(false);

  useEffect(() => {
    const hasChanged =
      userDetails.firstName !== user?.firstName ||
      userDetails.lastName !== user?.lastName ||
      userDetails.phone !== user?.phone ||
      userDetails.country !== user?.country ||
      userDetails.city !== user?.city ||
      userDetails.address !== user?.address ||
      userDetails.postalCode !== user?.postalCode;

    setIsUpdated(hasChanged);
  }, [userDetails, user]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserDetails({ ...userDetails, [event.target.name]: event.target.value });
  };

  const handleSaveChanges = async () => {
    try {
      const response = await updateUserInfo(userDetails.email, userDetails);

      // Extract the updated user from the response
      const updatedUser = response.data;

      console.log("Updated user object:", updatedUser);

      if (!updatedUser || !updatedUser.email) {
        throw new Error("Invalid user data received from server");
      }

      // Update the user in the store and localStorage
      setUser(updatedUser);
      localStorage.setItem("user", JSON.stringify(updatedUser));

      // Show success Snackbar
      setSnackbar({
        open: true,
        message: "Profile updated successfully!",
        severity: "success",
      });

      setIsUpdated(false); // Reset updated flag
    } catch (error) {
      console.error("Error updating user information:", error);

      // Show error Snackbar
      setSnackbar({
        open: true,
        message: "Failed to update profile. Please try again.",
        severity: "error",
      });
    }
  };

  const SectionHeader = ({ title }: { title: string }) => (
    <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
      <Typography
        variant="h6"
        sx={{
          flexShrink: 0,
          pr: 2,
          textAlign: "center",
          color: "#282828",
          fontWeight: "700",
          fontFamily: "Montserrat, sans-serif",
          fontSize: "14px",
        }}
      >
        {title}
      </Typography>
      <Divider
        sx={{ flexGrow: 1, borderBottomWidth: 3, bgcolor: "secondary.main" }}
      />
    </Box>
  );

  return (
    <Paper
      elevation={1}
      sx={{
        p: 2,
        borderRadius: 1,
        mt: 2,
        alignItems: { xs: "center" },
        maxWidth: 1200,
        margin: "auto",
      }}
    >
      <Box
        sx={{
          p: 2,
          maxWidth: 800,
          margin: "auto",
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          justifyContent: "space-between",
          alignItems: { xs: "center" },
        }}
      >
        <Box
          sx={{
            p: 2,
            width: { xs: "100%", md: "350px" },
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Avatar sx={{ width: 64, height: 64, mb: 2 }}>
            <AccountCircleIcon fontSize="large" />
          </Avatar>
          <StyledButton sx={{ width: "auto" }} variantType="outline-primary" fullWidth>
            Change Profile Picture
          </StyledButton>
        </Box>
        <Box sx={{ flexGrow: 1, pl: { md: 4 } }}>
          <SectionHeader title="Profile Details" />
          <Grid container spacing={1} sx={{ mb: 2 }}>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="First Name"
                name="firstName"
                value={userDetails.firstName}
                onChange={handleInputChange}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Last Name"
                name="lastName"
                value={userDetails.lastName}
                onChange={handleInputChange}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                disabled
                label="Email"
                name="email"
                value={userDetails.email}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Phone"
                name="phone"
                value={userDetails.phone}
                onChange={handleInputChange}
                margin="normal"
              />
            </Grid>
          </Grid>
          <SectionHeader title="Location Details" />
          <Grid container spacing={1}>
            <Grid item xs={12} md={6}>
              <Autocomplete
                fullWidth
                options={countryLabels.map((country) => country.label)}
                value={userDetails.country}
                onChange={(event: any, newValue: string | null) => {
                  setUserDetails({ ...userDetails, country: newValue || "" });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    margin="normal"
                    required
                    helperText={
                      userDetails.country === "" && "Country is required"
                    }
                    error={userDetails.country === ""}
                    {...params}
                    label="Country"
                  />
                )}
              />

              {/* <TextField
                fullWidth
                label="Country"
                name="country"
                value={userDetails.country}
                onChange={handleInputChange}
                margin="normal"
              /> */}
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="City"
                name="city"
                value={userDetails.city}
                onChange={handleInputChange}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Street Address"
                name="address"
                value={userDetails.address}
                onChange={handleInputChange}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Postal Code"
                name="postalCode"
                value={userDetails.postalCode}
                onChange={handleInputChange}
                margin="normal"
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
        <StyledButton
          variantType="primary"
          onClick={handleSaveChanges}
          disabled={!isUpdated}
        >
          Save Changes
        </StyledButton>
      </Box>
    </Paper>
  );
};

export default ProfileDetails;
