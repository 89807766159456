import { fetchAllDeviceTests } from "../services/device-test.service";

export const determineIoTVersion = async (serial: string) => {
  const numericSerial = parseInt(serial.replace(/-FB$/, ""));

  // Fetch all device tests
  const allDeviceTests = await fetchAllDeviceTests();

  // Check if the serial number exists in the fetched device tests and has device usage
  const isVersion9 = allDeviceTests.some(
    (test) =>
      test.deviceId === serial &&
      test.deviceUsage &&
      test.deviceUsage.length > 0
  );
  // If found in the device tests, it is Version 9
  if (isVersion9) {
    return "V9";
  }

  if (
    (numericSerial >= 18300 && numericSerial <= 19800) ||
    (numericSerial >= 73600 && numericSerial <= 77065) ||
    (numericSerial >= 958767 && numericSerial <= 966825)
  ) {
    return "V6";
  } else if (
    (numericSerial >= 19801 && numericSerial <= 20400) ||
    (numericSerial >= 77066 && numericSerial <= 77672) ||
    (numericSerial >= 966826 && numericSerial <= 967566)
  ) {
    return "V7";
  } else if (
    (numericSerial >= 20401 && numericSerial <= 20660) ||
    (numericSerial >= 77673 && numericSerial <= 78027) ||
    (numericSerial >= 967567 && numericSerial <= 969593)
  ) {
    return "V8";
  }

  return "UNKNOWN";
};
