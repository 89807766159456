import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Card,
  CardContent,
  Typography,
  Chip,
  Paper,
  Divider,
  IconButton,
} from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import axios from "axios";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { Line } from "react-chartjs-2";
import Chart from "chart.js/auto";
import ViewToggle from "../Button/ViewToggle"; // Import the ViewToggle component
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { ChartData } from "chart.js";
import { sendOldICleanlinesDeviceEmail } from "../../services/device.service";

dayjs.extend(relativeTime); // Register Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

// Function to convert string values to numbers
const convertToNumberArray = (data: (number | string)[]): number[] => {
  return data.map((item) =>
    typeof item === "string" ? parseFloat(item) : item
  );
};

interface OldICleanlinesDeviceEntry {
  _id: string;
  deviceId: string;
  timestamp: string;
  tdsIncoming: number;
  tdsOutgoing: number;
  pressureIncoming: number;
  pressureOutgoing: number;
  flowRate: string;
}

interface OldICleanlinesDevice {
  _id: string;
  entries: OldICleanlinesDeviceEntry[];
}

const API_URL = process.env.REACT_APP_API_URL;

const OldICleanLinesDashboard: React.FC = () => {
  const [deviceData, setDeviceData] = useState<OldICleanlinesDevice[]>([]);
  const [selectedDeviceEntries, setSelectedDeviceEntries] = useState<
    OldICleanlinesDeviceEntry[]
  >([]);
  const [selectedDeviceId, setSelectedDeviceId] = useState<string | null>(null);
  const [view, setView] = useState<string>("table"); // Default view is table
  const [offlineStatus, setOfflineStatus] = useState<Record<string, boolean>>(
    {}
  );

  const fetchDeviceData = async () => {
    try {
      const response = await axios.get(`${API_URL}/old-icleanlines/data`);
      setDeviceData(response.data);
      console.log(response.data);

      // Set default selected device to the first device
      if (response.data.length > 0) {
        const firstDevice = response.data[0];
        setSelectedDeviceId(firstDevice._id);
        setSelectedDeviceEntries(firstDevice.entries);
      }
    } catch (error) {
      console.error("Failed to fetch device data:", error);
    }
  };

  useEffect(() => {
    fetchDeviceData();
    const interval = setInterval(fetchDeviceData, 86400000); // Fetch data every 24 hours
    return () => clearInterval(interval);
  }, []);

  // Check if device 4 or 5 has gone offline and send an email only once
  useEffect(() => {
    deviceData.forEach((device) => {
      // Only proceed if the device ID is "4" (Overijse) or "5" (Hannut)
      if (device._id === "4" || device._id === "5") {
        const latestEntry = device.entries[0];
        const isOffline =
          dayjs().diff(dayjs(latestEntry.timestamp), "hour") >= 2;

        if (isOffline && !offlineStatus[device._id]) {
          // The device has transitioned from online to offline, so send the email
          const deviceName = device._id === "4" ? "Overijse" : "Hannut";

          sendOldICleanlinesDeviceEmail("melissam@tersano.com", deviceName);

          // Mark the device as offline in the state
          setOfflineStatus((prevStatus) => ({
            ...prevStatus,
            [device._id]: true, // Mark the device as offline
          }));
        } else if (!isOffline && offlineStatus[device._id]) {
          // The device has transitioned from offline to online, so reset its status
          setOfflineStatus((prevStatus) => ({
            ...prevStatus,
            [device._id]: false, // Mark the device as online
          }));
        }
      }
    });
  }, [deviceData, offlineStatus]);

  const columns: GridColDef[] = [
    {
      field: "timestamp",
      headerName: "Timestamp",
      width: 200,
      renderCell: (params) => (
        <span>{new Date(params.value).toLocaleString()}</span>
      ),
    },
    { field: "tdsIncoming", headerName: "TDS Incoming", flex: 1 },
    { field: "tdsOutgoing", headerName: "TDS Outgoing", flex: 1 },
    { field: "pressureIncoming", headerName: "Pressure Incoming", flex: 1 },
    { field: "pressureOutgoing", headerName: "Pressure Outgoing", flex: 1 },
    { field: "flowRate", headerName: "Flow Rate", flex: 1 },
  ];

  const handleCardClick = (device: OldICleanlinesDevice) => {
    setSelectedDeviceId(device._id);
    setSelectedDeviceEntries(device.entries);
  };

  const renderDeviceCard = (device: OldICleanlinesDevice) => {
    // Check the latest entry's timestamp for determining online/offline status
    const latestEntry = device.entries[0];
    const isOffline = dayjs().diff(dayjs(latestEntry.timestamp), "hour") >= 2;

    // Define a custom display name based on deviceId
    const deviceName =
      device._id === "4"
        ? "Overijse"
        : device._id === "5"
        ? "Hannut"
        : `Device ID: ${device._id}`;

    return (
      <Card
        key={device._id}
        onClick={() => handleCardClick(device)}
        sx={{
          mb: 2,
          cursor: "pointer",
          border:
            device._id === selectedDeviceId ? "2px solid #1976d2" : "none", // Highlight selected device
          bgcolor: device._id === selectedDeviceId ? "#e3f2fd" : "white", // Background color for selected device
        }}
      >
        <CardContent>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 1,
            }}
          >
            <Typography variant="h6">{deviceName}</Typography>
            <Chip
              label={isOffline ? "Offline" : "Online"}
              color={isOffline ? "error" : "success"}
              sx={{ ml: 2 }}
              size="small"
            />
          </Box>
          <Divider sx={{ mb: 1 }} />
          <Typography>Last Timestamp: {latestEntry.timestamp}</Typography>
          <Typography>TDS Incoming: {latestEntry.tdsIncoming}</Typography>
          <Typography>TDS Outgoing: {latestEntry.tdsOutgoing}</Typography>
          <Typography>
            Pressure Incoming: {latestEntry.pressureIncoming}
          </Typography>
          <Typography>
            Pressure Outgoing: {latestEntry.pressureOutgoing}
          </Typography>
        </CardContent>
      </Card>
    );
  };

  const chartData: ChartData<"line", number[], string> = {
    labels: selectedDeviceEntries.map((entry) =>
      dayjs(entry.timestamp).format("MM/DD/YYYY HH:mm")
    ),
    datasets: [
      {
        label: "TDS Incoming",
        data: convertToNumberArray(
          selectedDeviceEntries.map((entry) => entry.tdsIncoming)
        ),
        borderColor: "rgba(255, 99, 132, 1)",
        backgroundColor: "rgba(255, 99, 132, 0.2)",
        borderWidth: 1,
      },
      {
        label: "TDS Outgoing",
        data: convertToNumberArray(
          selectedDeviceEntries.map((entry) => entry.tdsOutgoing)
        ),
        borderColor: "rgba(54, 162, 235, 1)",
        backgroundColor: "rgba(54, 162, 235, 0.2)",
        borderWidth: 1,
      },
      {
        label: "Pressure Incoming",
        data: convertToNumberArray(
          selectedDeviceEntries.map((entry) => entry.pressureIncoming)
        ),
        borderColor: "rgba(255, 206, 86, 1)",
        backgroundColor: "rgba(255, 206, 86, 0.2)",
        borderWidth: 1,
      },
      {
        label: "Pressure Outgoing",
        data: convertToNumberArray(
          selectedDeviceEntries.map((entry) => entry.pressureOutgoing)
        ),
        borderColor: "rgba(75, 192, 192, 1)",
        backgroundColor: "rgba(75, 192, 192, 0.2)",
        borderWidth: 1,
      },
      {
        label: "Flow Rate",
        data: convertToNumberArray(
          selectedDeviceEntries.map((entry) => entry.flowRate)
        ),
        borderColor: "rgba(153, 102, 255, 1)",
        backgroundColor: "rgba(153, 102, 255, 0.2)",
        borderWidth: 1,
      },
    ],
  };

  const deviceName =
    selectedDeviceId === "4"
      ? "Overijse"
      : selectedDeviceId === "5"
      ? "Hannut"
      : selectedDeviceId;

  return (
    <Box
      sx={{
        flexGrow: 1,
        overflow: "hidden",
        px: 1,
        backgroundColor: "rgba(230,230,229,1)",
        py: 1,
      }}
    >
      {/* <Typography variant="h6" sx={{ mb: 2 }}>
        Old ICleanlines Dashboard
      </Typography>
      <Divider sx={{ mb: 4 }} /> */}
      <Grid container spacing={2} sx={{ border: "1px solid #ddd" }}>
        <Grid item xs={3} sx={{ borderRight: "1px solid #ddd" }}>
          {deviceData.map(renderDeviceCard)}
        </Grid>
        <Grid item xs={9}>
          <Box
            sx={{
              p: 1,
              mb: 1,
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h6">
              {selectedDeviceId
                ? `Selected Device: ${deviceName}`
                : "No Device Selected"}
            </Typography>
            <ViewToggle view={view} setView={setView} />
          </Box>

          {selectedDeviceEntries.length > 0 && view === "table" && (
            <DataGrid
              rows={selectedDeviceEntries}
              columns={columns}
              getRowId={(row) => row._id}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
              pageSizeOptions={[10]}
              autoHeight
              sx={{
                border: "1px solid #E0E0E0",
                ".MuiDataGrid-cell": {
                  backgroundColor: "white",
                  color: "#333",
                },
                ".MuiDataGrid-columnHeaders": {
                  backgroundColor: "white",
                  color: "#333",
                  borderBottom: "1px solid #e0e0e0",
                },
              }}
            />
          )}
          {selectedDeviceEntries.length > 0 && view === "card" && (
            <Paper
              elevation={0}
              sx={{
                height: 400,
                width: "100%",
                p: 3,
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Line data={chartData} options={{ responsive: true }} />
            </Paper>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default OldICleanLinesDashboard;
