import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Grid,
  CardActionArea,
  styled,
  Snackbar,
  Alert,
  Paper,
} from "@mui/material";
import useAuthStore from "../store/authStore";
import useDeviceStore from "../store/useDeviceStore";
import { Dashboard, Support, Language, AccountCircle, Add, DeviceHub } from "@mui/icons-material";
import greenTree from "../assets/images/green_tree.png";
import InstallationSteps from "../components/Device/InstallationSteps";
import RegisterDeviceModal from "../components/Device/RegisterDeviceModal";
import { DeviceData } from "../common/types/DeviceTypes";
import { Card } from "@mui/material";
import StyledButton from "../components/Tabs/StyledButton";

const HoverCard = styled(Paper)({
  "&:hover": {
    backgroundColor: "rgba(30, 58, 138, 1)",
    color: "white",
    "& .MuiSvgIcon-root": {
      fill: "white",
    },
    "& .MuiTypography-root": {
      color: "white",
    },
  },
});

const HomePage: React.FC<{
  onTabChange: (tabName: string, device?: any) => void;
  onDeviceSelect?: (device: DeviceData) => void;
}> = ({ onTabChange, onDeviceSelect }) => {
  const { user } = useAuthStore();
  const { fetchDevices, allDevices } = useDeviceStore();

  const [tutorialOpen, setTutorialOpen] = useState(false);
  const [registerDeviceModalOpen, setRegisterDeviceModalOpen] = useState(false);
  const [snackbar, setSnackbar] = useState<{
    open: boolean;
    message: string;
    severity: "success" | "error";
  }>({ open: false, message: "", severity: "success" });

  const commonCardStyle = {
    borderRadius: "8px",
    border: "1px solid #d1d1d1",
    backgroundColor: "background.paper",
    color: "text.primary",
    boxShadow: "0 1px 2px 0 rgb(0 0 0 / 0.05)",
  }

  const customerRoles = ["Customer", "GroupCustomer", "GlobalAccount", "NationalAccount", "SubCustomer"]

  useEffect(() => {
    if (!user) {
      console.error("User not logged in");
      return;
    }
    const fetchAndSetDevices = async () => {
      try {
        await fetchDevices();
      } catch (error) {
        console.error("Error fetching devices:", error);
      }
    };

    fetchAndSetDevices();
  }, [user, fetchDevices]);

  const handleSnackbarClose = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const handleDeviceRegisterSuccess = async () => {
    await fetchDevices();
    setSnackbar({
      open: true,
      message: "Device registered successfully!",
      severity: "success",
    });
  };

  const cardItems = [
    {
      title: "View Dashboard",
      path: "iClean",
      description: "Start gaining insights on your device's performance.",
      icon: <Dashboard sx={{ fontSize: 35, color: "primary.main" }} />,
    },
    {
      title: "Access Support Desk",
      path: "distributorSupport",
      description:
        "Access the support desk for assistance through phone or chat.",
      icon: <Support sx={{ fontSize: 35, color: "primary.main" }} />,
    },
    {
      title: "Visit Tersano Website",
      path: "https://www.tersano.com",
      description: "Learn more about our products on the Tersano website.",
      icon: <Language sx={{ fontSize: 35, color: "primary.main" }} />,
    },
    {
      title: "Set Up Profile",
      path: "profile",
      description: "Complete your profile for a personalized experience.",
      icon: <AccountCircle sx={{ fontSize: 35, color: "primary.main" }} />,
    },
  ];

  const handleCardClick = (path: string) => {
    if (path.startsWith("http")) {
      window.open(path, "_blank");
    } else {
      onTabChange(path);
    }
  };

  const handleDeviceClick = (device: any) => {
    if (onDeviceSelect) {
      onDeviceSelect(device);
    }
    onTabChange("deviceDetails", device);
  };

  const handleInstallDeviceClick = () => {
    onTabChange("install");
  };

  const recentDevices = allDevices.slice(0, 2);

  return (
    <Box sx={{ flexGrow: 1, p: 1.5 }}>
      <Grid container spacing={1.5}>
        <Grid item xs={12}>
          <Card
            elevation={0}
            sx={{
              ...commonCardStyle,
              display: "flex",
              flexDirection: "column",
              bgcolor: "white",
              gap: 2,
              p: 2,
              py: 8,
              px: 10,
            }}
          >
            <Box>
              <Typography variant="h4" gutterBottom>
                Hello {user?.firstName}, Welcome to Tersano Portal{" "}
                <img src={greenTree} alt="Device" width={20} />
              </Typography>
              <Typography
                variant="subtitle1"
                sx={{ fontSize: "inherit" }}
                gutterBottom
              >
                Start gaining insights on how your device is performing in
                search by visiting the dashboard.
              </Typography>
            </Box>
            <Typography
              sx={{
                color: "text.secondary",
              }}
            >
              The Tersano Portal is designed to help you manage your Tersano
              devices{user && !customerRoles.includes(user.role) && (", manage your customers, installers, ")} and your account. From
              this platform you are able to monitor in real-time device usage.
            </Typography>

            {user && !customerRoles.includes(user.role) && (
              <Typography variant="body2">
                {" "}
                New to the Portal?{" "}
                <a href="https://tersanocanada-my.sharepoint.com/:f:/g/personal/emmanuelj_tersano_com/EgFWfQEKoZtMk0HmYuyZUdkBcTV7gnsbSlcHRn3NQ_5MVQ?e=tOqXCQ">
                  Watch our Training Tutorial Videos here
                </a>
              </Typography>
            )}
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Box
            sx={{
              display: "flex",
              overflowX: "auto",
              gap: 1.5,
              alignItems: "stretch",
            }}
          >
            {cardItems.map((item) => (
              <Box
                key={item.title}
                sx={{
                  minWidth: "250px",
                  flex: "1",
                  display: "flex"
                }}
              >
                <HoverCard
                  sx={{
                    ...commonCardStyle,
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    p: 0,
                    "&:hover": {
                      transition: "transform 0.2s",
                    },
                  }}
                >
                  <CardActionArea
                    onClick={() => handleCardClick(item.path)}
                    sx={{
                      width: "100%",
                      display: "flex",
                      flexGrow: 1
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        p: 3,
                        width: "100%"
                      }}
                    >
                      {item.icon}
                      <Typography
                        variant="h6"
                        sx={{ mt: 2, textAlign: "center" }}
                      >
                        {item.title}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        sx={{ mt: 1, textAlign: "center" }}
                      >
                        {item.description}
                      </Typography>
                    </Box>
                  </CardActionArea>
                </HoverCard>
              </Box>
            ))}
          </Box>
        </Grid>

        {user && !customerRoles.includes(user.role) && (
          <Grid item xs={12} lg={allDevices.length > 0 ? 6 : 12}>
            <Paper
              elevation={0}
              sx={{
                ...commonCardStyle,
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                gap: 4,
                p: 8,
                px: { xs: 5, md: 10 },
              }}
            >
              <Box>
                {" "}
                <Typography variant="h5" sx={{ mb: 2, color: "grey.900" }}>
                  Ready to install a new device?
                </Typography>
                <Typography variant="body1" color="textSecondary" sx={{ mt: 1 }}>
                  Follow our step-by-step guide to set up your new Tersano device
                  for a customer. Click button below:
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: 3,
                }}
              >
                <Box>
                  <StyledButton
                    variantType="primary"
                    size="large"

                    onClick={handleInstallDeviceClick}
                    icon={<Add />}
                  >
                    Install Device
                  </StyledButton>
                </Box>
                <Box>
                  <StyledButton
                    size="large"
                    icon={<DeviceHub />}
                    onClick={() => setTutorialOpen(true)}
                  >
                    See Tutorial
                  </StyledButton>
                </Box>
              </Box>
            </Paper>
          </Grid>
        )}

        {allDevices.length > 0 && (
          <Grid item xs={12} lg={user && customerRoles.includes(user.role) ? 12 : 6}>
            <Box
              sx={{
                ...commonCardStyle,
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                gap: 2,
                p: 3
              }}
            >
              <Typography variant="h6" gutterBottom>
                New Devices
              </Typography>
              {recentDevices.map((device) => (
                <Box
                  key={device.deviceInformation.deviceId}
                  sx={{
                    width: "100%",
                    borderRadius: "8px",
                    backgroundColor: "#f5f5f5",
                    color: "text.primary",
                    cursor: "pointer",
                    boxShadow: "0 1px 2px 0 rgb(0 0 0 / 0.05)",
                    p: 1,
                    "&:hover": {
                      backgroundColor: "#bbdefb",
                      transition: "transform 0.2s",
                    },
                  }}
                  onClick={() => handleDeviceClick(device)}
                >
                  <Typography variant="body1" sx={{ fontWeight: 500 }}>
                    {device.deviceInformation.deviceName}
                  </Typography>
                  <Typography variant="body2">
                    {device.deviceLocation?.city},{" "}
                    {device?.deviceLocation?.state}
                  </Typography>
                  <Typography variant="subtitle2">
                    {device.deviceInformation.serial} |{" "}
                    <Typography variant="caption">
                      {new Date(device.createdAt!).toLocaleDateString()}
                    </Typography>
                  </Typography>
                </Box>
              ))}
            </Box>
          </Grid>
        )}
      </Grid>
      <InstallationSteps
        open={tutorialOpen}
        onClose={() => setTutorialOpen(false)}
      />
      <RegisterDeviceModal
        open={registerDeviceModalOpen}
        onClose={() => setRegisterDeviceModalOpen(false)}
        onSuccess={handleDeviceRegisterSuccess}
        onError={(message: string) => {
          setSnackbar({
            open: true,
            message,
            severity: "error",
          });
        }}
      />
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbar.severity}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box >
  );
};

export default HomePage;
