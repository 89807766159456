import React, { useState } from "react";
import {
  Box,
  Typography,
  Modal,
  Paper,
  Divider,
  useTheme,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ImageIcon from "@mui/icons-material/Image";
import StyledButton from "../Tabs/StyledButton";
import StyledIconButton from "../Tabs/StyledIconButton";

const steps = [
  {
    title: "Register your device on the portal",
    description:
      "Fill in the form for the device details and click save. Access the form by clicking install device on Sidebar or on Home page",
    helpText: "Need help with this step?",
    helpLink: "#",
    buttonText: "Next",
  },
  {
    title: "Give the device Power.",
    description:
      "Give the device power by connecting power cable but ensure there is no water flow by making sure the switch on device is off. ",
    helpText: "Need help with this step?",
    helpLink: "#",
    buttonText: "Next",
  },
  {
    title: "Give Device Access to Wifi",
    description:
      "Open any device you have phone/tablet wifi settings and search for a network with the serial number of the unit. Connect with password 123456789, \n Open your phone/laptop browser and enter tersano.local and complete the form.",
    helpText: "Need help with this step?",
    helpLink: "#",
    buttonText: "Next",
  },
  {
    title: "Verify device is online",
    description:
      "Head to the Tersano portal to make sure the device is online by navigating to manage devices, serial monitor, and verify status of your device as online.",
    helpText: "Need help with this step?",
    helpLink: "#",
    buttonText: "Done",
  },
];

const modalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: 300, sm: 400 },
  height: { xs: 400, md: 450 },
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: { xs: 3, sm: 3, md: 4 },
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
};

const InstallationSteps: React.FC<{ open: boolean; onClose: () => void }> = ({
  open,
  onClose,
}) => {
  const [activeStep, setActiveStep] = useState(0);
  const theme = useTheme();

  const handleNext = () => {
    if (activeStep < steps.length - 1) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else {
      onClose();
    }
  };

  const handlePrevious = () => {
    if (activeStep > 0) {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const handleSkip = () => {
    onClose();
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="installation-steps-modal"
      aria-describedby="installation-steps-modal-description"
    >
      <Paper sx={modalStyle}>
        <Box>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <StyledIconButton variantType="transparent" iconColor={theme.palette.primary.main} onClick={onClose}>
              <CloseIcon />
            </StyledIconButton>
            <Typography variant="h6" component="h2">
              Installation Guide
            </Typography>
            <Box sx={{ width: "40px" }}></Box>
          </Box>
          <Divider sx={{ my: 2 }} />
          <Typography variant="h6">{steps[activeStep].title}</Typography>
          <Typography sx={{ mt: 2 }}>
            {steps[activeStep].description}
          </Typography>
          <Box
            sx={{
              mt: 2,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ImageIcon sx={{ fontSize: 80, color: "grey.500" }} />
          </Box>
        </Box>
        <Box>
          <Typography
            sx={{ mt: 2, color: "primary.main", cursor: "pointer" }}
            component="a"
            href={steps[activeStep].helpLink}
          >
            {steps[activeStep].helpText}
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              mt: 3,
              gap: 1.5,
            }}
          >
            <StyledButton onClick={handleSkip}>Skip</StyledButton>
            {activeStep > 0 && (
              <StyledButton onClick={handlePrevious}>Previous</StyledButton>
            )}
            <StyledButton variantType="primary" onClick={handleNext}>
              {steps[activeStep].buttonText}
            </StyledButton>
          </Box>
        </Box>
      </Paper>
    </Modal>
  );
};

export default InstallationSteps;
