import { UserRoles } from "./UserTypes";

export enum ChangeRequestTypes {
  Other = "Other",
  AddDevice = "AddDevice",
  AddInstaller = "AddInstaller",
  AddCustomer = "AddCustomer",
  AddDistributor = "AddDistributor",
  AddSubDistributor = "AddSubDistributor",
  AddSubCustomer = "AddSubCustomer",
  EditDeviceDetails = "EditDevice",
  DeleteDevice = "DeleteDevice",
  EditInstallerDetails = "EditInstaller",
  UpdateDeviceDetails = "UpdateDeviceDetails",
}

export enum ChangeRequestStatus {
  Pending = "Pending",
  Approved = "Approved",
  Rejected = "Rejected",
}

export interface ChangeRequest {
  _id: string;
  type: ChangeRequestTypes;
  status: ChangeRequestStatus;
  userEmail: string;
  userRole: UserRoles;
  details: any;
  reviewedAt: Date;
  reviewedBy: string;
  createdAt: string;
  updatedAt: string;
}

export interface ChangeRequestPayload {
  type: ChangeRequestTypes;
  userEmail: string;
  userRole: string;
  details: any;
}
