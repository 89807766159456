import React, { useState, useEffect } from "react";
import { Bar } from "react-chartjs-2";
import "chart.js/auto";
import { Box, Typography, Divider, useTheme } from "@mui/material";
import dayjs from "dayjs";
import { ICleanDevice } from "../../../common/types/DeviceTypes";
import { Chart as ChartJS } from "chart.js/auto";

ChartJS.defaults.font.family = `"Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif`;
ChartJS.defaults.font.size = 11;
// ChartJS.defaults.font.weight = 500;
ChartJS.defaults.color = "#282828";

interface ChartDataSet {
  label: string;
  data: number[];
  backgroundColor: string;
  borderColor: string;
  borderWidth: number;
}

interface ChartDataState {
  labels: string[];
  datasets: ChartDataSet[];
}

interface TimeDynamicGraphProps {
  selectedDate: dayjs.Dayjs | null;
  selectedDevices: ICleanDevice[];
  isGallons?: boolean;
}

const TimeDynamicGraph: React.FC<TimeDynamicGraphProps> = ({
  selectedDate,
  selectedDevices,
  isGallons,
}) => {
  const theme = useTheme();

  const [chartData, setChartData] = useState<ChartDataState>({
    labels: [],
    datasets: [
      {
        label: "Liters Dispensed",
        data: [],
        backgroundColor: "rgba(30, 58, 138, 0.9)",
        borderColor: "rgba(30, 58, 138, 0.9)",
        borderWidth: 1,
      },
    ],
  });

  const generateMinuteData = (date: dayjs.Dayjs) => {
    const minutesMap = new Map<string, number>(); // Use a map to track usage by minute

    selectedDevices.forEach((device) => {
      device.deviceUsage.forEach((usage) => {
        const usageDate = dayjs(usage.uploadTimestamp);
        if (usageDate.isSame(date, "day")) {
          const timeLabel = usageDate.format("HH:mm");

          // convert to gallons if isGallons is true
          const usageAmount = isGallons
            ? usage.litersDispensed / 3.78541
            : usage.litersDispensed;

          minutesMap.set(
            timeLabel,
            (minutesMap.get(timeLabel) || 0) + usageAmount
          );
        }
      });
    });

    const labels = Array.from(minutesMap.keys()).sort();
    const data = labels.map((time) => minutesMap.get(time) || 0);

    return {
      labels,
      datasets: [
        {
          label: isGallons ? "Gallons Dispensed" : "Liters Dispensed",
          data,
          backgroundColor: "rgba(30, 58, 138, 0.9)",
          borderColor: "rgba(30, 58, 138, 0.9)",
          borderWidth: 1,
        },
      ],
    };
  };

  useEffect(() => {
    if (selectedDate) {
      const data = generateMinuteData(selectedDate);
      setChartData(data);
    }
  }, [selectedDate, selectedDevices]);

  return (
    <Box
      sx={{
        p: 2,
        borderRadius: "8px",
        border: "1px solid #d1d1d1",
        backgroundColor: "background.paper",
        color: "text.primary",
        height: "250px",
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        boxShadow: "0 1px 2px 0 rgb(0 0 0 / 0.05)",
      }}
    >
      <Typography
        variant="h6"
        sx={{
          textAlign: "center",
          mb: 1,
          color: "#282828",
          fontWeight: "700",
          fontSize: "13px",
        }}
      >
        Device Usage for {selectedDate?.format("MMM D, YYYY")}
      </Typography>
      <Divider sx={{ mb: 2 }} />
      <Box
        sx={{
          flexGrow: 1,
          width: "100%",
          height: "100%",
        }}
      >
        <Bar
          data={chartData}
          options={{
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              legend: {
                position: "top",
              },
              tooltip: {
                mode: "index",
                intersect: false,
              },
            },
            scales: {
              x: {
                grid: {
                  display: false,
                },
                ticks: {
                  autoSkip: true,
                  maxRotation: 0,
                  minRotation: 0,
                },
              },
              y: {
                beginAtZero: true,
                title: {
                  display: true,
                  text: isGallons ? "Gallons" : "Liters",
                },
              },
            },
            layout: {
              padding: {
                left: 10,
                right: 10,
                top: 0,
                bottom: 0,
              },
            },
          }}
        />
      </Box>
    </Box>
  );
};

export default TimeDynamicGraph;
