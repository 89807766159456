import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
  Font,
} from "@react-pdf/renderer";
import tersanoLogo from "../../assets/images/tersano-logo.png";

// Register Garamond font
Font.register({
  family: "Garamond",
  fonts: [
    {
      src: "/fonts/garamond.ttf",
      fontWeight: 400,
    },
  ],
});

const styles = StyleSheet.create({
  page: { backgroundColor: "#fff", padding: 30, fontFamily: "Garamond" },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 20,
  },
  logo: { width: 100 },
  title: { fontSize: 16, textAlign: "center", margin: 10, color: "black" },
  table: { width: "100%", marginTop: 20, borderStyle: "solid", borderWidth: 1 },
  tableRow: { flexDirection: "row" },
  tableColHeader: {
    width: "16.67%", // Adjust to fit 6 columns
    borderStyle: "solid",
    borderWidth: 1,
    padding: 5,
    backgroundColor: "#34495e",
  },
  tableCol: {
    width: "16.67%", // Adjust to fit 6 columns
    borderStyle: "solid",
    borderWidth: 1,
    padding: 5,
  },
  tableCellHeader: {
    fontSize: 12,
    fontWeight: "bold",
    color: "#ecf0f1",
    textAlign: "center",
  },
  tableCell: { fontSize: 10, textAlign: "center", color: "#2c3e50" },
  period: { fontSize: 12, marginBottom: 5, color: "black" },
  generatedInfo: { fontSize: 10, color: "black" },
});

interface SalesReportTemplateProps {
  reportName: string;
  reportData: {
    countryName: string;
    quarters: {
      Q1: number;
      Q2: number;
      Q3: number;
      Q4: number;
    };
    spend: string;
    bonus: string;
  }[];
  startDate: Date;
  endDate: Date;
  generatedBy: string;
  generatedAt: Date;
}

const SalesReportTemplate: React.FC<SalesReportTemplateProps> = ({
  reportName,
  reportData,
  startDate,
  endDate,
  generatedBy,
  generatedAt,
}) => {
  const renderTableHeaders = () => (
    <View style={styles.tableRow}>
      <View style={styles.tableColHeader}>
        <Text style={styles.tableCellHeader}>Country</Text>
      </View>
      <View style={styles.tableColHeader}>
        <Text style={styles.tableCellHeader}>Q1 (EUR)</Text>
      </View>
      <View style={styles.tableColHeader}>
        <Text style={styles.tableCellHeader}>Q2 (EUR)</Text>
      </View>
      <View style={styles.tableColHeader}>
        <Text style={styles.tableCellHeader}>Q3 (EUR)</Text>
      </View>
      <View style={styles.tableColHeader}>
        <Text style={styles.tableCellHeader}>Q4 (EUR)</Text>
      </View>
      <View style={styles.tableColHeader}>
        <Text style={styles.tableCellHeader}>Total Spend (EUR)</Text>
      </View>
      <View style={styles.tableColHeader}>
        <Text style={styles.tableCellHeader}>Bonus (EUR)</Text>
      </View>
    </View>
  );

  const renderTableRows = () =>
    reportData.map((item, index) => (
      <View style={styles.tableRow} key={index}>
        <View style={styles.tableCol}>
          <Text style={styles.tableCell}>{item.countryName}</Text>
        </View>
        <View style={styles.tableCol}>
          <Text style={styles.tableCell}>{item.quarters.Q1.toFixed(2)}</Text>
        </View>
        <View style={styles.tableCol}>
          <Text style={styles.tableCell}>{item.quarters.Q2.toFixed(2)}</Text>
        </View>
        <View style={styles.tableCol}>
          <Text style={styles.tableCell}>{item.quarters.Q3.toFixed(2)}</Text>
        </View>
        <View style={styles.tableCol}>
          <Text style={styles.tableCell}>{item.quarters.Q4.toFixed(2)}</Text>
        </View>
        <View style={styles.tableCol}>
          <Text style={styles.tableCell}>{item.spend}</Text>
        </View>
        <View style={styles.tableCol}>
          <Text style={styles.tableCell}>{item.bonus}</Text>
        </View>
      </View>
    ));

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
          <Image style={styles.logo} src={tersanoLogo} />
          <View>
            <Text style={styles.period}>
              Period: {new Date(startDate).toLocaleDateString()} -{" "}
              {new Date(endDate).toLocaleDateString()}
            </Text>
            <Text style={styles.generatedInfo}>
              Generated by: {generatedBy}
            </Text>
            <Text style={styles.generatedInfo}>
              Date: {new Date(generatedAt).toLocaleDateString()}
            </Text>
          </View>
        </View>
        <Text style={styles.title}>{reportName} Report</Text>
        <View style={styles.table}>
          {renderTableHeaders()}
          {renderTableRows()}
        </View>
      </Page>
    </Document>
  );
};

export default SalesReportTemplate;
