import React from "react";
import {
  AppBar,
  Box,
  Toolbar,
  Typography,
  Container,
  Grid,
  IconButton,
  useTheme,
  useMediaQuery,
  styled,
} from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import SettingsIcon from "@mui/icons-material/Settings";
import MessageIcon from "@mui/icons-material/Message";
import PaymentsIcon from "@mui/icons-material/Payments";
import InfoIcon from "@mui/icons-material/Info";
import { useNavigate } from "react-router-dom";
import ChatIcon from "@mui/icons-material/Chat";

const CustomerSupport: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();

  const supportItems = [
    {
      icon: <HelpOutlineIcon style={{ fontSize: "3.5rem" }} />,
      title: "FAQs",
      description: "Answers to common questions",
      path: "#",
    },
    // {
    //   icon: <LocalShippingIcon style={{ fontSize: "3.5rem" }} />,
    //   title: "Order Status",
    //   description: "Track shipping and delivery",
    //   path: "#",
    // },
    // {
    //   icon: <SettingsIcon style={{ fontSize: "3.5rem" }} />,
    //   title: "Warranty",
    //   description: "Submit a claim",
    //   path: "/warranty",
    // },
    // {
    //   icon: <MessageIcon style={{ fontSize: "3.5rem" }} />,
    //   title: "Contact Us",
    //   description: "Connect with our team",
    //   path: "#",
    // },
    // {
    //   icon: <InfoIcon style={{ fontSize: "3.5rem" }} />,
    //   title: "Product Instructions",
    //   description: "Instructions for assembly and care",
    //   path: "/customer-service",
    // },
    // {
    //   icon: <PaymentsIcon style={{ fontSize: "3.5rem" }} />,
    //   title: "Billing & Payment",
    //   description: "Billing and payment Info",
    //   path: "#",
    // },
  ];

  return (
    <Box p={0}>
      <Box mt={1} sx={{ display: "flex", justifyContent: "center" }}>
        <Box
          mt={1}
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            width: { xs: "90%", md: "90%" },
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              borderBottom: 1,
              borderColor: "divider",
              p: 2,
            }}
          >
            <Typography variant="h5">Help Desk</Typography>
          </Box>
          <Box
            textAlign="center"
            my={4}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                fontSize: "16px",
                display: "flex",
                alignItems: "center",
                gap: 1,
              }}
              variant="body1"
              gutterBottom
            >
              Chat <ChatIcon fontSize="medium" />
            </Typography>
            <Typography
              sx={{
                fontSize: "14px",
              }}
              variant="body1"
            >
              {" "}
              <a href="mailto:dev.team@tersano.com"> dev.team@tersano.com</a>
            </Typography>
            <Typography
              sx={{
                fontSize: "14px",
              }}
              variant="body2"
              gutterBottom
            >
              Monday - Friday: 6am - 5pm MT
            </Typography>
            <Typography
              sx={{
                fontSize: "14px",
              }}
              variant="body2"
              gutterBottom
            >
              Saturday & Sunday: 10am - 11:45am MT and 1pm - 3pm MT
            </Typography>

            <Typography
              sx={{
                fontSize: "14px",
              }}
              variant="body1"
              gutterBottom
            >
              or Call
            </Typography>
            <Typography
              sx={{
                fontSize: "14px",
              }}
              variant="body1"
              gutterBottom
            >
              <a href="tel:1-855-688-7269">1-226-961-4154</a>
            </Typography>
            <Typography
              sx={{
                fontSize: "14px",
              }}
              variant="body2"
            >
              Monday - Friday: 8am - 5pm MT
            </Typography>
          </Box>
          {/* <Grid container spacing={4}>
            {supportItems.map((item, index) => (
              <Grid item xs={12} sm={4} key={index}>
                <Box
                  textAlign="center"
                  p={3}
                  bgcolor="background.default"
                  color="primary.contrastText"
                  borderRadius={2}
                  onClick={() => navigate(item.path)}
                  sx={{
                    cursor: "pointer",
                    "&:hover": {
                      backgroundColor: "primary.main",
                      "& .MuiTypography-root": { color: "white" },
                      "& .MuiIconButton-root": { color: "white" },
                    },
                  }}
                >
                  <IconButton
                    size="large"
                    sx={{ color: "primary.dark", fontSize: "3rem" }}
                  >
                    {item.icon}
                  </IconButton>
                  <Typography
                    variant="h6"
                    component="div"
                    sx={{ color: "black", fontSize: "1.25rem" }}
                  >
                    {item.title}
                  </Typography>
                  <Typography
                    variant="body2"
                    component="div"
                    sx={{ color: "grey.800", fontSize: "1rem" }}
                  >
                    {item.description}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid> */}
        </Box>
      </Box>
    </Box>
  );
};

export default CustomerSupport;
