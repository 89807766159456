import React, { useState } from "react";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Chip,
} from "@mui/material";
import { DeviceErrors } from "../../common/types/DeviceTypes";

const DeviceErrorsHistoryTable: React.FC<{ deviceErrors: DeviceErrors[] }> = ({
  deviceErrors,
}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // Check for duplicates
  const ids = deviceErrors.map((error) => error._id);
  const uniqueIds = new Set(ids);
  if (ids.length !== uniqueIds.size) {
    console.log("Duplicate _id detected in deviceErrors data");
    console.log(ids);
  }
  return (
    <Box sx={{ p: 0 }}>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer>
          <Table stickyHeader aria-label="device table">
            <TableHead>
              <TableRow>
                <TableCell>#</TableCell>
                <TableCell>Serial Number</TableCell>
                <TableCell>Device Name</TableCell>
                <TableCell>Error Type</TableCell>
                <TableCell>Timestamp</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {deviceErrors
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <TableRow hover tabIndex={-1} key={row._id}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{row.serialNumber}</TableCell>
                      <TableCell>{row.deviceName}</TableCell>

                      <TableCell>
                        <Chip
                          label={row.error}
                          size="small"
                          sx={{
                            fontWeight: 600,
                            color: "#fff",
                            bgcolor:
                              row.error === "SERVICE"
                                ? "#f44336"
                                : row.error === "LOW FLOW"
                                ? "#ff9800"
                                : row.error === "LOW CARTRIDGE"
                                ? "#2196f3"
                                : "#4caf50",
                          }}
                        />
                      </TableCell>
                      <TableCell>
                        {new Date(row.date).toLocaleString()}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={deviceErrors.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
};

export default DeviceErrorsHistoryTable;
