import React, { useState, useEffect } from "react";
import { Box, FormControl, Autocomplete, TextField, Chip } from "@mui/material";
import { Customer } from "../../store/useCustomer";
import { DeviceData } from "../../common/types/DeviceTypes";
import { customerToDevices } from "../../services/bulkAssign.service";
import StyledButton from "../Tabs/StyledButton";

type AssignDevicesToCustomerProps = {
  devices: DeviceData[];
  customers: Customer[];
  onClose: () => void;
  onSuccess: (message: string) => void;
};

export default function AssignDevicesToCustomer({
  devices,
  customers,
  onClose,
  onSuccess,
}: AssignDevicesToCustomerProps) {
  const [selectedCustomer, setSelectedCustomer] = useState<Customer | null>(
    null
  );
  const [assignedDevices, setAssignedDevices] = useState<DeviceData[]>([]);
  const [availableDevices, setAvailableDevices] = useState<DeviceData[]>([]);

  useEffect(() => {
    if (selectedCustomer) {
      updateAvailableDevices(selectedCustomer);
    } else {
      setAvailableDevices(devices);
    }
  }, [selectedCustomer, devices]);

  const updateAvailableDevices = (customer: Customer) => {
    const filteredDevices = devices.filter(
      (device) =>
        device.deviceInformation?.customerEmail !== customer.email &&
        !device.deviceInformation?.additionalCustomers?.includes(customer.email)
    );
    setAvailableDevices(filteredDevices);
  };

  const handleCustomerSelect = (
    event: React.SyntheticEvent,
    value: Customer | null
  ) => {
    setSelectedCustomer(value);
    setAssignedDevices([]);
  };

  const handleDeviceAssignment = (
    event: React.SyntheticEvent,
    value: DeviceData[]
  ) => {
    setAssignedDevices(value);
  };

  const handleSaveAssignments = async () => {
    if (!selectedCustomer) return;

    const payload = {
      customer: selectedCustomer.email,
      deviceIds: assignedDevices.map(
        (device) => device.deviceInformation?.deviceId
      ),
    };

    try {
      await customerToDevices(payload);
      onSuccess("Devices assigned successfully");
    } catch (error) {
      console.error("Error assigning devices to customer:", error);
      // Add error handling here if needed
    }
  };

  return (
    <>
      <FormControl fullWidth variant="outlined" margin="dense">
        <Autocomplete
          options={customers}
          getOptionLabel={(option) =>
            `${option.firstName} ${option.lastName} - ${option.email}`
          }
          renderInput={(params) => (
            <TextField {...params} label="Select Customer" />
          )}
          value={selectedCustomer}
          onChange={handleCustomerSelect}
        />
      </FormControl>

      {selectedCustomer && (
        <Autocomplete
          multiple
          options={availableDevices}
          getOptionLabel={(option) =>
            option.deviceInformation?.deviceName || "Unknown Device"
          }
          value={assignedDevices}
          onChange={handleDeviceAssignment}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label="Select Devices"
              placeholder="Search devices"
              margin="normal"
            />
          )}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => {
              const { key, ...chipProps } = getTagProps({ index });
              return (
                <Chip
                  key={key}
                  label={
                    option.deviceInformation?.deviceName || "Unknown Device"
                  }
                  {...chipProps}
                />
              );
            })
          }
        />
      )}

      <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 1 }}>
        <StyledButton
          onClick={handleSaveAssignments}
          variantType="primary"
          disabled={!selectedCustomer || assignedDevices.length === 0}
        >
          Save Assignments
        </StyledButton>
      </Box>
    </>
  );
}
