import axios from "axios";
import {
  CreateProductWarranty,
  ProductWarranty,
  DeepPartial,
  CreateCustomerProductWarranty,
} from "../common/types/ProductWarrantyTypes";

const API_URL = process.env.REACT_APP_API_URL;

export const createProductWarranty = async (
  data: CreateProductWarranty
): Promise<ProductWarranty> => {
  try {
    const response = await axios.post(`${API_URL}/product-warranty`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Failed to create product warranty:", error);
    throw new Error("Failed to create product warranty");
  }
};

export const createCustomerProductWarranty = async (
  data: CreateCustomerProductWarranty
): Promise<ProductWarranty> => {
  try {
    const response = await axios.post(`${API_URL}/warranty/customer`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Failed to create customer product warranty:", error);
    throw new Error("Failed to create customer product warranty");
  }
};

export const fetchAllProductWarranties = async (): Promise<
  ProductWarranty[]
> => {
  try {
    const response = await axios.get(`${API_URL}/product-warranty`);
    return response.data;
  } catch (error) {
    console.error("Failed to fetch product warranties:", error);
    throw new Error("Failed to fetch product warranties");
  }
};

export const fetchDistributorInformation = async (
  email: string
): Promise<ProductWarranty> => {
  try {
    const response = await axios.get(
      `${API_URL}/product-warranty/distributor/${email}`
    );
    return response.data;
  } catch (error) {
    console.error("Failed to fetch distributor information:", error);
    throw new Error("Failed to fetch distributor information");
  }
};

export const updateProductWarrantyStatus = async (
  id: string,
  status: string
): Promise<ProductWarranty> => {
  try {
    const response = await axios.patch(
      `${API_URL}/product-warranty/${id}/status`,
      { status },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Failed to update product warranty status:", error);
    throw new Error("Failed to update product warranty status");
  }
};

// Service function for updating multiple warranties' statuses
export const updateMultipleProductWarrantyStatus = async (
  ids: string[], // Array of warranty IDs
  status: string // New status
): Promise<{ message: string }> => {
  try {
    const response = await axios.patch(
      `${API_URL}/product-warranty/bulk-status`,
      { ids, status }, // Correctly passing both `ids` and `status`
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(
      "Failed to update multiple product warranty statuses:",
      error
    );
    throw new Error("Failed to update multiple product warranty statuses");
  }
};

// Service function for updating multiple warranties' error codes and descriptions
export const updateBulkWarrantyErrors = async (
  ids: string[], // Array of warranty IDs
  returnReason: string, // New return reason
  errorCode: { code: string; description: string }[], // Array of error codes
  description?: string // New description
): Promise<{ message: string }> => {
  try {
    const response = await axios.patch(
      `${API_URL}/product-warranty/bulk-error`, // Your backend route for bulk updating error codes
      { ids, returnReason, errorCode }, // Passing the IDs, error codes (removed `description` field)
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Failed to update multiple product warranty errors:", error);
    throw new Error("Failed to update multiple product warranty errors");
  }
};

export const updateProductWarranty = async (
  id: string,
  updatedFields: DeepPartial<ProductWarranty> // Accepts DeepPartial<ProductWarranty>
): Promise<ProductWarranty> => {
  try {
    const response = await axios.patch(
      `${API_URL}/product-warranty/${id}`, // General endpoint for updating any field
      updatedFields, // Passing the fields to be updated
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return response.data; // Return the updated warranty data
  } catch (error) {
    throw error; // Throw the error
  }
};

export const fetchDeviceArrivals = async (
  email: string,
  role: string
): Promise<any> => {
  try {
    const response = await axios.get(`${API_URL}/device-arrivals/all`, {
      params: { email, role },
    });
    // Sort the claims by submittedDate in descending order
    const sortedClaims = response.data.sort(
      (a: any, b: any) =>
        new Date(b.submittedDate).getTime() -
        new Date(a.submittedDate).getTime()
    );
    return sortedClaims;
  } catch (error) {
    console.error("Failed to fetch warranty claims:", error);
    throw new Error("Failed to fetch warranty claims");
  }
};

export const updateDeviceArrivalStatus = async (
  id: string,
  status: string
): Promise<any> => {
  try {
    const response = await axios.patch(
      `${API_URL}/device-arrivals/${id}/status`,
      { status },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Failed to update device arrival status:", error);
    throw new Error("Failed to update device arrival status");
  }
};

interface ScanDeviceArrivalsRequest {
  serialNumbers: string[];
}

export const scanDeviceArrivals = async (
  data: ScanDeviceArrivalsRequest
): Promise<any> => {
  try {
    const response = await axios.post(`${API_URL}/device-arrivals/scan`, data);
    return response.data;
  } catch (error) {
    console.error("Failed to scan device arrivals:", error);
    throw error; // Keep the original Axios error
  }
};

interface ConfirmDeviceArrivalsRequest {
  warrantyIds: string[];
}

export const confirmDeviceArrival = async (
  data: ConfirmDeviceArrivalsRequest
): Promise<any> => {
  try {
    const response = await axios.post(
      `${API_URL}/device-arrivals/confirm`,
      data
    );
    return response.data;
  } catch (error) {
    console.error("Failed to confirm device arrivals:", error);
    throw new Error("Failed to confirm device arrivals");
  }
};

// Service function for uploading invalid serial numbers to Google Sheets
export const uploadToGoogleSheet = async (
  data: any
): Promise<{ status: string }> => {
  try {
    // API_URL should already be set up for the base server URL
    const response = await axios.post(
      `${API_URL}/device-arrivals/upload-to-google-sheet`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Failed to upload data to Google Sheets:", error);
    throw new Error("Failed to upload data to Google Sheets");
  }
};
