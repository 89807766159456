import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Button,
  Tooltip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Checkbox,
  Snackbar,
  Alert,
  AlertColor,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Modal,
  TextField,
  InputAdornment,
} from "@mui/material";
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  OpenInNew as OpenIcon,
  DriveFileRenameOutline as DriveFileRenameOutlineIcon,
  Description as DescriptionIcon,
} from "@mui/icons-material";
import { deleteReport, getAllReports } from "../../services/reports.service";
import useAuthStore from "../../store/authStore";
import openPDFWindow from "../../components/Reports/OpenPDFWindow";
import Papa from "papaparse";
import StyledIconButton from "../Tabs/StyledIconButton";

interface SalesReportData {
  _id: string;
  reportInformation: {
    name: string;
    description?: string;
    reportType: "ISS";
    generatedAt: Date;
    generatedBy: string;
    startDate?: Date;
    endDate?: Date;
  };
  reportData: any;
  reportCategory: "SalesReport";
}

const SalesReportHistory: React.FC = () => {
  const [data, setData] = useState<SalesReportData[]>([]);
  const [selected, setSelected] = useState<string[]>([]);
  const [selectedForDeletion, setSelectedForDeletion] = useState<string | null>(
    null
  );
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success" as AlertColor,
  });
  const { user } = useAuthStore();
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [selectedReport, setSelectedReport] = useState<SalesReportData | null>(
    null
  );
  const [editedName, setEditedName] = useState<string>("");
  const [editedDescription, setEditedDescription] = useState<string>("");

  const fetchData = useCallback(async () => {
    try {
      const response = await getAllReports(user?.email || "", user?.role || "");
      const filteredData = response.filter(
        (report: SalesReportData) => report.reportCategory === "SalesReport"
      );
      setData(filteredData);
    } catch (error) {
      console.error("Error fetching sales reports:", error);
    }
  }, [user]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleOpenDeleteModal = (id: string) => {
    const report = data.find((report) => report._id === id);
    if (report) {
      setSelectedForDeletion(report._id);
      setDeleteModalOpen(true);
    }
  };

  const handleDelete = async (id: string) => {
    try {
      await deleteReport(id);
      setData((prevData) => prevData.filter((report) => report._id !== id));
      setSnackbar({
        open: true,
        message: "Report deleted successfully",
        severity: "success",
      });
    } catch (error) {
      console.error("Error deleting report:", error);
      setSnackbar({
        open: true,
        message: "Failed to delete report",
        severity: "error",
      });
    } finally {
      setDeleteModalOpen(false);
    }
  };

  const handleEdit = (id: string) => {
    const report = data.find((report) => report._id === id);
    if (report) {
      setSelectedReport(report);
      setEditedName(report.reportInformation.name);
      setEditedDescription(report.reportInformation.description || "");
      setEditModalOpen(true);
    }
  };

  const handleSave = () => {
    if (selectedReport) {
      const updatedReport = {
        ...selectedReport,
        reportInformation: {
          ...selectedReport.reportInformation,
          name: editedName,
          description: editedDescription,
        },
      };
      setData((prevData) =>
        prevData.map((report) =>
          report._id === selectedReport._id ? updatedReport : report
        )
      );
      setEditModalOpen(false);
    }
  };

  const exportToCSV = (report: SalesReportData) => {
    if (
      Array.isArray(report.reportData?.data) &&
      report.reportData.data.length > 0
    ) {
      // Prepare the general information as key-value pairs
      const generalFields = {
        "Start Date": report.reportInformation.startDate
          ? new Date(report.reportInformation.startDate).toLocaleDateString()
          : "N/A",
        "End Date": report.reportInformation.endDate
          ? new Date(report.reportInformation.endDate).toLocaleDateString()
          : "N/A",
        "Report Date": new Date(
          report.reportInformation.generatedAt
        ).toLocaleDateString(),
        "Generated By": report.reportInformation.generatedBy,
        "Report Type": report.reportInformation.reportType,
      };

      // Convert general information to a CSV-friendly format
      const generalInfoCsv = Object.entries(generalFields)
        .map(([key, value]) => `${key}: ${value}`)
        .join("\n");

      // Flatten the `quarters` object for each data row
      const csvData = report.reportData.data.map((item: any) => {
        const { quarters, ...otherData } = item;

        // Ensure quarters are handled correctly
        const quartersFlattened = quarters
          ? {
            Q1: quarters.Q1 || 0,
            Q2: quarters.Q2 || 0,
            Q3: quarters.Q3 || 0,
            Q4: quarters.Q4 || 0,
          }
          : { Q1: 0, Q2: 0, Q3: 0, Q4: 0 };

        return {
          ...otherData,
          ...quartersFlattened,
        };
      });

      // Convert the report data table to CSV
      const dataCsv = Papa.unparse(csvData);

      // Combine the general info and the report data, separated by \n\n
      const completeCsv = `${generalInfoCsv}\n\n${dataCsv}`;

      // Create a blob from the complete CSV string
      const blob = new Blob([completeCsv], { type: "text/csv;charset=utf-8;" });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${report.reportInformation.name}.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  return (
    <Box sx={{ p: 0 }}>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity={snackbar.severity} sx={{ width: "100%" }}>
          {snackbar.message}
        </Alert>
      </Snackbar>

      <Paper sx={{ width: "100%", overflow: "hidden", mt: 2 }}>
        <TableContainer>
          <Table stickyHeader aria-label="sales report history table">
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    indeterminate={
                      selected.length > 0 && selected.length < data.length
                    }
                    checked={data.length > 0 && selected.length === data.length}
                    onChange={(e) =>
                      setSelected(
                        e.target.checked ? data.map((row) => row._id) : []
                      )
                    }
                  />
                </TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Report Type</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Generated By</TableCell>
                <TableCell align="center">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => (
                  <TableRow
                    hover
                    role="checkbox"
                    key={row._id}
                    selected={selected.includes(row._id)}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={selected.includes(row._id)}
                        onChange={() =>
                          setSelected((s) =>
                            s.includes(row._id)
                              ? s.filter((id) => id !== row._id)
                              : [...s, row._id]
                          )
                        }
                      />
                    </TableCell>
                    <TableCell>{row.reportInformation.name}</TableCell>
                    <TableCell>{row.reportInformation.description}</TableCell>
                    <TableCell>{row.reportInformation.reportType}</TableCell>
                    <TableCell>
                      {new Date(
                        row.reportInformation.generatedAt
                      ).toLocaleDateString()}
                    </TableCell>
                    <TableCell>{row.reportInformation.generatedBy}</TableCell>
                    <TableCell align="center">
                      <Tooltip title="Open">
                        <StyledIconButton variantType="transparent" onClick={() => openPDFWindow(row)}>
                          <DescriptionIcon />
                        </StyledIconButton>
                      </Tooltip>
                      <Tooltip title="Export to CSV">
                        <StyledIconButton variantType="transparent" onClick={() => exportToCSV(row)}>
                          <OpenIcon />
                        </StyledIconButton>
                      </Tooltip>
                      <Tooltip title="Edit">
                        <StyledIconButton variantType="transparent" onClick={() => handleEdit(row._id)}>
                          <EditIcon />
                        </StyledIconButton>
                      </Tooltip>
                      <Tooltip title="Delete">
                        <StyledIconButton
                          variantType="transparent"
                          onClick={() => handleOpenDeleteModal(row._id)}
                        >
                          <DeleteIcon />
                        </StyledIconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(_, newPage) => setPage(newPage)}
          onRowsPerPageChange={(e) => {
            setRowsPerPage(parseInt(e.target.value, 10));
            setPage(0);
          }}
        />
      </Paper>

      <Dialog
        open={isDeleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
      >
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this report?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteModalOpen(false)} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() =>
              selectedForDeletion && handleDelete(selectedForDeletion)
            }
            color="error"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Modal open={isEditModalOpen} onClose={() => setEditModalOpen(false)}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { xs: 220, sm: 400 },
            bgcolor: "background.paper",
            border: "1px solid gray",
            boxShadow: 24,
            pl: 4,
            pr: 4,
            pb: 4,
            borderRadius: 1,
          }}
        >
          <h3>Edit Report Details</h3>
          <TextField
            fullWidth
            margin="normal"
            label="Report Name"
            value={editedName}
            onChange={(e) => setEditedName(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <DriveFileRenameOutlineIcon />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            fullWidth
            margin="normal"
            label="Description"
            multiline
            rows={4}
            value={editedDescription}
            onChange={(e) => setEditedDescription(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <DescriptionIcon />
                </InputAdornment>
              ),
            }}
          />
          <Box sx={{ mt: 2, display: "flex", justifyContent: "flex-end" }}>
            <Button onClick={() => setEditModalOpen(false)} color="primary">
              Cancel
            </Button>
            <Button
              onClick={handleSave}
              color="primary"
              variant="contained"
              sx={{ ml: 2 }}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default SalesReportHistory;
