import React from "react";
import {
  Grid,
  ListItemText,
  ListItemSecondaryAction,
  Typography,
  ListItemButton,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { DeviceNotificationType } from "./AllNotifications";
import dayjs from "dayjs";

import QueryBuilderTwoToneIcon from "@mui/icons-material/QueryBuilderTwoTone";

interface NotificationItemProps {
  notification: DeviceNotificationType;
}

const NotificationItem: React.FC<NotificationItemProps> = ({
  notification,
}) => {
  const theme = useTheme();

  const getNotificationColor = (type: string) => {
    switch (type.toLowerCase()) {
      case "alert":
        return "yellow";
      case "warning":
        return "orange";
      case "error":
        return "red";
      case "info":
      default:
        return "blue";
    }
  };

  const notificationColor = getNotificationColor(notification.type);

  return (
    <ListItemButton
      sx={{
        px: 0,
        borderBottom: `1px solid ${theme.palette.divider}`,
        backgroundColor: notification.recipients[0].isRead
          ? "white"
          : "#ebf8fe",
      }}
    >
      <ListItemText
        primary={
          <Typography variant="h6" sx={{ color: notificationColor }}>
            {notification.type}: {notification.title}
          </Typography>
        }
        secondary={
          <Typography variant="subtitle2">{notification.message}</Typography>
        }
        sx={{ ml: 1 }}
      />
      <ListItemSecondaryAction
        sx={{ top: "50%", transform: "translateY(-50%)" }}
      >
        <Grid
          container
          justifyContent="flex-end"
          alignItems="center"
          spacing={1}
        >
          <Grid item>
            <QueryBuilderTwoToneIcon
              sx={{
                color: notificationColor,
              }}
            />
          </Grid>
          <Grid item>
            <Typography variant="subtitle2">
              {dayjs(notification.createdAt).fromNow()}
            </Typography>
          </Grid>
        </Grid>
      </ListItemSecondaryAction>
    </ListItemButton>
  );
};

export default NotificationItem;
