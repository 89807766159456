import React from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import { Paper, Typography, Divider, Box } from "@mui/material";
import L from "leaflet";
import "leaflet/dist/leaflet.css";

// Adjusting the Props to expect id as string
type Props = {
  center: { lat: number; lng: number };
  zoom: number;
  height: string;
  markers: Array<{
    id: string; // Changed from number to string
    position: { lat: number; lng: number };
    name: string;
  }>;
};

const MapView: React.FC<Props> = ({ center, zoom, height, markers }) => {
  const defaultIcon = L.icon({
    iconUrl: require("leaflet/dist/images/marker-icon.png"),
    iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
    shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    tooltipAnchor: [16, -28],
    shadowSize: [41, 41],
  });

  return (
    <Box sx={{ bgcolor: "white", p: 1, borderRadius: 1 }}>
      <MapContainer
        center={center}
        zoom={zoom}
        style={{ height: height, width: "100%" }}
      >
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        {markers.map((marker) => (
          <Marker key={marker.id} position={marker.position} icon={defaultIcon}>
            <Popup>{marker.name}</Popup>
          </Marker>
        ))}
      </MapContainer>
    </Box>
  );
};

export default MapView;
