import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Box,
  LinearProgress,
  useTheme,
} from "@mui/material";
import { LineChart, Line, XAxis, Tooltip, ResponsiveContainer } from "recharts";

// Define types for the data model
interface StatsData {
  totalDevices: number;
  activeDevices: number;
  onlineDevices: number;
  offlineDevices: number;
  averageRunSec: number;
  totalDistributors: number;
  growthData: { day: string; devices: number }[];
}

// Props for the StatsCard component
interface StatsCardProps {
  title: string;
  value: number;
  progress?: number;
  color?: string;
  chartData?: { day: string; devices: number }[];
}

// Fetch data function, simulated as async for real API call simulation
const fetchData = async (): Promise<StatsData> => {
  return Promise.resolve({
    totalDevices: 1500,
    activeDevices: 1200,
    onlineDevices: 1100,
    offlineDevices: 100,
    averageRunSec: 4500,
    totalDistributors: 45,
    growthData: [
      { day: "Mon", devices: 300 },
      { day: "Tue", devices: 500 },
      { day: "Wed", devices: 700 },
      { day: "Thu", devices: 600 },
      { day: "Fri", devices: 1100 },
    ],
  });
};

// StatsCard component for displaying individual statistics
const StatsCard: React.FC<StatsCardProps> = ({
  title,
  value,
  progress,
  color,
  chartData,
}) => {
  const theme = useTheme();

  return (
    <Card
      elevation={0}
      sx={{ minWidth: 275, m: 1, bgcolor: "white", borderRadius: 0 }}
    >
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          {title}
        </Typography>
        <Typography variant="h5" component="div">
          {value}
        </Typography>
        {progress && color && (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box sx={{ width: "100%", mr: 1 }}>
              <LinearProgress
                variant="determinate"
                value={progress}
                sx={{ bgcolor: color }}
              />
            </Box>
            <Box sx={{ minWidth: 35 }}>
              <Typography variant="body2" color="text.secondary">{`${Math.round(
                progress
              )}%`}</Typography>
            </Box>
          </Box>
        )}
        {chartData && (
          <ResponsiveContainer width="100%" height={100}>
            <LineChart data={chartData}>
              <Line
                type="monotone"
                dataKey="devices"
                stroke={theme.palette.primary.main}
                strokeWidth={2}
              />
              <XAxis dataKey="day" />
              <Tooltip />
            </LineChart>
          </ResponsiveContainer>
        )}
      </CardContent>
    </Card>
  );
};

// Main component to manage the device statistics dashboard
const DeviceReportStats: React.FC = () => {
  const [data, setData] = useState<StatsData>({
    totalDevices: 0,
    activeDevices: 0,
    onlineDevices: 0,
    offlineDevices: 0,
    averageRunSec: 0,
    totalDistributors: 0,
    growthData: [],
  });

  useEffect(() => {
    fetchData().then(setData);
  }, []);

  return (
    <Box sx={{ display: "flex", flexWrap: "wrap" }}>
      <StatsCard
        title="Total Devices"
        value={data.totalDevices}
        chartData={data.growthData}
      />
      <StatsCard
        title="Active Devices"
        value={data.activeDevices}
        progress={(data.activeDevices / data.totalDevices) * 100}
      />
      <StatsCard
        title="Online Devices"
        value={data.onlineDevices}
        progress={(data.onlineDevices / data.totalDevices) * 100}
      />
      <StatsCard
        title="Offline Devices"
        value={data.offlineDevices}
        progress={(data.offlineDevices / data.totalDevices) * 100}
      />
      <StatsCard
        title="Average Run Seconds"
        value={data.averageRunSec}
        progress={50}
      />
      <StatsCard
        title="Total Distributors"
        value={data.totalDistributors}
        progress={70}
      />
    </Box>
  );
};

export default DeviceReportStats;
