import React, { useState, useEffect } from "react";
import { PDFViewer } from "@react-pdf/renderer";
import ReportTemplate from "./ReportTemplate";
import InventoryReportTemplate from "./InventoryReportTemplate";
import WarrantyReportTemplate from "./WarrantyReportTemplate";
import { Box } from "@mui/material";
import SalesReportTemplate from "./SalesReportTemplate";

interface ReportData {
  _id: string;
  reportInformation: {
    name: string;
    description?: string;
    reportType: string;
    generatedAt: Date;
    generatedBy: string;
    startDate?: Date;
    endDate?: Date;
    devices?: string[];
    distributors?: string[];
  };
  reportData: any; // This should be typed based on the structure of your data
  reportCategory?: string;
}

interface PDFWindowViewerProps {
  selectedReport: ReportData;
  showToolbar?: boolean;
}

const PDFWindowViewer: React.FC<PDFWindowViewerProps> = ({
  selectedReport,
  showToolbar = true,
}) => {
  const { reportData } = selectedReport;
  const data = reportData.data;

  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      <PDFViewer width="100%" height="100%" showToolbar={showToolbar}>
        <>
          {selectedReport.reportCategory === "InventoryReport" && (
            <InventoryReportTemplate
              reportData={data}
              startDate={
                selectedReport.reportInformation.startDate || new Date()
              }
              endDate={selectedReport.reportInformation.endDate || new Date()}
              generatedBy={selectedReport.reportInformation.generatedBy}
              generatedAt={selectedReport.reportInformation.generatedAt}
              reportName={selectedReport.reportInformation.name}
              reportType={
                selectedReport.reportInformation.reportType as
                  | "Used"
                  | "Recovered"
                  | "Delta"
              }
            />
          )}
          {selectedReport.reportCategory === "WarrantyReport" && (
            <WarrantyReportTemplate
              reportData={data}
              startDate={
                selectedReport.reportInformation.startDate || new Date()
              }
              endDate={selectedReport.reportInformation.endDate || new Date()}
              generatedBy={selectedReport.reportInformation.generatedBy}
              generatedAt={selectedReport.reportInformation.generatedAt}
              reportName={selectedReport.reportInformation.name}
              reportType={
                selectedReport.reportInformation.reportType as
                  | "Credit"
                  | "Return"
                  | "Invalid Credit"
              }
            />
          )}
          {selectedReport.reportCategory === "SalesReport" && (
            <SalesReportTemplate
              reportData={data}
              startDate={
                selectedReport.reportInformation.startDate || new Date()
              }
              endDate={selectedReport.reportInformation.endDate || new Date()}
              generatedBy={selectedReport.reportInformation.generatedBy}
              generatedAt={selectedReport.reportInformation.generatedAt}
              reportName={selectedReport.reportInformation.name}
            />
          )}
          {selectedReport.reportCategory !== "InventoryReport" &&
            selectedReport.reportCategory !== "WarrantyReport" &&
            selectedReport.reportCategory !== "SalesReport" && (
              <ReportTemplate
                reportType={selectedReport.reportInformation.reportType}
                reportData={data}
                startDate={
                  selectedReport.reportInformation.startDate || new Date()
                }
                endDate={selectedReport.reportInformation.endDate || new Date()}
                generatedBy={selectedReport.reportInformation.generatedBy}
                generatedAt={selectedReport.reportInformation.generatedAt}
                devices={selectedReport.reportInformation.devices || []}
                distributors={selectedReport.reportInformation.distributors}
                reportName={selectedReport.reportInformation.name}
              />
            )}
        </>
      </PDFViewer>
    </Box>
  );
};

export default PDFWindowViewer;
