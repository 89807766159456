import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  useTheme
} from "@mui/material";
import dayjs from "dayjs";
import IcleanDeviceTable from "./Charts/IcleanDeviceTable";
import DynamicBarGraph from "./Charts/DynamicBarGraph";
import ICleanLinesMetricGraph from "./Charts/iCleanLinesMetricGraph";
import TimeDynamicGraph from "./Charts/TimeDynamicGraph";
import useDeviceStore from "../../store/useDeviceStore";
import TotalUsageCard from "./Charts/TotalUsageCard";
import AutoCycle from "../Cards/AutoCycle";
import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

const ICleanLinesDashboard: React.FC = () => {
  const {
    iCleanDevices,
    selectedIcleanDevices,
    dateFilter,
    setDateFilter,
    intervalFilter,
    setIntervalFilter,
    fetchDevices,
  } = useDeviceStore();

  const [deviceData, setDeviceData] = useState([]);
  const [isGallons, setIsGallons] = useState(false);
  const [selectedBarDate, setSelectedBarDate] = useState<Date | null>(null);
  const handleBarClick = (date: Date) => setSelectedBarDate(date);
  const theme = useTheme();

  const selectedDate =
    intervalFilter === "daily" && selectedBarDate
      ? dayjs(selectedBarDate)
      : dayjs(dateFilter);

  useEffect(() => {
    fetchDevices();
  }, [fetchDevices]);

  // Function to fetch device data from the API
  const fetchDeviceData = async () => {
    try {
      const response = await axios.get(`${API_URL}/old-icleanlines/data`);
      setDeviceData(response.data);
    } catch (error) {
      console.error("Failed to fetch device data:", error);
    }
  };

  useEffect(() => {
    fetchDeviceData();
  }, []);

  return (
    <Box>
      <Grid container spacing={1.25} alignItems="flex-start">
        {/* Left Column */}
        <Grid item xs={12} md={6}>
          <Grid
            container
            spacing={1.25}
            sx={{
              display: 'flex',
              alignItems: 'stretch',
            }}
          >
            <Grid item xs={12} lg={6} sx={{ display: 'flex' }}>
              <AutoCycle />
            </Grid>
            <Grid item xs={12} lg={6} sx={{ display: 'flex' }}>
              <TotalUsageCard
                selectedDevices={selectedIcleanDevices}
                isGallons={isGallons}
                color={theme.palette.success.main}
              />
            </Grid>
            <Grid item xs={12}>
              <ICleanLinesMetricGraph
                interval={intervalFilter}
                selectedDevices={selectedIcleanDevices}
                dateFilter={dateFilter}
                onBarClick={handleBarClick}
              />
            </Grid>
            <Grid item xs={12}>
              <TimeDynamicGraph
                selectedDate={selectedDate}
                selectedDevices={selectedIcleanDevices}
                isGallons={isGallons}
              />
            </Grid>
          </Grid>
        </Grid>
        {/* Right Column */}
        <Grid item xs={12} md={6}>
          <Grid container spacing={1.25}>
            <Grid item xs={12}>
              <IcleanDeviceTable
                devices={iCleanDevices}
                dateFilter={dateFilter}
                intervalFilter={intervalFilter}
                isGallons={isGallons}
                setIsGallons={() => setIsGallons(!isGallons)}
              />
            </Grid>
            <Grid item xs={12}>
              {/* <MapView center={center} zoom={zoom} markers={markers} /> */}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ICleanLinesDashboard;
