import React, { useState } from "react";
import {
  Modal,
  Box,
  Typography,
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormLabel,
  Divider,
  TextField,
  FormControl,
  Grid,
} from "@mui/material";
import { DeviceData, TestStatus } from "../../common/types/DeviceTypes";
import { updateDeviceTestStatus } from "../../services/device.service";
import {
  updateTestStatusPayload,
  TestComponentStatusPayload,
} from "../../common/types/payload";

interface TestModalProps {
  open: boolean;
  onClose: () => void;
  submitTestStatus: (
    deviceId: string,
    testStatus: updateTestStatusPayload
  ) => void;
  device: DeviceData;
}

interface TestStatusState {
  frontCover: TestComponentStatusPayload;
  filterAlarm: TestComponentStatusPayload;
  ribbonCable: TestComponentStatusPayload;
  offGasHasNoWater: TestComponentStatusPayload;
  valvesPresent: TestComponentStatusPayload;
  ozoneGeneratorPerMin: TestComponentStatusPayload;
}

const testDescriptions: Record<keyof TestStatusState, string> = {
  frontCover:
    "1. Check that the front cover assembly and LEDs are properly configured.",
  filterAlarm: "2. Ensure the filter alarm system is functional.",
  ribbonCable: "3. Verify the security and connection of the ribbon cable.",
  offGasHasNoWater:
    "4. Confirm no water leakage past float to carulite stack in offgas.",
  valvesPresent:
    "5. Inspect if all necessary valves are present and operational.",
  ozoneGeneratorPerMin: "6. Test ozone generator efficiency at 10,000 PPM.",
};

const TestModal: React.FC<TestModalProps> = ({
  open,
  onClose,
  submitTestStatus,
  device,
}) => {
  const [testStatus, setTestStatus] = useState<TestStatusState>({
    frontCover: {
      testStatus: device.testStatus.frontCover.testStatus || "UNKNOWN",
      reasonForFailure: device.testStatus.frontCover.reasonForFailure || "",
    },
    filterAlarm: {
      testStatus: device.testStatus.filterAlarm.testStatus || "UNKNOWN",
      reasonForFailure: device.testStatus.filterAlarm.reasonForFailure || "",
    },
    ribbonCable: {
      testStatus: device.testStatus.ribbonCable.testStatus || "UNKNOWN",
      reasonForFailure: device.testStatus.ribbonCable.reasonForFailure || "",
    },
    offGasHasNoWater: {
      testStatus: device.testStatus.offGasHasNoWater.testStatus || "UNKNOWN",
      reasonForFailure:
        device.testStatus.offGasHasNoWater.reasonForFailure || "",
    },
    valvesPresent: {
      testStatus: device.testStatus.valvesPresent.testStatus || "UNKNOWN",
      reasonForFailure: device.testStatus.valvesPresent.reasonForFailure || "",
    },
    ozoneGeneratorPerMin: {
      testStatus:
        device.testStatus.ozoneGeneratorPerMin.testStatus || "UNKNOWN",
      reasonForFailure:
        device.testStatus.ozoneGeneratorPerMin.reasonForFailure || "",
    },
  });

  const handleChange = (
    test: keyof TestStatusState,
    value: "UNKNOWN" | "PASSED" | "FAILED",
    reasonForFailure: string = ""
  ) => {
    setTestStatus((prev) => ({
      ...prev,
      [test]: {
        testStatus: value,
        reasonForFailure: value === "FAILED" ? reasonForFailure : "",
      },
    }));
  };

  const handleSubmit = async () => {
    await submitTestStatus(device.deviceInformation.deviceId, testStatus);
    onClose();
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: { xs: "80vw", sm: "auto", md: "auto" }, // Responsive width
          maxWidth: "90vw",
          maxHeight: "90vh",
          bgcolor: "background.paper",
          boxShadow: 24,
          overflowY: "auto", // Enable vertical scrolling
          p: 4,
        }}
      >
        <Typography variant="h6">HCU Product Testing Checklist</Typography>
        <Typography variant="body2" sx={{ mb: 2, color: "text.secondary" }}>
          Functionality Tests - choose whether each check passed or failed and
          reason for failure
        </Typography>
        <Divider sx={{ mb: 2 }} />
        <Grid container spacing={2}>
          {Object.keys(testStatus).map((key) => (
            <Grid item xs={12} sm={6} md={4} key={key}>
              <FormLabel component="legend">
                {testDescriptions[key as keyof TestStatusState]}
              </FormLabel>
              <RadioGroup
                row
                name={key}
                value={testStatus[key as keyof TestStatusState].testStatus}
                onChange={(e) =>
                  handleChange(
                    key as keyof TestStatusState,
                    e.target.value as "UNKNOWN" | "PASSED" | "FAILED"
                  )
                }
              >
                <FormControlLabel
                  value="PASSED"
                  control={<Radio />}
                  label="Passed"
                />
                <FormControlLabel
                  value="FAILED"
                  control={<Radio />}
                  label="Failed"
                />
                <FormControlLabel
                  value="UNKNOWN"
                  control={<Radio />}
                  label="Unknown"
                />
              </RadioGroup>
              <TextField
                fullWidth
                label="Reason for Failure (optional)"
                variant="outlined"
                size="small"
                margin="dense"
                disabled={
                  testStatus[key as keyof TestStatusState].testStatus !==
                  "FAILED"
                }
                value={
                  testStatus[key as keyof TestStatusState].reasonForFailure
                }
                onChange={(e) =>
                  handleChange(
                    key as keyof TestStatusState,
                    testStatus[key as keyof TestStatusState].testStatus,
                    e.target.value
                  )
                }
              />
            </Grid>
          ))}
        </Grid>
        <Box sx={{ mt: 2, display: "flex", justifyContent: "flex-end" }}>
          <Button variant="contained" onClick={handleSubmit}>
            Submit
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default TestModal;
