import React, { act, useEffect, useState } from "react";
import {
  Box,
  Typography,
  Snackbar,
  Alert,
  AlertColor,
  TableContainer,
  TextField,
  IconButton,
  Tooltip,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
  Chip,
  TablePagination,
  Modal,
  Paper,
  Grid,
  Button,
  Tabs,
  Tab,
  ListItemIcon,
  SelectChangeEvent,
  styled,
} from "@mui/material";
import {
  Search as SearchIcon,
  MoreVert as MoreVertIcon,
  FilterList as FilterListIcon,
  CloudDownload as CloudDownloadIcon,
  ViewComfy as ViewComfyIcon,
  Add as AddIcon,
  Delete as DeleteIcon,
  CheckCircleOutline as CheckCircleOutlineIcon,
  HourglassEmpty as HourglassEmptyIcon,
  AllInbox as AllInboxIcon,
  Close as CloseIcon,
  AssignmentTurnedIn as AssignmentTurnedInIcon,
  Reply as ReplyIcon,
} from "@mui/icons-material";
import PlainTab from "../Tabs/PlainTab";
import { StyledTabs } from "../Tabs/IconTab";
import ListIcon from "@mui/icons-material/List";
import Inventory2Icon from "@mui/icons-material/Inventory2";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import ChatIcon from "@mui/icons-material/Chat";
import DistributorSupport from "../DistributorSupport.tsx/DistributorSupport";
import { fetchWarrantyClaims } from "../../services/warranty.service";
import { DeviceData } from "../../common/types/DeviceTypes";
import useAuthStore from "../../store/authStore";
import HistoryIcon from "@mui/icons-material/History";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ArchiveIcon from "@mui/icons-material/Archive";
import WarrantyReportHistoryTable from "../Reports/WarrantyReportHistory";
import GenerateWarrantyReport from "../Reports/GenerateWarrantyReport";

interface WarrantyClaimData {
  serialNo: string;
  distributorEmail: string;
  customerEmail: string;
  faultType: string;
  status: string;
  submittedDate: string;
  updatedAt: string;
  detailedIssue?: string;
  photo?: string;
  _id: string;
}

const IconWrapper = styled(ListItemIcon)({
  justifyContent: "center",
  minWidth: "30px", // Ensures icons are properly aligned
  "& svg": {
    fill: "#9e9e9e", // Grey fill for the icon
    stroke: "black", // Black stroke color
    strokeWidth: "1px", // Thicker stroke to make it stand out
  },
  "&:hover": {
    color: "primary.main", // Light blue background on hover
    bgColor: "primary.main",
  },
});

interface ServiceReportsProps {
  devices: DeviceData[];
}

export const ServiceReports: React.FC<ServiceReportsProps> = ({ devices }) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [snackbar, setSnackbar] = useState<{
    open: boolean;
    message: string;
    severity: AlertColor | undefined;
  }>({ open: false, message: "", severity: undefined });

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
    localStorage.setItem("selectedServiceReportsTab", newValue.toString());
  };

  const [value, setValue] = useState(0);
  const [selected, setSelected] = useState<string[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search, setSearch] = useState("");
  const [sortField, setSortField] = useState<string>("");
  const [claims, setClaims] = useState<WarrantyClaimData[]>([]);
  const [selectedClaim, setSelectedClaim] = useState<WarrantyClaimData | null>(
    null
  );
  const [openModal, setOpenModal] = useState(false);
  const { user } = useAuthStore.getState();

  useEffect(() => {
    const fetchClaims = async () => {
      try {
        if (user && selectedTab === 1) {
          // Check if the user is defined and the "Warranty Claims" tab is selected
          const response = await fetchWarrantyClaims(user.email, user.role);
          setClaims(response);
        }
      } catch (error) {
        console.error("Error fetching warranty claims:", error);
      }
    };

    fetchClaims();
  }, [user, selectedTab]); // Add selectedTab to dependencies

  const handleOpenModal = (claim: WarrantyClaimData) => {
    setSelectedClaim(claim);
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedClaim(null);
  };

  const handleSelect = (name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = [...selected, name];
    } else {
      newSelected = selected.filter((id) => id !== name);
    }

    setSelected(newSelected);
  };

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setSelected(claims.map((claim) => claim.serialNo));
    } else {
      setSelected([]);
    }
  };

  const isSelected = (serialNo: string) => selected.includes(serialNo);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    setPage(0); // Reset the page to 0 when changing tabs
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const handleSortChange = (event: SelectChangeEvent<string>) => {
    setSortField(event.target.value as string);
  };

  const filteredClaims = claims.filter(
    (claim) =>
      claim.distributorEmail === user?.email && // Filter by logged-in user's email
      (claim.serialNo.toLowerCase().includes(search.toLowerCase()) ||
        claim.distributorEmail.toLowerCase().includes(search.toLowerCase()) ||
        claim.customerEmail.toLowerCase().includes(search.toLowerCase()) ||
        claim.faultType.toLowerCase().includes(search.toLowerCase()))
  );
  const sortedClaims = filteredClaims.sort((a, b) => {
    if (sortField === "serialNo") {
      return a.serialNo.localeCompare(b.serialNo);
    }
    if (sortField === "distributorEmail") {
      return a.distributorEmail.localeCompare(b.distributorEmail);
    }
    if (sortField === "customerEmail") {
      return a.customerEmail.localeCompare(b.customerEmail);
    }
    if (sortField === "faultType") {
      return a.faultType.localeCompare(b.faultType);
    }
    if (sortField === "status") {
      return a.status.localeCompare(b.status);
    }
    if (sortField === "submittedDate") {
      return (
        new Date(a.submittedDate).getTime() -
        new Date(b.submittedDate).getTime()
      );
    }
    return 0;
  });
  const getDisplayedClaims = () => {
    switch (value) {
      case 1:
        return sortedClaims.filter((claim) => claim.status === "Approved");
      case 2:
        return sortedClaims.filter(
          (claim) =>
            claim.status === "Initial review" ||
            claim.status === "Technical assessment"
        );
      case 3:
        return sortedClaims.filter((claim) => claim.status === "Closed");
      default:
        return sortedClaims;
    }
  };

  const displayedClaims = getDisplayedClaims().slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  return (
    <Box p={0}>
      <Box mt={0} sx={{ display: "flex", justifyContent: "center" }}>
        <Box
          mt={2}
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            width: { xs: "96%", md: "95%" },
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              p: 1,
              mb: 1,
            }}
          >
            <Typography variant="h5">Service Reports</Typography>
          </Box>

          <StyledTabs
            value={selectedTab}
            onChange={handleChangeTab}
            aria-label="Profile tabs"
            variant="scrollable" // Enable scrolling
            scrollButtons="auto" // Enable auto scrolling
          >
            <PlainTab label="Reports" />
            <PlainTab label="Generate Report" />
            <PlainTab label="Schedule Reports" />
            <PlainTab label="Archive Reports" />
          </StyledTabs>

          <Snackbar
            open={snackbar.open}
            autoHideDuration={6000}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            onClose={() => setSnackbar({ ...snackbar, open: false })}
          >
            <Alert severity={snackbar.severity} sx={{ width: "100%" }}>
              {snackbar.message}
            </Alert>
          </Snackbar>

          <Box sx={{ mt: 1 }}>
            {" "}
            {selectedTab === 0 && (
              <WarrantyReportHistoryTable reportCategory="WarrantyReport" />
            )}
            {selectedTab === 1 && (
              <GenerateWarrantyReport reportCategory="WarrantyReport" />
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
