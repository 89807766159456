import { useState } from "react";
import { Box, AppBar, Container } from "@mui/material";
import Header from "../components/GettingStarted/Header";

import GettingStartedTab from "../components/GettingStarted/GettingStartedTab";
import DistributorTab from "../components/GettingStarted/DistributorTab";
import InstallerTab from "../components/GettingStarted/InstallerTab";
import CustomerTab from "../components/GettingStarted/CustomerTab";
import CompanyTab from "../components/GettingStarted/CompanyTab";
import CustomerIntegrationTab from "../components/GettingStarted/CustomerIntegrationTab";
import TrainingVideosTab from "../components/GettingStarted/TrainingVideosTab";

// GettingStartedPage Component
const GettingStartedPage = () => {
  const [selectedTab, setSelectedTab] = useState<string>("getting-started");

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setSelectedTab(newValue);
  };

  const renderContent = () => {
    switch (selectedTab) {
      case "getting-started":
        return <GettingStartedTab handleTabChange={handleTabChange} />;
      case "distributor-portal":
        return <DistributorTab />;
      case "installer-portal":
        return <InstallerTab />;
      case "customer-portal":
        return <CustomerTab />;
      case "company-portal":
        return <CompanyTab />;
      case "customer-integration":
        return <CustomerIntegrationTab />;
      case "training-videos":
        return <TrainingVideosTab />;
      default:
        return <GettingStartedTab handleTabChange={handleTabChange} />;
    }
  };

  return (
    <Box sx={{ display: "flex", width: "100%" }}>
      <AppBar position="fixed" sx={{ zIndex: 1200 }}>
        <Header selectedTab={selectedTab} handleTabChange={handleTabChange} />
      </AppBar>
      {/* Content Area */}
      <Box sx={{ mt: 16, width: "100%" }}>
        <Container>{renderContent()}</Container>
      </Box>
    </Box>
  );
};

export default GettingStartedPage;
