import React, { useEffect, useMemo } from "react";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  IconButton,
  Tooltip,
  Checkbox,
  Chip,
  SelectChangeEvent,
  TablePagination,
  Snackbar,
  Alert,
} from "@mui/material";
import {
  Search as SearchIcon,
  MoreVert as MoreVertIcon,
  FilterList as FilterListIcon,
  CloudDownload as CloudDownloadIcon,
  ViewComfy as ViewComfyIcon,
  Add as AddIcon,
  People as PeopleIcon,
  Delete as DeleteIcon,
  AddCircle,
} from "@mui/icons-material";
import { ChangeRequest } from "../../common/types/ChangeRequestTypes";
import { fetchChangeRequestsForDistributor } from "../../services/change-request.service";
import useAuthStore from "../../store/authStore";

type Props = {};

function ChangeRequestsTable({}: Props) {
  const [changeRequests, setChangeRequests] = React.useState<ChangeRequest[]>(
    []
  );
  const [selected, setSelected] = React.useState<string[]>([]);
  const [selectAll, setSelectAll] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [search, setSearch] = React.useState("");
  const [sortField, setSortField] = React.useState("");
  const [snackbar, setSnackbar] = React.useState<{
    open: boolean;
    message: string;
    severity: "success" | "error" | "warning" | "info" | undefined;
  }>({ open: false, message: "", severity: undefined });
  const { user } = useAuthStore();

  useEffect(() => {
    const fetchData = async () => {
      if (user?.email) {
        try {
          const result = await fetchChangeRequestsForDistributor(user.email);

          // Check if result is an array, if not set it to an empty array
          setChangeRequests(Array.isArray(result) ? result : []);
        } catch (error) {
          // If there's an error, set changeRequests to an empty array as well
          setChangeRequests([]);
        }
      }
    };
    fetchData();
  }, [user?.email]); // Added user.email as a dependency

  const handleSelect = (name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = [...selected, name];
    } else if (selectedIndex === 0) {
      newSelected = [...selected.slice(1)];
    } else if (selectedIndex === selected.length - 1) {
      newSelected = [...selected.slice(0, -1)];
    } else if (selectedIndex > 0) {
      newSelected = [
        ...selected.slice(0, selectedIndex),
        ...selected.slice(selectedIndex + 1),
      ];
    }

    setSelected(newSelected);
  };

  const sortedAndFilteredRequests = useMemo(() => {
    const searched = changeRequests.filter((request) => {
      return (
        request.type.toLowerCase().includes(search.toLowerCase()) ||
        JSON.stringify(request.details)
          .toLowerCase()
          .includes(search.toLowerCase()) ||
        request.status.toLowerCase().includes(search.toLowerCase())
      );
    });

    return searched.sort((a, b) => {
      if (sortField === "createdAt") {
        return (
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        );
      } else if (sortField === "dateChanged") {
        return (
          new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
        );
      } else if (sortField === "type") {
        return a.type.localeCompare(b.type);
      } else if (sortField === "status") {
        return a.status.localeCompare(b.status);
      }

      return 0;
    });
  }, [changeRequests, sortField, search]);

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = changeRequests.map((n) => n._id);
      setSelected(newSelecteds);
      setSelectAll(true);
      return;
    }
    setSelected([]);
    setSelectAll(false);
  };

  const isSelected = (name: string) => selected.includes(name);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Implement other functions as needed

  const columns = [
    { id: "createdAt", label: "Date Created" },
    { id: "type", label: "Type" },
    { id: "details", label: "Details" },
    { id: "status", label: "Status" },
    { id: "dateChanged", label: "Date Modified" },
  ];

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  return (
    <Box p={0}>
      <TableContainer
        component={Box}
        sx={{
          mx: "auto",
          width: "100%",
          maxWidth: "calc(100% - 20px)",
          p: 1,
          my: 1,
          backgroundColor: "white",
          boxShadow: 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            p: 1.5,
            backgroundColor: "#f5f5f5",
            mb: 2,
          }}
        >
          <Snackbar
            open={snackbar.open}
            autoHideDuration={8000}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            onClose={() => setSnackbar({ ...snackbar, open: false })}
          >
            <Alert severity={snackbar.severity} sx={{ width: "100%" }}>
              {snackbar.message}
            </Alert>
          </Snackbar>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <TextField
              variant="outlined"
              size="small"
              placeholder="Search"
              value={search}
              onChange={handleSearchChange}
              sx={{ marginRight: 2, width: "300px" }}
              InputProps={{
                startAdornment: (
                  <SearchIcon sx={{ marginRight: 1, color: "gray" }} />
                ),
              }}
            />
            <FormControl
              variant="outlined"
              size="small"
              sx={{ marginRight: 2, width: "150px" }}
            >
              <InputLabel>Sort By</InputLabel>
              <Select
                value={sortField}
                onChange={(e) => setSortField(e.target.value as string)}
                label="Sort By"
              >
                <MenuItem value="">None</MenuItem>
                {columns.map((column) => (
                  <MenuItem key={column.id} value={column.id}>
                    {column.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Box>
        {selected.length > 1 && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              alignContent: "center",
              padding: 1,
              backgroundColor: "#f5f5f5",
              px: 2,
            }}
          >
            <Typography>{selected.length} selected</Typography>
            <Tooltip title="Delete Selected">
              <IconButton>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </Box>
        )}

        <Table sx={{ minWidth: 650 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <TableCell>
                <Checkbox
                  indeterminate={
                    selected.length > 0 &&
                    selected.length < changeRequests.length
                  }
                  checked={
                    changeRequests.length > 0 &&
                    selected.length === changeRequests.length
                  }
                  onChange={handleSelectAllClick}
                  inputProps={{ "aria-label": "select all changeRequests" }}
                />
              </TableCell>
              {columns.map((column) => (
                <TableCell key={column.id} align="left">
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedAndFilteredRequests
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((changeRequest) => {
                const isItemSelected = isSelected(changeRequest._id);
                return (
                  <TableRow
                    hover
                    onClick={() => handleSelect(changeRequest._id)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={changeRequest._id}
                    selected={isItemSelected}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox color="primary" checked={isItemSelected} />
                    </TableCell>

                    <TableCell align="left">
                      {new Date(changeRequest.createdAt).toLocaleString()}
                    </TableCell>
                    <TableCell align="left">{changeRequest.type}</TableCell>
                    <TableCell align="left">
                      {JSON.stringify(changeRequest.details)}
                    </TableCell>

                    <TableCell align="left">
                      <Chip
                        label={
                          changeRequest.status === "Approved"
                            ? "Approved"
                            : changeRequest.status === "Pending"
                            ? "Pending"
                            : "Closed"
                        }
                        color={
                          changeRequest.status === "Approved"
                            ? "success"
                            : changeRequest.status === "Pending"
                            ? "warning"
                            : "error"
                        }
                      />
                    </TableCell>

                    <TableCell align="left">
                      {new Date(changeRequest.updatedAt).toLocaleString()}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={sortedAndFilteredRequests.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            mr: 1,
          }}
        />
      </TableContainer>
    </Box>
  );
}

export default ChangeRequestsTable;
