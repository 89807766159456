import React, { useRef, useState, useEffect } from "react";
import { MapContainer, TileLayer, CircleMarker, Popup } from "react-leaflet";
import {
  Paper,
  Typography,
  Divider,
  Box,
  IconButton,
  Tooltip,
} from "@mui/material";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";

type Props = {
  center: { lat: number; lng: number };
  zoom: number;
  height: string;
  markers: Array<{
    id: string;
    position: { lat: number; lng: number };
    name: string;
    status: "ONLINE" | "OFFLINE";
  }>;
};

const MapView: React.FC<Props> = ({ center, zoom, height, markers }) => {
  const mapRef = useRef<HTMLDivElement>(null);
  const mapMarkersRef = useRef<L.Map | null>(null);
  const [isFullscreen, setIsFullscreen] = useState(false);

  useEffect(() => {
    if (mapMarkersRef.current && markers.length > 0) {
      const bounds = L.latLngBounds(markers.map((m) => m.position));
      mapMarkersRef.current.fitBounds(bounds, { padding: [50, 50] });
    }
  }, [markers]);

  const toggleFullscreen = () => {
    if (!mapRef.current) return;

    if (!document.fullscreenElement) {
      mapRef.current.requestFullscreen().catch((err) => {
        alert(
          `Error attempting to enable full-screen mode: ${err.message} (${err.name})`
        );
      });
      setIsFullscreen(true);
    } else {
      document.exitFullscreen();
      setIsFullscreen(false);
    }
  };

  const commonStyles = {
    borderRadius: "8px",
    border: "1px solid #e0e0e0",
    backgroundColor: "background.paper",
    color: "text.primary",
    boxShadow: 1,
  };

  return (
    <Paper
      elevation={0}
      sx={{
        ...commonStyles,
        pb: 1,
        mb: "0.75rem",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box sx={{ flex: 1 }} />
        <Typography
          variant="body1"
          sx={{
            fontWeight: "600",
            fontSize: "14px",
            flex: 1,
            textAlign: "center",
          }}
        >
          Device Locations
        </Typography>
        <Box sx={{ display: "flex", flex: 1, justifyContent: "flex-end" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                width: 12,
                height: 12,
                bgcolor: "green",
                marginRight: 0.5,
                borderRadius: "50%",
              }}
            />
            <Typography variant="caption" sx={{ marginRight: 2 }}>
              Online
            </Typography>
            <Box
              sx={{
                width: 12,
                height: 12,
                bgcolor: "red",
                marginRight: 0.5,
                borderRadius: "50%",
              }}
            />
            <Typography variant="caption">Offline</Typography>
          </Box>
          <Tooltip
            title={isFullscreen ? "Exit Fullscreen" : "Fullscreen"}
            placement="left"
          >
            <IconButton
              onClick={toggleFullscreen}
              sx={{
                backgroundColor: "white",
                "&:hover": { backgroundColor: "#f0f0f0" },
                "& svg": { fontSize: "2rem" },
              }}
            >
              {isFullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
      <Divider sx={{ mb: 2 }} />
      <Box
        ref={mapRef}
        sx={{ height: isFullscreen ? "100vh" : height, width: "100%" }}
      >
        <MapContainer
          center={center}
          zoom={zoom}
          style={{ height: "100%", width: "100%" }}
          ref={mapMarkersRef}
        >
          <TileLayer
            url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://carto.com/attributions">CARTO</a>'
          />
          {markers.map((marker) => (
            <CircleMarker
              key={marker.id}
              center={marker.position}
              color={marker.status === "ONLINE" ? "green" : "red"}
              radius={3}
              fillOpacity={1}
            >
              <Popup>{`${marker.name} - ${marker.status}`}</Popup>
            </CircleMarker>
          ))}
        </MapContainer>
      </Box>
    </Paper>
  );
};

export default MapView;
