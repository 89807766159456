import React, { useEffect, useState } from "react";
import { Box, Snackbar, Alert, AlertColor } from "@mui/material";
import DistributorCard from "../Distributor/DistributorCard";
import { getDistributorByInstallerEmail } from "../../services/distributor.service";
import { Distributor } from "../../store/useDistributor";
import { DeviceData } from "../../common/types/DeviceTypes";

interface DeviceDistributorsProps {
  device: DeviceData;
}

const DeviceDistributors: React.FC<DeviceDistributorsProps> = ({ device }) => {
  const [distributor, setDistributor] = useState<Distributor | null>(null);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success" as AlertColor,
  });

  useEffect(() => {
    const loadDistributor = async () => {
      try {
        const distributorData = await getDistributorByInstallerEmail(
          device.deviceInformation.installerEmail
        );
        if (distributorData) {
          setDistributor(distributorData);
        } else {
          setSnackbar({
            open: true,
            message: "Distributor not found",
            severity: "error",
          });
        }
      } catch (error) {
        setSnackbar({
          open: true,
          message: "Error fetching distributor",
          severity: "error",
        });
      }
    };

    loadDistributor();
  }, [device]);

  return (
    <Box>
      {distributor && <DistributorCard distributor={distributor} />}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert severity={snackbar.severity} sx={{ width: "100%" }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default DeviceDistributors;
