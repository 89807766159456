import { createRoot } from "react-dom/client";
import PDFWindowViewer from "./PDFWindowViewer";

const openPDFWindow = (selectedReport: any) => {
  const newWindow = window.open("", "_blank");

  if (newWindow) {
    // Set up the new window's document
    newWindow.document.title = "Report View";
    newWindow.document.body.innerHTML = `
      <html>
        <head>
          <title>Report View</title>
        </head>
        <body>
          <div id="pdf-container"></div>
        </body>
      </html>
    `;

    const container = newWindow.document.getElementById("pdf-container");

    if (container) {
      // Use createRoot to mount the component in the new window
      const root = createRoot(container);
      root.render(
        <PDFWindowViewer selectedReport={selectedReport} showToolbar={true} />
      );
    }
  }
};

export default openPDFWindow;
