// ReusableStepper.tsx
import React from "react";
import {
  Box,
  Stepper,
  Step,
  StepLabel,
  Paper,
  useMediaQuery,
  useTheme,
  styled,
  StepIconProps,
} from "@mui/material";
import Check from "@mui/icons-material/Check";
import { SxProps, Theme } from "@mui/system";
import StyledButton from "../Tabs/StyledButton";

const QontoConnector = styled(Stepper)(({ theme }) => ({
  "& .MuiStepConnector-line": {
    borderColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

const QontoStepIconRoot = styled("div")<{
  ownerState: { active?: boolean; completed?: boolean };
}>(({ theme, ownerState }) => ({
  color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
  display: "flex",
  height: 22,
  alignItems: "center",
  ...(ownerState.completed && {
    color: "#006400",
  }),
  ...(ownerState.active && {
    color: theme.palette.primary.main,
  }),
  "& .QontoStepIcon-completedIcon": {
    color: "#006400",
    zIndex: 1,
    fontSize: 24,
  },
  "& .QontoStepIcon-circle": {
    width: 15,
    height: 15,
    borderRadius: "50%",
    backgroundColor: "currentColor",
  },
}));

function QontoStepIcon(props: StepIconProps) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ completed, active }} className={className}>
      {completed ? (
        <Check className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  );
}

interface ReusableStepperProps {
  steps: string[];
  activeStep: number;
  handleStepClick: (index: number) => void;
  handleBack: () => void;
  handleNext: () => void;
  handleCancel: () => void;
  disableNext: boolean;
  disableFinish: boolean;
  children: React.ReactNode; // Add the 'children' property
  sxBox?: SxProps<Theme>; // Optional sx prop for Box
  sxPaper?: SxProps<Theme>; // Optional sx prop for Paper
  sxButton?: SxProps<Theme>; // Optional sx prop for Buttons
}

const StepperMenu: React.FC<ReusableStepperProps> = ({
  steps,
  activeStep,
  handleStepClick,
  handleBack,
  handleNext,
  handleCancel,
  disableNext,
  disableFinish,
  children,
  sxBox = {}, // Default to empty object if not provided
  sxPaper = {},
  sxButton = {},
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        mt: isMobile ? 1 : 0,
        flexDirection: "column",
        alignItems: "center",
        ...sxBox,
      }}
    >
      <Paper
        sx={{
          mt: isMobile ? 0 : 5,
          width: isMobile ? "87%" : "60%",
          height: "auto",
          p: 3,
          boxShadow: 0,
          border: "1px solid rgba(0, 0, 0, 0.13)",
          ...sxPaper,
        }}
      >
        <Stepper
          alternativeLabel
          activeStep={activeStep}
          connector={<QontoConnector />}
        >
          {steps.map((label, index) => (
            <Step key={label} onClick={() => handleStepClick(index)}>
              <StepLabel
                StepIconComponent={QontoStepIcon}
                sx={{
                  cursor: "pointer",
                  ...(activeStep === index && {
                    backgroundColor: theme.palette.background.default,
                    color: "white",
                    borderRadius: 1,
                    p: 1,
                  }),
                }}
              >
                {label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
        <Box sx={{ mt: 2 }}>
          {children}
          <Box
            sx={{ display: "flex", justifyContent: "flex-end", mt: 2, gap: 1 }}
          >
            <StyledButton disabled={activeStep === 0} onClick={handleBack}>
              Back
            </StyledButton>
            {activeStep !== steps.length - 1 && (
              <StyledButton
                variantType="primary"
                onClick={handleNext}
                disabled={disableNext}
              >
                Next
              </StyledButton>
            )}
            {activeStep === steps.length - 1 && (
              <StyledButton
                variantType="primary"
                onClick={handleNext}
                disabled={disableFinish}
              >
                Finish
              </StyledButton>
            )}
            <StyledButton variantType="outline-red" onClick={handleCancel}>
              Cancel
            </StyledButton>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default StepperMenu;
