import React from "react";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { Box, Typography } from "@mui/material";

const ProductInstructions: React.FC = () => {
  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    sidebarTabs: () => [], // Hide sidebar
  });

  return (
    <Box sx={{ width: "100%", maxWidth: 1000, mx: "auto", mt: 5 }}>
      <Typography variant="h4" align="center" gutterBottom>
        Product Instructions
      </Typography>
      <Worker workerUrl="https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.11.174/pdf.worker.min.js">
        <div
          style={{
            height: "800px",
            overflow: "auto",
            border: "1px solid rgba(0, 0, 0, 0.3)",
          }}
        >
          <Viewer
            fileUrl="/pdf/iCleanInstructions.pdf"
            plugins={[defaultLayoutPluginInstance]}
            defaultScale={1.5}
            initialPage={0}
            renderPage={(props) => (
              <div>
                {props.canvasLayer.children}
                {props.textLayer.children}
                {props.annotationLayer.children}
              </div>
            )}
          />
        </div>
      </Worker>
    </Box>
  );
};

export default ProductInstructions;
