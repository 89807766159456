import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import { ChangeRequest } from "../../common/types/ChangeRequestTypes";
import { formatJson } from "./ChangeRequestAdminPanel";
import StyledButton from "../Tabs/StyledButton";

interface ChangeRequestDetailsModalProps {
  open: boolean;
  handleClose: () => void;
  changeRequest: ChangeRequest;
  handleApprove: (id: string) => void;
  handleReject: (id: string) => void;
}

const ChangeRequestDetailsModal: React.FC<ChangeRequestDetailsModalProps> = ({
  open,
  handleClose,
  changeRequest,
  handleApprove,
  handleReject,
}) => {
  // Function to format the request type (split camel case)
  const formatRequestType = (type: string) => {
    return type.replace(/([a-z])([A-Z])/g, "$1 $2");
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>{formatRequestType(changeRequest.type)}</DialogTitle>
      <DialogContent>
        <Divider />
        <Box mt={2}>
          <Typography variant="body1" gutterBottom>
            <strong>User:</strong> {changeRequest.userEmail}
          </Typography>
          <Typography variant="body1" gutterBottom>
            <strong>User Role:</strong> {changeRequest.userRole}
          </Typography>
          <Typography variant="body1" gutterBottom>
            <strong>Date Created:</strong>{" "}
            {new Date(changeRequest.createdAt).toLocaleString()}
          </Typography>
        </Box>
        <Divider sx={{ my: 2 }} />
        <Box
          sx={{
            display: "flex",
            p: 0.5,
            pb: 2,
            justifyContent: "center",
            backgroundColor: "#f5f5f5",
            borderRadius: "4px",
            fontFamily: "monospace",
          }}
        >
          <Typography
            variant="body1"
            style={{ whiteSpace: "pre-wrap", fontWeight: 600 }}
          >
            {formatJson(JSON.stringify(changeRequest.details, null, 2))}
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <StyledButton onClick={handleClose}>Close</StyledButton>
        <StyledButton
          onClick={() => handleReject(changeRequest._id)}
          variantType="outline-red"
        >
          Reject
        </StyledButton>
        <StyledButton
          onClick={() => handleApprove(changeRequest._id)}
          variantType="primary"
        >
          Approve
        </StyledButton>
      </DialogActions>
    </Dialog>
  );
};

export default ChangeRequestDetailsModal;
