import React, { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";
import "chart.js/auto";
import dayjs from "dayjs";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import weekOfYear from "dayjs/plugin/weekOfYear";
import {
  Box,
  Typography,
  Divider,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { ICleanDevice } from "../../../common/types/DeviceTypes";
import { ChartData } from "chart.js/auto";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Chart as ChartJS } from "chart.js/auto";

ChartJS.defaults.font.family = `"Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif`;
ChartJS.defaults.font.size = 11;
// ChartJS.defaults.font.weight = 500;
ChartJS.defaults.color = "#282828";
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
dayjs.extend(weekOfYear);

interface iCleanLinesMetricGraphProps {
  interval: "daily" | "weekly" | "monthly";
  selectedDevices: ICleanDevice[];
  dateFilter: Date | null;
  onBarClick: (date: Date) => void;
}

// Mock data structure (you'll replace this with real data)
const mockData = {
  tds: {
    incoming: [400, 420, 380, 410, 430, 400, 390],
    outgoing: [15, 12, 14, 13, 15, 14, 13]
  },
  pressure: {
    incoming: [85, 88, 84, 86, 87, 85, 86],
    outgoing: [28, 30, 27, 29, 30, 28, 29]
  },
  flowRate: {
    incoming: [4.5, 4.4, 4.6, 4.5, 4.4, 4.5, 4.6],
    outgoing: [1.2, 1.1, 1.3, 1.2, 1.1, 1.2, 1.3]
  }
};

const ICleanLinesMetricGraph: React.FC<iCleanLinesMetricGraphProps> = ({
  interval,
  selectedDevices,
  dateFilter,
  onBarClick,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const createChartData = (metricData: { incoming: number[], outgoing: number[] }, labels: string[], isSecondary = false) => ({
    labels,
    datasets: [
      {
        label: "Incoming",
        data: metricData.incoming,
        borderColor: theme.palette.primary.main,
        backgroundColor: 'transparent',
        tension: 0.4,
        borderWidth: isSecondary ? 1 : 2,
        pointRadius: isSecondary ? 2 : 3,
      },
      {
        label: "Outgoing",
        data: metricData.outgoing,
        borderColor: theme.palette.secondary.main,
        backgroundColor: 'transparent',
        tension: 0.4,
        borderDash: isSecondary ? [5, 5] : [], // Dashed line for secondary graphs
        borderWidth: isSecondary ? 1 : 2,
        pointRadius: isSecondary ? 2 : 3,
      },
    ],
  });

  const chartOptions = (yAxisLabel: string, showLegend: boolean = false) => ({
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: showLegend,
        position: 'top' as const,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: yAxisLabel,
        },
      },
      x: {
        grid: {
          display: false,
        },
      },
    },
  });

  return (
    <Box sx={{
      p: 2,
      borderRadius: "8px",
      border: "1px solid #d1d1d1",
      backgroundColor: "background.paper",
      display: 'flex',
      flexDirection: 'column',
      gap: 1,
      height: 'auto',
      minHeight: '380px', 
      overflow: 'hidden' 
    }}>
      <Typography variant="h6" sx={{ textAlign: "center", fontWeight: "700", fontSize: "13px" }}>
        System Metrics
      </Typography>
      
      <Divider />

      {/* TDS Graph */}
      <Box sx={{ height: '100px', mb: 3}}> 
        <Typography variant="subtitle2" sx={{ mb: 0.5 }}>Total Dissolved Solids (TDS)</Typography>
        <Line
          data={createChartData(mockData.tds, ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'])}
          options={chartOptions('PPM')}
        />
      </Box>

      {/* Pressure Graph */}
      <Box sx={{ height: '100px', mb: 3 }}> 
        <Typography variant="subtitle2" sx={{ mb: 0.5 }}>Pressure</Typography>
        <Line
          data={createChartData(mockData.pressure, ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'])}
          options={chartOptions('PSI')}
        />
      </Box>

      {/* Flow Rate Graph */}
      <Box sx={{ height: '100px', mb: 3 }}> 
        <Typography variant="subtitle2" sx={{ mb: 0.5 }}>Flow Rate</Typography>
        <Line
          data={createChartData(mockData.flowRate, ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'])}
          options={chartOptions('GPM')}
        />
      </Box>
    </Box>
  );
};

export default ICleanLinesMetricGraph;
