import React, { useState, useEffect, useCallback, useMemo } from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  Chip,
  Snackbar,
  Tooltip,
  TablePagination,
  TextField,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Alert,
  AlertColor,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import {
  Search as SearchIcon,
  Delete as DeleteIcon,
  Add,
} from "@mui/icons-material";
import {
  fetchInstaller,
  deleteInstaller,
} from "../../services/distributor.service";
import useAuthStore from "../../store/authStore";
import AddInstallerModal from "./CreateInstallerModal";
import StyledButton from "../Tabs/StyledButton";
import StyledIconButton from "../Tabs/StyledIconButton";

const InstallersTable: React.FC = () => {
  const [installers, setInstallers] = useState<any[]>([]);
  const [selected, setSelected] = useState<string[]>([]);
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [modalOpen, setModalOpen] = useState(false); // Add Installer Modal
  const [filterField, setFilterField] = useState<string>("");
  const [filterValue, setFilterValue] = useState<string>("");
  const [sortField, setSortField] = useState<string>("");
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false); // Delete Installer Modal
  const [snackbar, setSnackbar] = useState<{
    open: boolean;
    message: string;
    severity: AlertColor | undefined;
  }>({ open: false, message: "", severity: undefined });

  const { user } = useAuthStore();

  {
    /* Add Installer Modal */
  }
  const handleOpenModal = () => setModalOpen(true);
  const handleCloseModal = () => setModalOpen(false);

  {
    /* Delete Installer Modal */
  }
  const handleOpenDeleteModal = () => setDeleteModalOpen(true);
  const handleCloseDeleteModal = () => setDeleteModalOpen(false);

  const loadInstallers = useCallback(async () => {
    try {
      if (!user) return;
      const installersData = await fetchInstaller(user.email, user.role);
      console.log(installersData);
      setInstallers(installersData);
    } catch (error) {
      setSnackbar({
        open: true,
        message: "Failed to fetch installers",
        severity: "error",
      });
    }
  }, [user]);

  useEffect(() => {
    loadInstallers();
  }, [loadInstallers]);

  const handleSnackbarChange = (snackbarData: {
    open: boolean;
    message: string;
    severity: AlertColor | undefined;
  }) => {
    setSnackbar(snackbarData);
  };

  const handleInstallerCreationSuccess = () => {
    setSnackbar({
      open: true,
      message: "Installer successfully added.",
      severity: "success",
    });
    loadInstallers(); // Refresh the list after adding an installer
    handleCloseModal(); // Close the modal
  };

  const handleSelect = (name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = [...selected, name];
    } else if (selectedIndex === 0) {
      newSelected = [...selected.slice(1)];
    } else if (selectedIndex === selected.length - 1) {
      newSelected = [...selected.slice(0, -1)];
    } else if (selectedIndex > 0) {
      newSelected = [
        ...selected.slice(0, selectedIndex),
        ...selected.slice(selectedIndex + 1),
      ];
    }

    setSelected(newSelected);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = installers.map((n) => n._id);
      setSelected(newSelecteds);
      setSelectAll(true);
      return;
    }
    setSelected([]);
    setSelectAll(false);
  };

  const isSelected = (name: string) => selected.includes(name);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Implement other functions as needed

  const columns = [
    { id: "createdAt", label: "Created Time" },
    { id: "firstName", label: "First Name" },
    { id: "lastName", label: "Last Name" },
    { id: "country", label: "Country" },
    { id: "state", label: "State" },
    { id: "city", label: "City" },
    { id: "address", label: "Address" },
    { id: "phone", label: "Phone" },
    { id: "email", label: "Email" },
    { id: "status", label: "Status" },
  ];

  const handleFilterChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setFilterField(event.target.value as string);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const filteredAndSortedInstallers = useMemo(() => {
    let filteredInstallers = installers;

    // Apply search filter
    if (search) {
      const searchTerm = search.toLowerCase();
      filteredInstallers = filteredInstallers.filter((installer) =>
        Object.values(installer).join(" ").toLowerCase().includes(searchTerm)
      );
    }

    // Apply sorting
    if (sortField) {
      filteredInstallers = [...filteredInstallers].sort((a, b) => {
        const fieldA = a[sortField]?.toString().toLowerCase() ?? "";
        const fieldB = b[sortField]?.toString().toLowerCase() ?? "";

        if (fieldA < fieldB) return -1;
        if (fieldA > fieldB) return 1;
        return 0;
      });
    }

    return filteredInstallers;
  }, [installers, search, sortField]);

  // Step 3: Apply Pagination
  const paginatedInstallers = useMemo(() => {
    return filteredAndSortedInstallers.slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage
    );
  }, [filteredAndSortedInstallers, page, rowsPerPage]);

  const handleDeleteInstaller = async () => {
    try {
      await deleteInstaller(selected[0]);
      setSnackbar({
        open: true,
        message: "Installer deleted successfully",
        severity: "success",
      });
      setSelected([]);
      loadInstallers();
    } catch (error) {
      setSnackbar({
        open: true,
        message: "Failed to delete installer",
        severity: "error",
      });
    }
  };

  return (
    <Box p={0}>
      <Box mt={0} sx={{ display: "flex", justifyContent: "center" }}>
        <Box
          mt={2}
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            width: { xs: "95%", md: "96%" },
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              p: 0,
              mb: 1,
            }}
          >
            <Typography sx={{ ml: 1 }} variant="h6">
              Manage Your Installers
            </Typography>
            <StyledButton
              onClick={handleOpenModal}
              variantType="primary"
              icon={<Add />}
            >
              Add Installer
            </StyledButton>
          </Box>
          <TableContainer
            component={Box}
            sx={{
              mx: "auto",
              width: "100%",
              maxWidth: "calc(100% - 20px)",
              p: 1,
              my: 1,
              backgroundColor: "white",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                p: 1.5,
                backgroundColor: "#f5f5f5",
                mb: 1,
              }}
            >
              <AddInstallerModal
                open={modalOpen}
                onClose={() => setModalOpen(false)}
                onSnackbarChange={handleSnackbarChange}
                onSuccess={handleInstallerCreationSuccess}
              />

              <Snackbar
                open={snackbar.open}
                autoHideDuration={8000}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                onClose={() => setSnackbar({ ...snackbar, open: false })}
              >
                <Alert severity={snackbar.severity} sx={{ width: "100%" }}>
                  {snackbar.message}
                </Alert>
              </Snackbar>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <TextField
                  variant="outlined"
                  size="small"
                  placeholder="Search"
                  value={search}
                  onChange={handleSearchChange}
                  sx={{ marginRight: 2, width: "300px" }}
                  InputProps={{
                    startAdornment: (
                      <SearchIcon sx={{ marginRight: 1, color: "gray" }} />
                    ),
                  }}
                />
                <FormControl
                  variant="outlined"
                  size="small"
                  sx={{ marginRight: 2, width: "150px" }}
                >
                  <InputLabel>Sort By</InputLabel>
                  <Select
                    value={sortField}
                    onChange={(e) => setSortField(e.target.value as string)}
                    label="Sort By"
                  >
                    <MenuItem value="">None</MenuItem>
                    {columns.map((column) => (
                      <MenuItem key={column.id} value={column.id}>
                        {column.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {/* <FormControl
                  variant="outlined"
                  size="small"
                  sx={{ marginRight: 2, width: "150px" }}
                >
                  <InputLabel>Filter By</InputLabel>
                  <Select
                    value={filterField}
                    //onChange={handleFilterChange}
                    label="Filter By"
                  >
                    <MenuItem value="">None</MenuItem>
                    {columns.map((column) => (
                      <MenuItem key={column.id} value={column.id}>
                        {column.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl> */}
                {filterField && (
                  <TextField
                    variant="outlined"
                    size="small"
                    placeholder={`Filter ${filterField}`}
                    value={filterValue}
                    onChange={handleFilterChange}
                    sx={{ marginRight: 2, width: "300px" }}
                  />
                )}
              </Box>
            </Box>
            {selected.length > 0 && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  alignContent: "center",
                  padding: 1,
                  backgroundColor: "#f5f5f5",
                  px: 2,
                }}
              >
                <Typography>{selected.length} selected</Typography>
                <Tooltip title="Delete Selected">
                  <StyledIconButton
                    variantType="transparent"
                    sx={selected.length > 1 ? { display: "none" } : {}}
                    onClick={handleOpenDeleteModal}
                  >
                    <DeleteIcon />
                  </StyledIconButton>
                </Tooltip>
              </Box>
            )}

            <Table sx={{ minWidth: 650 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Checkbox
                      indeterminate={
                        selected.length > 0 &&
                        selected.length < installers.length
                      }
                      checked={
                        installers.length > 0 &&
                        selected.length === installers.length
                      }
                      onChange={handleSelectAllClick}
                      inputProps={{ "aria-label": "select all Installers" }}
                    />
                  </TableCell>
                  {columns.map((column) => (
                    <TableCell key={column.id} align="left">
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedInstallers
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((installer) => {
                    const isItemSelected = isSelected(installer._id);
                    return (
                      <TableRow
                        hover
                        onClick={() => handleSelect(installer._id)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={installer._id}
                        selected={isItemSelected}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox color="primary" checked={isItemSelected} />
                        </TableCell>

                        <TableCell align="left">
                          {new Date(installer.createdAt).toLocaleString()}
                        </TableCell>
                        <TableCell align="left">
                          {installer.firstName}
                        </TableCell>
                        <TableCell align="left">{installer.lastName}</TableCell>
                        <TableCell align="left">{installer.country}</TableCell>
                        <TableCell align="left">{installer.state}</TableCell>
                        <TableCell align="left">{installer.city}</TableCell>
                        <TableCell align="left">{installer.address}</TableCell>
                        <TableCell align="left">{installer.phone}</TableCell>
                        <TableCell align="left">{installer.email}</TableCell>
                        <TableCell align="left">
                          <Chip
                            label={installer.isActive ? "Active" : "Inactive"}
                            color={installer.isActive ? "success" : "error"}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={paginatedInstallers.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              sx={{
                mr: 1,
              }}
            />
          </TableContainer>
        </Box>
      </Box>
      <Dialog open={isDeleteModalOpen} onClose={handleCloseDeleteModal}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this Installer?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <StyledButton onClick={handleCloseDeleteModal}>Cancel</StyledButton>

          <StyledButton
            onClick={() => {
              handleDeleteInstaller();
              handleCloseDeleteModal();
            }}
            variantType="destructive"
          >
            Delete
          </StyledButton>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default InstallersTable;
