import React from "react";
import {
  Box,
  Typography,
  Grid,
  TextField,
  Paper,
  useTheme
} from "@mui/material";
import PhoneIcon from "@mui/icons-material/Phone";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import StyledButton from "../Tabs/StyledButton";
import StyledIconButton from "../Tabs/StyledIconButton";

const ContactUs: React.FC = () => {
  const theme = useTheme();

  return (
    <Box sx={{ width: "100%", maxWidth: 1200, mx: "auto", mt: 5, p: 3 }}>
      <Grid container spacing={3}>
        {/* Left side: Contact Information */}
        <Grid item xs={12} md={6}>
          <Paper elevation={0} sx={{ p: 3, height: 480 }}>
            <Typography variant="h4" gutterBottom>
              Contact Us
            </Typography>

            <Box display="flex" gap={1} alignItems="center" mb={2}>
              <StyledIconButton variantType="transparent" iconColor={theme.palette.secondary.main}>
                <PhoneIcon />
              </StyledIconButton>
              <Box>
                <Typography variant="body1" fontWeight="bold">
                  CALL US
                </Typography>
                <Typography variant="body2">1 (800) 808-1723</Typography>
              </Box>
            </Box>

            <Box display="flex" gap={1} alignItems="center" mb={2}>
              <StyledIconButton variantType="transparent" iconColor={theme.palette.secondary.main}>
                <AccessTimeIcon />
              </StyledIconButton>
              <Box>
                <Typography variant="body1" fontWeight="bold">
                  BUSINESS HOURS
                </Typography>
                <Typography variant="body2">
                  Mon - Fri: 6 am - 5 pm, Sat - Sun: Closed
                </Typography>
              </Box>
            </Box>

            <Box display="flex" gap={1} alignItems="center" mb={2}>
              <StyledIconButton variantType="transparent" iconColor={theme.palette.secondary.main}>
                <LocationOnIcon />
              </StyledIconButton>
              <Box>
                <Typography variant="body1" fontWeight="bold">
                  LOCATION
                </Typography>
                <Typography variant="body2">
                  3440 North Talbot Rd., Oldcastle, ON
                </Typography>
              </Box>
            </Box>

            <Box mt={4}>
              <iframe
                title="Google Map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3023.5023231879183!2d-82.96069888459273!3d42.23742167919651!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x883b29722effc89b%3A0x253fe26f0792cd8d!2sTersano%20Inc.!5e0!3m2!1sen!2sus!4v1696961531380!5m2!1sen!2sus"
                width="100%"
                height="250"
                style={{ border: 0 }}
                allowFullScreen={false}
                loading="lazy"
              />
            </Box>
          </Paper>
        </Grid>

        {/* Right side: Contact Form */}
        <Grid item xs={12} md={6}>
          <Paper elevation={0} sx={{ p: 3, height: 480 }}>
            <Typography variant="h4" gutterBottom sx={{ mb: 5 }}>
              Get In Touch
            </Typography>

            <form>
              <TextField
                fullWidth
                label="Enter your Name"
                variant="outlined"
                margin="normal"
              />
              <TextField
                fullWidth
                label="Enter a valid email address"
                variant="outlined"
                margin="normal"
              />
              <TextField
                fullWidth
                label="Your Message"
                variant="outlined"
                multiline
                rows={6}
                margin="normal"
              />
              <StyledButton fullWidth variantType="primary" size="large">
                Submit
              </StyledButton>
            </form>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ContactUs;
