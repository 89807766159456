import { create } from "zustand";
import { DeviceData } from "../common/types/DeviceTypes";
import { fetchCustomersForUser } from "../services/customer.service";
export interface Customer {
  _id: string;
  firstName: string;
  lastName: string;
  email: string;
  country: string;
  devices: DeviceData[];
  phone: string;
  isActive: boolean;
  isEmailVerified: boolean;
  role: string;
  state: string;
  city: string;
  postalCode: string;
  address: string;
  distributor?: string;
  customerGroup?: string;
  distributorEmail?: string;
  createdAt: string;
  updatedAt: string;
}

interface CustomerState {
  customers: Customer[];
  fetchCustomersForUser: () => Promise<void>;
}

const useCustomerStore = create<CustomerState>((set) => ({
  customers: [],
  fetchCustomersForUser: async () => {
    try {
      const response = await fetchCustomersForUser();
      set({ customers: response });
    } catch (error) {
      console.error("Failed to fetch customers:", error);
    }
  },
}));

export default useCustomerStore;
